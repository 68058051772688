// Uncomment the line below when using script in html ...Neeraj Ojha
// 'use strict';

// EligibilityCriteriaApplication Package
const EligibilityCriteriaApplication = function(eligibilityCriteria, patientData)  { 
/***************************************************************************
Author:       Neeraj Ojha
Organization: EunoChains, Inc.
Date:         June, 2022
License:      All rights reserved by EunoChains Inc.

NOTICE:  All information contained herein is, and remains the property 
of EunoChains, Inc. and its suppliers, if any.  The intellectual and 
technical concepts contained herein are proprietary to EunoChains, Inc. 
and its suppliers and may be covered by U.S. and Foreign Patents, 
patents in process, and are protected by trade secret or copyright law.  
Dissemination of this information or reproduction of this material is 
strictly forbidden unless prior written permission is obtained from 
EunoChains, Inc.
**************************************************************************/
  //----------------------------------------------------------------------
  // Namespace: EunoChains
  //----------------------------------------------------------------------
  let EunoChains = {};
  //----------------------------------------------------------------------
  // EunoChains Framework Abstract Classes
  //----------------------------------------------------------------------
  class Base {
    constructor() { 
      if(!!Base._self) { return Base._self; } Base._self = this;
    }
    toString() { try { return JSON.stringify(this); } catch(e) { return "" }}
    toObject() { try { return JSON.parse(JSON.stringify(this)); } catch(e) { return {} }}
  }
  class Factory extends Base {
    New() { throw Error(this.constructor.name + " - Abstract Function 'New' Not Implemented") }
    Equal() { throw Error(this.constructor.name + " - Abstract Function 'Equal' Not Implemented") }
  }
  class Library extends Base  {
    Import() { throw Error(this.constructor.name + " - Abstract Function 'Import' Not Implemented") }
    Export() { throw Error(this.constructor.name + " - Abstract Function 'Export' Not Implemented") }
  }
  class Values extends Base {
    Import() { throw Error(this.constructor.name + " - Abstract Function 'Import' Not Implemented") }
    Export() { throw Error(this.constructor.name + " - Abstract Function 'Export' Not Implemented") }
  }
  class DataSet extends Base {
    Create() { throw Error(this.constructor.name + " - Abstract Function 'Create' Not Implemented") }
    Read() { throw Error(this.constructor.name + " - Abstract Function 'Read' Not Implemented") }
    Update() { throw Error(this.constructor.name + " - Abstract Function 'Update' Not Implemented") }
    Delete() { throw Error(this.constructor.name + " - Abstract Function 'Delete' Not Implemented") }
    Import() { throw Error(this.constructor.name + " - Abstract Function 'Import' Not Implemented") }
    Export() { throw Error(this.constructor.name + " - Abstract Function 'Export' Not Implemented") }
  }
  
  EunoChains.Framework = {
    Base,
    Factory,
    Library,
    Values,
    DataSet
  };
  //----------------------------------------------------------------------
  // EunoChains Application Data
  //----------------------------------------------------------------------
  const FHIRResourceType = {
    Bundle: "Bundle",
    Patient: "Patient",
    Organization: "Organization",
    Observation: "Observation",
    Condition: "Condition",
    EvidenceVariable: "EvidenceVariable",
    CodeSystem: "CodeSystem"
  }
  //----------------------------------------------------------------------
  // EunoChains Application Classes
  //----------------------------------------------------------------------

  class Environment  {
    Defined(x) { return  Object.prototype.toString.call(x) !== '[object Undefined]'; }
    Undefined(x) { return Object.prototype.toString.call(x) === '[object Undefined]'; }
    Null(x) { return Object.prototype.toString.call(x) === '[object Null]' }
    NotNull(x) { return Object.prototype.toString.call(x) === '[object Null]' }
    Object(x) { return Object.prototype.toString.call(x) === '[object Object]' }
    ValidObject(x) { return (Object.prototype.toString.call(x) === '[object Object]' && Object.keys(x).length > 0 ) }
    Array(x) { return Object.prototype.toString.call(x) === '[object Array]' }
    ValidArray(x) { return (Object.prototype.toString.call(x) === '[object Array]' && x.length > 0 ) }
    String(x) { return Object.prototype.toString.call(x) === '[object String]' }
    ValidString(x) { return (Object.prototype.toString.call(x) === '[object String]' && x.length > 0) }
    Boolean(x) { return Object.prototype.toString.call(x) === '[object Boolean]' }
    False(x) { return (Object.prototype.toString.call(x) === '[object Boolean]' && x === false) }
    True(x) { return (Object.prototype.toString.call(x) === '[object Boolean]' && x === true) }
    Number(x) { return Object.prototype.toString.call(x) === '[object Number]' }
    Zero(x) { return (Object.prototype.toString.call(x) === '[object Number]' && x === 0) }
    PositiveNumber(x) { return (Object.prototype.toString.call(x) === '[object Number]' && x > 0) }
    NegativeNumber(x) { return (Object.prototype.toString.call(x) === '[object Number]' && x < 0) }
    DateString(x) { return (Object.prototype.toString.call(x) === '[object String]' && !isNaN(Date.parse(x)))}
    Date(x) { return Object.prototype.toString.call(x) === '[object Date]' }
    ValidCodeableConcept(x) { return (this.ValidObject(x) && this.ValidArray(x.coding)) }
    ValidCoding(x) { return ( this.ValidObject(x) && this.ValidString(x.system) && this.ValidString(x.code) )} 
    ValidExtension(x) { return (this.ValidObject(x) && this.ValidString(x.url) && this.ValidObject(x.valueCoding))}
    ValidQuantity(x) { return ( this.ValidObject(x) && this.Number(x.value) && 
                              ((this.ValidString(x.system) && this.ValidString(x.code)) || this.ValidString(x.unit)))}
    ValidRange(x) { return ( this.ValidObject(x) && this.ValidQuantity(x.high) && this.ValidQuantity(x.low))}
    EvidenceVariable(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                  x.resourceType === FHIRResourceType.EvidenceVariable)}
    Patient(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.Patient)}
    Bundle(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.Bundle)}
    Observation(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.Observation)}
    Condition(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.Condition)}
    Organization(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.Organization)}
    CodeSystem(x) { return (this.ValidObject(x) && this.ValidString(x.resourceType) &&
                                    x.resourceType === FHIRResourceType.CodeSystem)}
  }
  
  const Is = new Environment();
  Object.freeze(Is);
  class Random  {
    Generate() {
      return Math.random().toString(16).slice(2).padStart(32,'0');
    }
  }
  const ID = new Random();
  Object.freeze(ID);

  class Logger  {
    Log(msg) {
      try { console.log(msg);} 
      catch(e) { console.log('Hlog(Error): Incompatible console.log message type'); }
    }
  }
  const Console = new Logger();
  Object.freeze(Console);
  

  class EunoChainsCodings {
    constructor() {
      Object.assign(this, {
        "Defined": {
          "id": "Defined",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "defined",
          "display": "Defined"
        },
        "Undefined": {
          "id": "Undefined",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "undefined",
          "display": "Undefined"
        },
        "OK": {
          "id": "OK",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "ok",
          "display": "OK"
        },
        "Error": {
          "id": "Error",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error",
          "display": "Error"
        },
        "True": {
          "id": "True",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "true",
          "display": "True"
        },
        "False": {
          "id": "False",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "false",
          "display": "False"
        },
        "Valid": {
          "id": "Valid",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "valid",
          "display": "Valid"
        },
        "Invalid": {
          "id": "Invalid",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "invalid",
          "display": "InValid"
        },
        "Found": {
          "id": "Found",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "found",
          "display": "Found"
        },
        "NotFound": {
          "id": "NotFound",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "notfound",
          "display": "NotFound"
        },
        "Function.Error.IncorrectParameters": {
          "id": "Function.Error.IncorrectParameters",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_function_incorrect_parameters",
          "display": "(Rule-F01 Violation): Function Called with Incorrect Parameters"
        },
        "Function.Error.InvalidParameters": {
          "id": "Function.Error.InvalidParameters",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_function_invalid_parameters",
          "display": "(Rule-F02 Violation): Function Called with Invalid Parameters"
        },
        "EligibilityCriteria.Result.Match": {
          "id": "EligibilityCriteria.Result.Match",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_eligibility_criteria_match",
          "display": "Eligibility Criteria Matches with the Patient Data"
        },
        "EligibilityCriteria.Result.NoMatch": {
          "id": "EligibilityCriteria.Result.NoMatch",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_eligibility_criteria_no_match",
          "display": "Eligibility Criteria Does Not Match with the Patient Data"
        },
        "EligibilityCriteria.Result.PartialMatch": {
          "id": "EligibilityCriteria.Result.PartialMatch",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_eligibility_criteria_partialmatch",
          "display": "Eligibility Criteria Match with the Patient Data is Partial"
        },
        "EligibilityCriteria.Result.Undetermined": {
          "id": "EligibilityCriteria.Result.Undetermined",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_eligibility_criteria_undetermined",
          "display": "Eligibility Criteria Match with the Patient Data is Undetermined"
        },
        "EligibilityCriteria.Error.NotAFHIRResource": {
          "id": "EligibilityCriteria.Error.NotAFHIRResource",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_not_a_fhir_resource",
          "display": "(Rule-EC01 Violation): Eligibility Criteria expressions are not encoded within a FHIR EvidenceVariable Resource."
        },
        "EligibilityCriteria.Error.InvalidExtension": {
          "id": "EligibilityCriteria.Error.InvalidExtension",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_invalid_extension",
          "display": "(Rule-EC02 Violation): FHIR EvidenceVariable Resource has Invalid Extension Element."
        },
        "EligibilityCriteria.Error.NoStatus": {
          "id": "EligibilityCriteria.Error.NoStatus",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_no_status",
          "display": "(Rule-EC03 Violation): FHIR EvidenceVariable Resource mandatory status element is Missing."
        },
        "EligibilityCriteria.Error.InvalidStatus": {
          "id": "EligibilityCriteria.Error.InvalidStatus",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_invalid_status",
          "display": "(Rule-EC04 Violation): FHIR EvidenceVariable Resource mandatory status field element is Invalid."
        },
        "EligibilityCriteria.Error.NoCharacteristic": {
          "id": "EligibilityCriteria.Error.NoCharacteristic",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_no_characteristic",
          "display": "(Rule-EC05 Violation): Eligibility Criteria expressions have no Eligibility Characteristics defined."
        },
        "EligibilityCriteria.Error.InvalidCharacteristic": {
          "id": "EligibilityCriteria.Error.InvalidCharacteristic",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_eligibility_criteria_invalid_characteristic",
          "display": "(Rule-EC06 Violation): Eligibility Criteria expressions have Invalid Eligibility Characteristic definition."
        },
        "PatientData.Error.NotAFHIRResource": {
          "id": "PatientData.Error.NotAFHIRResource",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_not_a_fhir_resource",
          "display": "(Rule-PD01 Violation): Patient Data is not a FHIR Bundle Resource"
        },
        "PatientData.Error.NoType": {
          "id": "PatientData.Error.NoType",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_no_type",
          "display": "(Rule-PD02 Violation): Patient Data Bundle has no mandatory type element defined"
        },
        "PatientData.Error.InvalidType": {
          "id": "PatientData.Error.InvalidType",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_invalid_type",
          "display": "(Rule-PD03 Violation): Patient Data Bundle type element is not set to 'collection'"
        },
        "PatientData.Error.NoEntry": {
          "id": "PatientData.Error.NoEntry",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_no_entry",
          "display": "(Rule-PD04 Violation): Patient Data Bundle has No 'entry' element"
        },
        "PatientData.Error.InvalidEntry": {
          "id": "PatientData.Error.InvalidEntry",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_invalid_entry",
          "display": "(Rule-PD05 Violation): Patient Data has Invalid 'entry' element"
        },
        "PatientData.Error.NoData": {
          "id": "PatientData.Error.NoData",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_patient_data_zero_entries",
          "display": "(Rule-PD07 Violation): Patient Data has No 'entry' element members"
        },
        "Characteristic.Result.Match": {
          "id": "Characteristic.Result.Match",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_characteristic_match",
          "display": "Characteristic Matches with the Patient Data"
        },
        "Characteristic.Result.NoMatch": {
          "id": "Characteristic.Result.NoMatch",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_characteristic_no_match",
          "display": "Characteristic Does Not Match with the Patient Data"
        },
        "Characteristic.Result.PartialMatch": {
          "id": "Characteristic.Result.PartialMatch",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_characteristic_partialmatch",
          "display": "Characteristic Match with the Patient Data is Partially Matched"
        },
        "Characteristic.Result.Undetermined": {
          "id": "Characteristic.Result.Undetermined",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_characteristic_undetermined",
          "display": "Characteristic Match with the Patient Data is UnDetermined"
        },
        "Characteristic.Result.Unrecognized": {
          "id": "Characteristic.Result.Unrecognized",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "result_characteristic_unrecognized",
          "display": "Characteristic Definition is Unrecognized"
        },
        "Characteristic.Error.InvalidFormat": {
          "id": "Characteristic.Error.InvalidFormat",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_characteristic_invalid_format",
          "display": "(Rule-CH01 Violation): Characteristic not encoded properly"
        },
        "Characteristic.Error.InvalidExtension": {
          "id": "Characteristic.Error.InvalidExtension",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_characteristic_invalid_extension",
          "display": "(Rule-CH02 Violation): Characteristic has an invalid 'extension' element"
        },
        "Characteristic.Error.TypeAndValue.NoType": {
          "id": "Characteristic.Error.TypeAndValue.NoType",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_characteristic_definition_typeandvalue_no_type",
          "display": "(Rule-DTV01 Violation): Characteristic Definition TypeAndValue has No 'type' Element"
        },
        "Characteristic.Error.TypeAndValue.InvalidType": {
          "id": "Characteristic.Error.TypeAndValue.InvalidType",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_characteristic_definition_typeandvalue_invalid_type",
          "display": "(Rule-DTV02 Violation): Characteristic Definition TypeAndValue has Invalid 'type' Element"
        },
        "Characteristic.Error.TypeAndValue.UnRecognizedType": {
          "id": "Characteristic.Error.TypeAndValue.UnRecognizedType",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_typeandvalue_unrecognized_type",
          "display": "(Rule-DTV03 Violation): Characteristic Definition TypeAndValue has Unrecognized 'type' Element"
        },
        "Characteristic.Error.TypeAndValue.NoValue": {
          "id": "Characteristic.Error.TypeAndValue.NoValue",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_typeandvalue_no_value",
          "display": "(Rule-DTV04 Violation): Characteristic Definition TypeAndValue has No 'value' Element"
        },
        "Characteristic.Error.TypeAndValue.InvalidValue": {
          "id": "Characteristic.Error.TypeAndValue.InvalidValue",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_typeandvalue_invalid_value",
          "display": "(Rule-DTV05 Violation): Characteristic Definition TypeAndValue has Invalid 'value' Element"
        },
        "Characteristic.Error.Combination.NoCode": {
          "id": "Characteristic.Error.Combination.NoCode",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_no_code",
          "display": "(Rule-DC01 Violation): Characteristic Definition Combination has No mandatory 'code' Element"
        },
        "Characteristic.Error.Combination.InvalidCode": {
          "id": "Characteristic.Error.Combination.InvalidCode",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_invalid_code",
          "display": "(Rule-DC02 Violation): Characteristic Definition Combination has Invalid 'code' Element"
        },
        "Characteristic.Error.Combination.UnRecognizedCode": {
          "id": "Characteristic.Error.Combination.UnRecognizedCode",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_unrecognized_code",
          "display": "(Rule-DC02 Violation): Characteristic Definition Combination has Unrecognized 'code' Element"
        },
        "Characteristic.Error.Combination.NoCharacteristic": {
          "id": "Characteristic.Error.Combination.NoCharacteristic",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_no_characteristic",
          "display": "(Rule-DC03 Violation): Characteristic Definition Combination No 'characteristic' Elements"
        },
        "Characteristic.Error.Combination.InvalidCharacteristic": {
          "id": "Characteristic.Error.Combination.InvalidCharacteristic",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_invalid_characteristic",
          "display": "(Rule-DC04 Violation): Characteristic Definition Combination has Invalid 'characteristic' Elements"
        },
        "Characteristic.Error.Combination.NoThreshold": {
          "id": "Characteristic.Error.Combination.NoThreshold",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_no_threshold",
          "display": "(Rule-DC05 Violation): Characteristic Definition Combination has No 'threshold' Element"
        },
        "Characteristic.Error.Combination.InvalidThreshold": {
          "id": "Characteristic.Error.Combination.InvalidThreshold",
          "system": "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
          "code": "error_definition_combination_has_invalid_threshold",
          "display": "(Rule-DC06 Violation): Characteristic Definition Combination has Invalid 'threshold' Element"
        }
      });
    }
  }
  const Results = new EunoChainsCodings();
  Object.freeze(Results);

  class Identifiers  {
    constructor () {
     
      this["SNOMEDCT"] = "http://snomed.info/sct";
      this["LOINC"] = "http://loinc.org";
      this["UOM"] = "http://unitsofmeasure.org";
      this["FHIR"] = "http://hl7.org/fhir/resource-types";
      this["OMB"] = "urn:oid:2.16.840.1.113883.6.238";
      this["EunoChainsCodeSet"] = "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992";
      this["USCoreRaceExtension"] = "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race";
      this["OMBCategoryExtension"] = "ombCategory";
      this["EunoChainsExtension"] = "http://www.eunochains.com/fhir/Extension/68e980d41a3effd2ac65d1c39d4617948213220f6886de7d5bbd2e3c5e4063fd";
      this["ConditionClinicalStatus"] = "http://terminology.hl7.org/CodeSystem/condition-clinical";
      this["ConditionVerificationStatus"] = "http://terminology.hl7.org/CodeSystem/condition-ver-status";
    }
    Create(key, value) {
      if(!key || !Is.ValidString(key) || !value || !Is.ValidString(value)) { return false; }
      if(!!Is.Defined(this[key])) { return false; }
      this[key] = value;
      return true;
    }
    Read(key) {
      if(!key || !Is.ValidString(key)) { return undefined; }
      return this[key];
    }
    Update(key, value) {
      if(!key || !Is.ValidString(key) || !value || !Is.ValidString(value)) { return false; }
      if(!!Is.Undefined(this[key])) { return false; }
      this[key] = value;
      return true;
    }
    Delete(key) {
      if(!key || !Is.ValidString(key)) { return false; }
      if(!!Is.Undefined(this[key])) { return false; }
      delete this[key];
      return true;
    }
  }

  const URI = new Identifiers();
  Object.freeze(URI);

  class ValueCodingFactory  {
    New(reference) {
      if(!Is.ValidCoding(reference)) {return {}}
      return Object.assign({id: ID.Generate()}, reference);
    }
    Equal(coding1, coding2) {
      if(!Is.ValidCoding(coding1) || !Is.ValidCoding(coding2)) {return false;}
      return (coding1.system === coding2.system &&
              coding1.code === coding2.code)
    }
  }

  const ValueCoding = new ValueCodingFactory();
  Object.freeze(ValueCoding);

  class ExtensionFactory  {
    New(reference) {
      if(!Is.ValidCoding(reference)) {return {}}
      let vc = ValueCoding.New(reference);
      return Object.assign({}, {id: ID.Generate(), url: URI.EunoChainsExtension, valueCoding:vc});
    }
    Equal(extension1, extension2) {
      if(!Is.ValidExtension(extension1) || !Is.ValidExtension(extension2)) {return false}
      return (extension1.url === extension2.url && 
              ValueCoding.Equal(extension1.valueCoding, extension2.valueCoding ))
    }
  }
  const Extension = new ExtensionFactory();
  Object.freeze(Extension);
  

  
  
    // FHIR Resources 
  const Organization = Object.freeze({
    resourceType: "Organization",
    name: "EunoChains Inc.",
    identifier: [{ use: "official", system: "www.godaddy.com", value: "www.eunochains.com" },
                 { use: "official", system: "http://www.eunochains.com/fhir/identifiers/Organization", value:"d7b0557fcf208276517d1fee3f08ce435bc311f1a32308dddcc2d50dac0e1d9e"}],
    type: [{coding:[{system:"http://hl7.org/fhir/ValueSet/organization-type", code:"bus", display:"Non-Healthcare Business or Corporation"}], text: "Health IT Infrastructure Provider"}],
    active: true,
    description: "Decentralized Collaborative Health IT Applications Infrastructure Provider",
    contact: [{name: {text: "Neeraj Ojha"}, telecom:[ {system:"email", value:"nojha@eunochains.com"}] }]
  });
  const EunoChainsCodeSystem = Object.freeze({
    resourceType: "CodeSystem",
    name: "EunoChainsApplicationsCodeSystem",
    identifier: [{ system: "http://www.eunochains.com/fhir/identifiers/CodeSystem", value: "714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992" }],
    url: "http://www.eunochains.com/fhir/identifiers/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992",
    meta: { profile : ["http://hl7.org/fhir/StructureDefinition/shareablecodesystem"], lastUpdated: "07-22-2022" },
    date: "2022-07-22", title: "EunoChains Application(s) CodeSystem", version: "20220722", description: "EunoChains Applications CodeSystem",
    status: "draft", experimental: false, publisher: "EunoChains Inc.",
    contact: [{name: "Neeraj Ojha", telecom:[ {system:"email", value:"nojha@eunochains.com"}] }],
    text: { status : "generated", 
      div : "<div xmlns=\"http://www.w3.org/1999/xhtml\">\n     <p>EunoChains Applications CodeSystem</p> <p>Developed by: EunoChains team</p>\n      <p>Published for alpha use on 22-Jul 2022</p>\n</div>"
    },
    description: "This is a code system for EunoChains Inc.'s Application(s)",
    topic: [{coding:[{system:"http://hl7.org/fhir/ValueSet/definition-topic", code:"assessment", display:"Assessment"}]}],
    caseSensitive: true, content: "complete",
    concept: [
      {code: "ok", display: "OK"},
      {code: "error", display: "Error"},
      {code: "true", display: "True"},
      {code: "false", display: "False"},
      {code: "pass", display: "Pass"},
      {code: "fail", display: "Fail"},
      {code: "undetermined", display: "Undetermined"},
      {code: "match", display: "Match"},
      {code: "mismatch", display: "MisMatch"},
      {code: "same", display: "Same"},
      {code: "different", display: "Different"},
      {code: "like", display: "Like"},
      {code: "unlike", display: "Unlike"},
      {code: "valid", display: "Valid"},
      {code: "invalid", display: "InValid"},
      {code: "processable", display: "Processable"},
      {code: "unprocessable", display: "UnProcessable"},
      {code: "compatiple", display: "Compatible"},
      {code: "uncompatible", display: "UnCompatible"},
      {code: "compliant", display: "Compliant"},
      {code: "noncompliant", display: "NonCompliant"},
      {code: "structured", display: "Structured"},
      {code: "unstructured", display: "UnStructured"},
      {code: "found", display: "Found"},
      {code: "notfound", display: "NotFound"},
      {code: "defined", display: "Defined"},
      {code: "undefined", display: "UnDefined"},
      {code: "null", display: "Null"},
      {code: "notnull", display: "NotNull"},
      {code: "empty", display: "Empty"},
      {code: "notempty", display: "NotEmpty"},
      {code: "identified", display: "Identified"},
      {code: "unidentified", display: "UnIdentified"},
      {code: "deidentified", display: "DeIdentified"},
      {code: "mutable", display: "Mutable"},
      {code: "nonmutable", display: "NonMutable"},
      {code: "readable", display: "Readable"},
      {code: "nonreadable", display: "NonReadable"},
      {code: "writeable", display: "Writeable"},
      {code: "nonwriteable", display: "NonWriteable"},
      {code: "executable", display: "Executable"},
      {code: "nonexecutable", display: "NonExecutable"},
      {code: "computable", display: "Computable"},
      {code: "noncomputable", display: "NonComputable"},
      {code: "mandatory", display: "Mandatory"},
      {code: "nonmandatory", display: "NonMandatory"},
      {code: "ignorable", display: "Ignorable"},
      {code: "nonignorable", display: "NonIgnorable"},
      {code: "variant", display: "Variant"},
      {code: "invariant", display: "InVariant"}
    ]
  });
    


  //----------------------------------------------------------------------
  // Processing Helpers
  //----------------------------------------------------------------------
  
  const IsCharacteristicSetValid = function(chset){
    if(!chset || !Is.ValidArray(chset)) { return false; }
    for(const chelem of chset) {
      if(!chelem || !Is.Object(chelem)) { return false; }
      if( !chelem.definitionReference &&
          !chelem.definitionCanonical &&
          !chelem.definitionCodeableConcept &&
          !chelem.definitionExpression &&
          !chelem.definitionId &&
          !chelem.definitionByTypeAndValue &&
          !chelem.definitionByCombination &&
          !chelem.description) {
          return false;
      }
    }
    return true;
  }
  
  const IsPatientDataSetValid = function(pdset) {
    if(!pdset || !Is.ValidArray(pdset)){  return false; }
    for(const pdelem of pdset) {
      if(!pdelem || !Is.Object(pdelem) || !pdelem.resource
         || !Is.Object(pdelem.resource) || !pdelem.resource.resourceType ) {
        return false;
      }
    }
    return true;
  }
  
  const ProcessCharacteristic = function(characteristic, data){
    if(!characteristic || !Is.Object(characteristic) || 
        !data || !Is.Object(data)) {
      return;
    } 
    if(!characteristic.extension) { characteristic.extension = [];}
    
    if(!!characteristic.definitionByTypeAndValue){
      return ProcessDefinitionTypeAndValue(characteristic, data);
    }
    if(!!characteristic.definitionByCombination){
      return ProcessDefinitionCombination(characteristic, data);
    }
    let extension = Extension.New(Results["Characteristic.Result.Unrecognized"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessDefinitionTypeAndValue = function(characteristic, data){
    if(!characteristic || !data) { return }
    let definition = characteristic.definitionByTypeAndValue;
    if(!definition.type) {
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.NoType"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    if(!Is.ValidCodeableConcept(definition.type)) {
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidType"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    if( !definition.valueCodeableConcept	&& 
        !definition.valueBoolean && 
        !definition.valueQuantity && 
        !definition.valueRange &&
        !definition.valueReference &&
        !definition.valueId) {
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.NoValue"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let ageTypeCoding = [{system:URI.SNOMEDCT, code:"397669002"}, {system: URI.SNOMEDCT, code: "424144002"}];
    let raceTypeCoding = [{system:URI.SNOMEDCT, code:"103579009"}];
    let conditionTypeCoding = [{system:URI.SNOMEDCT, code:"64572001"}, {system:URI.FHIR, code:"Condition"}];
    let observationTypeCoding = [{system:URI.LOINC, code:"any"}];
    for(const elem of definition.type.coding) {
      for(const agecode of ageTypeCoding) {
        if(elem.system === agecode.system && elem.code === agecode.code){
          return ProcessAgeCriteria(characteristic, data);
        }
      }
      for( const racecode of raceTypeCoding) {
        if(elem.system === racecode.system && elem.code === racecode.code){
          return ProcessRaceCriteria(characteristic, data);
        }
      }
      for( const conditioncode of conditionTypeCoding){
        if(elem.system === conditioncode.system && elem.code === conditioncode.code){
          return ProcessConditionCriteria(characteristic, data);
        }
      }
      for(const observationcode of observationTypeCoding){
        if(elem.system === observationcode.system){
          return ProcessObservationCriteria(characteristic, data);
        }
      }
    }
    let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.UnRecognizedType"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessDefinitionCombination = function(characteristic, data){
    if(!characteristic || !data) { return }
    let definition = characteristic.definitionByCombination;
    if(!definition.code){
      let extension = Extension.New(Results["Characteristic.Error.Combination.NoCode"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let codeList = ["all-of", "any-of", "at-least", "at-most", "statistical", "net-effect", "dataset"];
    if(!Is.ValidString(definition.code) || !codeList.includes(definition.code)){
      let extension = Extension.New(Results["Characteristic.Error.Combination.InvalidCode"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let recognizedList = ["all-of", "any-of", "at-least", "at-most"];
    if(!recognizedList.includes(definition.code)){
      let extension = Extension.New(Results["Characteristic.Error.Combination.UnRecognizedCode"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    if(!definition.characteristic || !Is.ValidArray(definition.characteristic)){
      let extension = Extension.New(Results["Characteristic.Error.Combination.NoCharacteristic"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    } 
    if(! IsCharacteristicSetValid(definition.characteristic)){
      let extension = Extension.New(Results["Characteristic.Error.Combination.InvalidCharacteristic"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let thresholdList = ["at-least", "at-most"];
    if(!!thresholdList.includes(definition.code)){
      if(!definition.threshold){
        let extension = Extension.New(Results["Characteristic.Error.Combination.NoThreshold"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(!Is.Number(definition.threshold) || definition.threshold <= 0 ){
        let extension = Extension.New(Results["Characteristic.Error.Combination.InvalidThreshold"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
    }
    for(const ch of definition.characteristic){
      ProcessCharacteristic(ch, data)
    }
    let match = 0;
    let nomatch = 0;
    let other = 0;
    let total = definition.characteristic.length;
    for( const ch of definition.characteristic ) {
      let chResult = ch.extension[ch.extension.length - 1];
      if(!Is.ValidExtension(chResult)){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(!Is.ValidCoding(chResult.valueCoding) ) {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(!!ValueCoding.Equal(chResult.valueCoding, Results["Characteristic.Result.Match"])){
        if(!ch.exclude) { match = match + 1;  continue; }
        else {nomatch = nomatch + 1; continue; }
      }
      if(!!ValueCoding.Equal(chResult.valueCoding, Results["Characteristic.Result.NoMatch"])){
        if(!!ch.exclude) { match = match + 1;  continue; }
        else  {nomatch = nomatch + 1; continue; }
      }
      other = other + 1;
    }
    switch(definition.code){
      case 'all-of': 
        if(match === total) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if(nomatch > 0) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        break;
      case 'any-of':  
        if(match > 0) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        } 
        if(nomatch === total) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        } 
        break;
      case "at-most":
        // BAcomment: is it better to define Match if (match + other) < threshold
        if(match <= definition.threshold) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        } 
        // BAcomment: is this next part correct?  Shouldn't it be (match > definition.threshold) Is a break needed later (I added it)?
        if(nomatch === total) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        break;
      case "at-least":
        if(match >= definition.threshold) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        } 
        if( (match + other) < definition.threshold ) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        break;
      default:
        break;
    }
    let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessAgeCriteria = function(characteristic, data){
    
    if(!characteristic || !data) {return}
    const AgeHelpers = {
      AgeInMonths: function(bdaystr, todaystr) {
        if(!bdaystr || typeof bdaystr !== 'string') { return null; }
        if(!bdaystr || typeof bdaystr !== 'string') { return null; }
        let ref = new Date(bdaystr);
        let today = new Date(todaystr);
        let temp = (today.getFullYear() - ref.getFullYear())*12;
        return (temp + (today.getMonth() - ref.getMonth()));
      },
      AgeInYears: function(bdaystr, todaystr) {
        if(!bdaystr || typeof bdaystr !== 'string') { return null; }
        if(!bdaystr || typeof bdaystr !== 'string') { return null; }
        let ref = new Date(bdaystr);
        let today = new Date(todaystr);
        let temp = (today.getFullYear() - ref.getFullYear())*12;
        let ageinmonths = (temp + (today.getMonth() - ref.getMonth()));
        return Math.floor(ageinmonths/12);
      }
    }

    let definition = characteristic.definitionByTypeAndValue;
    if(!definition.valueQuantity && !definition.valueRange){
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    

    let patientResources = data["Patient"];
    if(!patientResources || !Is.ValidArray(patientResources) ){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
     
    let patient = patientResources[0];
    if(!patient.birthDate || !Is.DateString(patient.birthDate)){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    
    let birthdate = patient.birthDate;
     
    let today = new Date().toString();
    let ageinmths = AgeHelpers.AgeInMonths(birthdate,today);
    let ageinyrs = AgeHelpers.AgeInYears(birthdate,today);
    let monthTexts = ["months", "month", "mon", "mo", "mos", "mm", "m"]; 
    let yearTexts = ["years", "year", "yr", "yrs", "yy", "y", "yyyy"]; 
    
    if(!!definition.valueQuantity){
      
      let element = definition.valueQuantity;
      if(!Is.Number(element.value)){
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let age = undefined;
      if(yearTexts.includes(element.unit.toLowerCase()) ||
          (element.system === URI["UOM"] && element.code === "a")){
        age = ageinyrs;
      }
      if(monthTexts.includes (element.unit.toLowerCase()) ||
           (element.system === URI["UOM"] && element.code === "mo")){
          age = ageinmths;
      }
      
      if(!!Is.Undefined(age)){
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
     
      if(!element.comparator){
        if(age === definition.valueQuantity.value){
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      
      switch(element.comparator){
        case '>': 
          if(age > definition.valueQuantity.value){
            let extension = Extension.New(Results["Characteristic.Result.Match"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          } else {
            let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
          }
        case '>=':
          if(age >= definition.valueQuantity.value){
            let extension = Extension.New(Results["Characteristic.Result.Match"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          } else {
            let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          }
        case '<':
          if(age < definition.valueQuantity.value){
            let extension = Extension.New(Results["Characteristic.Result.Match"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          } else {
            let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          }
        case '<=':
          if(age <= definition.valueQuantity.value){
            let extension = Extension.New(Results["Characteristic.Result.Match"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          } else {
            let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
            return;
          }
        default:
          let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
      }
    }
    if(!!definition.valueRange){
      
      let element = definition.valueRange;
      if(!Is.ValidRange(definition.valueRange)) {
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let age = undefined;
      if(yearTexts.includes(element.low.unit.toLowerCase()) ||
          (element.low.system === URI["UOM"] && element.low.code === "a")){
        age = ageinyrs;
      }
      if(monthTexts.includes (element.low.unit.toLowerCase()) ||
           (element.low.system === URI["UOM"] && element.low.code === "mo")){
          age = ageinmths;
      }
      if(!!Is.Undefined(age)){
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(age >= element.low.value && age <= element.high.value){
        let extension = Extension.New(Results["Characteristic.Result.Match"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      } else {
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
    }
    let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessRaceCriteria = function(characteristic, data){
    if(!characteristic || !data) {return;}
    let definition = characteristic.definitionByTypeAndValue;
    if(!definition.valueCodeableConcept || !Is.ValidCodeableConcept(definition.valueCodeableConcept) ){
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    } 
    let definitionCoding = definition.valueCodeableConcept.coding;
    
    let patientResources = data["Patient"];
    if(!patientResources || !Is.ValidArray(patientResources) ){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let patient = patientResources[0];
    if(!patient.extension && !Is.ValidArray(patient.extension)){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    
    for(const ext of patient.extension){
      if(!!ext && !!ext.url && ext.url === URI.USCoreRaceExtension && !!Is.ValidArray(ext.extension)){
       for(const omb of ext.extension){
        if(!!Is.ValidString(omb.url) && omb.url === URI.OMBCategoryExtension && !!Is.ValidCoding(omb.valueCoding)){
          let targetCoding = omb.valueCoding;
          for(const sourceCoding of definitionCoding){
            if(targetCoding.system === sourceCoding.system && 
               targetCoding.code === sourceCoding.code) {
                let extension = Extension.New(Results["Characteristic.Result.Match"]);
                characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
                return;
               }
          }
          
        }
       }
       let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
    }
    let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessConditionCriteria = function(characteristic, data){
    if(!characteristic || !data) {return}

    let definition = characteristic.definitionByTypeAndValue;
    if(!definition.valueCodeableConcept || !Is.ValidCodeableConcept(definition.valueCodeableConcept)) {
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let definitionCoding = definition.valueCodeableConcept.coding;
    // BAcomment: Consider changing the code from 'Undetermined' to 'NoMatch'
    if(!data["Condition"] || !Is.ValidArray(data["Condition"])){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let conditions = data["Condition"];
    for(const condition of conditions){
       if(!Is.ValidCodeableConcept(condition.clinicalStatus)){
        continue;
      }
      
      let clinicalCode = ["active", "recurrence", "relapse"];
      let ok = false;
      for(const status of condition.clinicalStatus.coding){
        if(!Is.ValidCoding(status)) {continue;}
        if(status.system === URI["ConditionClinicalStatus"] && 
          clinicalCode.includes(status.code)) {
            
            ok = true; 
            break;
          }
      }
      if(!ok) { continue; }
      if(!condition.verificationStatus || !Is.ValidCodeableConcept(condition.verificationStatus)) {
        continue;
      } 
      let verificationCode = ["confirmed"];
      ok = false;
      for(const status of condition.verificationStatus.coding){
        if(!Is.ValidCoding(status)) { continue; }
        if(status.system === URI["ConditionVerificationStatus"] && 
          verificationCode.includes(status.code)) {
            ok = true; 
            break;
          }
      }
      if(!ok) { continue; }
      if(!condition.code || !Is.ValidCodeableConcept(condition.code)){
        continue;
      }
       
      let conditionCoding = condition.code.coding;
      for( const cc of conditionCoding){
        for( const dc of  definitionCoding){
          if(!!cc.system && !!dc.system && !!cc.code && !!dc.code &&
             cc.system === dc.system && cc.code === dc.code){
              let extension = Extension.New(Results["Characteristic.Result.Match"]);
              characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
              return;
          }
        }
      }
    }
    let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  }
  const ProcessObservationCriteria = function(characteristic, data) {
    if(!characteristic || !data) {return}
    let definition = characteristic.definitionByTypeAndValue;
    let {type} = definition;
    if(!type || !Is.ValidCodeableConcept(type)){
      let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidType"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    
    if( ! data["Observation"] || !Is.ValidArray(data["Observation"])){
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    let observations = [];
    for( const observation of data["Observation"] ){
      if(!observation.status || !["final", "amended", "corrected"].includes(observation.status) ||
         !observation.code || !Is.ValidCodeableConcept(observation.code)){
        continue;
      }
      for(const dCoding of definition.type.coding ){
        if(!Is.ValidCoding(dCoding)) {
          let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidType"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        for(const oCoding of observation.code.coding) {
          if(!Is.ValidCoding(oCoding)) { continue; }
          if(dCoding.system === oCoding.system && dCoding.code === oCoding.code) {
              observations.push(observation)
            }
        }
      }
    }
    if(observations.length <= 0) {
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    }
    observations.sort((a,b) => {
      if (!!a.meta && !!b.meta && !!a.meta.lastUpdated && !!b.meta.lastUpdated)  {
        let d1 = Date.parse(a.meta.lastUpdated);
        let d2 = Date.parse(b.meta.lastUpdated);
        if(!isNaN(d1) && !isNaN(d2) && d1 > d2) return -1;
        if(!isNaN(d1) && !isNaN(d2) && d1 < d2) return 1
      }
      return 0;
    });
    let latest = observations[0];
    //CASE ValueBoolean
    if(!!Is.Defined(definition.valueBoolean)) {
      if(!!Is.Defined(latest.valueBoolean)) {
        if(latest.valueBoolean === definition.valueBoolean) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        } else {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
      } else { 
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
    }
    //CASE ValueCodeableConcept
    if(!!Is.Defined(definition.valueCodeableConcept)) {
      if( !!Is.Defined(latest.valueCodeableConcept) ) {
        for(const dCoding of definition.valueCodeableConcept.coding) {
          for(const lCoding of latest.valueCodeableConcept.coding) {
            if( dCoding.system === lCoding.system && dCoding.code === lCoding.code) {
                let extension = Extension.New(Results["Characteristic.Result.Match"]);
                characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
                return;
            }
          }
        }
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      } else { 
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
    }
    // CASE ValueQuantity ValueQuantity
    if(!!definition.valueQuantity && !!latest.valueQuantity){
      let {system:dsystem, code:dcode, unit:dunit} = definition.valueQuantity;
      let {system:lsystem, code:lcode, unit:lunit} = latest.valueQuantity;
      //console.log("ValueQuantity ValueQuantity", dsystem, dcode, dunit, lsystem,lcode, lunit);
      if( !(!!dsystem && !!lsystem && !!dcode && !!lcode && dsystem === lsystem && dcode === lcode)
           &&
          !(!!dunit && !!lunit && dunit === lunit)){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      } 
      
      let {comparator:dcomparator, value:dvalue} = definition.valueQuantity;
      let {comparator:lcomparator, value:lvalue} = latest.valueQuantity;
      //console.log("DC V", dcomparator, lcomparator,dvalue, lvalue);
      if(typeof dvalue === 'undefined' || typeof lvalue === 'undefined') {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      
      if(!dcomparator){
        if( !lcomparator && lvalue === dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension))); 
          return;
        } 
        if(!lcomparator && lvalue !== dvalue){
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }// End !dcomparator
      
      if(dcomparator === ">") {
        if((!lcomparator && lvalue > dvalue) ||
           (lcomparator === ">" && lvalue >= dvalue) ||
           (lcomparator === ">=" && lvalue > dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if((!lcomparator && lvalue <= dvalue) ||(lcomparator === "<" && lvalue <= dvalue) ||
           (lcomparator === "<=" && lvalue <= dvalue)){
            let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
            characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
         
      }// End dcomparator >
      if(dcomparator === ">=") {
        if((!lcomparator && lvalue >= dvalue) ||
           (lcomparator === ">" && lvalue >= dvalue) ||
           (lcomparator === ">=" && lvalue >= dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if((!lcomparator && lvalue < dvalue) || (lcomparator === "<" && lvalue <= dvalue) ||
           (lcomparator === "<=" && lvalue < dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
         
      }// End dcomparator >=
      if(dcomparator === "<") {
        if((!lcomparator && lvalue < dvalue) ||
           (lcomparator === "<" && lvalue <= dvalue) ||
           (lcomparator === "<=" && lvalue < dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension))); 
          return;
        }
        if((!lcomparator && lvalue >= dvalue) ||(lcomparator === ">" && lvalue >= dvalue) ||
           (lcomparator === ">=" && lvalue >= dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
         
      }// End dcomparator <=
      if(dcomparator === "<=") {
        if((!lcomparator && lvalue <= dvalue) ||
           (lcomparator === "<" && lvalue <= dvalue) ||
           (lcomparator === "<=" && lvalue <= dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if((!lcomparator && lvalue > dvalue) ||(lcomparator === ">" && lvalue >= dvalue) ||
           (lcomparator === ">=" && lvalue > dvalue)){
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
         
      }// End dcomparator <=
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    
    } // End of ValueQuantity ValueQuantity
     
  // CASE ValueQuantity ValueRange
    if(!!definition.valueQuantity && !!latest.valueRange){
      let {system:dsystem, code:dcode, unit:dunit} = definition.valueQuantity;
      let {system:lsystem, code:lcode, unit:lunit} = latest.valueRange.high;
    //console.log("ValueQuantity ValueRange", dsystem, dcode, dunit, lsystem,lcode, lunit);
    if( !(!!dsystem && !!lsystem && !!dcode && !!lcode && dsystem === lsystem && dcode === lcode)
           &&
          !(!!dunit && !!lunit && dunit === lunit)){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));; 
        return;
      }
      
      let {comparator:dcomparator, value:dvalue} = definition.valueQuantity;
      let {high:High, low:Low} = latest.valueRange;
      if(!High || !Low) {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      
    let {value:lhighvalue} = High;
      let {value:llowvalue} = Low;
      
      if(typeof dvalue === 'undefined' || 
         typeof lhighvalue === 'undefined' ||
         typeof llowvalue === 'undefined'){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(!dcomparator) {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      
      if(dcomparator === ">" ) {
        if(llowvalue > dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if(lhighvalue <= dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(dcomparator === ">=" ) {
        if(llowvalue >= dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if(lhighvalue < dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(dcomparator === "<" ) {
        if(lhighvalue < dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if(llowvalue >= dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(dcomparator === "<=" ) {
        if(lhighvalue <= dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        if(llowvalue > dvalue) {
          let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return;
        }
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    } // End of ValueQuantity ValueRange
  // CASE ValueRange ValueQuantity 
    if(!!definition.valueRange && !!latest.valueQuantity){
      let {system:dsystem, code:dcode, unit:dunit} = definition.valueRange.high;
      let {system:lsystem, code:lcode, unit:lunit} = latest.valueQuantity;
    //console.log("ValueRange ValueQuantity", dsystem, dcode, dunit, lsystem,lcode, lunit);
    if( !(!!dsystem && !!lsystem && !!dcode && !!lcode && dsystem === lsystem && dcode === lcode)
           &&
          !(!!dunit && !!lunit && dunit === lunit)){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      } 
      let {high:dHigh, low:dLow} = definition.valueRange;
      if(!dHigh || !dLow ) {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let {value:dhigh} = dHigh;
      let {value:dlow} = dLow;
      
      let {comparator:lcomparator, value:lvalue} = latest.valueQuantity;
  
      if(typeof dhigh === 'undefined' || typeof dlow === 'undefined'){
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if( typeof lvalue === 'undefined') {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(!lcomparator) {
        if(lvalue >= dlow && lvalue <= dhigh) {
          let extension = Extension.New(Results["Characteristic.Result.Match"]);
          characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
          return
        }
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(lcomparator === '>' && lvalue >= dhigh){
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return
      }
      if(lcomparator === '>=' && lvalue > dhigh){
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return
      }
      if(lcomparator === '<' && lvalue <= dlow){
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return
      }
      if(lcomparator === '<=' && lvalue < dlow){
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return
      }
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
      characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    } // End of ValueRange ValueQuantity 
  // CASE ValueRange ValueRange 
    if(!!definition.valueRange && !!latest.valueRange){
      let {system:dsystem, code:dcode, unit:dunit} = definition.valueRange.high;
      let {system:lsystem, code:lcode, unit:lunit} = latest.valueRange.high;
     if( !(!!dsystem && !!lsystem && !!dcode && !!lcode && dsystem === lsystem && dcode === lcode)
           &&
          !(!!dunit && !!lunit && dunit === lunit)){
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      } 
      let {high:dHigh, low:dLow} = definition.valueRange;
      if(!dHigh || !dLow ) {
        let extension = Extension.New(Results["Characteristic.Error.TypeAndValue.InvalidValue"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let {high:lHigh, low:lLow} = latest.valueRange;
      if(!lHigh || !lLow ) {
        let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let {value:dhigh} = dHigh;
      let {value:dlow} = dLow;
      let {value:lhigh} = lHigh;
      let {value:llow} = lLow;
      
      if(llow >= dlow && lhigh <= dhigh) {
        let extension = Extension.New(Results["Characteristic.Result.Match"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      if(lhigh < dlow || llow > dhigh) {
        let extension = Extension.New(Results["Characteristic.Result.NoMatch"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
        return;
      }
      let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
        characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
      return;
    } // End of CASE ValueRange ValueRange 
  
    // Hmm...
    let extension = Extension.New(Results["Characteristic.Result.Undetermined"]);
    characteristic.extension.push(JSON.parse(JSON.stringify(extension)));
    return;
  } // End ProcessObservationCriteria
   
  
  //----------------------------------------------------------------------
  // Processing Starts Here
  //----------------------------------------------------------------------

  //console.log("Error Check - Function.Error.IncorrectParameters");
  if( arguments.length !== 2 || !eligibilityCriteria || !patientData ) {
    let ec = Object.assign({}, { resourceType: "EvidenceVariable",
               id: "EunoChainsGenerated",
               extension: [], status: "unknown"});
    let extension = Extension.New(Results["Function.Error.IncorrectParameters"]);
    
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  
  //console.log("Error Check - Function.Error.InvalidParameters");
  if(!Is.Object(eligibilityCriteria) || !Is.Object(patientData)) {
    let ec = Object.assign({}, { resourceType: "EvidenceVariable",
               id: "EunoChainsGenerated",
               extension: [], status: "unknown"});
    let extension = Extension.New(Results["Function.Error.InvalidParameters"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - EligibilityCriteria.Error.NotAFHIRResource");
  if(!Is.EvidenceVariable(eligibilityCriteria)) {
    let ec = Object.assign({}, { resourceType: "EvidenceVariable",
               id: "EunoChainsGenerated",
               extension: [], status: "unknown"});
    let extension = Extension.New(Results["EligibilityCriteria.Error.NotAFHIRResource"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - EligibilityCriteria.Error.InvalidExtension");
  if(!!eligibilityCriteria.extension && !Is.Array(eligibilityCriteria.extension)) {
    let ec = Object.assign({}, { resourceType: "EvidenceVariable",
               id: "EunoChainsGenerated",
               extension: [], status: "unknown"});
    let extension = Extension.New(Results["EligibilityCriteria.Error.InvalidExtension"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  // From Here On we can report back on the EC Parameter

  //Globals 
  let ec = Object.assign({}, eligibilityCriteria);
  if(!ec.extension) { ec.extension = [];}
  //console.log("Error Check - EligibilityCriteria.Error.NoStatus");
  if(!ec.status || !Is.ValidString(ec.status)){
    let extension = Extension.New(Results["EligibilityCriteria.Error.NoStatus"]);
    ec.status = "unknown";
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - EligibilityCriteria.Error.InvalidStatus");
  let statusCodes = ["draft", "active", "retired",  "unknown"];
  if(!statusCodes.includes(ec.status)){
    let extension = Extension.New(Results["EligibilityCriteria.Error.InvalidStatus"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - EligibilityCriteria.Error.NoCharacteristic");
  if(!ec.characteristic || !Is.ValidArray(ec.characteristic)){
    let extension = Extension.New(Results["EligibilityCriteria.Error.NoCharacteristic"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }

  //console.log("Error Check - EligibilityCriteria.Error.InvalidCharacteristic");
  if(!IsCharacteristicSetValid(ec.characteristic)){
    let extension = Extension.New(Results["EligibilityCriteria.Error.InvalidCharacteristic"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }

  // EligibilityCriteria is OK... Check PatientData

  //console.log("Error Check - PatientData.Error.NotAFHIRResource");
  if(!Is.Bundle(patientData)){
    let extension = Extension.New(Results["PatientData.Error.NotAFHIRResource"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - PatientData.Error.NoType");
  if(!patientData.type || !Is.ValidString(patientData.type)){
    let extension = Extension.New(Results["PatientData.Error.NoType"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - PatientData.Error.InvalidType");
  if(patientData.type !== "collection"){
    let extension = Extension.New(Results["PatientData.Error.InvalidType"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - PatientData.Error.NoEntry");
  if(!patientData.entry || !Is.ValidArray(patientData.entry)){
    let extension = Extension.New(Results["PatientData.Error.NoEntry"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  //console.log("Error Check - PatientData.Error.InvalidEntry");
  if(!IsPatientDataSetValid(patientData.entry)){
    let extension = Extension.New(Results["PatientData.Error.InvalidEntry"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  // PatientData is OK .. Reformat Data for use
  let pd = {};
  for(const rec of patientData.entry){
    let resourceType = rec.resource.resourceType;
    if(!pd[resourceType]){
      pd[resourceType] = []
    }
    pd[resourceType].push(rec.resource)
  }
  
  // console.log("Reformatted Patient Data \n", JSON.stringify(pd, null,2));
  // Process
  for(const ch of ec.characteristic){
    ProcessCharacteristic(ch, pd)
  }
  
  // Compute
  let counter = 0;
  for(const ch of ec.characteristic){
    if(!ch.extension || !Is.ValidArray(ch.extension)) {
      let extension = Extension.New(Results["EligibilityCriteria.Result.Undetermined"]);
      ec.extension.push(JSON.parse(JSON.stringify(extension)));
      return ec;
    }
    
    let chResult = ch.extension[ch.extension.length - 1];
    if(!Is.ValidExtension(chResult)){
      let extension = Extension.New(Results["EligibilityCriteria.Result.Undetermined"]);
      ec.extension.push(JSON.parse(JSON.stringify(extension)));
      return ec;
    }
    if(!Is.ValidCoding(chResult.valueCoding) ) {
      let extension = Extension.New(Results["EligibilityCriteria.Result.Undetermined"]);
      ec.extension.push(JSON.parse(JSON.stringify(extension)));
      return ec;
    }
    if(!!ValueCoding.Equal(chResult.valueCoding, Results["Characteristic.Result.Match"])){
      if(!ch.exclude) {continue;}
      if(!!ch.exclude) {
        let extension = Extension.New(Results["EligibilityCriteria.Result.NoMatch"]);
        ec.extension.push(JSON.parse(JSON.stringify(extension)));
        return ec;
      }
    }
    if(!!ValueCoding.Equal(chResult.valueCoding, Results["Characteristic.Result.NoMatch"])){
      if(!!ch.exclude) {continue;}
      if(!ch.exclude) {
        let extension = Extension.New(Results["EligibilityCriteria.Result.NoMatch"]);
        ec.extension.push(JSON.parse(JSON.stringify(extension)));
        return ec;
      }
    }
    counter = counter + 1;
  }
  // BAcomment: Should this be partial match?
  if(counter > 0){
    let extension = Extension.New(Results["EligibilityCriteria.Result.Undetermined"]);
    ec.extension.push(JSON.parse(JSON.stringify(extension)));
    return ec;
  }
  let extension = Extension.New(Results["EligibilityCriteria.Result.Match"]);
  ec.extension.push(JSON.parse(JSON.stringify(extension)));
  return ec;
} // End of Matching Function
//----------------------------------------------------------------------
//----------------------------------------------------------------------
// END OF FUNCTION SCRIPT
//----------------------------------------------------------------------

export default EligibilityCriteriaApplication;
