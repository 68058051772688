import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import { Checkbox, TextField } from '@mui/material';

const picoAliases = {
  "Population Condition": ['Chronic Kidney Disease', 'Coronary Disease', 'type 2 diabetes'],
  "Population Age": ['Infant', 'Child', 'Adolescent', 'Adult'],
  "Intervention": ['SGLT2 Inhibitors', 'GLP1 Agonists']
}

const SearchQueryPage = ({ useTitle }) => {
  useTitle("FEvIR Search");
  const globalContext = useContext(FevirContext)
  const [initialLoadedState, setInitialLoadedState] = useState(false);
  const [searchQueryState, setSearchQueryState] = useState({});
  const [searchResultsLoadingState, setSearchResultsLoadingState] = useState(false);
  const [resourceTypeValuesState, setResourceTypeValuesState] = useState([]);
  const [typeValuesState, setTypeValuesState] = useState([]);
  const [classifierValuesState, setClassifierValuesState] = useState({});
  const [currentSearchResultsState, setCurrentSearchResultsState] = useState(false);
  const [openAdvancedSearchState, setOpenAdvancedSearchState] = useState(false);
  const [openPicoSearchState, setOpenPicoSearchState] = useState(false);
  const [usePicoAliasesState, setUsePicoAliasesState] = useState(true);
  const [includedResourceTypeValuesState, setIncludedResourceTypeValuesState] = useState([]);
  const [selectedResourceTypeValuesState, setSelectedResourceTypeValuesState] = useState({ resourceTypeLimiter: [] });
  const [fevirPlatformSelectedState, setFevirPlatformSelectedState] = useState(false);
  const [medlineSelectedState, setMedlineSelectedState] = useState(false);
  const [medlineSRLimitedSelectedState, setMedlineSRLimitedSelectedState] = useState(false);
  const [projectFoiState, setProjectFoiState] = useState("");
  const [completedSearchesState, setCompletedSearchesState] = useState({
    "medline": false, "medlineSR": false, "fevir": false
  });
  const [medlineLoadingState, setMedlineLoadingState] = useState(false);
  const [searchResultsReloadingState, setSearchResultsReloadingState] = useState(false);
  const [saveSearchResultsState, setSaveSearchResultsState] = useState({ "triggered": false, "saved": false })

  const [fevirSearchResultsState, setFevirSearchResultsState] = useState([]);
  const [medlineSearchResultsState, setMedlineSearchResultsState] = useState([]);
  const [totalSearchResultsDictionaryState, setTotalSearchResultsDictionaryState] = useState({});
  const [totalSearchResultsState, setTotalSearchResultsState] = useState([]);

  const history = useHistory();
  if (false) {
    history.push(`/resources/Project/${projectFoiState}`);
  }

  useEffect(() => {
    if (fevirSearchResultsState.length > 0) {
      let newEntries = {};
      for (const item of fevirSearchResultsState) {
        newEntries[item.targetfoi] = item;
      }
      setTotalSearchResultsDictionaryState(prevState => { return { ...prevState, ...newEntries } });
    }
  }, [fevirSearchResultsState]);

  useEffect(() => {
    if (medlineSearchResultsState.length > 0) {
      let newEntries = {};
      for (const item of medlineSearchResultsState) {
        newEntries[item.targetfoi] = item;
      }
      setTotalSearchResultsDictionaryState(prevState => { return { ...prevState, ...newEntries } });
    }
  }, [medlineSearchResultsState]);

  useEffect(() => {
    if (Object.keys(totalSearchResultsDictionaryState).length > 0) {
      let totalSearchResults = [];
      for (const key in totalSearchResultsDictionaryState) {
        totalSearchResults.push(totalSearchResultsDictionaryState[key]);
      }
      setTotalSearchResultsState(totalSearchResults);
    }
  }, [totalSearchResultsDictionaryState]);

  const openAdvancedSearch = async () => {
    if (openAdvancedSearchState) {
      setOpenPicoSearchState(false);
      setClassifierValuesState({});
      let searchIndexResponse = await submitToFevirServer(globalContext, 120000, { functionid: 'getsearchindex', 'idToken': '' }, true, false);
      if (searchIndexResponse?.success) {
        setResourceTypeValuesState(searchIndexResponse.resourceTypeValues);
        setTypeValuesState(searchIndexResponse.typeValues);
        setClassifierValuesState(searchIndexResponse.typeSpecificClassifierValuesDictionary);
      } else {
        console.log("FAILURE of getsearchindex");
      }
    };
  }

  useEffect(() => {
    openAdvancedSearch();
  }, [openAdvancedSearchState]);

  const openPicoSearch = async () => {
    if (openPicoSearchState) {
      setOpenAdvancedSearchState(false);
      setClassifierValuesState({});
      setTypeValuesState(['Population Condition', 'Population Age', 'Intervention']);
      if (usePicoAliasesState) {
        setResourceTypeValuesState(['Composition']);
        setClassifierValuesState(picoAliases);
      } else {
        let searchIndexResponse = await submitToFevirServer(globalContext, 60000, { functionid: 'getsearchindex', 'idToken': '' }, true, false);
        if (searchIndexResponse.success) {
          if (openPicoSearchState && searchQueryState.searchPiece) {
            setResourceTypeValuesState(['Composition']);
          } else {
            setResourceTypeValuesState(searchIndexResponse.resourceTypeValues);
          }
          setClassifierValuesState(searchIndexResponse.typeSpecificClassifierValuesDictionary);
        }
      }
    }
  };

  useEffect(() => {
    openPicoSearch();
  }, [openPicoSearchState, usePicoAliasesState]);

  useEffect(async () => {
    if (!initialLoadedState && !globalContext.userState.loading) {
      setInitialLoadedState(true);
      setResourceTypeValuesState(['ActivityDefinition', 'ArtifactAssessment', 'Bundle', 'Citation', 'CodeSystem',
        'Composition', 'Evidence', 'EvidenceReport', 'EvidenceVariable', 'Group', 'Library', 'List', 'Measure',
        'Organization', 'PlanDefinition', 'Practitioner', 'PractitionerRole', 'Project',
        'Questionnaire', 'QuestionnaireResponse', 'ResearchStudy', 'StructureDefinition', 'ValueSet']);
      /*
      let searchIndexResponse = await submitToFevirServer(globalContext, 60000, { functionid: 'getsearchindex', 'idToken': '' }, true, false);
      let searchIndex = searchIndexResponse?.searchIndex;
      if (searchIndexResponse.success) {
        let resourceTypeValues = [];
        for (const entry of searchIndex) {
          if (entry.targetfoiresourcetype && entry.targetfoiresourcetype !== "MISSINGDATA" &&
            !resourceTypeValues.includes(entry.targetfoiresourcetype)) {
            resourceTypeValues.push(entry.targetfoiresourcetype);
          }
        }
        setResourceTypeValuesState(resourceTypeValues);
      }
      */
    }
  }, [globalContext.userState]);

  useEffect(async () => {
    if (searchResultsLoadingState) {
      setCurrentSearchResultsState(false);
      if (fevirPlatformSelectedState) {
        let resourceTypeLimiter;
        if (selectedResourceTypeValuesState.resourceTypeLimiter.length > 0) {
          resourceTypeLimiter = selectedResourceTypeValuesState.resourceTypeLimiter;
        } else {
          resourceTypeLimiter = searchQueryState.resourceTypeLimiter;
        }
        let body = {
          functionid: 'getsearchresults',
          'idToken': '',
          'contextInfo': { 'searchQueryPage': true },
          'searchQuery': {
            'searchParameters': searchQueryState.searchPiece,
            'searchSimpleString': searchQueryState.simpleString,
            'resourceTypeLimiter': resourceTypeLimiter
          }
        }
        if (resourceTypeLimiter || searchQueryState.simpleString || searchQueryState.searchPiece) {
          let searchResultsResponse = await submitToFevirServer(globalContext, 60000, body, true, false);
          if (searchResultsResponse?.success) {
            if (searchResultsResponse.searchResults) {
              setFevirSearchResultsState(searchResultsResponse.searchResults);
              setCompletedSearchesState(prevState => { return { ...prevState, "fevir": true } });
              setCurrentSearchResultsState(true);
            }
            if (searchResultsResponse.includedResourceTypeValues) {
              setIncludedResourceTypeValuesState(searchResultsResponse.includedResourceTypeValues);
            }
          }
        }
      }
      setSearchResultsLoadingState(false);
      if (medlineSelectedState) {
        if (searchQueryState.simpleString) {
          setMedlineLoadingState(true);
          const medlinebody = {
            'functionid': 'medlinesearchresults',
            'medlinequery': searchQueryState.simpleString,
            'idToken': ''
          };
          let medlineresponse = await submitToFevirServer(globalContext, 120000, medlinebody, true, false);
          if (medlineresponse?.success) {
            setMedlineSearchResultsState(medlineresponse.searchResults);
            setCompletedSearchesState(prevState => { return { ...prevState, "medline": true } });
            setCurrentSearchResultsState(true);
          } else {
            alert("Something went wrong with the MEDLINE search.");
          }
          setMedlineLoadingState(false);
        }
      }
      if (medlineSRLimitedSelectedState && !medlineSelectedState) {
        if (searchQueryState.simpleString) {
          setMedlineLoadingState(true);
          const medlinebody = {
            'functionid': 'medlinesearchresults',
            'medlinequery': searchQueryState.simpleString + " AND (systematic[sb] OR guideline[sb])",
            'idToken': ''
          };
          let medlineresponse = await submitToFevirServer(globalContext, 90000, medlinebody, true, false);
          if (medlineresponse?.success) {
            setMedlineSearchResultsState(medlineresponse.searchResults);
            setCompletedSearchesState(prevState => { return { ...prevState, "medlineSR": true } });
            setCurrentSearchResultsState(true);
          } else {
            alert("Something went wrong with the MEDLINE search.");
          }
          setMedlineLoadingState(false);
        }
      }
    }
  }, [searchResultsLoadingState]);

  useEffect(async () => {
    if (saveSearchResultsState.triggered) {
      const body = {
        'functionid': "savesearchresults",
        'searchQueryState': searchQueryState,
        'projectFoi': projectFoiState,
        'searchResultState': totalSearchResultsState,
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 120000, body, true, false);
      if (response?.success) {
        if (response.projectFOI !== projectFoiState) {
          setProjectFoiState(response.projectFOI);
        }
        setSaveSearchResultsState({ "triggered": true, "saved": true });
      }
    }
  }, [saveSearchResultsState.triggered]);

  const DisplaySearchQuery = ({ searchQuery }) => {
    let searchQueryString = "";
    for (const searchPiece of searchQuery) {
      if (searchPiece.type && searchPiece.classifier) {
        searchQueryString += searchPiece.type + " has any value of " + searchPiece.classifier.join(', ') + "; AND ";
      }
    }
    return <>{searchQueryString.slice(0, -6)}</>
  }

  const searchClick = () => {
    setTotalSearchResultsState([]);
    setFevirSearchResultsState([]);
    setMedlineSearchResultsState([]);
    setTotalSearchResultsDictionaryState({});
    setSearchResultsLoadingState(true);
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      searchClick();
    }
  }

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <TextField className="inputField" type='text' label={'Simple Search String'} size="small" multiline
        variant='outlined' value={searchQueryState.simpleString || ""} onKeyUp={handleKeyUp}
        onChange={(e) => { setSearchQueryState(prevState => { return { ...prevState, "simpleString": e.target.value } }); }} />
      <br /> <br />
      {resourceTypeValuesState.length ?
        <>
          <DataEntry asArray={true} datatype='code' elementName='resourceTypeLimiter' fieldLabel='Limit by Resource Type'
            allowedValues={resourceTypeValuesState} dataEntryStyle='dropdownsearch'
            startingValue={searchQueryState.resourceTypeLimiter || []} setResourceState={setSearchQueryState} />
          <p style={{ paddingTop: "12px" }}>Limit to resource types of: {(searchQueryState.resourceTypeLimiter &&
            searchQueryState.resourceTypeLimiter.length > 0) ?
            searchQueryState.resourceTypeLimiter.join(', ') : "[No limits]"}</p>
          <br />
        </>
        :
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
      <br />
      <p><b>Select Sources to Search:</b></p>
      <div style={{ marginLeft: "24px" }}>
        <p>
          <Checkbox
            onChange={(e) => { setFevirPlatformSelectedState(!fevirPlatformSelectedState); }}
            checked={fevirPlatformSelectedState}
            color="primary" style={{ paddingLeft: "4px", paddingRight: "4px" }}
          />
          &nbsp;&nbsp;
          FEvIR Platform
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Checkbox
            onChange={(e) => { setMedlineSelectedState(!medlineSelectedState); }}
            checked={medlineSelectedState}
            color="primary" style={{ paddingLeft: "4px", paddingRight: "4px" }}
          />
          &nbsp;&nbsp;
          MEDLINE
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Checkbox
            onChange={(e) => { setMedlineSRLimitedSelectedState(!medlineSRLimitedSelectedState); }}
            checked={medlineSRLimitedSelectedState}
            color="primary" style={{ paddingLeft: "4px", paddingRight: "4px" }}
          />
          &nbsp;&nbsp;
          MEDLINE (limited to systematic reviews and guidelines)
        </p>
      </div>
      <span>
        <Button style={{ color: "#000000", width: "360px", float: "left" }} className="formButton" content="Search"
          onClick={searchClick} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {(!searchResultsLoadingState && !searchResultsReloadingState &&
          totalSearchResultsState.length > 0 && currentSearchResultsState) &&
          <>
            {saveSearchResultsState.triggered ?
              <>
                {saveSearchResultsState.saved ?
                  <Button style={{ color: "#000000", width: "360px", float: "right" }} className="formButton"
                    content="Go To Saved Search Results"
                    onClick={() => { history.push(`/resources/Project/${projectFoiState}`); }} />
                  :
                  <span style={{ float: "right" }}>
                    <img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Saving Search Results...
                  </span>
                }
              </>
              :
              <>
                <Button style={{ color: "#000000", width: "360px", float: "right" }} className="formButton"
                  content="Save Search Results"
                  onClick={() => { setSaveSearchResultsState({ "triggered": true, "saved": false }); }} />
              </>}
          </>}
      </span>
      <br /><br />
      {searchResultsLoadingState ?
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Loading...</>
        :
        <>{searchResultsReloadingState ?
          <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Loading Updated Search Results...</>
          :
          <div style={{ marginTop: "18px" }}>
            {medlineLoadingState && <div>
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> MEDLINE Search Import Loading...</>
            </div>}
            {(totalSearchResultsState.length > 0 && currentSearchResultsState && (completedSearchesState.fevir ||
               completedSearchesState.medline || completedSearchesState.medlineSR)) && <>
                <p>
                  {fevirSearchResultsState.length > 0 && <>{fevirSearchResultsState.length} FEvIR search results. </>}
                  {medlineSearchResultsState.length > 0 && <>{medlineSearchResultsState.length} MEDLINE search results. </>}
                  {totalSearchResultsState.length} total search results.
                </p>
                <DataEntry asArray={true} datatype='code' elementName='resourceTypeLimiter'
                  fieldLabel='Limit Results by Resource Type'
                  allowedValues={includedResourceTypeValuesState} dataEntryStyle='dropdownsearch'
                  startingValue={selectedResourceTypeValuesState.resourceTypeLimiter || []} setResourceState={setSelectedResourceTypeValuesState} />
                <br />
                {totalSearchResultsState.map((result, resultIndex) => {
                  return <p key={resultIndex}>
                    <a href={result.onClick} >{result.display}</a>
                  </p>
                })}
              </>}
            {(!(totalSearchResultsState.length > 0) && currentSearchResultsState && fevirPlatformSelectedState) &&
              "No matching entries on FEvIR Platform."}
          </div>
        }</>
      }
      <br /><br />
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => { setOpenAdvancedSearchState(!openAdvancedSearchState) }}>
        Advanced Search -
        {openAdvancedSearchState ? <>Collapse ▼</> : <>Expand ►</>}
      </span>
      {openAdvancedSearchState && <>
        {(typeValuesState.length && Object.keys(classifierValuesState).length) ?
          <>
            <DataEntry asArray={true} datatype='SearchPiece' elementName='searchPiece' fieldLabel='Search Query Piece'
              typeValueSet={typeValuesState} classifierValueSet={classifierValuesState}
              startingValue={searchQueryState.searchPiece} setResourceState={setSearchQueryState} />
            <p style={{ paddingTop: "12px" }}>Search query is: {searchQueryState.searchPiece &&
              <DisplaySearchQuery searchQuery={searchQueryState.searchPiece} />}</p>
            <br />
          </>
          :
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
      </>}
      <br /><br />
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => { setOpenPicoSearchState(!openPicoSearchState) }}>
        PICO Search -
        {openPicoSearchState ? <>Collapse ▼</> : <>Expand ►</>}
      </span>
      {openPicoSearchState && <>
        {(typeValuesState.length && Object.keys(classifierValuesState).length) ?
          <>
            <br />
            <span className={"unselectable"} style={{ cursor: "pointer" }}
              onClick={() => { setUsePicoAliasesState(!usePicoAliasesState) }}>
              Before adding a PICO Search Query Piece -
              {usePicoAliasesState ? <>- Click here to limit choices to available data -</> : <>+ Click here to use PICO Aliases +</>}
            </span>
            <br />
            <DataEntry asArray={true} datatype='SearchPiece' elementName='searchPiece' fieldLabel='PICO Search Query Piece'
              typeValueSet={typeValuesState}
              classifierValueSet={classifierValuesState}
              startingValue={searchQueryState.searchPiece} setResourceState={setSearchQueryState} />
            <p style={{ paddingTop: "12px" }}>Search query is: {searchQueryState.searchPiece &&
              <DisplaySearchQuery searchQuery={searchQueryState.searchPiece} />}</p>
            <br />
          </>
          :
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
      </>}
    </Segment>
  </div>

};

export default SearchQueryPage;