import React, { memo, useState, useEffect, useContext, useImperativeHandle } from 'react';
import { emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet, sectionCodePopulation, sectionCodeInterventionDescription, sectionCodeComparatorDescription, sectionCodeResearchStudy, sectionCodeSummaryOfFindings, sectionCodeInterventionGroup, sectionCodeComparatorGroup, sectionCodeSubgroup, sectionCodeIntroduction, sectionCodeMethods, sectionCodeDiscussion, sectionCodeReferences, sectionCodeCompetingInterests, sectionCodeAcknowledgements, sectionCodeAppendices, sectionCodeResultWithComparatorAlone, sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionAloneCalculated, sectionCodeResultWithInterventionVsComparator, compositionTypeCodeComparativeEvidenceSynthesisReport, emptyTextNoData, sectionCodeResults, sectionCodeColumnHeaders, sectionCodeOutcomeMeasure, sectionCodeSampleSize, sectionCodeCertaintyOfEvidence, sectionCodeAssertion, sectionCodeGroups, sectionCodeGroupAssignment, sectionCodeVariables, compositionTypeCodeOutcomeMeasureSynthesisReport, compositionTypeCodeSummaryOfFindings } from './CodeSystemLookup';
import { DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import {
  generateGroupSummary, generateSubgroupsSummary, generateResearchStudySummary, generateEvidenceSummary,
  generateCalculatedInterventionRateSummary,
  generateCompositionSummary,
  generateVariablesSummary
} from './GenerateNarrativeSummaryFunctions';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { Button } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import { EditTheSectionButton } from './NavigationCompositionSectionSegment';
import { NarrativeEntry, submitFhirResources } from './ComparativeEvidenceReportAuthor';

let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

/*
const PicosSectionsDataEntry = memo(({ startingValue, setSourceJsonState, setResourceState }) => {
  let startingPicosSections = [];
  if (startingValue) {
    startingPicosSections = startingValue.filter(section => section?.code?.coding?.[0]?.code === "summary-of-findings-entry-for-a-single-outcome") || [];
  }

  const [picosSectionsState, setPicosSectionsState] = useState({ "section": startingPicosSections });

  useEffect(() => {
    if (picosSectionsState.section?.length && JSON.stringify(picosSectionsState.section) !== JSON.stringify(startingPicosSections)) {
      let picosSections = JSON.parse(JSON.stringify(picosSectionsState.section));
      setResourceState(prevState => {
        let starterSection = prevState.section.filter(section => section?.code?.coding?.[0]?.code !== "summary-of-findings-entry-for-a-single-outcome");
        let newSection = starterSection.concat(picosSections);
        return { ...prevState, section: newSection };
      })
    }
  }, [picosSectionsState]);

  return <DataEntry asArray={true} datatype="PicoSection" elementName="section"
    setSourceJsonState={setSourceJsonState}
    startingValue={picosSectionsState.section} setResourceState={setPicosSectionsState} />
});

      <p>Ignore what is below here.</p>
      <PicosSectionsDataEntry
        startingValue={resourceState.section || null}
        resourceState={resourceState} setResourceState={setResourceState} />
*/

const TopSectionDataEntry = ({ sectionCode, startingValue, resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState, history, setFhirEntryState }) => {

  let resourceDictionary = sourceJsonState.resourceDictionary;
  let groupReferences = sourceJsonState.groupReferences;

  const [topSectionState, setTopSectionState] = useState(null);
  const [startingTopSectionState, setStartingTopSectionState] = useState(null);
  const [populationEntryState, setPopulationEntryState] = useState(null);

  useEffect(() => {
      let startingTopSection = {
        "title": sectionCode + " Section",
        "code": { "text": sectionCode },
        "author": [],
        "focus": {},
        "text": emptyTextNoData,
        "orderedBy": {},
        "entry": [],
        "emptyReason": {},
        "section": []
      };
      let startingPopulationEntry = null;
      if (startingValue) {
        if (startingValue.title) { startingTopSection.title = startingValue.title; }
        if (startingValue.code) {
          startingTopSection.code = startingValue.code;
        } else if (sectionCode === "Population") {
          startingTopSection.code = sectionCodePopulation;
        } else if (sectionCode === "Intervention") {
          startingTopSection.code = sectionCodeInterventionDescription;
        } else if (sectionCode === "Comparator") {
          startingTopSection.code = sectionCodeComparatorDescription;
        } else if (sectionCode === "Research Study") {
          startingTopSection.code = sectionCodeResearchStudy; 
      } else if (sectionCode === "Variables (Outcome Measures)") {
        startingTopSection.code = sectionCodeVariables;
        } else if (sectionCode === "Results") {
          startingTopSection.code = sectionCodeResults;
        } else if (sectionCode === "Summary of Findings") {
          startingTopSection.code = sectionCodeSummaryOfFindings;
        }
        if (startingValue.author) { startingTopSection.author = startingValue.author; }
        if (startingValue.focus) { startingTopSection.focus = startingValue.focus; }
        if (startingValue.text) { startingTopSection.text = startingValue.text; }
        if (startingValue.orderedBy) { startingTopSection.orderedBy = startingValue.orderedBy; }
        if (startingValue.entry) {
          startingTopSection.entry = startingValue.entry;
          if (sectionCode === "Population") {
            if (startingValue.entry.length > 1) {
              alert("The Population section has more than one entry value. Only the first entry value will be kept if the content is edited.");
            }
            startingPopulationEntry = startingValue.entry[0];
          }
        }
        if (startingValue.emptyReason) { startingTopSection.emptyReason = startingValue.emptyReason; }
        if (startingValue.section) {
          startingTopSection.section = startingValue.section;
        }
      } else {
        startingTopSection = {
          "title": sectionCode + " Section",
          "code": { "text": sectionCode }
        };
      }
      setTopSectionState(JSON.parse(JSON.stringify(startingTopSection)));
      setStartingTopSectionState(JSON.parse(JSON.stringify(startingTopSection)));
      setPopulationEntryState({ "referenceInstance": startingPopulationEntry });   
  }, [])

  useEffect(() => {
    if (sectionCode === "Population" && populationEntryState?.referenceInstance) {
      setTopSectionState(prevState => {
        return { ...prevState, entry: [JSON.parse(JSON.stringify(populationEntryState.referenceInstance))] };
      });
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }, [populationEntryState])

  useEffect(() => {
    if (topSectionState && JSON.stringify(topSectionState) !== JSON.stringify(startingTopSectionState)) {
      let newResource = JSON.parse(JSON.stringify(resourceState));
      let newSection = JSON.parse(JSON.stringify(topSectionState));
      newSection = autoEditEmptyReason(newSection);
      if (sectionCode === "Population") {
        newResource.section[1] = newSection;
        if (newSection.section?.[0]?.entry?.length) {
          let newSubpopulationFoiList = newSection.section[0].entry.map(item => { return item.reference?.split("/")[1] });
          if (newSubpopulationFoiList?.length) {
            setSubpopulationFoiListState(newSubpopulationFoiList);
          }
        }
      } else if (sectionCode === "Intervention") {
        newResource.section[2] = newSection;
      } else if (sectionCode === "Comparator") {
        newResource.section[3] = newSection;
      } else if (sectionCode === "Research Study") {
        newResource.section[4] = newSection;
      } else if (sectionCode === "Variables (Outcome Measures)") {
        newResource.section[6] = newSection;
      } else if (sectionCode === "Results") {
        newResource.section[7] = newSection;
      } else if (sectionCode === "Summary of Findings") {
        newResource.section[8] = newSection;
      }
      setResourceState(newResource);
      console.log("setResourceState from ComparativeEvidenceSynthesisReport TopSectionDataEntry")
    }
  }, [topSectionState]);

  const [expandAuthorEntryState, setExpandAuthorEntryState] = useState(false);
  const [subpopulationFoiListState, setSubpopulationFoiListState] = useState(null);

  let relatesTo = [
    {
      type: "part-of",
      resourceReference: {
        "reference": "Composition/" + resourceState.id,
        "type": "Composition",
        "display": resourceState.title || resourceState.name
      }
    }
  ];

  const addPopulationSubgroupSection = async (sectionTitle, profile, compositionType, sourceJsonState, globalContext) => {
    let entry = [];
    let newCompositionArray = [];
    if (compositionType && sourceJsonState?.groupReferences?.subpopulations?.length) {
      newCompositionArray = sourceJsonState.groupReferences.subpopulations.map((subgroup) => {
        if (profile === "SummaryOfFindings") {
          return {
            "resourceType": "Composition",
            "meta": {
              "profile": [profile]
            },
            "type": compositionType,
            "relatesTo": relatesTo,
            "title": sectionTitle + " for " + subgroup.display,
            "section": [
              {
                "title": "Column Headers",
                "code": sectionCodeColumnHeaders,
                "section": [
                  {
                    "title": "Outcome Measure",
                    "code": sectionCodeOutcomeMeasure,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
                    }
                  },
                  {
                    "title": "Sample Size",
                    "code": sectionCodeSampleSize,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample Size</p></div>"
                    }
                  },
                  {
                    "title": "Result Without Treatment",
                    "code": sectionCodeResultWithComparatorAlone,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
                    }
                  },
                  {
                    "title": "Result With Treatment--Observed",
                    "code": sectionCodeResultWithInterventionAlone,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
                    }
                  },
                  {
                    "title": "Result With Treatment--Derived",
                    "code": sectionCodeResultWithInterventionAloneCalculated,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
                    }
                  },
                  {
                    "title": "Effect Estimate",
                    "code": sectionCodeResultWithInterventionVsComparator,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
                    }
                  },
                  {
                    "title": "Certainty of Evidence",
                    "code": sectionCodeCertaintyOfEvidence,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of Evidence</p></div>"
                    }
                  },
                  {
                    "title": "Assertion",
                    "code": sectionCodeAssertion,
                    "text": {
                      "status": "generated",
                      "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
                    }
                  }
                ]
              },
              {
                "title": "Summary of Findings",
                "code": sectionCodeSummaryOfFindings,
                "text": emptyTextNoData,
                "section": [],
                "emptyReason": emptyReasonNotStarted
              },
              {
                "title": "Groups",
                "code": sectionCodeGroups,
                "section": [
                  {
                    "title": "Total Group",
                    "code": sectionCodePopulation,
                    "text": emptyTextNoData,
                    "entry": [subgroup]
                  },
                  {
                    "title": "Intervention Group",
                    "code": sectionCodeInterventionGroup,
                    "text": emptyTextNoData,
                    "entry": sourceJsonState.groupReferences.interventionDescription ? [sourceJsonState.groupReferences.interventionDescription] : [],
                    "emptyReason": sourceJsonState.groupReferences.interventionDescription ? "" : emptyReasonNotStarted
                  },
                  {
                    "title": "Comparator Group",
                    "code": sectionCodeComparatorGroup,
                    "text": emptyTextNoData,
                    "entry": sourceJsonState.groupReferences.comparatorDescription ? [sourceJsonState.groupReferences.comparatorDescription] : [],
                    "emptyReason": sourceJsonState.groupReferences.comparatorDescription ? "" : emptyReasonNotStarted
                  },
                  {
                    "title": "Group Assignment",
                    "code": sectionCodeGroupAssignment,
                    "text": emptyTextNoData,
                    "emptyReason": emptyReasonNotStarted
                  }
                ]
              },
              {
                "title": "Variables (Measures)",
                "code": sectionCodeVariables,
                "text": emptyTextNoData,
                "entry": groupReferences?.variables ? groupReferences.variables : [],
                "emptyReason": groupReferences?.variables ? "" : emptyReasonNotStarted
              }
            ]
          }
        }
        return {
          "resourceType": "Composition",
          "meta": {
            "profile": [profile]
          },
          "type": compositionType,
          "relatesTo": relatesTo,
          "title": sectionTitle + " for " + subgroup.display,
          "section": [
            {
              "title": "Groups",
              "code": sectionCodeGroups,
              "section": [
                {
                  "title": "Total Group",
                  "code": sectionCodePopulation,
                  "entry": [subgroup]
                },
                {
                  "title": "Intervention Group",
                  "code": sectionCodeInterventionGroup,
                  "text": emptyTextNoData,
                  "entry": sourceJsonState.groupReferences.interventionDescription ? [sourceJsonState.groupReferences.interventionDescription] : [],
                  "emptyReason": sourceJsonState.groupReferences.interventionDescription ? "" : emptyReasonNotStarted
                },
                {
                  "title": "Comparator Group",
                  "code": sectionCodeComparatorGroup,
                  "text": emptyTextNoData,
                  "entry": sourceJsonState.groupReferences.comparatorDescription ? [sourceJsonState.groupReferences.comparatorDescription] : [],
                  "emptyReason": sourceJsonState.groupReferences.comparatorDescription ? "" : emptyReasonNotStarted
                },
                {
                  "title": "Group Assignment",
                  "code": sectionCodeGroupAssignment,
                  "text": emptyTextNoData,
                  "emptyReason": emptyReasonNotStarted
                }
              ]
            },
            {
              "title": "Variables (Measures)",
              "code": sectionCodeVariables,
              "text": emptyTextNoData,
              "entry": groupReferences?.variables ? groupReferences.variables : [],
              "emptyReason": groupReferences?.variables ? "" : emptyReasonNotStarted
            },
            {
              "title": "Results",
              "code": sectionCodeResults,
              "text": emptyTextNoData,
              "emptyReason": emptyReasonNotStarted
            }
          ]
        }
      });
    }
    let createdFois;
    if (newCompositionArray.length) {
      createdFois = await submitFhirResources(newCompositionArray, globalContext);
    }
    alert("Click Update on the left before editing the " + sectionTitle + " section.");
    if (createdFois) {
      entry = createdFois.map(foi => {
        return {
          "reference": "Composition/" + foi,
          "type": "Composition"
        }
      });
    }

    setTopSectionState(prevState => {
      return {
        ...prevState, section: [{
          "title": sectionTitle,
          "code": sectionCodeSubgroup,
          "entry": entry
        }]
      };
    });
  };

  if (topSectionState) {
    if (sectionCode === "Population") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateGroupSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Group" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Group Resource (StudyEligibilityCriteria or SystematicReviewEligibilityCriteria Profile) for the Population.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='Reference' elementName='referenceInstance' fieldLabel='Group Resource (StudyEligibilityCriteria or SystematicReviewEligibilityCriteria Profile)'
              startingValue={populationEntryState.referenceInstance} referencedResourceTypes={['Group']}
              startingResourceType="Group" selectProfile={["StudyEligibilityCriteria", "SystematicReviewEligibilityCriteria"]}
              startCollapsed enableCreation={true}
              setResourceState={setPopulationEntryState} setSourceJsonState={setSourceJsonState}
              globalContext={globalContext}
              fullResourceState={resourceState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Population Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Population Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[1, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Population Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[1, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => { addPopulationSubgroupSection("Population Subgroups"); }} >
              <p><b>Click to add Population Subgroups. Update the Resource before editing the Population Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Intervention" || sectionCode === "Comparator") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateGroupSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="Group" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <h3>{sectionCode} Section Content: Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the {sectionCode} .</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry'
              fieldLabel='Group Resource (ExposureDefinition or ComparatorDefinition Profile)'
              startingValue={topSectionState.entry} referencedResourceTypes={['Group']}
              startingResourceType="Group" selectProfile={['ExposureDefinition', 'ComparatorDefinition']}
              startCollapsed enableCreation={true}
              setResourceState={setTopSectionState} setSourceJsonState={setSourceJsonState}
              globalContext={globalContext}
              fullResourceState={resourceState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
        </div>
      </>
    } else if (sectionCode === "Research Study") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateResearchStudySummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="ResearchStudy" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the ResearchStudy Resource containing the Study Design information.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='ResearchStudy Resource'
              startingValue={topSectionState.entry} referencedResourceTypes={['ResearchStudy']}
              startingResourceType="ResearchStudy"
              startCollapsed startEmptyArrayClosed enableCreation={true}
              setResourceState={setTopSectionState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
        </div>
      </>
    } else if (sectionCode === "Variables (Outcome Measures)") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            generateSummaryFunction={generateVariablesSummary}
            sectionEntry={topSectionState.entry} globalContext={globalContext}
            resourceType="EvidenceVariable" resourceDictionary={resourceDictionary}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the EvidenceVariable Resource(s) representing the Outcome Measures.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='EvidenceVariable Resource'
              startingValue={topSectionState.entry} referencedResourceTypes={['EvidenceVariable']}
              startingResourceType="EvidenceVariable" setProfile="VariableDefinition"
              startCollapsed startEmptyArrayClosed enableCreation={true}
              setResourceState={setTopSectionState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
        </div>
      </>
    } else if (sectionCode === "Results") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            sectionEntry={topSectionState.entry}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resources (OutcomeMeasureSynthesisReport Profile) for the Population.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Composition Resource (OutcomeMeasureSynthesisReport Profile)'
              startingValue={topSectionState.entry} referencedResourceTypes={['Composition']}
              startingResourceType="Composition" setProfile="OutcomeMeasureSynthesisReport"
              addElementValues={{
                type: compositionTypeCodeOutcomeMeasureSynthesisReport || "",
                relatesTo: relatesTo || "",
                section: [
                  {
                    "title": "Groups",
                    "code": sectionCodeGroups,
                    "section": [
                      {
                        "title": "Total Group",
                        "code": sectionCodePopulation,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.totalGroup ? [groupReferences.totalGroup] : [],
                        "emptyReason": groupReferences?.totalGroup ? "" : emptyReasonNotStarted

                      },
                      {
                        "title": "Intervention Group",
                        "code": sectionCodeInterventionGroup,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.interventionDescription ? [groupReferences.interventionDescription] : [],
                        "emptyReason": groupReferences?.interventionDescription ? "" : emptyReasonNotStarted
                      },
                      {
                        "title": "Comparator Group",
                        "code": sectionCodeComparatorGroup,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.comparatorDescription ? [groupReferences.comparatorDescription] : [],
                        "emptyReason": groupReferences?.comparatorDescription ? "" : emptyReasonNotStarted
                      },
                      {
                        "title": "Group Assignment",
                        "code": sectionCodeGroupAssignment,
                        "text": emptyTextNoData,
                        "emptyReason": emptyReasonNotStarted
                      }
                    ]
                  },
                  {
                    "title": "Variables (Measures)",
                    "code": sectionCodeVariables,
                    "text": emptyTextNoData,
                    "entry": groupReferences?.variables ? groupReferences.variables : [],
                    "emptyReason": groupReferences?.variables ? "" : emptyReasonNotStarted
                  },
                  {
                    "title": "Results",
                    "code": sectionCodeResults,
                    "text": emptyTextNoData,
                    "emptyReason": emptyReasonNotStarted
                  }
                ]
              }}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              setResourceState={setTopSectionState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Results for Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Results for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[6, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Results for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[6, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => {
              addPopulationSubgroupSection("Results for Subgroups",
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-measure-synthesis-report",
                compositionTypeCodeOutcomeMeasureSynthesisReport, sourceJsonState, globalContext);
            }} >
              <p><b>Click to add Results for Subgroups. Update the Resource before editing the Results for Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else if (sectionCode === "Summary of Findings") {
      return <>
        <div style={{ marginLeft: "24px" }}>
          <NarrativeEntry elementName='text' fieldLabel={sectionCode + ' Section Summary'}
            sectionEntry={topSectionState.entry}
            startingValue={topSectionState.text} setResourceState={setTopSectionState} />
          <br />
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setExpandAuthorEntryState(!expandAuthorEntryState) }}>
            {expandAuthorEntryState ? <>
              <b>Collapse Section Author Data Entry ▼</b>
            </> : <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </>}
          </span>
          <br />
          {expandAuthorEntryState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel={sectionCode + ' Section Author'}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              startingValue={topSectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              setResourceState={setTopSectionState} />
          </>}
          <h3>{sectionCode} Section Content: Create or identify the Composition Resources (SummaryOfFindings Profile) for the Population.</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel='Composition Resource (SummaryOfFindings Profile)'
              startingValue={topSectionState.entry} referencedResourceTypes={['Composition']}
              startingResourceType="Composition" setProfile="SummaryOfFindings"
              addElementValues={{
                type: compositionTypeCodeSummaryOfFindings || "",
                relatesTo: relatesTo || "",
                section: [
                  {
                    "title": "Column Headers",
                    "code": sectionCodeColumnHeaders,
                    "section": [
                      {
                        "title": "Outcome Measure",
                        "code": sectionCodeOutcomeMeasure,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
                        }
                      },
                      {
                        "title": "Sample Size",
                        "code": sectionCodeSampleSize,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample Size</p></div>"
                        }
                      },
                      {
                        "title": "Result Without Treatment",
                        "code": sectionCodeResultWithComparatorAlone,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
                        }
                      },
                      {
                        "title": "Result With Treatment--Observed",
                        "code": sectionCodeResultWithInterventionAlone,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
                        }
                      },
                      {
                        "title": "Result With Treatment--Derived",
                        "code": sectionCodeResultWithInterventionAloneCalculated,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
                        }
                      },
                      {
                        "title": "Effect Estimate",
                        "code": sectionCodeResultWithInterventionVsComparator,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
                        }
                      },
                      {
                        "title": "Certainty of Evidence",
                        "code": sectionCodeCertaintyOfEvidence,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of Evidence</p></div>"
                        }
                      },
                      {
                        "title": "Assertion",
                        "code": sectionCodeAssertion,
                        "text": {
                          "status": "generated",
                          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
                        }
                      }
                    ]
                  },
                  {
                    "title": "Summary of Findings",
                    "code": sectionCodeSummaryOfFindings,
                    "text": emptyTextNoData,
                    "section": [],
                    "emptyReason": emptyReasonNotStarted
                  },
                  {
                    "title": "Groups",
                    "code": sectionCodeGroups,
                    "section": [
                      {
                        "title": "Total Group",
                        "code": sectionCodePopulation,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.totalGroup ? [groupReferences.totalGroup] : [],
                        "emptyReason": groupReferences?.totalGroup ? "" : emptyReasonNotStarted

                      },
                      {
                        "title": "Intervention Group",
                        "code": sectionCodeInterventionGroup,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.interventionDescription ? [groupReferences.interventionDescription] : [],
                        "emptyReason": groupReferences?.interventionDescription ? "" : emptyReasonNotStarted
                      },
                      {
                        "title": "Comparator Group",
                        "code": sectionCodeComparatorGroup,
                        "text": emptyTextNoData,
                        "entry": groupReferences?.comparatorDescription ? [groupReferences.comparatorDescription] : [],
                        "emptyReason": groupReferences?.comparatorDescription ? "" : emptyReasonNotStarted
                      },
                      {
                        "title": "Group Assignment",
                        "code": sectionCodeGroupAssignment,
                        "text": emptyTextNoData,
                        "emptyReason": emptyReasonNotStarted
                      }
                    ]
                  },
                  {
                    "title": "Variables (Measures)",
                    "code": sectionCodeVariables,
                    "text": emptyTextNoData,
                    "entry": groupReferences?.variables ? groupReferences.variables : [],
                    "emptyReason": groupReferences?.variables ? "" : emptyReasonNotStarted
                  }
                ]
              }}
              startCollapsed startEmptyArrayClosed enableCreation={true}
              setResourceState={setTopSectionState} />
          </div>
          <br />
          {((!topSectionState.section || topSectionState.section.length === 0) &&
            (!topSectionState.entry || topSectionState.entry.length === 0) &&
            (!topSectionState.text || topSectionState.text.status === "empty")) && <>
              <h3>Explain why empty</h3>
              <div style={{ marginLeft: "24px" }}>
                <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                  startingValue={topSectionState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
                  codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                  setResourceState={setTopSectionState} />
              </div>
            </>}
          <br />
          {topSectionState.section?.[0] ? <>
            <h3>Summary of Findings for Subgroups</h3>
            <div style={{ marginLeft: "24px" }}>
              <EditTheSectionButton theThis="the" sectionName="Summary of Findings for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[7, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
              <p>Narrative Status: {topSectionState.section[0].text?.status || "empty"}</p>
              <DisplayFromFHIR xhtml={topSectionState.section[0].text?.div || null} />
              <br />
              {topSectionState.section[0].orderedBy && <span>
                <b>Ordered by: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].orderedBy} /><br />
              </span>}
              {(Array.isArray(topSectionState.section[0].entry) && topSectionState.section[0].entry.length > 0) &&
                topSectionState.section[0].entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {topSectionState.section[0].emptyReason && <span>
                <b>Empty Reason: </b>
                <DisplayFromFHIR codeableConcept={topSectionState.section[0].emptyReason} /><br />
              </span>}
              <EditTheSectionButton theThis="this" sectionName="Summary of Findings for Subgroups"
                sectionSelected={topSectionState.section[0]} sectionPathIndexes={[7, 0]}
                resourceStateId={resourceState.id} history={history}
                setFhirEntryState={setFhirEntryState} />
            </div>
          </> : <div>
            <span onClick={() => {
              addPopulationSubgroupSection("Summary of Findings for Subgroups",
                "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings",
                compositionTypeCodeSummaryOfFindings, sourceJsonState, globalContext);
            }} >
              <p><b>Click to add Results for Subgroups. Update the Resource before editing the Results for Subgroups section.</b></p>
            </span>
          </div>}
        </div>
      </>
    } else {
      return <>
        <p>Unrecognized section detected with sectionCode:</p>
        <p>{sectionCode}</p>
      </>
    }
  } else {
    return <></>;
  }
}

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": sectionCodeIntroduction,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyPopulation = {
    "title": "Population",
    "code": sectionCodePopulation,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyIntervention = {
    "title": "Intervention",
    "code": sectionCodeInterventionDescription,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyComparator = {
    "title": "Comparator",
    "code": sectionCodeComparatorDescription,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyResearchStudy = {
    "title": "Research Study",
    "code": sectionCodeResearchStudy,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": sectionCodeMethods,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyResults = {
    "title": "Results",
    "code": sectionCodeResults,
    "emptyReason": emptyReasonNotStarted
  };
  let emptySummaryOfFindings = {
    "title": "Summary of Findings",
    "code": sectionCodeSummaryOfFindings,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": sectionCodeDiscussion,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyReferences = {
    "title": "References Section",
    "code": sectionCodeReferences,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": sectionCodeCompetingInterests,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": sectionCodeAcknowledgements,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": sectionCodeAppendices,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyVariables = {
    "title": "Variables (Measures)",
    "code": sectionCodeVariables,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let sectionDictionary = {
    introduction: emptyIntroduction,
    population: emptyPopulation,
    intervention: emptyIntervention,
    comparator: emptyComparator,
    researchStudy: emptyResearchStudy,
    methods: emptyMethods,
    variables: emptyVariables,
    results: emptyResults,
    summaryOfFindings: emptySummaryOfFindings,
    discussion: emptyDiscussion,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "population" || sectionCode === "Population") {
      sectionDictionary.population = section;
    } else if (sectionCode === "intervention-description" || sectionCode === "Intervention Description" ||
      sectionCode === "intervention" || sectionCode === "Intervention") {
      sectionDictionary.intervention = section;
    } else if (sectionCode === "comparator-description" || sectionCode === "Comparator Description" ||
      sectionCode === "comparator" || sectionCode === "Comparator") {
      sectionDictionary.comparator = section;
    } else if (sectionCode === "research-study" || sectionCode === "Research Study") {
      sectionDictionary.researchStudy = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "variables" || sectionCode === "Variables") {
      sectionDictionary.variables = section;
    } else if (sectionCode === "results" || sectionCode === "Results") {
      sectionDictionary.results = section;
    } else if (sectionCode === "summary-of-findings" || sectionCode === "Summary of findings") {
      sectionDictionary.summaryOfFindings = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.introduction,
    sectionDictionary.population,
    sectionDictionary.intervention,
    sectionDictionary.comparator,
    sectionDictionary.researchStudy,
    sectionDictionary.methods,
    sectionDictionary.variables,
    sectionDictionary.results,
    sectionDictionary.summaryOfFindings,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray.length !== reorderedSectionArray.length ||
    JSON.stringify(sectionArray) !== JSON.stringify(reorderedSectionArray)) {
    setSectionsReorderedState({done: true, sectionArray: reorderedSectionArray});
  }
};

const ComparativeEvidenceSynthesisReportAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded, history, setFhirEntryState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let defaultType = compositionTypeCodeComparativeEvidenceSynthesisReport;

  const [sectionsReorderedState, setSectionsReorderedState] = useState({done: false, sectionArray: []});

  useEffect(() => {
    if (resourceState) {
    reorderSections(JSON.parse(JSON.stringify(resourceState.section)), setSectionsReorderedState);
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState.done) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
        setResourceState(prevState => {
          return {
            ...prevState,
            type: prevState.type || defaultType,
            section: JSON.parse(JSON.stringify(sectionsReorderedState.sectionArray))
          };
        });
        console.log("setResourceState from ComparativeEvidenceSynthesisReportAuthor")
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded || !sourceJsonState.loaded2) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  useEffect(() => {
    setSourceJsonState(prevState => { return { ...prevState, loaded2: false } });
  }, [resourceState.section]);

  return <>
    {(sourceJsonState.loaded && resourceState.section?.length > 11) ?
      <div style={{ marginTop: "12px" }}>
        {adaptationReportState?.adaptOn ?
          <>
            <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <h3 id="population">Population</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={1}
                fieldLabel="Population Section Summary"
                sectionCode="section[1]:https://fevir.net/resources/CodeSystem/179423#population"
                startingValue={resourceState.section[1] || null}
                fixedTitle={"Population"} fixedCode={sectionCodePopulation} startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                generateTextDivFunction={generateGroupSummary}
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["Group"]}
                entryStartingResourceType="Group"
                entrySelectProfile={["StudyEligibilityCriteria", "SystematicReviewEligibilityCriteria"]}
                entryHeader="Create or identify the Group Resource (StudyEligibilityCriteria or SystematicReviewEligibilityCriteria Profile) for the Population."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true}
                editableSectionCodes={false} addSectionAllowed={false}
                allowedSectionCodes={[
                  sectionCodeSubgroup
                ]}
                sectionDictionary={{
                  '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup","display":"Subgroup"}]}': {
                    'startCollapsed': false,
                    'fixedTitle': "Population Subgroups",
                    'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                    'emptyTextDivValue': "[No data.]",
                    'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                    'entryHeader': "Create or identify the Group Resource(s) for the Population Subgroups.",
                    'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                    'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                    'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                    'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                  }
                }}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <h3 id="intervention">Intervention</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={2}
                fieldLabel="Intervention Description"
                sectionCode="section[2]:https://fevir.net/resources/CodeSystem/179423#intervention-description"
                startingValue={resourceState.section[2] || null}
                fixedTitle={"Intervention Description"} fixedCode={sectionCodeInterventionDescription} startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                generateTextDivFunction={generateGroupSummary}
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["Group"]}
                entryStartingResourceType="Group"
                entrySelectProfile={["ExposureDefinition", "ComparatorDefinition"]}
                entryHeader="Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Intervention."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                noSection={true}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <h3 id="comparator">Comparator</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={3}
                fieldLabel="Comparator Description"
                sectionCode="section[3]:https://fevir.net/resources/CodeSystem/179423#comparator-description"
                startingValue={resourceState.section[3] || null}
                fixedTitle={"Comparator Description"} fixedCode={sectionCodeComparatorDescription} startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                generateTextDivFunction={generateGroupSummary}
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["Group"]}
                entryStartingResourceType="Group"
                entrySelectProfile={["ExposureDefinition", "ComparatorDefinition"]}
                entryHeader="Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Comparator."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                noSection={true}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <h3 id="research-study">Research Study</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={4}
                fieldLabel="Research Study Section Summary"
                sectionCode="section[4]:https://fevir.net/resources/CodeSystem/179423#research-study"
                startingValue={resourceState.section[4] || null}
                fixedTitle={"Research Study"} fixedCode={sectionCodeResearchStudy} startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                generateTextDivFunction={generateResearchStudySummary}
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["ResearchStudy"]}
                entryStartingResourceType="ResearchStudy"
                entryHeader="Create or identify the ResearchStudy Resource containing the Study Design information."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true}
                editableSectionCodes={true} addSectionAllowed={true}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <h3 id="outcome-measures">Outcome Measures</h3>
            <DocumentSectionEntry sectionArrayIndex={6}
              fieldLabel="Variables (Measures) Section" globalContext={globalContext}
              sectionCode="section[6]:https://fevir.net/resources/CodeSystem/179423#variables"
              startingValue={resourceState.section[6] || null}
              fixedTitle="Outcome Measures"
              fixedCode={sectionCodeVariables} editTextDiv={true} textDeletable={true} editTextStatus={false}
              emptyTextDivValue="<p>[No data.]</p>" generateSummaryFromArrayFunction={generateVariablesSummary}
              editAuthor={false}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
              entryStartCollapsed={true}
              entryHeader="Create or identify the EvidenceVariable Resource(s) to represent the Outcome Measures."
              entryReferencedResourceTypes={['EvidenceVariable']}
              entryStartingResourceType="EvidenceVariable"
              entrySetProfile="VariableDefinition"
              entryStartEmptyArrayClosed={true}
              editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              noSection={true} editOrderedBy={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <h3 id="results">Results</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={7}
                fieldLabel="Results Section Summary"
                sectionCode="section[7]:https://fevir.net/resources/CodeSystem/179423#results"
                startingValue={resourceState.section[7] || null}
                fixedTitle={"Results"} fixedCode={sectionCodeResults}
                startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["Composition"]}
                entryStartingResourceType="Composition"
                entrySetProfile="OutcomeMeasureSynthesisReport"
                entryHeader="Create or identify the Composition Resource (OutcomeMeasureSynthesisReport Profile) for the Population."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true}
                editableSectionCodes={false} addSectionAllowed={false}
                allowedSectionCodes={[
                  sectionCodeSubgroup
                ]}
                sectionDictionary={{
                  '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup","display":"Subgroup"}]}': {
                    'startCollapsed': false,
                    'fixedTitle': "Results for Subgroups",
                    'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                    'emptyTextDivValue': "[No data.]",
                    'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                    'entryHeader': "Create or identify the Composition Resource(s) for the Outcome Measure Synthesis Reports for Subgroups.",
                    'entryReferencedResourceTypes': ["Composition"], 'entryStartCollapsed': true,
                    'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Composition",
                    'entrySetProfile': "OutcomeMeasureSynthesisReport",
                    'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                    'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                  }
                }}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <h3 id="summary-of-findings">Summary of Findings</h3>
            <div style={{ marginLeft: "24px" }}>
              <DocumentSectionEntry sectionArrayIndex={8}
                fieldLabel="Summary of Findings Section Summary"
                sectionCode="section[8]:https://fevir.net/resources/CodeSystem/179423#summary-of-findings"
                startingValue={resourceState.section[8] || null}
                fixedTitle={"Summary of Findings"} fixedCode={sectionCodeSummaryOfFindings}
                startCollapsed editTextDiv={true} textDeletable={true}
                emptyTextDivValue="[No data.]"
                editAuthor={true}
                noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
                entryEnableCreation={true} entryStartCollapsed={true}
                entryReferencedResourceTypes={["Composition"]}
                entryStartingResourceType="Composition"
                entrySetProfile="SummaryOfFindings"
                entryHeader="Create or identify the Composition Resource (SummaryOfFindings Profile) for the Population."
                editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
                emptyReasonStartCollapsed={true}
                emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                editSection={true}
                editableSectionCodes={false} addSectionAllowed={false}
                allowedSectionCodes={[
                  sectionCodeSubgroup
                ]}
                sectionDictionary={{
                  '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup","display":"Subgroup"}]}': {
                    'startCollapsed': false,
                    'fixedTitle': "Summary of Findings for Subgroups",
                    'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                    'emptyTextDivValue': "[No data.]",
                    'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                    'entryHeader': "Create or identify the Composition Resource(s) for the Summary of Findings Reports for Subgroups.",
                    'entryReferencedResourceTypes': ["Composition"], 'entryStartCollapsed': true,
                    'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Composition",
                    'entrySetProfile': "SummaryOfFindings",
                    'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                    'emptyReasonStartCollapsed': true, 'noSection': true, 'editOrderedBy': true
                  }
                }}
                setResourceState={setResourceState}
                compositionId={resourceState.id} compositionTitle={resourceState.title}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
            <EditDiscussion sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <EditReferences sectionIndex={10} previousVersionLoaded={previousVersionLoaded}
              resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <EditCompetingInterests sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <EditAcknowledgements sectionIndex={12} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            <EditAppendices sectionIndex={13} resourceState={resourceState} setResourceState={setResourceState}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </>
          :
          <>
            <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
            <h3 id="population">Population</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Population"
                startingValue={resourceState.section[1] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
                history={history} setFhirEntryState={setFhirEntryState} />
            </div>
            <br />
            <h3 id="intervention">Intervention</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Intervention"
                startingValue={resourceState.section[2] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
              />
            </div>
            <h3 id="comparator">Comparator</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Comparator"
                startingValue={resourceState.section[3] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState} />
            </div>
            <br />
            <h3 id="research-study">Research Study</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Research Study"
                startingValue={resourceState.section[4] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} />
            </div>
            <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <h3 id="outcome-measures">Outcome Measures</h3>
            <TopSectionDataEntry sectionCode="Variables (Outcome Measures)"
                startingValue={resourceState.section[6] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} />
            <br />
            <h3 id="results">Results</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Results"
                startingValue={resourceState.section[7] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} />
            </div>
            <br />
            <h3 id="summary-of-findings">Summary of Findings</h3>
            <div style={{ marginLeft: "24px" }}>
              <TopSectionDataEntry sectionCode="Summary of Findings"
                startingValue={resourceState.section[8] || null}
                globalContext={globalContext}
                resourceState={resourceState} setResourceState={setResourceState}
                sourceJsonState={sourceJsonState} />
            </div>
            <br />
            <EditDiscussion sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditReferences sectionIndex={10} previousVersionLoaded={previousVersionLoaded}
              resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditCompetingInterests sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAcknowledgements sectionIndex={12} resourceState={resourceState} setResourceState={setResourceState} />
            <br />
            <EditAppendices sectionIndex={13} resourceState={resourceState} setResourceState={setResourceState} />
          </>
        }
      </div>
      :
      <div><p>Loading ...</p></div>}
  </>
};

const changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport = (sectionDetailEditSettings, section, resourceDictionary) => {

  if (section.code?.coding?.[0]?.code === "population" || section.code?.text === "Population") {
    sectionDetailEditSettings.fixedTitle = "Population";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Population.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.addSingleSectionFixedTitleFixedCodeNoText = {
      "title": "Population Subgroups",
      "code": sectionCodeSubgroup
    };
  }
  if (section.code?.coding?.[0]?.code === "subgroup" || section.code?.text === "Subgroups" || section.code?.text === "Population Subgroups") {
    sectionDetailEditSettings.fixedTitle = "Population Subgroups";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction = generateSubgroupsSummary;
    sectionDetailEditSettings.entryFoiList = getFoisFromReference(section.entry, "Group", resourceDictionary);
    sectionDetailEditSettings.usesLoadSourceJson = true;
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource(s) for the Population Subgroups.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
    if (section.title === "Results for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Results for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Outcome Measure Synthesis Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "OutcomeMeasureSynthesisReport";
    } else if (section.title === "Summary of Findings for Subgroups") {
      sectionDetailEditSettings.fixedTitle = "Summary of Findings for Subgroups";
      delete sectionDetailEditSettings.generateNarrativeFromEntryArrayFunction;
      delete sectionDetailEditSettings.entryFoiList;
      sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource(s) for the Summary of Findings Reports for Subgroups.";
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
      sectionDetailEditSettings.entryStartingResourceType = "Composition";
      sectionDetailEditSettings.entrySetProfile = "SummaryOfFindings";
    }
  }
  if (section.code?.coding?.[0]?.code === "intervention-description" || section.code?.text === "Intervention Description") {
    sectionDetailEditSettings.fixedTitle = "Intervention Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition Profile) for the Intervention Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "ExposureDefinition";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "intervention-group" || section.code?.text === "Intervention Group") {
    sectionDetailEditSettings.fixedTitle = "Intervention Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Intervention Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-description" || section.code?.text === "Comparator Description") {
    sectionDetailEditSettings.fixedTitle = "Comparator Description";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource (ExposureDefinition or ComparatorDefinition Profile) for the Comparator Description.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySelectProfile = ["ExposureDefinition", "ComparatorDefinition"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "comparator-group" || section.code?.text === "Comparator Group") {
    sectionDetailEditSettings.fixedTitle = "Comparator Group";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Group Resource for the Comparator Group.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "research-study" || section.code?.text === "Research Study") {
    sectionDetailEditSettings.fixedTitle = "Research Study";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateResearchStudySummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the ResearchStudy Resource containing the Study Design information.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ResearchStudy"];
    sectionDetailEditSettings.entryStartingResourceType = "ResearchStudy";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "variables" || section.code?.text === "Variables") {
    sectionDetailEditSettings.fixedTitle = "Variables (Outcome Measures)";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateVariablesSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the EvidenceVariable Resource(s) representing the Outcome Measures.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["EvidenceVariable"];
    sectionDetailEditSettings.entryStartingResourceType = "EvidenceVariable";
    sectionDetailEditSettings.entrySetProfile = "VariableDefinition";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "results" || section.code?.text === "Results") {
    sectionDetailEditSettings.fixedTitle = "Results";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource (OutcomeMeasureSynthesisReport Profile) for the Population.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "OutcomeMeasureSynthesisReport";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.addSingleSectionFixedTitleFixedCodeNoText = {
      "title": "Results for Subgroups",
      "code": sectionCodeSubgroup
    };
  }
  if (section.code?.coding?.[0]?.code === "summary-of-findings" || section.code?.text === "Summary of Findings") {
    sectionDetailEditSettings.fixedTitle = "Summary of Findings";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateCompositionSummary;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Create or identify the Composition Resource (SummaryOfFindings Profile) for the Population.";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "OutcomeMeasureSynthesisReport";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.addSingleSectionFixedTitleFixedCodeNoText = {
      "title": "Summary of Findings for Subgroups",
      "code": sectionCodeSubgroup
    };
  }

  return sectionDetailEditSettings;
}

export { ComparativeEvidenceSynthesisReportAuthor, changeSectionDetailEditSettingsForComparativeEvidenceSynthesisReport };