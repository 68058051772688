import React, { useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { useHistory } from "react-router-dom";
import FevirContext from './FevirContext';
import { createSummaryOfFindingsReport } from './SummaryOfFindingsFunctions';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';

const EvidenceTableDisplay = ({ section }) => {
  if (!Array.isArray(section)) {
    return <p>Section content is not iterable.</p>
  }
  if (section.length === 0) {
    return <p>There is no content.</p>
  }

  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Outcome</Table.HeaderCell>
          <Table.HeaderCell>Population</Table.HeaderCell>
          <Table.HeaderCell>Intervention</Table.HeaderCell>
          <Table.HeaderCell>Comparator</Table.HeaderCell>
          <Table.HeaderCell>Result with Comparator</Table.HeaderCell>
          <Table.HeaderCell>Result with Intervention</Table.HeaderCell>
          <Table.HeaderCell>Effect Estimate</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {section.map((itemSection, index) => {
          let populationSection = null;
          let interventionGroupSection = null;
          let comparatorGroupSection = null;
          let comparatorEvidenceSection = null;
          let interventionEvidenceSection = null;
          let comparativeEvidenceSection = null;
          if (Array.isArray(itemSection.section)) {
            for (let subsection of itemSection.section) {
              let subsectionCode = subsection.code?.coding?.[0]?.code;
              if (subsectionCode === "population") {
                populationSection = subsection;
              } else if (subsectionCode === "intervention-group") {
                interventionGroupSection = subsection;
              } else if (subsectionCode === "comparator-group") {
                comparatorGroupSection = subsection;
              } else if (subsectionCode === "result-with-comparator-alone") {
                comparatorEvidenceSection = subsection;
              } else if (subsectionCode === "result-with-intervention-alone") {
                interventionEvidenceSection = subsection;
              } else if (subsectionCode === "result-with-intervention-vs-comparator") {
                comparativeEvidenceSection = subsection;
              }
            }
            return <Table.Row key={index}>
              <Table.Cell style={{ verticalAlign: "top" }}>
                <span>
                  {itemSection.title || itemSection.focus?.display}
                  <br />
                  <DisplayFromFHIR reference={itemSection.focus} />
                </span>
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {populationSection && <span>
                  <DisplayFromFHIR xhtml={populationSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={populationSection.entry?.[0]} />
                </span>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {interventionGroupSection && <span>
                  <DisplayFromFHIR xhtml={interventionGroupSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={interventionGroupSection.entry?.[0]} />
                </span>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {comparatorGroupSection && <span>
                  <DisplayFromFHIR xhtml={comparatorGroupSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={comparatorGroupSection.entry?.[0]} />
                </span>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {comparatorEvidenceSection && <span>
                  <DisplayFromFHIR xhtml={comparatorEvidenceSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={comparatorEvidenceSection.entry?.[0]} />
                </span>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {interventionEvidenceSection && <span>
                  <DisplayFromFHIR xhtml={interventionEvidenceSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={interventionEvidenceSection.entry?.[0]} />
                </span>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {comparativeEvidenceSection && <span>
                  <DisplayFromFHIR xhtml={comparativeEvidenceSection.text?.div} />
                  <br />
                  <DisplayFromFHIR reference={comparativeEvidenceSection.entry?.[0]} />
                </span>}
              </Table.Cell>
            </Table.Row>
          } else {
            return <span>
              <DisplayFromFHIR xhtml={itemSection.text?.div} />
            </span>
          }
        })}
      </Table.Body>
    </Table>
  </>
}

const OutcomeMeasureSynthesisReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }
  let howToCite;
  let summaryOfFindingsStarter = {
    "derivedFrom": {
      "type": "derived-from",
      "label": "Derived from",
      "resourceReference": {
        "reference": "Composition/" + fhirJson.id,
        "type": "Composition",
        "display": fhirJson.title || fhirJson.name
      }
    },
    "outcomesRelatesTo": []
  };
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
      if (relatedArtifactEntry.type === "depends-on") {
        if (relatedArtifactEntry.label === "Intervention Group") {
          summaryOfFindingsStarter.interventionGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Comparator Group") {
          summaryOfFindingsStarter.comparatorGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Outcome Measure") {
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
    }
  }
  if (Array.isArray(fhirJson.subject)) {
    summaryOfFindingsStarter.subject = fhirJson.subject;
  }
  if (Array.isArray(fhirJson.section) && fhirJson.section.length > 0) {
    if (fhirJson.section[0].code?.coding?.[0]?.code === "groups" &&
      fhirJson.section[2]?.code?.coding?.[0]?.code === "results") {
      summaryOfFindingsStarter.outcomesSection = fhirJson.section[2].section || [];
      if (fhirJson.section[0].section?.[1]?.entry?.[0]) {
        summaryOfFindingsStarter.interventionGroup = {
          "type": "depends-on",
          "label": "Intervention Group",
          "resourceReference": fhirJson.section[0].section[1].entry[0]
        };
      }
      if (fhirJson.section[0].section?.[2]?.entry?.[0]) {
        summaryOfFindingsStarter.comparatorGroup = {
          "type": "depends-on",
          "label": "Comparator Group",
          "resourceReference": fhirJson.section[0].section[1].entry[0]
        };
      }
      if (fhirJson.section[1]?.entry?.length) {
        for (let entry of fhirJson.section[1].entry) {
          let relatedArtifactEntry = {
            "type": "depends-on",
            "label": "Outcome Measure",
            "resourceReference": entry
          };
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
      summaryOfFindingsStarter.groupsSection = fhirJson.section[0];
      summaryOfFindingsStarter.variablesSection = fhirJson.section[1];
    }
  }

  const history = useHistory();
  const globalContext = useContext(FevirContext);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <Button className="formButton" style={{ color: "#000000" }}
        content={`Create a Summary of Findings Report`}
        onClick={async () => {
          let resourceFOI = await createSummaryOfFindingsReport(fhirJson.title, summaryOfFindingsStarter, globalContext);
          if (resourceFOI) {
            history.push("/");
            history.push(`/resources/Composition/${resourceFOI}`);
          }
        }} />
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Report Title: </b>
          {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
        </p>
        {description &&
          <div><b>Report Description: </b><DisplayFromFHIR markdown={description} />
          </div>}
        {Array.isArray(fhirJson.subject) &&
          fhirJson.subject.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
          })}
        {Array.isArray(fhirJson.category) &&
          fhirJson.category.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
          })}
        {(fhirJson.section?.[0]?.text && Object.keys(fhirJson.section[0].text).length > 0) && <div>
          {fhirJson.section[0].text.status === "empty" ?
            <></>
            :
            <div>
              <b>Narrative Summary: </b>
              <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                <DisplayFromFHIR xhtml={fhirJson.section[0].text.div} />
              </div>
            </div>}
          <br />
        </div>}
        {fhirJson.section?.[0]?.orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={fhirJson.section[0].orderedBy} /><br /></span>}
        {fhirJson.section?.[0]?.emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={fhirJson.section[0].emptyReason} /><br /></span>}
      </div>
      <h3 id="groups">Groups</h3>
      <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[0]} />
      <h3 id="outcome-measures">Outcome Measures</h3>
      <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[1]} />
      <h3 id="results">Results</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson?.section?.[2]?.code?.coding?.[0]?.code === "results" ?
          <>{fhirJson.section[2].section ?
            <EvidenceTableDisplay section={fhirJson.section[2].section} />
            :
            <p>Unable to display results. Results section does not contain sections.</p>
          }</>
          :
          <p>Unable to display results. Results section not detected.</p>
        }
      </div>
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.category) && fhirJson.category.length > 0 && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0 && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default OutcomeMeasureSynthesisReportViewerTool;