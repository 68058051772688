import { React, useState } from "react";
import { Modal, Button } from 'semantic-ui-react';

const ConfirmModal = ({ confirmModalState, setConfirmModalState }) => {
    const modalClose = () => {
        setConfirmModalState(prevState => { return { ...prevState, "open": false, "runFunction": null, "saveFunction": null, "buttonTextOkay": "Okay", "buttonTextCancel": "Cancel", buttonTextSave: "Save", buttonColorSave: "green", buttonColorOkay: "green", buttonColorCancel: "red" }; });
    }
    return (
        <Modal
            style={{ width: "50%", margin: "16px" }}
            dimmer={<Modal.Dimmer blurring style={{ backgroundColor: "#00000077" }} />}
            open={confirmModalState.open}
            centered={false}
            content={<div style={{ whiteSpace: "pre-wrap" }}>
                <Modal.Header style={{ padding: "6px 16px 0px 16px", color: "#AC3B61", textAlign: "center" }}><h2>{confirmModalState.header}</h2></Modal.Header>
                <br />
                <div style={{ padding: "0px 16px 0px 16px" }}>
                    {confirmModalState.content && <><h3>{confirmModalState.content}</h3><br /></>}
                    <br />
                    <div style={{ width: "100%", textAlign: "center" }}>
                        {confirmModalState.saveFunction && <>
                            <Button className="formButton" color={confirmModalState.buttonColorSave || 'green'} style={{ padding: "6px", width: "150px" }}
                                content={confirmModalState.buttonTextSave || "Save"}
                                onClick={() => {
                                    confirmModalState.saveFunction();
                                    modalClose();
                                }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                        }
                        <Button className="formButton" color={confirmModalState.buttonColorOkay || 'green'} style={{ padding: "6px", width: "150px" }}
                            content={confirmModalState.buttonTextOkay || "Okay"}
                            onClick={() => {
                                confirmModalState.runFunction(confirmModalState.functionParameters);
                                modalClose();
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button className="formButton" color={confirmModalState.buttonColorCancel || 'red'} style={{ padding: "6px", width: "150px" }}
                            content={confirmModalState.buttonTextCancel || "Cancel"}
                            onClick={modalClose}
                        />
                    </div>
                    <br />
                </div>
            </div>}
        />
    )
}

const ButtonWithConfirmModal = ({ buttonContent, modalHeader, message, conditionForConfirmModal, functionIfConfirmed, disabled }) => {

    const [confirmModalState, setConfirmModalState] = useState({
        open: false, header: modalHeader || "",
        content: message || "",
        runFunction: functionIfConfirmed || null, saveFunction: null, buttonTextOkay: "Okay", buttonTextCancel: "Cancel",
        buttonTextSave: "Save", buttonColorSave: "green", buttonColorOkay: "green", buttonColorCancel: "red"
    });

    return <>
        <ConfirmModal confirmModalState={confirmModalState} setConfirmModalState={setConfirmModalState} />
        <Button className="formButton"
            color="blue"
            style={{ color: "#FFFFFF" }}
            content={buttonContent}
            onClick={async () => {
                if (conditionForConfirmModal()) {
                    setConfirmModalState(prevState => { return { ...prevState, open: true, runFunction: functionIfConfirmed || null }; });
                } else {
                    await functionIfConfirmed();
                }
            }}
            disabled={disabled} />
    </>
}

export { ConfirmModal, ButtonWithConfirmModal };