import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { submitResource } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import FevirContext from './FevirContext';
import ProfilesByResourceType from './ProfilesByResourceType';

const NewTitleEntry = ({ newResourceState, setNewResourceState }) => {

  let itemTypeDisplay = newResourceState.resourceType + " Resource";
  if (newResourceState.meta?.profile) {
    for (const profile of newResourceState.meta.profile) {
      const profileDictionary = ProfilesByResourceType[newResourceState.resourceType];
      for (const key in profileDictionary) {
        if (profileDictionary[key].url === profile) {
          itemTypeDisplay = key;
          break;
        }
      }
    }
  }
  return <div>
    <div style={{ marginLeft: "24px" }}>
      <p>Enter a title here and click Submit to create a new blank {itemTypeDisplay}.</p>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={newResourceState.title} setResourceState={setNewResourceState} />
    </div>
  </div>
}

const ProfileSpecificCreateResourcePage = ({ useTitle, resourceStarter }) => {
  let pageTitle = "FEvIR Resource Entry";

  useTitle(pageTitle);
  const globalContext = useContext(FevirContext);
  const [fhirEntryState, setFhirEntryState] = useState({ "fhirEntry": "", "status": "active" });

  const [newResourceState, setNewResourceState] = useState(resourceStarter);

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  useEffect(() => {
    if (JSON.stringify(newResourceState) !== JSON.stringify({ "resourceType": "", "title": "" })) {
      let newJson = {
        "resourceType": newResourceState.resourceType,
        "meta": newResourceState.meta,
        "title": newResourceState.title
      };
      if (newResourceState.resourceType === "CodeSystem") {
        newJson.concept = [];
      }
      if (newResourceState.section) {
        newJson.section = newResourceState.section;
      } else if (newResourceState.resourceType === "Composition") {
        newJson.section = [];
      }
      changeFhirEntryState(JSON.stringify(newJson), "fhirEntry");
    }
  }, [newResourceState])

  const history = useHistory();

  if (!resourceStarter) {
    return <div>
      <p>THERE IS AN ERROR. YOU HAVE REACHED THE ProfileSpecificCreateResourcePage WITHOUT A resourceStarter VALUE. EMAIL support@computablepublishing.com</p>
    </div>
  }

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <br /><br />
      {globalContext.userState.firebaseuid ?
        <>
          <NewTitleEntry newResourceState={newResourceState} setNewResourceState={setNewResourceState} />
          <br />
          <Button className="formButton positive" content="Submit" compact
            onClick={() => { submitResource(globalContext, history, fhirEntryState, true); }} />
        </>
        :
        <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
    </Segment>
  </div>
};

export default ProfileSpecificCreateResourcePage;