import React, { useState, useEffect, memo } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { getFoiFromReference, getFoisFromReference } from './ResourceDictionaryFunctions';
import { emptyReasonValueSet } from './CodeSystemLookup';
import { generateEvidenceSummary, generateEvidenceVariableSummary, generateGroupSummary, generateVariablesSummary } from './GenerateNarrativeSummaryFunctions';
import SEVCO from './SEVCO';
import submitToFevirServer from './SubmitToFevirServer';
import { handleAdaptChange } from './AdaptItemDataEntryFunctions';
import { NarrativeEntry } from './NarrativeEntry';

const SingleEvidenceEntry = memo(({ startingValue, addElementValues, globalContext,
  statisticType, statisticUnit, setResourceState, resourceDictionary, knownDataCount,
  resourceState, setSourceJsonState,
  path, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  let startingEntryValues = { "statisticType": "", "quantity": "", "knownDataCount": "", "entryInstance": "" };
  let startingEvidenceValues = { "foi": "", "statisticType": "", "quantity": "", "knownDataCount": "", "json": "" };
  if (statisticType) {
    startingEntryValues.statisticType = statisticType;
    startingEvidenceValues.statisticType = statisticType;
  }
  if (statisticUnit) {
    startingEntryValues.quantity = { "unit": statisticUnit };
    startingEvidenceValues.quantity = { "unit": statisticUnit };
  }
  if (knownDataCount || knownDataCount === 0) {
    startingEntryValues.knownDataCount = knownDataCount;
    startingEvidenceValues.knownDataCount = knownDataCount;
  }
  if (startingValue && startingValue[0]) {
    startingEntryValues.entryInstance = startingValue[0];
    let foi = "";
    if (startingValue[0].reference) {
      if (startingValue[0].reference.split("/")[1] && !isNaN(startingValue[0].reference.split("/")[1])) {
        foi = startingValue[0].reference.split("/")[1];
      }
    } else if (startingValue[0].identifier?.system === "https://fevir.net/FLI" &&
      startingValue[0].identifier.value) {
      let fli = startingValue[0].identifier.value;
      if (resourceDictionary?.fliToFoi) {
        foi = resourceDictionary["fliToFoi"][fli];
      }
    }
    if (foi && resourceDictionary[foi]) {
      let EvidenceJson = resourceDictionary[foi];
      startingEvidenceValues.foi = EvidenceJson.id;
      startingEvidenceValues.json = EvidenceJson;
      if (EvidenceJson.statistic?.[0]) {
        if (EvidenceJson.statistic[0].statisticType) {
          startingEvidenceValues.statisticType = EvidenceJson.statistic[0].statisticType;
          startingEntryValues.statisticType = EvidenceJson.statistic[0].statisticType;
        }
        if (EvidenceJson.statistic[0].quantity) {
          startingEvidenceValues.quantity = EvidenceJson.statistic[0].quantity;
          startingEntryValues.quantity = EvidenceJson.statistic[0].quantity;
        }
        if (EvidenceJson.statistic[0].sampleSize?.knownDataCount ||
          EvidenceJson.statistic[0].sampleSize?.knownDataCount === 0) {
          startingEvidenceValues.knownDataCount = EvidenceJson.statistic[0].sampleSize.knownDataCount;
          startingEntryValues.knownDataCount = EvidenceJson.statistic[0].sampleSize.knownDataCount;
        }
      }
    }
  }

  const [entryState, setEntryState] = useState(JSON.parse(JSON.stringify(startingEntryValues || null)));
  const [evidenceJsonState, setEvidenceJsonState] = useState(JSON.parse(JSON.stringify(startingEvidenceValues || null)));
  const [changesAvailableToSaveState, setChangesAvailableToSaveState] = useState(false);
  const [saveJsonChangesState, setSaveJsonChangesState] = useState(false);
  const [revisedValueState, setRevisedValueState] = useState({ "revisedValue": null, "rationale": "" });
  const [startingValueState, setStartingValueState] = useState(JSON.parse(JSON.stringify(startingValue || null)));

  const createNewReferencedResource = async (title, statisticType, quantity, knownDataCount, globalContext, addElementValues) => {

    let newResource = {
      "resourceType": "Evidence",
      "meta": {},
      "title": title || "[Untitled Evidence]",
      "status": "active",
      "statistic": [{}]
    };

    if (addElementValues) {
      for (const key in addElementValues) {
        if (addElementValues[key]) {
          newResource[key] = addElementValues[key];
          if (!title && key === "title") {
            title = newResource.title;
          }
        }
      }
    }

    if (newResource.statistic) {
      newResource.statistic[0].statisticType = statisticType;
      newResource.statistic[0].quantity = quantity;
      if (newResource.statistic[0].sampleSize) {
        newResource.statistic[0].sampleSize.knownDataCount = knownDataCount;
      } else {
        newResource.statistic[0].sampleSize = { "knownDataCount": knownDataCount };
      }
    } else {
      newResource.statistic = [{
        "statisticType": statisticType, "quantity": quantity,
        "sampleSize": { "knownDataCount": knownDataCount }
      }]
    }

    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': JSON.stringify(newResource),
      'title': title,
      'status': 'active',
    };
    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    let resourceFOI;
    let evidenceResourceValues;
    if (response.success) {
      resourceFOI = response.formstateid;
      evidenceResourceValues = {
        foi: resourceFOI, statisticType: statisticType, quantity: quantity,
        knownDataCount: knownDataCount, json: newResource
      }
    }
    return evidenceResourceValues;
  };

  const createEvidenceResource = async () => {
    let title = entryState?.entryInstance?.display || "[Untitled evidence]";
    let statisticType = evidenceJsonState.statisticType;
    let quantity = evidenceJsonState.quantity;
    let knownDataCount = evidenceJsonState.knownDataCount;
    let evidenceResourceValues = await createNewReferencedResource(title,
      statisticType, quantity, knownDataCount, globalContext, addElementValues);
    if (evidenceResourceValues?.foi) {
      setEvidenceJsonState(evidenceResourceValues);
      setEntryState({
        "statisticType": statisticType, "quantity": quantity, "knownDataCount": knownDataCount,
        "entryInstance": {
          "reference": "Evidence/" + evidenceResourceValues.foi,
          "type": "Evidence",
          "display": title
        }
      });
      setChangesAvailableToSaveState(false);
    } else {
      alert("A problem occurred when creating this Evidence Resource.");
    }
  };

  const saveReferencedResourceJsonChanges = async () => {
    setSaveJsonChangesState(true);
    if (evidenceJsonState?.foi) {
      let workingJson = JSON.parse(JSON.stringify(evidenceJsonState.json));
      if (typeof workingJson === "object") {
        if (!workingJson.statistic) {
          workingJson.statistic = [{}]
        }
        workingJson.statistic[0].statisticType = evidenceJsonState.statisticType;
        workingJson.statistic[0].quantity = evidenceJsonState.quantity;
        workingJson.statistic[0].sampleSize = { "knownDataCount": evidenceJsonState.knownDataCount };
        let body = {
          'functionid': 'updatefhirresource',
          'idToken': '',
          'fhirEntry': JSON.stringify(workingJson, null, 2),
          'resourcetype': "Evidence",
          'resourceid': evidenceJsonState.foi,
          'title': workingJson.title || workingJson.name,
          'status': "active",
          'bypasswarnings': true
        };
        let response = await submitToFevirServer(globalContext, 9000, body, false, false);
        if (!response?.success) {
          if (response.warningMessage) {
            alert(response.warningMessage);
          } else {
            alert("The Resource updating did not occur. You might not have editing rights to the referenced Resource.")
          }
        }
        loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState, null, null);
        setChangesAvailableToSaveState(false);
      } else {
        alert("ERROR: workingJson is not an object. Contact support@computablepublishing.com");
        console.log(evidenceJsonState);
      }
    }
    setSaveJsonChangesState(false);
    setChangesAvailableToSaveState(false);
  }

  useEffect(() => {
    if (entryState.entryInstance && startingEntryValues.entryInstance !== entryState.entryInstance) {
      setResourceState(prevState => { 
        return { ...prevState, "entry": [entryState.entryInstance] }; });
    }
  }, [entryState]);

  useEffect(() => {
    if (evidenceJsonState && JSON.stringify(evidenceJsonState) !== JSON.stringify(startingEvidenceValues)) {
      setChangesAvailableToSaveState(true);
    }
  }, [evidenceJsonState]);

  useEffect(() => {
    if (revisedValueState.revisedValue !== null && adaptationReportState.adaptationDictionary &&
      "[" + JSON.stringify(revisedValueState.revisedValue) + "]" !== JSON.stringify(adaptationReportState.adaptationDictionary[path]?.initialValue)) {
      let revisedValue = JSON.parse(JSON.stringify(revisedValueState.revisedValue));
      handleAdaptChange(adaptationReportState.adaptationDictionary, path,
        [revisedValue],
        setAdaptationReportState, setChangeAvailableToSaveState, "entry",
        setResourceState, JSON.parse(JSON.stringify(revisedValueState.rationale)));
      setStartingValueState(revisedValue);
    }
  }, [revisedValueState]);

  if (adaptationReportState?.adaptOn) {
    return <>
      {adaptationReportState.adaptationDictionary[path]?.itemChanged && <>
        <p>Explain the rationale for the change to the entry (reference to Evidence Resource) value. (Optional)</p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='string' elementName='rationale' fieldLabel='Rationale for change'
            startingValue={revisedValueState.rationale} setResourceState={setRevisedValueState} />
        </div>
      </>}
      <br />
      <DataEntry datatype='Reference' elementName='revisedValue' fieldLabel='Evidence Resource'
        startingValue={startingValueState[0] || null} referencedResourceTypes={["Evidence"]}
        startCollapsed={true} enableCreation={true} inTableCell={true}
        startingResourceType={"Evidence"}
        addElementValues={addElementValues}
        setResourceState={setRevisedValueState}
        fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
      />
      <br /> <br />
    </>
  }

  return <>
    {evidenceJsonState.foi ?
      <Button className="formButton" positive={changesAvailableToSaveState ? true : false}
        style={{ color: changesAvailableToSaveState ? "#FFFFFF" : "#000000" }}
        content={"Save changes to Evidence Resource"}
        onClick={saveReferencedResourceJsonChanges}
        disabled={(saveJsonChangesState || !changesAvailableToSaveState || (!evidenceJsonState || (!evidenceJsonState.foi && !evidenceJsonState.quantity)))} />
      :
      <Button className="formButton" style={{ color: "#000000" }}
        content={`Create this Evidence Resource`}
        onClick={createEvidenceResource} />}
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='statisticType' fieldLabel='Statistic Type'
        startingValue={evidenceJsonState.statisticType}
        valueSet={SEVCO.statisticType} inTableCell={true} startCollapsed={true}
        setResourceState={setEvidenceJsonState} />
      <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity' inTableCell={true}
        startingValue={evidenceJsonState.quantity} setResourceState={setEvidenceJsonState} />
      <DataEntry datatype='unsignedInt' elementName='knownDataCount' fieldLabel='Known Data Count'
        startingValue={evidenceJsonState.knownDataCount} setResourceState={setEvidenceJsonState} />
    </div>
    <DataEntry datatype='Reference' elementName='entryInstance' fieldLabel='Reference to this Evidence Resource'
      startingValue={entryState.entryInstance} referencedResourceTypes={["Evidence"]}
      startCollapsed={true} enableCreation={true} inTableCell={true}
      startingResourceType={"Evidence"}
      addElementValues={addElementValues}
      setResourceState={setEntryState}
      fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
    />
  </>
});

const EditTableCellModal = memo(({ setOpenEditModalState, setResourceState, generateTextDivFunction, entryHeader,
  endSectionState, entryStartingResourceType, resourceDictionary, globalContext, sectionCode, setProfile,
  statisticType, statisticUnit, knownDataCount, entryAddElementValues, setSourceJsonState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, resourceState,
  singleEvidenceEntry }) => {

  const submitChange = () => {
    setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <div>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ paddingLeft: "180px" }} >Edit Table Cell Content</h3>
      </div>
      <Button style={{ color: "#FFFFFF", position: "absolute", top: "6px", width: "180px", float: "left" }}
        className="formButton"
        content="Submit Changes" positive
        onClick={submitChange}
      />
      <Button style={{ padding: "6px", position: "absolute", top: "6px", right: "14px" }}
        className="formButton negative"
        content="✖"
        onClick={() => {
          setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
        }} />
    </div>
    <br /><br /><br />
    <NarrativeEntry elementName='text' fieldLabel='Section Summary'
      editTextStatus={false} editTextDiv={true}
      generateSummaryFunction={generateTextDivFunction}
      entryFoi={getFoiFromReference(endSectionState.entry, entryStartingResourceType, resourceDictionary)}
      resourceDictionary={resourceDictionary} globalContext={globalContext}
      startingValue={endSectionState.text} setResourceState={setResourceState}
      path={sectionCode}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    <br /><br />
    {entryHeader ?
      <h3>{entryHeader}</h3>
      :
      <h3>Change Resource References for the Section</h3>
    }
    <br />
    <div style={{ marginLeft: "24px" }}>
      {singleEvidenceEntry ?
        <SingleEvidenceEntry startingValue={endSectionState.entry}
          statisticType={statisticType} statisticUnit={statisticUnit}
          knownDataCount={knownDataCount}
          addElementValues={entryAddElementValues}
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          setResourceState={setResourceState} resourceState={resourceState}
          setSourceJsonState={setSourceJsonState}
          path={sectionCode + '.entry'}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        :
        <DataEntry asArray={true} datatype='Reference'
          elementName='entry' fieldLabel={entryHeader}
          startingValue={endSectionState.entry}
          setProfile={setProfile} startingResourceType={entryStartingResourceType}
          referencedResourceTypes={[entryStartingResourceType]}
          enableCreation={true}
          setResourceState={setResourceState}
          path={sectionCode + '.entry'}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      }
    </div>
    {((!endSectionState.section || endSectionState.section.length === 0) &&
      (!endSectionState.entry || endSectionState.entry.length === 0) &&
      (!endSectionState.text || endSectionState.text.status === "empty")) && <>
        <h3>Explain why this Table Cell is empty</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={endSectionState.emptyReason} valueSet={emptyReasonValueSet}
            startCollapsed={true}
            setResourceState={setResourceState}
            path={sectionCode + '.emptyReason'}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
      </>}
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "180px", float: "left" }} className="formButton"
      content="Submit Changes" positive
      onClick={submitChange}
    />
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
});

export default EditTableCellModal;