const toolVersions = {
    "platform": "version 0.279.0 (December 13, 2024)",
    "api": "v0.10.0 (November 21, 2024)",
    "Generic": "v0.8.0 (February 28, 2023)",
    "ActivityDefinition": "v0.21.0 (September 20, 2024)",
    "Adaptation": "v0.23.1 (November 11, 2024)",
    "Baseline Measure Report": "v0.19.0 (November 20, 2024)",
    "Bundle": "v0.5.0 (September 201, 2024)",
    "Citation": "v1.29.0 (December 10, 2024)",
    "Classification": "v0.10.3 (December 12, 2024)",
    "CodeSystem": "v0.45.0 (October 17, 2024)",
    "Comment": "v0.6.1 (December 5, 2023)",
    "Comparative Evidence Report": "v0.42.0 (November 20, 2024)",
    "Comparative Evidence Synthesis Report": "v0.13.0 (November 20, 2024)",
    "Comparison": "v0.6.2 (November 11, 2024)",
    "Composition": "v0.28.0 (November 6, 2024)",
    "Evidence": "v0.37.0 (September 20, 2024)",
    "EvidenceReport": "v0.10.0 (October 18, 2024)",
    "EvidenceReportPackage": "v0.5.0 (October 18, 2024)",
    "EvidenceVariable": "v0.34.0 (October 29, 2024)",
    "Group": "v0.34.0 (September 20, 2024)",
    "Guideline": "v0.26.1 (November 19, 2024)",
    "Library": "v0.4.0 (September 20, 2024)",
    "List": "v0.6.0 (September 20, 2024)",
    "M11Report": "v0.24.0 (October 18, 2024)",
    "Measure": "v0.4.1 (September 20, 2024)",
    "Outcome Measure Report": "v0.12.0 (November 25, 2024)",
    "Outcome Measure Synthesis Report": "v0.7.0 (November 26, 2024)",
    "Participant Flow Report": "v0.10.0 (November 20, 2024)",
    "PlanDefinition": "v0.5.0 (September 20, 2024)",
    "Project": "v0.63.4 (September 19, 2024)",
    "Questionnaire": "v0.5.0 (May 11, 2023)",
    "QuestionnaireResponse": "v0.3.1 (February 28, 2022)",
    "Rating": "v0.7.0 (July 10, 2023)",
    "Recommendation": "v0.31.1 (November 19, 2024)",
    "RecommendationResource": "v0.9.1 (January 12, 2022)",
    "ResearchStudy": "v0.14.0 (December 5, 2024)",
    "SchemaElement": "v0.4.0 (June 28, 2022)",
    "SoftwareScript": "v0.7.2 (April 10, 2023)",
    "Summary Of Findings": "v0.35.1 (November 19, 2024)",
    "Systematic Review": "v0.2.1 (August 1, 2024)",
    "ValueSet": "v0.11.0 (September 20, 2024)",
    "createevidence": "v0.1.1 (June 26, 2021)",
    "createcodeableconcept": "v0.6.2 (April 13, 2022)",
    "createcommentrating": "v0.13.2 (December 23, 2021)",
    "Risk of Bias Assessment Tool": "v0.18.0 (November 11, 2024)",
    "Risk of Bias Assessment Reader": "v0.3.0 (November 16, 2022)",
    "Recommendation Justification": "v0.29.1 (November 11, 2024)",
    "docxtojsonsections": "v0.3.2 (September 16, 2024)",
    "medlineconvert": "v1.22.6 (November 11, 2024)",
    "medlinesearchimport": "v0.1.0 (June 16, 2023)",  //DELETE THIS AND ANYTHING USES IT, REMOVE ROUTING
    "ctgovconvert": "v4.15.0 (December 6, 2024)",
    "fhirtoctgovjson": "v1.4.1 (September 1, 2022)",
    "recommendationtableviewer": "v0.9.0 (March 21, 2022)",
    "knowledgeportaldemo": "v0.17.2 (March 21, 2022)",
    "myballot": "v0.7.0 (May 2, 2024)",
    "ris": "v0.13.0 (February 5, 2024)",
    "gradepro": "v0.11.0 (November 27, 2024)",
    "magic": "v0.27.1 (December 12, 2024)",
    "srdr": "v0.4.0 (August 21, 2023)",
    "measuringtherate": "v0.23.0 (September 3, 2024)" //RADAR Tool
  };

export default toolVersions;