import React, { useState, useEffect, memo } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { DisplayFromFHIR, jsonErrorCheck2 } from './ResourceFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import { DataEntry } from './DataEntryFormFunctions';
import { useToasts } from 'react-toast-notifications';
import { NarrativeEntry } from './NarrativeEntry';
import { emptyReasonNotStarted } from './CodeSystemLookup';

// What it does. (All done on the server)
//1. Composition Resource is primary URL for human use and target for classifications on FEvIR Platform
//2. Bundle Resource (type = document) is the 'computable resource' for the document -- apply to Computable Resource portion of citation summary
//3. When Composition Resource is updated, map all referenced Resources to the corresponding Bundle and Bundle is automatically updated and stored in formstatetable
//3a. The FLI for the Bundle Resource can be 'DocumentFromComposition' + compositionFoi
//4. TO DO: On Client-Side, if the Bundle Builder/Viewer is put into edit mode for a Bundle.type=document with a Composition on the FEvIR Platform, move the user to the corresponding Authoring Tool around the Composition Resource

const getResourcesJsonArray = async (globalContext, resourceFoiArray) => {
  const body = {
    'functionid': 'getmultiplefhirresources',
    'resourceids': resourceFoiArray,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 20000, body, true, false);
  if (response?.success && response.resourceentries) {
    let resourcesJsonArray = response.resourceentries.map(entry => {
      return JSON.parse(entry.toolstatestring);
    });
    return resourcesJsonArray;
  } else {
    return null;
  }
}

const builderUpdateCompositionDocumentJson = async (resourceId, fhirEntryState, formInputsStateRef, globalContext) => {

  let compositionState = formInputsStateRef.current.compositionDocumentState;

  const body = {
    'functionid': "builderupdatecompositiondocumentjson",
    'idToken': "",
    'resourceId': resourceId,
    'fhirEntryState': fhirEntryState,
    'compositionState': compositionState
  };

  let response = await submitToFevirServer(globalContext, 20000, body, true, false);

  if (response?.success) {
    let fhirJson = response.fhirJson;
    let newClassificationJson = response.newClassificationJson;
    return [fhirJson, newClassificationJson];
  }

}

const sectionDetailViewChangesToJson = (sectionDetailState) => {
  let sectionDetailJson = {};
  sectionDetailJson.title = sectionDetailState.title || "DELETEME";
  sectionDetailJson.code = sectionDetailState.code || "DELETEME";
  sectionDetailJson.focus = sectionDetailState.focus || "DELETEME";
  sectionDetailJson.text = sectionDetailState.text || "DELETEME";
  sectionDetailJson.author = sectionDetailState.author || "DELETEME";
  sectionDetailJson.orderedBy = sectionDetailState.orderedBy || "DELETEME";
  sectionDetailJson.entry = sectionDetailState.entry || "DELETEME";
  sectionDetailJson.section = sectionDetailState.section || "DELETEME";
  sectionDetailJson.emptyReason = sectionDetailState.emptyReason || "DELETEME";
  sectionDetailJson.extension = sectionDetailState.extension || "DELETEME";
  for (const key in sectionDetailJson) {
    if (sectionDetailJson[key] === "DELETEME" ||
      (typeof sectionDetailJson[key] === "object" && Object.keys(sectionDetailJson[key]).length === 0) ||
      sectionDetailJson[key] === "" || sectionDetailJson[key] === undefined || sectionDetailJson[key] === null) {
      delete sectionDetailJson[key];
    }
  }
  return sectionDetailJson;
}

const builderUpdateCompositionSectionDetailJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  let sectionDetailState = formInputsStateRef.current.sectionState;
  let newSectionDetailJson = sectionDetailViewChangesToJson(sectionDetailState);
  let sectionPathIndexes = sectionDetailState.sectionPathIndexes;
  let section = fhirJson;
  for (let i in sectionPathIndexes) {
    let sectionIndex = sectionPathIndexes[i];
    if (parseInt(i) + 1 === sectionPathIndexes.length) {
      section.section[sectionIndex] = newSectionDetailJson;
    } else {
      section = section.section[sectionIndex];
    }
  }
  fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirJson);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  return fhirJson;
}

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } })
  console.log("setResourceState from CompostionDocumentBundleFunctions handleChange")
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}
//copied to SectionDetail (so can be deleted from here when all section editing moved to SectionDetail)
const submitCreateCitation = async (addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry) => {
  if (createCitationModalState.title) {
    const body = {
      'functionid': 'createcitationforreferencelist', //modeled from submitaddcitationtoproject
      'idToken': "",
      'title': createCitationModalState.title,
      'pmid': createCitationModalState.pmid,
      'description': createCitationModalState.description,
      'compositionFOI': compositionFOI,
      'cmpositionTitle': compositionTitle,
      'username': globalContext.userState.name
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        if (response.citationFOI) {
          addCitationToEntry(response.citationFOI, createCitationModalState.title);
        }
        addToast('Your Citation for this reference list has been submitted.', { appearance: 'success' });
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false, "title": "", "pmid": "", "description": "" }; });
        window.open("/resources/Citation/" + response.citationFOI, "_blank"); //opens newly created Citation resource in a new tab
      } else {
        alert("There was a problem with your submission. Perhaps the PMID you provided is non-existant");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    alert("A title is required.");
  }
};
//copied to SectionDetail (so can be deleted from here when all section editing moved to SectionDetail)
const CreateCitationModal = ({ addToast, globalContext, compositionFOI, compositionTitle,
  createCitationModalState, setCreateCitationModalState, addCitationToEntry }) => {
  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Create and Add Citation to Reference List</h3>
    <br />
    <TextField style={{ width: "580px" }} className="inputField" type='text' label={'Citation Title'}
      size="small" variant='outlined' value={createCitationModalState.title}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, title: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "280px" }} className="inputField" type='number' label={'PMID (optional)'}
      size="small" variant='outlined' value={createCitationModalState.pmid}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, pmid: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "580px" }} multiline className="inputField" type='text'
      label={'Citation Description (optional)'} size="small" variant='outlined'
      value={createCitationModalState.description}
      onChange={(e) => {
        setCreateCitationModalState(prevState => { return { ...prevState, description: e.target.value }; });
      }} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Citation" positive
      onClick={() => {
        submitCreateCitation(addToast, globalContext, compositionFOI, compositionTitle,
          createCitationModalState, setCreateCitationModalState, addCitationToEntry);
      }}
    />
    <br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={createCitationModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const autoEditEmptyReason = (newSection) => {
  if (newSection.section?.length || newSection.entry?.length ||
    (newSection.text && newSection.text.status !== "empty")) {
    if (newSection.emptyReason) {
      delete newSection.emptyReason;
    }
  } else if (!newSection.emptyReason) {
    newSection.emptyReason = emptyReasonNotStarted;
  }
  return newSection;
}
// TO DO
//map the use of xxxState across Builder/Viewer Tools and determine if Authoring/Viewing Tools should be handled the same way or consolidated into documentBundleState
//recommendationTextViewChangesToJson is async/await because it creates/updates PlanDefinition and RecommendationJustification Resources
//Does this async/await appraoch apply across the Authoring/Viewing Tools? Perhaps yes if the real role is editing Composition PLUS structured data

// entryResourceEntry is (the interface to enter changes to the referenced Resource JSON)
const DocumentSectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, sectionArrayIndex, parentSectionCode,
  fixedTitle, editTitle, fixedCode, editCode, editCodeAllowedValues, inTableCell,
  editCodeValueSet, editCodeInTableCell, editCodeSystemChoices, editCodeSystemChoicesOpen, editCodeStartCollapsed,
  editCodeCodeableConceptLevelValueSet, editAuthor, editFocus, editFocusEnableCreation, editFocusReferencedResourceTypes,
  editFocusStartCollapsed, editFocusStartingResourceType,
  editFocusSetProfile, editFocusSelectProfile, editFocusSelectAnyProfile, editFocusAddElementValues,
  textStatusAutomatic, editTextStatus, editTextDiv, textDeletable, generateTextDivFunction,
  autogenerateTextDiv, emptyTextDivValue, editTextFunction, generateNarrativeFromSingleEntryFunction,
  generateNarrativeFromEntryArrayFunction, entryFoiList,
  fixedOrderedBy, editOrderedBy, editOrderedByAllowedValues, editOrderedByValueSet, editOrderedByInTableCell,
  editOrderedBySystemChoices, editOrderedBySystemChoicesOpen, editOrderedByStartCollapsed,
  editOrderedByCodeableConceptLevelValueSet, entryLimitToOne, entryLimitToTwo, noEntry, entryHeader,
  entryDeletable, entryInstanceDeletable, entryEnableCreation, entryReferencedResourceTypes,
  statisticType, statisticUnit, knownDataCount, createCitation,
  entryStartCollapsed, entryStartEmptyArrayClosed, entryStartingResourceType, entrySetProfile, entrySelectProfile, entrySelectAnyProfile,
  entryAddElementValues, fixedEmptyReason, editEmptyReason, emptyReasonAllowedValues, emptyReasonValueSet,
  emptyReasonInTableCell, emptyReasonSystemChoices, emptyReasonSystemChoicesOpen, emptyReasonStartCollapsed,
  emptyReasonCodeableConceptLevelValueSet, noSection, editSection, containedSectionsHeader,
  addSectionAllowed, sectionEntryRecommendationOnly,
  allowedSectionCodes, requiredSectionCodes, editableSectionCodes, entryResourceEntry,
  topSection, sectionDictionary, resourceDictionary, setSourceJsonState, globalContext,
  resourceState, previousVersionLoaded,
  compositionId, compositionTitle, sectionCode,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  if (adaptationReportState?.pathList && !sectionCode && startingValue?.code) {
    if (sectionArrayIndex || sectionArrayIndex === 0) {
      if (startingValue.code.text) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section[" + sectionArrayIndex + "]:" + JSON.stringify(startingValue.code);
      }
    } else {
      if (startingValue.code.text) {
        sectionCode = "section:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section:" + JSON.stringify(startingValue.code);
      }
    }
    if (parentSectionCode) {
      sectionCode = parentSectionCode.split(":")[0] + "." + sectionCode;
    }
  }

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }
  //TODO - move these values to imported constants
  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];
  let orderedByValueSet = [
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
    { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
  ];
  let orderedByCodeableConceptLevelValueSet = [
    {
      "coding": [{ "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" }
      ]
    },
    {
      "coding": [
        { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
      ]
    }
  ];
  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp }, author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }
  if (fixedTitle) {
    startingSection.title = fixedTitle;
  }
  if (fixedCode) {
    startingSection.code = fixedCode;
  }
  if (fixedOrderedBy) {
    startingSection.orderedBy = fixedOrderedBy;
  }

  const [sectionState, setSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const { addToast } = useToasts();
  const [createCitationModalState, setCreateCitationModalState] = useState({
    "modalOpen": false,
    "title": "", "pmid": "", "description": ""
  });
  const [citationEntryRevisedState, setCitationEntryRevisedState] = useState(false);

  const addCitationToEntry = (citationFOI, title) => {
    setSectionState(prevState => {
      let newEntry = prevState.entry || [];
      newEntry.push({
        "reference": "Citation/" + citationFOI,
        "type": "Citation", "display": title
      });
      return { ...prevState, "entry": newEntry };
    });
    setCitationEntryRevisedState(true);
  };

  useEffect(() => {
    if (citationEntryRevisedState) {
      alert("Citation added."); //This alert appears at the right time but does not re-render the component
      setCitationEntryRevisedState(false);
    }
  }, [citationEntryRevisedState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0 &&
      JSON.stringify(startingSection) !== JSON.stringify(sectionState)) {
      let newSection = {};
      if (sectionState.extension) { newSection.extension = sectionState.extension; }
      if (sectionState.title) { newSection.title = sectionState.title; }
      if (sectionState.code) { newSection.code = sectionState.code; }
      if (Array.isArray(sectionState.author) && sectionState.author.length > 0) {
        newSection.author = sectionState.author;
      }
      if (sectionState.focus) { newSection.focus = sectionState.focus; }
      if (sectionState.text) { newSection.text = sectionState.text; }
      if (sectionState.orderedBy) { newSection.orderedBy = sectionState.orderedBy; }
      if (Array.isArray(sectionState.entry) && sectionState.entry.length > 0) {
        newSection.entry = sectionState.entry;
      }
      if (sectionState.emptyReason) { newSection.emptyReason = sectionState.emptyReason; }
      if (Array.isArray(sectionState.section) && sectionState.section.length > 0) {
        newSection.section = sectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          let newSectionArray = prevState.section || [];
          newSectionArray[sectionArrayIndex] = newSection;
          return { ...prevState, "section": newSectionArray };
        });
        console.log("setResourceState from CompostionDocumentBundleFunctions DocumentSectionEntry")
      } else if (elementName || elementName === 0 || elementName === "0") {
        handleChange(elementName, newSection, setResourceState);
      }
    }
  }), [sectionState]);

  if (startCollapsedState) {
    return <>
      <div>
        {fieldLabel && <b>{fixedTitle || sectionState.title || fieldLabel}: </b>}
        {startingValue && <div style={{ marginLeft: "24px" }}>
          {(startingSection.title && !inTableCell) && <p><b>Title: </b>{startingSection.title}</p>}
          {startingSection.text?.div &&
            <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
          {startingSection.entry?.length > 0 && <>
            {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
              :
              <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
          </>}
          {startingSection.section?.length > 0 && <>
            {inTableCell ? <><br />Contains {startingSection.section.length} subsections</> : <>
              <div>
                <p><b>Subsections:</b></p>
                {startingSection.section.map((section, sectionIndex) => {
                  return <div key={sectionIndex} style={{ marginLeft: "24px" }}>
                    {section.title && <span><b>Title: </b>{section.title}</span>}
                    {section.text?.div && <div><br /><b>Summary: </b><DisplayFromFHIR xhtml={section.text.div} /></div>}
                  </div>
                })}
              </div>
            </>}</>}
          <br />
        </div>}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
        &nbsp;&nbsp;{!inTableCell && (fixedTitle || sectionState.title || fieldLabel)}
      </div>
    </>
  } else {
    return <>
      {createCitation && <>
        <CreateCitationModal addToast={addToast} globalContext={globalContext}
          compositionFOI={resourceState.id} compositionTitle={resourceState.title}
          createCitationModalState={createCitationModalState}
          setCreateCitationModalState={setCreateCitationModalState}
          addCitationToEntry={addCitationToEntry} />
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={"Create Citation"}
          onClick={async () => {
            setCreateCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
          }}
          disabled={previousVersionLoaded}
        />
        <br /></>}
      {fieldLabel && <p><b>{fixedTitle || sectionState.title || fieldLabel}: </b></p>}
      <div style={{ marginLeft: "24px" }}>
        {fixedTitle && <p><b>Title: </b>{fixedTitle}</p>}
        {editTitle && <>
          <DataEntry datatype='string' elementName='title' fieldLabel='Section Title'
            startingValue={sectionState.title} setResourceState={setSectionState}
            path={sectionCode + '.title'}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>}
        {editCode && <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Section Code'
          startingValue={sectionState.code} allowedValues={editCodeAllowedValues}
          valueSet={editCodeValueSet} inTableCell={editCodeInTableCell} systemChoices={editCodeSystemChoices}
          systemChoicesOpen={editCodeSystemChoicesOpen} startCollapsed={editCodeStartCollapsed ?? true}
          codeableConceptLevelValueSet={editCodeCodeableConceptLevelValueSet}
          setResourceState={setSectionState} />}
        {(textStatusAutomatic || editTextStatus || editTextDiv || textDeletable ||
          generateTextDivFunction || autogenerateTextDiv || editTextFunction) &&
          <NarrativeEntry elementName='text' fieldLabel='Section Summary'
            editTextStatus={editTextStatus} editTextDiv={editTextDiv} emptyTextDivValue={emptyTextDivValue}
            editTextFunction={editTextFunction}
            generateSummaryFunction={generateTextDivFunction}
            generateNarrativeFromSingleEntryFunction={generateNarrativeFromSingleEntryFunction}
            generateNarrativeFromEntryArrayFunction={generateNarrativeFromEntryArrayFunction}
            entryFoi={sectionState.entry?.[0]?.reference?.split("/")[1]}
            entryFoiList={entryFoiList}
            resourceDictionary={resourceDictionary} globalContext={globalContext}
            startingValue={sectionState.text} setResourceState={setSectionState}
            path={sectionCode}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {editAuthor && <>
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
            {editAuthorCollapsedState ? <>
              <b>Add/Edit Author(s) for the Section ►</b>
            </> : <>
              <b>Collapse Section Author Data Entry ▼</b>
            </>}
          </span>
          <br />
          {!editAuthorCollapsedState && <>
            <DataEntry asArray={true} datatype='Reference' elementName='author'
              fieldLabel='Section Author'
              startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
              startCollapsed startEmptyArrayClosed
              setResourceState={setSectionState}
              path={sectionCode + '.author'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </>
          }
        </>}
        {editFocus && <>
          {editFocusEnableCreation ?
            <h3>Create or identify the Resource defining the focus for this section.</h3>
            :
            <h3>Identify the Resource defining the focus for this section.</h3>}
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='Reference' elementName='focus'
              fieldLabel={editFocusStartingResourceType + ' Resource'}
              startingValue={sectionState.focus} referencedResourceTypes={editFocusReferencedResourceTypes}
              startingResourceType={editFocusStartingResourceType} setProfile={editFocusSetProfile}
              selectProfile={editFocusSelectProfile} selectAnyProfile={editFocusSelectAnyProfile}
              startCollapsed={editFocusStartCollapsed ?? true} enableCreation={editFocusEnableCreation}
              setResourceState={setSectionState} addElementValues={editFocusAddElementValues}
              fullResourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState}
              path={sectionCode + '.focus'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>}
        {editOrderedBy && <>
          <h3>Ordering of Section Content</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='orderedBy' fieldLabel='Ordered by'
              startingValue={sectionState.orderedBy} valueSet={editOrderedByValueSet || orderedByValueSet}
              startCollapsed={editOrderedByStartCollapsed ?? true}
              codeableConceptLevelValueSet={editOrderedByCodeableConceptLevelValueSet || orderedByCodeableConceptLevelValueSet}
              allowedValues={editOrderedByAllowedValues} inTableCell={editOrderedByInTableCell}
              systemChoices={editOrderedBySystemChoices} systemChoicesOpen={editOrderedBySystemChoicesOpen}
              setResourceState={setSectionState}
              path={sectionCode + '.orderedBy'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>}
        {!noEntry && <>
          {entryHeader ?
            <h3>{entryHeader}</h3>
            :
            <h3>{fieldLabel} Section Content (as Resource Entries)</h3>
          }
          <div style={{ marginLeft: "24px" }}>
            <DataEntry asArray={true} datatype='Reference' elementName='entry' fieldLabel={fieldLabel + ' Content Entry'}
              startingValue={sectionState.entry} referencedResourceTypes={entryReferencedResourceTypes}
              startCollapsed={entryStartCollapsed || sectionEntryRecommendationOnly}
              startEmptyArrayClosed={entryStartEmptyArrayClosed || sectionEntryRecommendationOnly}
              inTableCell={inTableCell}
              deletableArray={entryDeletable || sectionEntryRecommendationOnly}
              deletable={entryInstanceDeletable || sectionEntryRecommendationOnly}
              enableCreation={entryEnableCreation || sectionEntryRecommendationOnly}
              startingResourceType={entryStartingResourceType ? entryStartingResourceType : sectionEntryRecommendationOnly && "Composition"}
              setProfile={entrySetProfile ? entrySetProfile : sectionEntryRecommendationOnly && "Recommendation"}
              selectProfile={entrySelectProfile} selectAnyProfile={entrySelectAnyProfile}
              addElementValues={entryAddElementValues}
              setResourceState={setSectionState} fullResourceState={resourceState}
              globalContext={globalContext} setSourceJsonState={setSourceJsonState}
              path={sectionCode + '.entry'}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>}
        {editSection && <>
          {containedSectionsHeader ?
            <p><b>{containedSectionsHeader}</b></p>
            :
            <p><b>Contained Sections (for text with subheadings):</b></p>
          }
          <SectionArrayDataEntry startingValue={sectionState.section} setResourceState={setSectionState}
            addSectionAllowed={addSectionAllowed}
            allowedSectionCodes={allowedSectionCodes}
            requiredSectionCodes={requiredSectionCodes}
            editableSectionCodes={editableSectionCodes}
            sectionDictionary={sectionDictionary}
            topSection={sectionState.title || topSection + " subsection"}
            compositionId={compositionId} compositionTitle={compositionTitle}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState}
            globalContext={globalContext} parentSectionCode={sectionCode}
            sectionEntryRecommendationOnly={sectionEntryRecommendationOnly}
            entryReferencedResourceTypes={entryReferencedResourceTypes}
            entryResourceEntry={entryResourceEntry}
            resourceState={resourceState} setSourceJsonState={setSourceJsonState}
          />
        </>}
        {(editEmptyReason && (!sectionState.section || sectionState.section.length === 0) &&
          (!sectionState.entry || sectionState.entry.length === 0) &&
          (!sectionState.text || sectionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={sectionState.emptyReason} valueSet={emptyReasonValueSet}
                startCollapsed={emptyReasonStartCollapsed || inTableCell}
                allowedValues={emptyReasonAllowedValues} inTableCell={emptyReasonInTableCell || inTableCell} systemChoices={emptyReasonSystemChoices}
                systemChoicesOpen={emptyReasonSystemChoicesOpen} codeableConceptLevelValueSet={emptyReasonCodeableConceptLevelValueSet}
                setResourceState={setSectionState}
                path={sectionCode + '.emptyReason'}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </div>
          </>}
      </div>
    </>
  }
})

const SectionArrayDataEntry = memo(({ startingValue, setResourceState, topSection,
  addSectionAllowed, parentSectionCode,
  allowedSectionCodes, requiredSectionCodes, editableSectionCodes, sectionDictionary,
  compositionId, compositionTitle,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, globalContext,
  sectionEntryRecommendationOnly,
  entryReferencedResourceTypes, entryResourceEntry, resourceState, setSourceJsonState }) => {
  let showDataEntry = true;
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    //startingArrayAsObject['0'] = null;
    showDataEntry = false;
  }

  const [arrayState, setArrayState] = useState(JSON.parse(JSON.stringify(startingArrayAsObject || {})));
  const [showDataEntryState, setShowDataEntryState] = useState(showDataEntry);

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) && !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      if (newArray.length > 0) {
        handleChange("section", newArray, setResourceState);
      } else {
        handleChange("section", null, setResourceState);
      }
    }
  }), [arrayState]);

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {(showDataEntryState === true) &&
        Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
          return <div key={keyValuePairIndex}>
            {keyValuePair[1] === "DELETEME" ?
              <>
                <p><b>{topSection + ' Section entry ' + (keyValuePairIndex + 1)}: </b></p>
                <div style={{ marginLeft: "24px" }}>WILL BE DELETED.</div>
              </>
              :
              <>{(sectionDictionary && keyValuePair[1]?.code &&
                Object.keys(sectionDictionary).includes(JSON.stringify(keyValuePair[1].code))) ?
                <DocumentSectionEntry elementName={keyValuePair[0]} topSection={topSection}
                  parentSectionCode={parentSectionCode} sectionArrayIndex={keyValuePair[0]}
                  globalContext={globalContext}
                  fieldLabel={topSection + ' Section entry ' + (keyValuePairIndex + 1)}
                  startingValue={keyValuePair[1]} setResourceState={setArrayState}
                  startCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].startCollapsed}
                  fixedTitle={sectionDictionary[JSON.stringify(keyValuePair[1].code)].fixedTitle}
                  editTitle={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTitle}
                  editTextStatus={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextStatus}
                  editTextDiv={typeof (sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextDiv) === "boolean" ?
                    sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextDiv : true}
                  textDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].textDeletable}
                  emptyTextDivValue={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyTextDivValue}
                  editTextFunction={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editTextFunction}
                  entryFoi={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryFoi}
                  generateTextDivFunction={sectionDictionary[JSON.stringify(keyValuePair[1].code)].generateTextDivFunction}
                  editFocus={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocus}
                  editFocusEnableCreation={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusEnableCreation}
                  editFocusReferencedResourceTypes={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusReferencedResourceTypes}
                  editFocusStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusStartCollapsed}
                  editFocusStartingResourceType={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusStartingResourceType}
                  editFocusSetProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSetProfile}
                  editFocusSelectProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSelectProfile}
                  editFocusSelectAnyProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editFocusSelectAnyProfile}
                  noEntry={sectionDictionary[JSON.stringify(keyValuePair[1].code)].noEntry}
                  entryHeader={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryHeader}
                  entryDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryDeletable}
                  entryInstanceDeletable={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryInstanceDeletable}
                  entryEnableCreation={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryEnableCreation}
                  entryReferencedResourceTypes={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryReferencedResourceTypes}
                  entryStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartCollapsed}
                  entryStartEmptyArrayClosed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartEmptyArrayClosed}
                  entryStartingResourceType={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entryStartingResourceType}
                  entrySetProfile={sectionDictionary[JSON.stringify(keyValuePair[1].code)].entrySetProfile}
                  editEmptyReason={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editEmptyReason}
                  emptyReasonValueSet={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonValueSet}
                  emptyReasonCodeableConceptLevelValueSet={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonCodeableConceptLevelValueSet}
                  emptyReasonStartCollapsed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].emptyReasonStartCollapsed}
                  noSection={sectionDictionary[JSON.stringify(keyValuePair[1].code)].noSection}
                  editSection={sectionDictionary[JSON.stringify(keyValuePair[1].code)].editSection}
                  addSectionAllowed={sectionDictionary[JSON.stringify(keyValuePair[1].code)].addSectionAllowed}
                  containedSectionsHeader={sectionDictionary[JSON.stringify(keyValuePair[1].code)].containedSectionsHeader}
                  sectionDictionary={sectionDictionary[JSON.stringify(keyValuePair[1].code)].sectionDictionary}
                  compositionId={compositionId} compositionTitle={compositionTitle}
                  adaptationReportState={adaptationReportState}
                  setAdaptationReportState={setAdaptationReportState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState}
                  resourceState={resourceState} setSourceJsonState={setSourceJsonState}
                />
                :
                <DocumentSectionEntry elementName={keyValuePair[0]} topSection={topSection}
                  parentSectionCode={parentSectionCode} sectionArrayIndex={keyValuePair[0]}
                  fieldLabel={topSection + ' Section entry ' + (keyValuePairIndex + 1)}
                  editTitle={true} editCode={true}
                  startingValue={keyValuePair[1]} setResourceState={setArrayState}
                  sectionDictionary={sectionDictionary} editTextDiv={true}
                  startCollapsed={keyValuePair[1] && true}
                  addSectionAllowed={addSectionAllowed}
                  editSection={addSectionAllowed}
                  compositionId={compositionId} compositionTitle={compositionTitle}
                  adaptationReportState={adaptationReportState}
                  setAdaptationReportState={setAdaptationReportState}
                  setChangeAvailableToSaveState={setChangeAvailableToSaveState}
                  sectionEntryRecommendationOnly={sectionEntryRecommendationOnly}
                  entryReferencedResourceTypes={entryReferencedResourceTypes}
                  entryResourceEntry={entryResourceEntry}
                  resourceState={resourceState} setSourceJsonState={setSourceJsonState}
                />
              }</>
            }
          </div>
        })}
      <><br />
        {addSectionAllowed &&
          <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Section"}
            onClick={() => {
              setArrayState(prevState => {
                let arrayLength = Object.keys(prevState).length;
                let arrayIndexNumber = arrayLength.toString();
                if (prevState['0'] === null) {
                  arrayIndexNumber = '1';
                }
                if (allowedSectionCodes?.length === 1) {
                  if (sectionDictionary[JSON.stringify(allowedSectionCodes[0])]?.sectionDictionary) {
                    let subsection = [];
                    for (let key in sectionDictionary[JSON.stringify(allowedSectionCodes[0])].sectionDictionary) {
                      subsection.push({ "code": JSON.parse(key) });
                    }
                    return {
                      ...prevState,
                      [arrayIndexNumber]: { "code": allowedSectionCodes[0], "section": subsection }
                    };
                  } else {
                    return {
                      ...prevState,
                      [arrayIndexNumber]: { "code": allowedSectionCodes[0] }
                    };
                  }
                } else {
                  return {
                    ...prevState,
                    //['0']: prevState['0'] || "",
                    [arrayIndexNumber]: ""
                  };
                }
              });
              if (!showDataEntryState) {
                setShowDataEntryState(true);
              }
            }} />}
      </>
    </div>
  </div>
});

//note no author at the "Bundle Resource" level -- the metadata needs to be in Composition in the Bundle

export {
  autoEditEmptyReason, builderUpdateCompositionDocumentJson, builderUpdateCompositionSectionDetailJson,
  DocumentSectionEntry, getResourcesJsonArray
};