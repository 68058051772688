import React, { useState, useEffect, useContext, memo } from 'react';
import { emptyReasonNotStarted, emptyReasonValueSet, emptyTextNoData, sectionCodeComparatorGroup, sectionCodeInterventionGroup, sectionCodePopulation, sectionCodeResultWithComparatorAlone, sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionAloneCalculated, sectionCodeSummaryOfFindingsForSingleOutcome } from './CodeSystemLookup';
import { DataEntry, DatatypeSelector, ExpandToAddOrEdit } from './DataEntryFormFunctions';
import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { Button, Table } from 'semantic-ui-react';
import { TextField, Checkbox } from '@mui/material';
import SEVCO from './SEVCO';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import { ModelCharacteristicDisplay } from './EvidenceDisplay';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
  console.log("setResourceState from DataEntryFormResourceBackboneFunctions handleChange")
}

const SearchPieceEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  dataEntryStyle, typeValueSet, classifierValueSet }) => {
  let startingSearchPiece = { type: "", classifier: [] };
  let startingTypeSpecificClassifierValues = [];
  if (!startingValue) {
    startingSearchPiece = {};
  } else {
    if (startingValue.extension) { startingSearchPiece.extension = startingValue.extension; }
    if (startingValue.type) {
      startingSearchPiece.type = startingValue.type;
      startingTypeSpecificClassifierValues = classifierValueSet[startingValue.type];
    }
    if (startingValue.classifier) { startingSearchPiece.classifier = startingValue.classifier; }
  }

  const [searchPieceState, setSearchPieceState] = useState(JSON.parse(JSON.stringify(startingSearchPiece || {})));
  const [typeSpecificClassifierValuesState, setTypeSpecificClassifierValuesState] = useState(JSON.parse(JSON.stringify(startingTypeSpecificClassifierValues || {})));

  useEffect((() => {
    if (Object.keys(searchPieceState).length) {
      let newSearchPiece = {};
      if (searchPieceState.extension) { newSearchPiece.extension = searchPieceState.extension; }
      if (searchPieceState.type) {
        newSearchPiece.type = searchPieceState.type;
        setTypeSpecificClassifierValuesState(classifierValueSet[newSearchPiece.type]);
      }
      if (Array.isArray(searchPieceState.classifier) && searchPieceState.classifier.length > 0) {
        newSearchPiece.classifier = searchPieceState.classifier;
      }
      if (Object.keys(newSearchPiece).length === 0) {
        newSearchPiece = null;
      }
      handleChange(elementName, newSearchPiece, setResourceState);
    }
  }), [searchPieceState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='code' elementName='type' fieldLabel='Attribute Type'
        allowedValues={typeValueSet} dataEntryStyle='dropdownsearch'
        startingValue={searchPieceState.type} setResourceState={setSearchPieceState} />
      <DataEntry asArray={true} datatype='code' elementName='classifier' fieldLabel='Has Values Of'
        allowedValues={typeSpecificClassifierValuesState} dataEntryStyle='dropdownsearch'
        startingValue={searchPieceState.classifier || []} setResourceState={setSearchPieceState} />
    </div>
  </>
});

const ActivityDefinitionDynamicValueEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingDynamicValue = { path: "", expression: {} };
  if (!startingValue) {
    startingDynamicValue = {};
  } else {
    if (startingValue.extension) { startingDynamicValue.extension = startingValue.extension; }
    if (startingValue.path) { startingDynamicValue.path = startingValue.path; }
    if (startingValue.expression) { startingDynamicValue.expression = startingValue.expression; }
  }

  const [dynamicValueState, setDynamicValueState] = useState(JSON.parse(JSON.stringify(startingDynamicValue || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(dynamicValueState).length) {
      let newDynamicValue = {};
      if (dynamicValueState.extension) { newDynamicValue.extension = dynamicValueState.extension; }
      if (dynamicValueState.path) { newDynamicValue.path = dynamicValueState.path; }
      if (dynamicValueState.expression && Object.keys(dynamicValueState.expression).length) {
        newDynamicValue.expression = dynamicValueState.expression;
      }
      if (Object.keys(newDynamicValue).length === 0) {
        newDynamicValue = null;
      }
      handleChange(elementName, newDynamicValue, setResourceState);
    }
  }), [dynamicValueState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.path && <p><b>Path: </b>{startingValue.path}</p>}
        {startingValue.expression && <div><b>Expression: </b><DisplayFromFHIR expression={startingValue.expression} /></div>}
        <br />
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='path' fieldLabel='Path'
          startingValue={dynamicValueState.path} setResourceState={setDynamicValueState} />
        <DataEntry datatype='Expression' elementName='expression' fieldLabel='Expression'
          startingValue={dynamicValueState.expression} setResourceState={setDynamicValueState} />
      </div>
    </>
  }
});

const activityDefinitionDotParticipantDotTypeValues = ["careteam", "device", "group", "healthcareservice", "location", "organization", "patient", "practitioner", "practitionerrole", "relatedperson"];
const activityDefinitionDotParticipantDotTypeReferenceResourceTypes = ['CareTeam', 'Device', 'DeviceDefinition', 'Endpoint', 'Group', 'HealthcareService', 'Location', 'Organization', 'Patient', 'Practitioner', 'PractitionerRole', 'RelatedPerson']
const activityDefinitionDotParticipantDotRoleSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/practitioner-role', 'display': 'HL7 FHIR Practitioner Role' }, { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }, { 'uri': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode', 'display': 'HL7 V3 Role Code' }]
const activityDefinitionDotParticipantDotFunctionValueSet = [
  { system: "http://hl7.org/fhir/action-participant-function", code: "performer", display: "Perfomer" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "author", display: "Author" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "reviewer", display: "Reviewer" },
  { system: "http://hl7.org/fhir/action-participant-function", code: "witness", display: "Witness" }
]
const ActivityDefinitionParticipantEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingParticipant = { type: "", typeCanonical: "", typeReference: {}, role: {}, function: {} };
  if (!startingValue) {
    startingParticipant = {};
  } else {
    if (startingValue.extension) { startingParticipant.extension = startingValue.extension; }
    if (startingValue.type) { startingParticipant.type = startingValue.type; }
    if (startingValue.typeCanonical) { startingParticipant.typeCanonical = startingValue.typeCanonical; }
    if (startingValue.typeReference) { startingParticipant.typeReference = startingValue.typeReference; }
    if (startingValue.role) { startingParticipant.role = startingValue.role; }
    if (startingValue.function) { startingParticipant.function = startingValue.function; }
  }

  const [participantState, setParticipantState] = useState(JSON.parse(JSON.stringify(startingParticipant || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(participantState).length) {
      let newParticipant = {};
      if (participantState.extension) { newParticipant.extension = participantState.extension; }
      if (participantState.type) { newParticipant.type = participantState.type; }
      if (participantState.typeCanonical) { newParticipant.typeCanonical = participantState.typeCanonical; }
      if (participantState.typeReference && Object.keys(participantState.typeReference).length) {
        newParticipant.typeReference = participantState.typeReference;
      }
      if (participantState.role && Object.keys(participantState.role).length) {
        newParticipant.role = participantState.role;
      }
      if (participantState.function && Object.keys(participantState.function).length) {
        newParticipant.function = participantState.function;
      }
      if (Object.keys(newParticipant).length === 0) {
        newParticipant = null;
      }
      handleChange(elementName, newParticipant, setResourceState);
    }
  }), [participantState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.type && <p><b>Participant type: </b>{startingValue.type}</p>}
        {startingValue.typeCanonical && <p><b>Participant type canonical: </b><DisplayFromFHIR uri={startingValue.typeCanonical} /></p>}
        {startingValue.typeReference && <p><b>Participant type reference: </b><DisplayFromFHIR reference={startingValue.typeReference} /></p>}
        {startingValue.role && <span><b>Participant role: </b><DisplayFromFHIR codeableConcept={startingValue.role} /></span>}
        {startingValue.function && <span><b>Participant function: </b><DisplayFromFHIR codeableConcept={startingValue.function} /></span>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='type' fieldLabel='Participant type'
          allowedValues={activityDefinitionDotParticipantDotTypeValues}
          startingValue={participantState.type} setResourceState={setParticipantState} />
        <DataEntry datatype='uri' elementName='typeCanonical' fieldLabel='Participant type canonical'
          startingValue={participantState.typeCanonical} setResourceState={setParticipantState} />
        <DataEntry datatype='Reference' elementName='typeReference' fieldLabel='Participant type reference'
          referencedResourceTypes={activityDefinitionDotParticipantDotTypeReferenceResourceTypes}
          startCollapsed
          startingValue={participantState.typeReference} setResourceState={setParticipantState} />
        <DataEntry datatype='CodeableConcept' elementName='role' fieldLabel='Participant role'
          systemChoices={activityDefinitionDotParticipantDotRoleSystemChoices} systemChoicesOpen
          startCollapsed
          startingValue={participantState.role} setResourceState={setParticipantState} />
        <DataEntry datatype='CodeableConcept' elementName='function' fieldLabel='Participant function'
          valueSet={activityDefinitionDotParticipantDotFunctionValueSet} startCollapsed
          startingValue={participantState.function} setResourceState={setParticipantState} />
      </div>
    </>
  }
})

const CodeSystemPropertyEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingProperty = { "code": "", "uri": "", "description": "", "type": "" }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingProperty.extension = startingValue.extension; }
    if (startingValue.code) { startingProperty.code = startingValue.code; }
    if (startingValue.uri) { startingProperty.uri = startingValue.uri; }
    if (startingValue.description) { startingProperty.description = startingValue.description; }
    if (startingValue.type) { startingProperty.type = startingValue.type; }
  }

  const [codeSystemPropertyState, setCodeSystemPropertyState] = useState(JSON.parse(JSON.stringify(startingProperty || {})));

  useEffect((() => {
    if (Object.keys(codeSystemPropertyState).length > 0) {
      let newProperty = {};
      if (codeSystemPropertyState.extension) { newProperty.extension = codeSystemPropertyState.extension; }
      if (codeSystemPropertyState.code) { newProperty.code = codeSystemPropertyState.code; }
      if (codeSystemPropertyState.uri) { newProperty.uri = codeSystemPropertyState.uri; }
      if (codeSystemPropertyState.description) { newProperty.description = codeSystemPropertyState.description; }
      if (codeSystemPropertyState.type) { newProperty.type = codeSystemPropertyState.type; }
      if (Object.keys(newProperty).length === 0) {
        newProperty = null;
      }
      handleChange(elementName, newProperty, setResourceState);
    }
  }), [codeSystemPropertyState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='code' fieldLabel='Code'
        startingValue={codeSystemPropertyState.code} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='uri' elementName='uri' fieldLabel='Formal identifier'
        startingValue={codeSystemPropertyState.uri} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='string' elementName='description' fieldLabel='Description'
        startingValue={codeSystemPropertyState.description} setResourceState={setCodeSystemPropertyState} />
      <DataEntry datatype='code' elementName='type' fieldLabel='Datatype'
        allowedValues={["code", "Coding", "string", "integer", "boolean", "dateTime", "decimal"]}
        startingValue={codeSystemPropertyState.type} setResourceState={setCodeSystemPropertyState} />
    </div>
  </>
})

const languageCodes = ['ar', 'bg', 'bn', 'cs', 'bs', 'da', 'de', 'de-AT', 'de-CH', 'de-DE', 'el',
  'en', 'en-AU', 'en-CA', 'en-GB', 'en-IN', 'en-NZ', 'en-SG', 'en-US', 'es', 'es-AR', 'es-ES', 'es-UY',
  'et', 'fi', 'fr', 'fr-BE', 'fr-CH', 'fr-FR', 'fr-CA', 'fy',
  'hi', 'hr', 'is', 'it', 'it-CH', 'it-IT', 'ja', 'ko', 'lt', 'lv', 'nl', 'nl-BE', 'nl-NL',
  'no', 'pa', 'pl', 'pt', 'pt-PT', 'pt-BR', 'ro', 'ru', 'sk',
  'sl', 'sr', 'sv', 'sv-FI', 'sv-SE', 'te', 'zh', 'zh-CN', 'zh-HK', 'zh-SG', 'zh-TW'];
const CodeSystemConceptDesignationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingDesignation = {
    "language": "",
    "use": {
      "system": "http://snomed.info/sct",
      "code": "900000000000013009",
      "display": "Synonym (core metadata concept)"
    },
    "value": ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingDesignation.extension = startingValue.extension; }
    if (startingValue.language) { startingDesignation.language = startingValue.language; }
    if (startingValue.use) { startingDesignation.use = startingValue.use; }
    if (startingValue.value) { startingDesignation.value = startingValue.value; }
  }
  if (startingDesignation.use.code === "900000000000003001") {
    startingDesignation.primaryTerm = true;
  }

  const [codeSystemConceptDesignationState, setCodeSystemConceptDesignationState] = useState(JSON.parse(JSON.stringify(startingDesignation || {})));
  const [addLanguageState, setAddLanguageState] = useState(startingDesignation.language ? true : false);

  useEffect((() => {
    if (Object.keys(codeSystemConceptDesignationState).length > 0) {
      let newDesignation = {};
      if (codeSystemConceptDesignationState.extension) { newDesignation.extension = codeSystemConceptDesignationState.extension; }
      if (codeSystemConceptDesignationState.language) { newDesignation.language = codeSystemConceptDesignationState.language; }
      if (codeSystemConceptDesignationState.use) { newDesignation.use = codeSystemConceptDesignationState.use; }
      if (codeSystemConceptDesignationState.value) { newDesignation.value = codeSystemConceptDesignationState.value; }
      if (codeSystemConceptDesignationState.primaryTerm) {
        newDesignation.use = {
          "system": "http://snomed.info/sct",
          "code": "900000000000003001",
          "display": "Fully specified name"
        }
      } else if (codeSystemConceptDesignationState.primaryTerm === false) {
        newDesignation.use = {
          "system": "http://snomed.info/sct",
          "code": "900000000000013009",
          "display": "Synonym (core metadata concept)"
        }
      }
      if (Object.keys(newDesignation).length === 0) {
        newDesignation = null;
      }
      handleChange(elementName, newDesignation, setResourceState);
    }
  }), [codeSystemConceptDesignationState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='value' fieldLabel='Alternative term'
        startingValue={codeSystemConceptDesignationState.value} setResourceState={setCodeSystemConceptDesignationState} />
      <span onClick={() => { setAddLanguageState(!addLanguageState) }} >
        {addLanguageState ?
          <>Collapse language</> :
          <>{codeSystemConceptDesignationState.language ? <>Edit language</> : <>Add language</>}</>
        }
      </span>
      {addLanguageState && <>
        <DataEntry datatype='code' elementName='language' fieldLabel='Language Code (See https://build.fhir.org/valueset-languages.html)'
          allowedValues={languageCodes}
          startingValue={codeSystemConceptDesignationState.language} setResourceState={setCodeSystemConceptDesignationState} />
        {codeSystemConceptDesignationState.language &&
          <DataEntry datatype='boolean' elementName='primaryTerm' fieldLabel='Primary Term?'
            startingValue={codeSystemConceptDesignationState.primaryTerm} setResourceState={setCodeSystemConceptDesignationState} />}
      </>}
    </div>
  </>
})

const CompositionTableCellEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  dataEntryStyle, addTableRowModalState, setSourceJsonState }) => {
  let startingTableCell = {
    "title": "",
    "code": "",
    "textStatus": "",
    "textDiv": "",
    "entry": []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.title) { startingTableCell.title = startingValue.title; }
    if (startingValue.code) { startingTableCell.code = startingValue.code; }
    if (startingValue.text) {
      startingTableCell.textStatus = startingValue.text.status;
      startingTableCell.textDiv = startingValue.text.div;
    }
    if (startingValue.entry) { startingTableCell.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTableCell.emptyReason = startingValue.emptyReason; }
  }
  if (dataEntryStyle === "SummaryOfFindings") {
    if (startingValue.code.coding?.[0]?.code === "EvidenceVariable-outcome" && addTableRowModalState) {
      if (addTableRowModalState.newRowTitle) {
        startingTableCell.textStatus = "generated";
        startingTableCell.textDiv = addTableRowModalState.newRowTitle;
      }
      if (addTableRowModalState.newRowFocus) {
        startingTableCell.entry = [addTableRowModalState.newRowFocus];
      }
    }
    if ((startingTableCell.textStatus === "" || startingTableCell.textStatus === "empty") &&
      !startingTableCell.textDiv && startingTableCell.entry.length === 0 && !startingTableCell.emptyReason) {
      startingTableCell.emptyReason = emptyReasonNotStarted;
    }
  }

  const [compositionTableCellState, setCompositionTableCellState] = useState(JSON.parse(JSON.stringify(startingTableCell || {})));

  useEffect((() => {
    if (Object.keys(compositionTableCellState).length > 0) {
      let newTableCell = {};
      if (compositionTableCellState.title) { newTableCell.title = compositionTableCellState.title; }
      if (compositionTableCellState.code) { newTableCell.code = compositionTableCellState.code; }
      if (compositionTableCellState.textDiv) {
        newTableCell.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + compositionTableCellState.textDiv + "</div>"
        }
      } else {
        newTableCell.text = JSON.parse(JSON.stringify(emptyTextNoData));
      }
      if (Array.isArray(compositionTableCellState.entry) && compositionTableCellState.entry.length > 0) {
        newTableCell.entry = compositionTableCellState.entry;
      }
      if ((!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) {
        newTableCell.text.status = "empty";
        if (compositionTableCellState.emptyReason && Object.keys(compositionTableCellState.emptyReason).length > 0) {
          newTableCell.emptyReason = compositionTableCellState.emptyReason;
        } else {
          newTableCell.emptyReason = { "text": "[Data deleted]" };
        }
      } else if (newTableCell.emptyReason) {
        delete newTableCell.emptyReason;
      }
      if (Object.keys(newTableCell).length === 0) {
        newTableCell = null;
      }
      handleChange(elementName, newTableCell, setResourceState);
    }
  }), [compositionTableCellState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='textDiv' fieldLabel='Narrative Summary'
        startingValue={compositionTableCellState.textDiv} setResourceState={setCompositionTableCellState} />
      <DataEntry asArray={true} datatype='Reference' elementName='entry'
        fieldLabel='Source Content (as Resource Reference)' enableCreation={true}
        setSourceJsonState={setSourceJsonState}
        startingValue={compositionTableCellState.entry} setResourceState={setCompositionTableCellState} />
      {(dataEntryStyle === "SummaryOfFindings" &&
        (!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) && <>
          <br />
          <p><b>Explain why this Table Cell is empty</b></p>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={compositionTableCellState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            setResourceState={setCompositionTableCellState} />
        </>}
      <br />
    </div>
  </>
})

const EvidenceStatisticEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingStatistic = {
    description: "", note: [], statisticType: "", category: "", quantity: "", numberOfEvents: "",
    numberAffected: "", sampleSize: "", attributeEstimate: [], modelCharacteristic: [], analysisPlan: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingStatistic.extension = startingValue.extension; }
    if (startingValue.description) { startingStatistic.description = startingValue.description; }
    if (startingValue.note) { startingStatistic.note = startingValue.note; }
    if (startingValue.statisticType) { startingStatistic.statisticType = startingValue.statisticType; }
    if (startingValue.category) { startingStatistic.category = startingValue.category; }
    if (startingValue.quantity) { startingStatistic.quantity = startingValue.quantity; }
    if (startingValue.numberOfEvents || startingValue.numberOfEvents === 0) { startingStatistic.numberOfEvents = startingValue.numberOfEvents; }
    if (startingValue.numberAffected || startingValue.numberAffected === 0) { startingStatistic.numberAffected = startingValue.numberAffected; }
    if (startingValue.sampleSize) { startingStatistic.sampleSize = startingValue.sampleSize; }
    if (startingValue.attributeEstimate) { startingStatistic.attributeEstimate = startingValue.attributeEstimate; }
    if (startingValue.modelCharacteristic) { startingStatistic.modelCharacteristic = startingValue.modelCharacteristic; }
    if (startingValue.analysisPlan) { startingStatistic.analysisPlan = startingValue.analysisPlan; }
  }

  const [evidenceStatisticState, setEvidenceStatisticState] = useState(JSON.parse(JSON.stringify(startingStatistic || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(evidenceStatisticState).length > 0) {
      let newEvidenceStatistic = {};
      if (evidenceStatisticState.extension) { newEvidenceStatistic.extension = evidenceStatisticState.extension; }
      if (evidenceStatisticState.description) { newEvidenceStatistic.description = evidenceStatisticState.description; }
      if (Array.isArray(evidenceStatisticState.note) && evidenceStatisticState.note.length > 0) {
        newEvidenceStatistic.note = evidenceStatisticState.note;
      }
      if (evidenceStatisticState.statisticType && Object.keys(evidenceStatisticState.statisticType).length > 0) {
        newEvidenceStatistic.statisticType = evidenceStatisticState.statisticType;
      }
      if (evidenceStatisticState.category && Object.keys(evidenceStatisticState.category).length > 0) {
        newEvidenceStatistic.category = evidenceStatisticState.category;
      }
      if (evidenceStatisticState.quantity && Object.keys(evidenceStatisticState.quantity).length > 0) {
        newEvidenceStatistic.quantity = evidenceStatisticState.quantity;
      }
      if (evidenceStatisticState.numberOfEvents || evidenceStatisticState.numberOfEvents === 0) {
        newEvidenceStatistic.numberOfEvents = evidenceStatisticState.numberOfEvents;
      }
      if (evidenceStatisticState.numberAffected || evidenceStatisticState.numberAffected === 0) {
        newEvidenceStatistic.numberAffected = evidenceStatisticState.numberAffected;
      }
      if (evidenceStatisticState.sampleSize && Object.keys(evidenceStatisticState.sampleSize).length > 0) {
        newEvidenceStatistic.sampleSize = evidenceStatisticState.sampleSize;
      }
      if (Array.isArray(evidenceStatisticState.attributeEstimate) && evidenceStatisticState.attributeEstimate.length > 0) {
        newEvidenceStatistic.attributeEstimate = evidenceStatisticState.attributeEstimate;
      }
      if (Array.isArray(evidenceStatisticState.modelCharacteristic) && evidenceStatisticState.modelCharacteristic.length > 0) {
        newEvidenceStatistic.modelCharacteristic = evidenceStatisticState.modelCharacteristic;
      }
      if (Array.isArray(evidenceStatisticState.analysisPlan) && evidenceStatisticState.analysisPlan.length > 0) {
        newEvidenceStatistic.analysisPlan = evidenceStatisticState.analysisPlan;
      }
      if (Object.keys(newEvidenceStatistic).length === 0) {
        newEvidenceStatistic = null;
      }
      handleChange(elementName, newEvidenceStatistic, setResourceState);
    }
  }), [evidenceStatisticState]);

  let statisticTypeDisplay = "";
  if (startingValue.statisticType) {
    if (startingValue.statisticType.coding?.length > 0) {
      if (startingValue.statisticType.coding[0].display) {
        statisticTypeDisplay = startingValue.statisticType.coding[0].display;
      } else if (startingValue.statisticType.coding[0].code) {
        statisticTypeDisplay = startingValue.statisticType.coding[0].code;
      }
    }
    if (startingValue.statisticType.text) {
      statisticTypeDisplay += "   " + startingValue.statisticType.text;
    }
  }
  let statisticValue = "";
  if (startingValue.quantity) {
    let comparator = "";
    if (startingValue.quantity.comparator) {
      comparator = startingValue.quantity.comparator + " ";
    }
    let unit = "";
    if (startingValue.quantity.unit) {
      unit = " " + startingValue.quantity.unit;
    }
    let value = startingValue.quantity.value;
    if (value && !unit && statisticTypeDisplay.replace("   ", "").toLowerCase() === "percentage") {
      unit = "%";
    }
    statisticValue = comparator + value + unit;
  }
  let sampleSizeQuantified = "";
  if (startingValue.sampleSize) {
    if (startingValue.sampleSize.numberOfStudies) {
      sampleSizeQuantified = startingValue.sampleSize.numberOfStudies + " studies";
    }
    if (startingValue.sampleSize.numberOfParticipants) {
      if (sampleSizeQuantified) {
        sampleSizeQuantified += ", ";
      }
      sampleSizeQuantified += startingValue.sampleSize.numberOfParticipants + " participants";
    }
    if (startingValue.sampleSize.knownDataCount) {
      if (sampleSizeQuantified) {
        sampleSizeQuantified += ", ";
      }
      sampleSizeQuantified += startingValue.sampleSize.knownDataCount + " counted";
    }
  }

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.category && <span><b>Category:</b> {startingValue.category.text}</span>}
        {startingValue.description && <span><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /></span>}
        {Array.isArray(startingValue.note) && startingValue.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}
        <Table style={{ margin: "4px", width: "600px" }}><Table.Body>
          <Table.Row><Table.Cell style={{ width: "280px", paddingTop: "6px", paddingBottom: "6px", paddingLeft: "12px" }}>
            <b>{statisticTypeDisplay}</b>
          </Table.Cell>
            <Table.Cell style={{ paddingTop: "6px", paddingBottom: "6px", paddingLeft: "12px" }}>
              <b>{statisticValue}</b>
            </Table.Cell></Table.Row>
        </Table.Body></Table>
        {!isNaN(startingValue.numberOfEvents) && <span><b>Number of Events: </b>{startingValue.numberOfEvents}<br /></span>}
        {!isNaN(startingValue.numberAffected) && <span><b>Number Affected: </b>{startingValue.numberAffected}<br /></span>}
        {startingValue.sampleSize && <><b>Sample Size: </b>
          <div style={{ marginLeft: "24px" }}>
            {sampleSizeQuantified && <><b></b>{sampleSizeQuantified}<br /></>}
            {startingValue.sampleSize.description && <span><b>Sample Size Description: </b><DisplayFromFHIR markdown={startingValue.sampleSize.description} /><br /></span>}
            {Array.isArray(startingValue.sampleSize.note) && startingValue.sampleSize.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}
          </div>
        </>}
        {startingValue.attributeEstimate?.length > 0 &&
          <Table style={{ margin: "4px" }}>
            <Table.Header><Table.Row>
              <Table.HeaderCell className={"leftAlignedCell"} style={{ padding: "6px", width: "360px" }}>Attribute</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: "6px" }}>Value</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: "6px" }}>Notes</Table.HeaderCell>
            </Table.Row></Table.Header>
            <Table.Body>
              {startingValue.attributeEstimate.map((attributeEstimate, aeIndex) => {
                let calculatedPercentageAttribute = false;
                let attribute = "";
                if (attributeEstimate.level) {
                  attribute = 100 * attributeEstimate.level + "% ";
                }
                if (attributeEstimate.type) {
                  if (attributeEstimate.type.coding?.length > 0) {
                    if (attributeEstimate.type.coding[0].display) {
                      attribute += attributeEstimate.type.coding[0].display;
                    } else if (attributeEstimate.type.coding[0].code) {
                      attribute += attributeEstimate.type.coding[0].code;
                    }
                  }
                  if (attributeEstimate.type.text) {
                    if (attributeEstimate.type.text.toLowerCase() === "percentage") {
                      calculatedPercentageAttribute = true;
                    }
                    attribute += " " + attributeEstimate.type.text;
                  }
                }
                let attributeValue = "";
                if (attributeEstimate.quantity) {
                  let comparator = "";
                  if (attributeEstimate.quantity.comparator) {
                    comparator = attributeEstimate.quantity.comparator + " ";
                  }
                  let unit = "";
                  if (attributeEstimate.quantity.unit) {
                    unit = " " + attributeEstimate.quantity.unit;
                  }
                  let attributeEstimateValue = "";
                  if (attributeEstimate.quantity.value !== undefined) {
                    attributeEstimateValue = attributeEstimate.quantity.value
                  }
                  attributeValue = comparator + attributeEstimate.quantity.value + unit;
                  if (calculatedPercentageAttribute && attributeEstimate.quantity.value && (comparator === "" || comparator === " ") && attributeEstimate.quantity.unit === "%") {
                    try {
                      //The plus symbol belongs here, +parseFloat(4).toFixed(2) === 4 as an int vs parseFloat(4).toFixed(2) === "4.00" as a string
                      attributeEstimateValue = +parseFloat(attributeEstimate.quantity.value).toFixed(2);
                      if (attributeEstimateValue.toString() !== "NaN") {
                        attributeValue = attributeEstimateValue + unit;
                      }
                    } catch (e) {
                    }
                  }
                }
                if (attributeValue === "" && attributeEstimate.range) {
                  let lowValue = "No Lower Bound";
                  let highValue = "No Upper Bound";
                  if (attributeEstimate.range.low?.value !== undefined) {
                    lowValue = attributeEstimate.range.low.value;
                    if (attributeEstimate.range.low.unit) {
                      lowValue += " " + attributeEstimate.range.low.unit;
                    }
                  }
                  if (attributeEstimate.range.high?.value !== undefined) {
                    highValue = attributeEstimate.range.high.value;
                    if (attributeEstimate.range.high.unit) {
                      highValue += " " + attributeEstimate.range.high.unit;
                    }
                  }
                  attributeValue = lowValue + " to " + highValue;
                }
                return <Table.Row key={aeIndex}>
                  <Table.Cell>{attribute}</Table.Cell>
                  <Table.Cell>{attributeValue}</Table.Cell>
                  <Table.Cell>
                    {attributeEstimate.description && <><b>Description: </b><DisplayFromFHIR markdown={attributeEstimate.description} /><br /></>}
                    {attributeEstimate.note && <>{attributeEstimate.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}</>}
                  </Table.Cell>
                </Table.Row>
              }
              )}
            </Table.Body>
          </Table>}
        {(Array.isArray(startingValue.modelCharacteristic) &&
          startingValue.modelCharacteristic.length > 0) &&
          startingValue.modelCharacteristic.map((item, itemIndex) => {
            return <div key={itemIndex}>
              <p><b>Model Characteristic {itemIndex + 1} of {startingValue.modelCharacteristic.length}</b></p>
              <div style={{ marginLeft: "24px" }}>
                <ModelCharacteristicDisplay modelCharactersticInstance={item} />
              </div>
            </div>
          })}
        <br />
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed
          startingValue={evidenceStatisticState.description} setResourceState={setEvidenceStatisticState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceStatisticState.note} setResourceState={setEvidenceStatisticState} />
        <DataEntry datatype='CodeableConcept' elementName='statisticType' fieldLabel='Statistic Type'
          startingValue={evidenceStatisticState.statisticType} startCollapsed
          valueSet={SEVCO.statisticType}
          setResourceState={setEvidenceStatisticState} />
        <DataEntry datatype='CodeableConcept' elementName='category' fieldLabel='Category'
          startingValue={evidenceStatisticState.category} startCollapsed
          setResourceState={setEvidenceStatisticState} />
        <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
          startingValue={evidenceStatisticState.quantity} startCollapsed
          setResourceState={setEvidenceStatisticState} />
        <br /><br />
        <span><b>Number of Events:</b>
          &nbsp;&nbsp;
          <DataEntry datatype='unsignedInt' elementName='numberOfEvents' fieldLabel='Number of Events'
            startingValue={evidenceStatisticState.numberOfEvents}
            setResourceState={setEvidenceStatisticState} />
        </span>
        <br /><br />
        <span><b>Number Affected:</b>
          &nbsp;&nbsp;
          <DataEntry datatype='unsignedInt' elementName='numberAffected' fieldLabel='Number Affected'
            startingValue={evidenceStatisticState.numberAffected}
            setResourceState={setEvidenceStatisticState} />
        </span>
        <DataEntry datatype='EvidenceStatisticSampleSize' elementName='sampleSize' fieldLabel='Sample Size'
          startingValue={evidenceStatisticState.sampleSize} setResourceState={setEvidenceStatisticState} />
        <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attributeEstimate' fieldLabel='Attribute Estimate'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceStatisticState.attributeEstimate} setResourceState={setEvidenceStatisticState} />
        <DataEntry asArray={true} datatype='EvidenceStatisticModelCharacteristic' elementName='modelCharacteristic' fieldLabel='Model Characteristic'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceStatisticState.modelCharacteristic} setResourceState={setEvidenceStatisticState} />
        {evidenceStatisticState.analysisPlan && <>
          <b>Analysis Plan:</b>
          <SimpleResourceFieldViewer resource={evidenceStatisticState.analysisPlan} parentElement={""} />
        </>}
      </div>
    </>
  }
})

const EvidenceStatisticSampleSizeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingSampleSize = {
    description: "", note: [], numberOfStudies: "", numberOfParticipants: "", knownDataCount: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSampleSize.extension = startingValue.extension; }
    if (startingValue.description) { startingSampleSize.description = startingValue.description; }
    if (startingValue.note) { startingSampleSize.note = startingValue.note; }
    if (startingValue.numberOfStudies || startingValue.numberOfStudies === 0) { startingSampleSize.numberOfStudies = startingValue.numberOfStudies; }
    if (startingValue.numberOfParticipants || startingValue.numberOfParticipants === 0) { startingSampleSize.numberOfParticipants = startingValue.numberOfParticipants; }
    if (startingValue.knownDataCount || startingValue.knownDataCount === 0) { startingSampleSize.knownDataCount = startingValue.knownDataCount; }
  }

  const [evidenceStatisticSampleSizeState, setEvidenceStatisticSampleSizeState] = useState(JSON.parse(JSON.stringify(startingSampleSize || {})));

  useEffect((() => {
    if (Object.keys(evidenceStatisticSampleSizeState).length > 0) {
      let newStatisticSampleSize = {};
      if (evidenceStatisticSampleSizeState.extension) { newStatisticSampleSize.extension = evidenceStatisticSampleSizeState.extension; }
      if (evidenceStatisticSampleSizeState.description) { newStatisticSampleSize.description = evidenceStatisticSampleSizeState.description; }
      if (Array.isArray(evidenceStatisticSampleSizeState.note) && evidenceStatisticSampleSizeState.note.length > 0) {
        newStatisticSampleSize.note = evidenceStatisticSampleSizeState.note;
      }
      if (evidenceStatisticSampleSizeState.numberOfStudies || evidenceStatisticSampleSizeState.numberOfStudies === 0) {
        newStatisticSampleSize.numberOfStudies = evidenceStatisticSampleSizeState.numberOfStudies;
      }
      if (evidenceStatisticSampleSizeState.numberOfParticipants || evidenceStatisticSampleSizeState.numberOfParticipants === 0) {
        newStatisticSampleSize.numberOfParticipants = evidenceStatisticSampleSizeState.numberOfParticipants;
      }
      if (evidenceStatisticSampleSizeState.knownDataCount || evidenceStatisticSampleSizeState.knownDataCount === 0) {
        newStatisticSampleSize.knownDataCount = evidenceStatisticSampleSizeState.knownDataCount;
      }
      if (Object.keys(newStatisticSampleSize).length === 0) {
        newStatisticSampleSize = null;
      }
      handleChange(elementName, newStatisticSampleSize, setResourceState);
    }
  }), [evidenceStatisticSampleSizeState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={evidenceStatisticSampleSizeState.description} setResourceState={setEvidenceStatisticSampleSizeState} />
      <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticSampleSizeState.note} setResourceState={setEvidenceStatisticSampleSizeState} />
      <br /><br />
      <span><b>Number of Studies:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberOfStudies' fieldLabel='Number of Studies'
          startingValue={evidenceStatisticSampleSizeState.numberOfStudies}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
      <br /><br />
      <span><b>Number of Participants:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='numberOfParticipants' fieldLabel='Number of Participants'
          startingValue={evidenceStatisticSampleSizeState.numberOfParticipants}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
      <br /><br />
      <span><b>Known Data Count:</b>
        &nbsp;&nbsp;
        <DataEntry datatype='unsignedInt' elementName='knownDataCount' fieldLabel='Known Data Count'
          startingValue={evidenceStatisticSampleSizeState.knownDataCount}
          setResourceState={setEvidenceStatisticSampleSizeState} />
      </span>
    </div>
  </>
})

const EvidenceStatisticAttributeEstimateEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingAttributeEstimate = {
    description: "", note: [], type: "", quantity: "", level: "", range: "", attributeEstimate: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingAttributeEstimate.extension = startingValue.extension; }
    if (startingValue.description) { startingAttributeEstimate.description = startingValue.description; }
    if (startingValue.note) { startingAttributeEstimate.note = startingValue.note; }
    if (startingValue.type) { startingAttributeEstimate.type = startingValue.type; }
    if (startingValue.quantity) { startingAttributeEstimate.quantity = startingValue.quantity; }
    if (startingValue.level) { startingAttributeEstimate.level = startingValue.level; }
    if (startingValue.range) { startingAttributeEstimate.range = startingValue.range; }
    if (startingValue.attributeEstimate) { startingAttributeEstimate.attributeEstimate = startingValue.attributeEstimate; }
  }

  const [evidenceStatisticAttributeEstimateState, setEvidenceStatisticAttributeEstimateState] = useState(JSON.parse(JSON.stringify(startingAttributeEstimate || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(evidenceStatisticAttributeEstimateState).length > 0) {
      let newAttributeEstimate = {};
      if (evidenceStatisticAttributeEstimateState.extension) { newAttributeEstimate.extension = evidenceStatisticAttributeEstimateState.extension; }
      if (evidenceStatisticAttributeEstimateState.description) { newAttributeEstimate.description = evidenceStatisticAttributeEstimateState.description; }
      if (Array.isArray(evidenceStatisticAttributeEstimateState.note) && evidenceStatisticAttributeEstimateState.note.length > 0) {
        newAttributeEstimate.note = evidenceStatisticAttributeEstimateState.note;
      }
      if (evidenceStatisticAttributeEstimateState.type && Object.keys(evidenceStatisticAttributeEstimateState.type).length > 0) {
        newAttributeEstimate.type = evidenceStatisticAttributeEstimateState.type;
      }
      if (evidenceStatisticAttributeEstimateState.quantity && Object.keys(evidenceStatisticAttributeEstimateState.quantity).length > 0) {
        newAttributeEstimate.quantity = evidenceStatisticAttributeEstimateState.quantity;
      }
      if (evidenceStatisticAttributeEstimateState.level || evidenceStatisticAttributeEstimateState.level === 0) {
        newAttributeEstimate.level = evidenceStatisticAttributeEstimateState.level;
      }
      if (evidenceStatisticAttributeEstimateState.range && Object.keys(evidenceStatisticAttributeEstimateState.range).length > 0) {
        newAttributeEstimate.range = evidenceStatisticAttributeEstimateState.range;
      }
      if (Array.isArray(evidenceStatisticAttributeEstimateState.attributeEstimate) && evidenceStatisticAttributeEstimateState.attributeEstimate.length > 0) {
        newAttributeEstimate.attributeEstimate = evidenceStatisticAttributeEstimateState.attributeEstimate;
      }
      if (Object.keys(newAttributeEstimate).length === 0) {
        newAttributeEstimate = null;
      }
      handleChange(elementName, newAttributeEstimate, setResourceState);
    }
  }), [evidenceStatisticAttributeEstimateState]);

  let calculatedPercentageAttribute = false;
  let attribute = "";
  if (startingValue.level) {
    attribute = 100 * startingValue.level + "% ";
  }
  if (startingValue.type) {
    if (startingValue.type.coding?.length > 0) {
      if (startingValue.type.coding[0].display) {
        attribute += startingValue.type.coding[0].display;
      } else if (startingValue.type.coding[0].code) {
        attribute += startingValue.type.coding[0].code;
      }
    }
    if (startingValue.type.text) {
      if (startingValue.type.text.toLowerCase() === "percentage") {
        calculatedPercentageAttribute = true;
      }
      attribute += " " + startingValue.type.text;
    }
  }
  let attributeValue = "";
  if (startingValue.quantity) {
    let comparator = "";
    if (startingValue.quantity.comparator) {
      comparator = startingValue.quantity.comparator + " ";
    }
    let unit = "";
    if (startingValue.quantity.unit) {
      unit = " " + startingValue.quantity.unit;
    }
    let attributeEstimateValue = "";
    if (startingValue.quantity.value !== undefined) {
      attributeEstimateValue = startingValue.quantity.value
    }
    attributeValue = comparator + startingValue.quantity.value + unit;
    if (calculatedPercentageAttribute && startingValue.quantity.value && (comparator === "" || comparator === " ") && startingValue.quantity.unit === "%") {
      try {
        //The plus symbol belongs here, +parseFloat(4).toFixed(2) === 4 as an int vs parseFloat(4).toFixed(2) === "4.00" as a string
        attributeEstimateValue = +parseFloat(startingValue.quantity.value).toFixed(2);
        if (attributeEstimateValue.toString() !== "NaN") {
          attributeValue = attributeEstimateValue + unit;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  if (attributeValue === "" && startingValue.range) {
    let lowValue = "No Lower Bound";
    let highValue = "No Upper Bound";
    if (startingValue.range.low?.value !== undefined) {
      lowValue = startingValue.range.low.value;
      if (startingValue.range.low.unit) {
        lowValue += " " + startingValue.range.low.unit;
      }
    }
    if (startingValue.range.high?.value !== undefined) {
      highValue = startingValue.range.high.value;
      if (startingValue.range.high.unit) {
        highValue += " " + startingValue.range.high.unit;
      }
    }
    attributeValue = lowValue + " to " + highValue;
  }

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        <Table style={{ margin: "4px" }}>
          <Table.Header><Table.Row>
            <Table.HeaderCell className={"leftAlignedCell"} style={{ padding: "6px", width: "360px" }}>Attribute</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: "6px" }}>Value</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: "6px" }}>Notes</Table.HeaderCell>
          </Table.Row></Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{attribute}</Table.Cell>
              <Table.Cell>{attributeValue}</Table.Cell>
              <Table.Cell>
                {startingValue.description && <><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /></>}
                {startingValue.note && <>{startingValue.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}</>}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description' startCollapsed
          startingValue={evidenceStatisticAttributeEstimateState.description} setResourceState={setEvidenceStatisticAttributeEstimateState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceStatisticAttributeEstimateState.note} setResourceState={setEvidenceStatisticAttributeEstimateState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Attirbute Estimate Type'
          startingValue={evidenceStatisticAttributeEstimateState.type} startCollapsed
          valueSet={SEVCO.statisticType}
          setResourceState={setEvidenceStatisticAttributeEstimateState} />
        <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
          startingValue={evidenceStatisticAttributeEstimateState.quantity} startCollapsed
          setResourceState={setEvidenceStatisticAttributeEstimateState} />
        <br /><br />
        <span><b>Level:</b>
          &nbsp;&nbsp;
          <DataEntry datatype='decimal' elementName='level' fieldLabel='Level'
            startingValue={evidenceStatisticAttributeEstimateState.level}
            setResourceState={setEvidenceStatisticAttributeEstimateState} />
        </span>
        <DataEntry datatype='Range' elementName='range' fieldLabel='Range'
          startingValue={evidenceStatisticAttributeEstimateState.range} startCollapsed
          setResourceState={setEvidenceStatisticAttributeEstimateState} />
        <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attributeEstimate' fieldLabel='Attribute Estimate'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceStatisticAttributeEstimateState.attributeEstimate} setResourceState={setEvidenceStatisticAttributeEstimateState} />
      </div>
    </>
  }
})

const ExtensionStatisticModelIncludeIfValueAllowedDatatypes = ['boolean', 'CodeableConcept', 'Quantity', 'Range', 'Expression'];
const ExtensionStatisticModelIncludeIfEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {

  //There can/should only be one of these elements: valueBoolean, valueCodeableConcept, valueQuantity, valueRange, or valueExpression
  let startingExtensionStatisticModelIncludeIf = {
    otherExtensions: [],
    attribute: null,
    valueBoolean: null,
    valueCodeableConcept: null,
    valueQuantity: null,
    valueRange: null,
    valueExpression: null
  };

  let startingIncludeIfDotValueDatatype = 'none';

  if (Array.isArray(startingValue)) {
    for (const extension of startingValue) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
        extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
        for (const ext of extension.extension) {
          if (ext.url === "attribute") {
            startingExtensionStatisticModelIncludeIf.attribute = ext.valueCodeableConcept;
          } else if (ext.url === "value") {
            if (typeof ext.valueBoolean === 'boolean') {
              startingExtensionStatisticModelIncludeIf.valueBoolean = ext.valueBoolean;
              startingIncludeIfDotValueDatatype = "boolean";
            } else if (ext.valueCodeableConcept) {
              startingExtensionStatisticModelIncludeIf.valueCodeableConcept = ext.valueCodeableConcept;
              startingIncludeIfDotValueDatatype = "CodeableConcept";
            } else if (ext.valueQuantity) {
              startingExtensionStatisticModelIncludeIf.valueQuantity = ext.valueQuantity;
              startingIncludeIfDotValueDatatype = "Quantity";
            } else if (ext.valueRange) {
              startingExtensionStatisticModelIncludeIf.valueRange = ext.valueRange;
              startingIncludeIfDotValueDatatype = "Range";
            } else if (ext.valueExpression) {
              startingExtensionStatisticModelIncludeIf.valueExpression = ext.valueExpression;
              startingIncludeIfDotValueDatatype = "Expression";
            }
          }
        }
      } else {
        startingExtensionStatisticModelIncludeIf.otherExtensions.push(extension);
      }
    }
  }

  const [extensionStatisticModelIncludeIfState, setExtensionStatisticModelIncludeIfState] = useState(JSON.parse(JSON.stringify(startingExtensionStatisticModelIncludeIf || {})));
  const [includeIfDotValueDatatypeState, setIncludeIfDotValueDatatypeState] = useState(startingIncludeIfDotValueDatatype);

  useEffect((() => {
    if (includeIfDotValueDatatypeState === 'boolean') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueQuantity': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'CodeableConcept') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Quantity') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueCodeableConcept': null, 'valueRange': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Range') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueExpression': null } })
    }
    if (includeIfDotValueDatatypeState === 'Expression') {
      setExtensionStatisticModelIncludeIfState(prevState => { return { ...prevState, 'valueBoolean': null, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueRange': null } })
    }
  }), [includeIfDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(extensionStatisticModelIncludeIfState).length > 0) {
      let newExtension = extensionStatisticModelIncludeIfState.otherExtensions || [];
      let extensionIndex;
      for (let index in newExtension) {
        if (newExtension[index].url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
          newExtension[index].url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
          extensionIndex = index;
          break;
        }
      }
      let newIncludeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": extensionStatisticModelIncludeIfState.attribute
          },
          {
            "url": "value",
            "valueBoolean": extensionStatisticModelIncludeIfState.valueBoolean,
            "valueCodeableConcept": extensionStatisticModelIncludeIfState.valueCodeableConcept,
            'valueQuantity': extensionStatisticModelIncludeIfState.valueQuantity,
            'valueRange': extensionStatisticModelIncludeIfState.valueRange,
            'valueExpression': extensionStatisticModelIncludeIfState.valueExpression
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      if (extensionStatisticModelIncludeIfState.valueBoolean === null) {
        delete newIncludeIf.extension[1].valueBoolean;
      }
      if (extensionStatisticModelIncludeIfState.valueCodeableConcept === null) {
        delete newIncludeIf.extension[1].valueCodeableConcept;
      }
      if (extensionStatisticModelIncludeIfState.valueQuantity === null) {
        delete newIncludeIf.extension[1].valueQuantity;
      }
      if (extensionStatisticModelIncludeIfState.valueRange === null) {
        delete newIncludeIf.extension[1].valueRange;
      }
      if (extensionStatisticModelIncludeIfState.valueExpression === null) {
        delete newIncludeIf.extension[1].valueExpression;
      }
      if (extensionIndex !== undefined) {
        if (extensionStatisticModelIncludeIfState.attribute ||
          extensionStatisticModelIncludeIfState.valueCodeableConcept ||
          extensionStatisticModelIncludeIfState.valueQuantity ||
          extensionStatisticModelIncludeIfState.valueRange ||
          extensionStatisticModelIncludeIfState.valueExpression) {
          newExtension[extensionIndex] = newIncludeIf;
        } else {
          delete newExtension[extensionIndex];
        }
      } else {
        if (extensionStatisticModelIncludeIfState.attribute ||
          extensionStatisticModelIncludeIfState.valueCodeableConcept ||
          extensionStatisticModelIncludeIfState.valueQuantity ||
          extensionStatisticModelIncludeIfState.valueRange ||
          extensionStatisticModelIncludeIfState.valueExpression) {
          newExtension.push(newIncludeIf);
        }
      }
      handleChange(elementName, newExtension, setResourceState);
    }
  }), [extensionStatisticModelIncludeIfState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='attribute' fieldLabel='Type of Condition'
        startingValue={extensionStatisticModelIncludeIfState.attribute}
        setResourceState={setExtensionStatisticModelIncludeIfState} />
      <DatatypeSelector elementXName='value[x]'
        allowedDatatypes={ExtensionStatisticModelIncludeIfValueAllowedDatatypes}
        datatypeState={includeIfDotValueDatatypeState}
        setDatatypeState={setIncludeIfDotValueDatatypeState} />
      {includeIfDotValueDatatypeState === 'boolean' &&
        <DataEntry datatype='boolean' elementName='valueBoolean' fieldLabel='Value as Boolean'
          startingValue={extensionStatisticModelIncludeIfState.valueBoolean}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'CodeableConcept' &&
        <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Value as CodeableConcept'
          startingValue={extensionStatisticModelIncludeIfState.valueCodeableConcept}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Quantity' &&
        <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Value as Quantity'
          startingValue={extensionStatisticModelIncludeIfState.valueQuantity}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Range' &&
        <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Value as Range'
          startingValue={extensionStatisticModelIncludeIfState.valueRange}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
      {includeIfDotValueDatatypeState === 'Expression' &&
        <DataEntry datatype='Expression' elementName='valueExpression' fieldLabel='Value as Expression'
          startingValue={extensionStatisticModelIncludeIfState.valueExpression}
          setResourceState={setExtensionStatisticModelIncludeIfState} />}
    </div>
  </>
})

const EvidenceStatisticModelCharacteristicDotValueAllowedDatatypes = ['CodeableConcept', 'Quantity', 'Range'];
const EvidenceStatisticModelCharacteristicEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingModelCharacteristic = {
    extension: [],
    conditionCodeableConcept: "", conditionExpression: "", code: "",
    valueQuantity: "", valueRange: "", valueCodeableConcept: "",
    intended: "", applied: "",
    variable: [], attribute: [], attributeEstimate: [], modelCharacteristic: []
  }
  let startingEvidenceModelCharacteristicDotValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingModelCharacteristic.extension = startingValue.extension; }
    if (startingValue.conditionCodeableConcept) {
      if (!startingModelCharacteristic.extension) {
        startingModelCharacteristic.extension = [];
      }
      let includeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": startingValue.conditionCodeableConcept
          },
          {
            "url": "value",
            "valueBoolean": true
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      startingModelCharacteristic.extension.push(includeIf);
    }
    if (startingValue.conditionExpression) {
      if (!startingModelCharacteristic.extension) {
        startingModelCharacteristic.extension = [];
      }
      let includeIf = {
        "extension": [
          {
            "url": "attribute",
            "valueCodeableConcept": { "text": "Defined by Expression" }
          },
          {
            "url": "value",
            "valueExpression": startingValue.conditionExpression
          }
        ],
        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if"
      };
      startingModelCharacteristic.extension.push(includeIf);
    }
    if (startingValue.code) { startingModelCharacteristic.code = startingValue.code; }
    if (startingValue.valueCodeableConcept) {
      startingModelCharacteristic.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingEvidenceModelCharacteristicDotValueDatatype = "CodeableConcept";
    }
    if (startingValue.valueQuantity) {
      startingModelCharacteristic.valueQuantity = startingValue.valueQuantity;
      startingEvidenceModelCharacteristicDotValueDatatype = "Quantity";
    }
    if (startingValue.valueRange) {
      startingModelCharacteristic.valueRange = startingValue.valueRange;
      startingEvidenceModelCharacteristicDotValueDatatype = "Range";
    }
    if (typeof startingValue.intended === "boolean") {
      startingModelCharacteristic.intended = startingValue.intended;
    }
    if (typeof startingValue.applied === "boolean") {
      startingModelCharacteristic.applied = startingValue.applied;
    }
    if (startingValue.variable) { startingModelCharacteristic.variable = startingValue.variable; }
    if (startingValue.attribute) { startingModelCharacteristic.attribute = startingValue.attribute; }
    if (startingValue.attributeEstimate) { startingModelCharacteristic.attributeEstimate = startingValue.attributeEstimate; }
    if (startingValue.modelCharacteristic) { startingModelCharacteristic.modelCharacteristic = startingValue.modelCharacteristic; }
  }

  const [evidenceStatisticModelCharacteristicState, setEvidenceStatisticModelCharacteristicState] = useState(JSON.parse(JSON.stringify(startingModelCharacteristic || {})));
  const [evidenceStatisticModelCharacteristicDotValueDatatypeState, setEvidenceStatisticModelCharacteristicDotValueDatatypeState] = useState(startingEvidenceModelCharacteristicDotValueDatatype);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueRange': null } })
    }
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueRange': null } })
    }
    if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range') {
      setEvidenceStatisticModelCharacteristicState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null } })
    }
  }), [evidenceStatisticModelCharacteristicDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(evidenceStatisticModelCharacteristicState).length > 0) {
      let newModelCharacteristic = {};
      if (evidenceStatisticModelCharacteristicState.extension?.length > 0) { newModelCharacteristic.extension = evidenceStatisticModelCharacteristicState.extension; }
      if (evidenceStatisticModelCharacteristicState.code && Object.keys(evidenceStatisticModelCharacteristicState.code).length > 0) {
        newModelCharacteristic.code = evidenceStatisticModelCharacteristicState.code;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept' &&
        evidenceStatisticModelCharacteristicState.valueCodeableConcept &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueCodeableConcept).length > 0) {
        newModelCharacteristic.valueCodeableConcept = evidenceStatisticModelCharacteristicState.valueCodeableConcept;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity' &&
        evidenceStatisticModelCharacteristicState.valueQuantity &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueQuantity).length > 0) {
        newModelCharacteristic.valueQuantity = evidenceStatisticModelCharacteristicState.valueQuantity;
      }
      if (evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range' &&
        evidenceStatisticModelCharacteristicState.valueRange &&
        Object.keys(evidenceStatisticModelCharacteristicState.valueRange).length > 0) {
        newModelCharacteristic.valueRange = evidenceStatisticModelCharacteristicState.valueRange;
      }
      if (typeof evidenceStatisticModelCharacteristicState.intended === "boolean") {
        newModelCharacteristic.intended = evidenceStatisticModelCharacteristicState.intended;
      }
      if (typeof evidenceStatisticModelCharacteristicState.applied === "boolean") {
        newModelCharacteristic.applied = evidenceStatisticModelCharacteristicState.applied;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicState.variable) && evidenceStatisticModelCharacteristicState.variable.length > 0) {
        newModelCharacteristic.variable = evidenceStatisticModelCharacteristicState.variable;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicState.attribute) && evidenceStatisticModelCharacteristicState.attribute.length > 0) {
        newModelCharacteristic.attribute = evidenceStatisticModelCharacteristicState.attribute;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicState.attributeEstimate) && evidenceStatisticModelCharacteristicState.attributeEstimate.length > 0) {
        newModelCharacteristic.attributeEstimate = evidenceStatisticModelCharacteristicState.attributeEstimate;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicState.modelCharacteristic) && evidenceStatisticModelCharacteristicState.modelCharacteristic.length > 0) {
        newModelCharacteristic.modelCharacteristic = evidenceStatisticModelCharacteristicState.modelCharacteristic;
      }
      if (Object.keys(newModelCharacteristic).length === 0) {
        newModelCharacteristic = null;
      }
      handleChange(elementName, newModelCharacteristic, setResourceState);
    }
  }), [evidenceStatisticModelCharacteristicState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        <div style={{ marginLeft: "24px" }}>
          <ModelCharacteristicDisplay modelCharactersticInstance={startingValue} />
        </div>
        <br />
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Model Characteristic Specification'
          startingValue={evidenceStatisticModelCharacteristicState.code} startCollapsed
          setResourceState={setEvidenceStatisticModelCharacteristicState} />
        <DatatypeSelector elementXName='value[x]'
          allowedDatatypes={EvidenceStatisticModelCharacteristicDotValueAllowedDatatypes}
          datatypeState={evidenceStatisticModelCharacteristicDotValueDatatypeState}
          setDatatypeState={setEvidenceStatisticModelCharacteristicDotValueDatatypeState} />
        {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'CodeableConcept' &&
          <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept'
            fieldLabel='Value as CodeableConcept' startCollapsed
            startingValue={evidenceStatisticModelCharacteristicState.valueCodeableConcept}
            setResourceState={setEvidenceStatisticModelCharacteristicState} />}
        {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Quantity' &&
          <DataEntry datatype='SimpleQuantity' elementName='valueQuantity'
            fieldLabel='Value as Quantity' startCollapsed
            startingValue={evidenceStatisticModelCharacteristicState.valueQuantity}
            setResourceState={setEvidenceStatisticModelCharacteristicState} />}
        {evidenceStatisticModelCharacteristicDotValueDatatypeState === 'Range' &&
          <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Value as Range'
            startCollapsed
            startingValue={evidenceStatisticModelCharacteristicState.valueRange}
            setResourceState={setEvidenceStatisticModelCharacteristicState} />}
        <DataEntry datatype='boolean' elementName='intended' fieldLabel='Intended' storeFalse={true}
          startingValue={evidenceStatisticModelCharacteristicState.intended ?? null}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />
        {evidenceStatisticModelCharacteristicState.intended &&
          <ExtensionStatisticModelIncludeIfEntry elementName='extension' fieldLabel='Include If'
            startingValue={evidenceStatisticModelCharacteristicState.extension}
            setResourceState={setEvidenceStatisticModelCharacteristicState} />}
        <DataEntry datatype='boolean' elementName='applied' fieldLabel='Applied' storeFalse={true}
          startingValue={evidenceStatisticModelCharacteristicState.applied ?? null}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />
        <DataEntry asArray={true} datatype='EvidenceStatisticModelCharacteristicVariable' elementName='variable'
          fieldLabel='Variable'
          dataEntryStyle={evidenceStatisticModelCharacteristicState.intended && "includeIf"}
          startEmptyArrayClosed={true} deletableArray={true}
          startingValue={evidenceStatisticModelCharacteristicState.variable}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />
        <DataEntry asArray={true} datatype='EvidenceStatisticAttributeEstimate' elementName='attribute'
          fieldLabel='Attribute' startCollapsed
          startEmptyArrayClosed={true} deletableArray={true}
          startingValue={evidenceStatisticModelCharacteristicState.attributeEstimate}
          setResourceState={setEvidenceStatisticModelCharacteristicState} />
      </div>
    </>
  }
})

const evidenceStatisticModelCharacteristicVariableDotHandlingCodeValues = ['continuous', 'dichotomous', 'ordinal', 'polychotomous'];
const evidenceStatisticModelCharacteristicVariableDotVariableDefinitionResourceTypes = ['Group', 'EvidenceVariable'];
const EvidenceStatisticModelCharacteristicVariableEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingVariable = {
    variableDefinition: "", handling: "", valueCategory: [], valueQuantity: [], valueRange: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariable.extension = startingValue.extension; }
    if (startingValue.variableDefinition) { startingVariable.variableDefinition = startingValue.variableDefinition; }
    if (startingValue.handling) { startingVariable.handling = startingValue.handling; }
    if (startingValue.valueCategory) { startingVariable.valueCategory = startingValue.valueCategory; }
    if (startingValue.valueQuantity) { startingVariable.valueQuantity = startingValue.valueQuantity; }
    if (startingValue.valueRange) { startingVariable.valueRange = startingValue.valueRange; }
  }

  const [evidenceStatisticModelCharacteristicVariableState, setEvidenceStatisticModelCharacteristicVariableState] = useState(JSON.parse(JSON.stringify(startingVariable || {})));

  useEffect((() => {
    if (Object.keys(evidenceStatisticModelCharacteristicVariableState).length > 0) {
      let newEvidenceStatisticModelCharacteristicVariable = {};
      if (evidenceStatisticModelCharacteristicVariableState.extension?.length > 0) { newEvidenceStatisticModelCharacteristicVariable.extension = evidenceStatisticModelCharacteristicVariableState.extension; }
      if (evidenceStatisticModelCharacteristicVariableState.variableDefinition && Object.keys(evidenceStatisticModelCharacteristicVariableState.variableDefinition).length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.variableDefinition = evidenceStatisticModelCharacteristicVariableState.variableDefinition;
      }
      if (evidenceStatisticModelCharacteristicVariableState.handling) { newEvidenceStatisticModelCharacteristicVariable.handling = evidenceStatisticModelCharacteristicVariableState.handling; }
      if (Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueCategory) && evidenceStatisticModelCharacteristicVariableState.valueCategory.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueCategory = evidenceStatisticModelCharacteristicVariableState.valueCategory;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueQuantity) && evidenceStatisticModelCharacteristicVariableState.valueQuantity.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueQuantity = evidenceStatisticModelCharacteristicVariableState.valueQuantity;
      }
      if (Array.isArray(evidenceStatisticModelCharacteristicVariableState.valueRange) && evidenceStatisticModelCharacteristicVariableState.valueRange.length > 0) {
        newEvidenceStatisticModelCharacteristicVariable.valueRange = evidenceStatisticModelCharacteristicVariableState.valueRange;
      }
      if (Object.keys(newEvidenceStatisticModelCharacteristicVariable).length === 0) {
        newEvidenceStatisticModelCharacteristicVariable = null;
      }
      handleChange(elementName, newEvidenceStatisticModelCharacteristicVariable, setResourceState);
    }
  }), [evidenceStatisticModelCharacteristicVariableState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Reference' elementName='variableDefinition' fieldLabel='Variable Definition'
        startingValue={evidenceStatisticModelCharacteristicVariableState.variableDefinition}
        referencedResourceTypes={evidenceStatisticModelCharacteristicVariableDotVariableDefinitionResourceTypes}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry datatype='code' elementName='handling' fieldLabel='Handling'
        startingValue={evidenceStatisticModelCharacteristicVariableState.handling}
        allowedValues={evidenceStatisticModelCharacteristicVariableDotHandlingCodeValues}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='CodeableConcept' elementName='valueCategory'
        fieldLabel='Value Option (as Category)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueCategory}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='Quantity' elementName='valueQuantity'
        fieldLabel='Value Option (as Quantity)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueQuantity}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      <DataEntry asArray={true} datatype='Range' elementName='valueRange'
        fieldLabel='Value Option (as Range)'
        startEmptyArrayClosed={true} deletableArray={true}
        startingValue={evidenceStatisticModelCharacteristicVariableState.valueRange}
        setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />
      {dataEntryStyle === "includeIf" &&
        <ExtensionStatisticModelIncludeIfEntry elementName='extension' fieldLabel='Include If'
          startingValue={evidenceStatisticModelCharacteristicVariableState.extension}
          setResourceState={setEvidenceStatisticModelCharacteristicVariableState} />}
    </div>
  </>
})

const EvidenceVariableCategoryDotValueAllowedDatatypes = ['CodeableConcept', 'Quantity', 'Range', 'Reference'];
const EvidenceVariableCategoryEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingVariableCategory = {
    name: "", valueCodeableConcept: "", valueQuantity: "", valueRange: "", valueReference: ""
  }
  let startingEvidenceVariableCategoryDotValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableCategory.extension = startingValue.extension; }
    if (startingValue.name) { startingVariableCategory.name = startingValue.name; }
    if (startingValue.valueCodeableConcept) {
      startingVariableCategory.valueCodeableConcept = startingValue.valueCodeableConcept;
      startingEvidenceVariableCategoryDotValueDatatype = "CodeableConcept";
    }
    if (startingValue.valueQuantity) {
      startingVariableCategory.valueQuantity = startingValue.valueQuantity;
      startingEvidenceVariableCategoryDotValueDatatype = "Quantity";
    }
    if (startingValue.valueRange) {
      startingVariableCategory.valueRange = startingValue.valueRange;
      startingEvidenceVariableCategoryDotValueDatatype = "Range";
    }
    if (startingValue.valueReference) {
      startingVariableCategory.valueReference = startingValue.valueReference;
      startingEvidenceVariableCategoryDotValueDatatype = "Reference";
    }
  }

  const [evidenceVariableCategoryState, setEvidenceVariableCategoryState] = useState(JSON.parse(JSON.stringify(startingVariableCategory || {})));
  const [evidenceVariableCategoryDotValueDatatypeState, setEvidenceVariableCategoryDotValueDatatypeState] = useState(startingEvidenceVariableCategoryDotValueDatatype);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueRange': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Quantity') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueCodeableConcept': null, 'valueRange': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Range') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueReference': null } })
    }
    if (evidenceVariableCategoryDotValueDatatypeState === 'Reference') {
      setEvidenceVariableCategoryState(prevState => { return { ...prevState, 'valueQuantity': null, 'valueCodeableConcept': null, 'valueRange': null } })
    }
  }), [evidenceVariableCategoryDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(evidenceVariableCategoryState).length > 0) {
      let newEvidenceVariableCategory = {};
      if (evidenceVariableCategoryState.extension) { newEvidenceVariableCategory.extension = evidenceVariableCategoryState.extension; }
      if (evidenceVariableCategoryState.name) { newEvidenceVariableCategory.name = evidenceVariableCategoryState.name; }
      if (evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept' && evidenceVariableCategoryState.valueCodeableConcept && Object.keys(evidenceVariableCategoryState.valueCodeableConcept).length > 0) {
        newEvidenceVariableCategory.valueCodeableConcept = evidenceVariableCategoryState.valueCodeableConcept;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Quantity' && evidenceVariableCategoryState.valueQuantity && Object.keys(evidenceVariableCategoryState.valueQuantity).length > 0) {
        newEvidenceVariableCategory.valueQuantity = evidenceVariableCategoryState.valueQuantity;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Range' && evidenceVariableCategoryState.valueRange && Object.keys(evidenceVariableCategoryState.valueRange).length > 0) {
        newEvidenceVariableCategory.valueRange = evidenceVariableCategoryState.valueRange;
      }
      if (evidenceVariableCategoryDotValueDatatypeState === 'Reference' && evidenceVariableCategoryState.valueReference && Object.keys(evidenceVariableCategoryState.valueReference).length > 0) {
        newEvidenceVariableCategory.valueReference = evidenceVariableCategoryState.valueReference;
      }
      if (Object.keys(newEvidenceVariableCategory).length === 0) {
        newEvidenceVariableCategory = null;
      }
      handleChange(elementName, newEvidenceVariableCategory, setResourceState);
    }
  }), [evidenceVariableCategoryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {(startingValue.name && typeof startingValue.name === "string") && <p><b>Name: </b>{startingValue.name}</p>}
        {startingValue.valueCodeableConcept && <span><b>Value: </b><DisplayFromFHIR codeableConcept={startingValue.valueCodeableConcept} /></span>}
        {startingValue.valueQuantity && <span> <b>Value: </b>{getStringFromFHIR.Quantity(startingValue.valueQuantity)}</span>}
        {startingValue.valueRange && <span><b>Value: </b>{getStringFromFHIR.Range(startingValue.valueRange)}</span>}
        {startingValue.valueReference && <span><b>Value: </b><DisplayFromFHIR reference={startingValue.valueReference} /></span >}
      </div >}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div >
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='name' fieldLabel='Category Name'
          startingValue={evidenceVariableCategoryState.name} setResourceState={setEvidenceVariableCategoryState} />
        <DatatypeSelector elementXName='value[x]' allowedDatatypes={EvidenceVariableCategoryDotValueAllowedDatatypes}
          datatypeState={evidenceVariableCategoryDotValueDatatypeState} setDatatypeState={setEvidenceVariableCategoryDotValueDatatypeState} />
        {evidenceVariableCategoryDotValueDatatypeState === 'CodeableConcept' &&
          <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Category Definition as CodeableConcept'
            startingValue={evidenceVariableCategoryState.valueCodeableConcept}
            setResourceState={setEvidenceVariableCategoryState} />}
        {evidenceVariableCategoryDotValueDatatypeState === 'Quantity' &&
          <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Category Definition as Quantity'
            startingValue={evidenceVariableCategoryState.valueQuantity}
            setResourceState={setEvidenceVariableCategoryState} />}
        {evidenceVariableCategoryDotValueDatatypeState === 'Range' &&
          <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Category Definition as Range'
            startingValue={evidenceVariableCategoryState.valueRange}
            setResourceState={setEvidenceVariableCategoryState} />}
        {evidenceVariableCategoryDotValueDatatypeState === 'Reference' &&
          <DataEntry datatype='Reference' elementName='valueReference' fieldLabel='Category Definition as Reference'
            startingValue={evidenceVariableCategoryState.valueReference} enableCreation={true}
            referencedResourceTypes={["Group"]}
            setResourceState={setEvidenceVariableCategoryState} />}
      </div>
    </>
  }
})

const EvidenceVariableConstraintEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingVariableConstraint = {
    conditional: "", minimumQuantity: "", maximumQuantity: "",
    earliestDateTime: "", latestDateTime: "", minimumStringLength: "", maximumStringLength: "",
    code: "", expression: "", expectedValueSet: "", expectedUnitsValueSet: "", anyValueAllowed: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableConstraint.extension = startingValue.extension; }
    if (startingValue.conditional) { startingVariableConstraint.conditional = startingValue.conditional; }
    if (startingValue.minimumQuantity) { startingVariableConstraint.minimumQuantity = startingValue.minimumQuantity; }
    if (startingValue.maximumQuantity) { startingVariableConstraint.maximumQuantity = startingValue.maximumQuantity; }
    if (startingValue.earliestDateTime) { startingVariableConstraint.earliestDateTime = startingValue.earliestDateTime; }
    if (startingValue.latestDateTime) { startingVariableConstraint.latestDateTime = startingValue.latestDateTime; }
    if (startingValue.minimumStringLength) { startingVariableConstraint.minimumStringLength = startingValue.minimumStringLength; }
    if (startingValue.maximumStringLength) { startingVariableConstraint.maximumStringLength = startingValue.maximumStringLength; }
    if (startingValue.code) { startingVariableConstraint.code = startingValue.code; }
    if (startingValue.expression) { startingVariableConstraint.expression = startingValue.expression; }
    if (startingValue.expectedValueSet) { startingVariableConstraint.expectedValueSet = startingValue.expectedValueSet; }
    if (startingValue.expectedUnitsValueSet) { startingVariableConstraint.expectedUnitsValueSet = startingValue.expectedUnitsValueSet; }
    if (typeof startingValue.anyValueAllowed === "boolean") {
      startingVariableConstraint.anyValueAllowed = startingValue.anyValueAllowed;
    }
  }

  const [evidenceVariableConstraintState, setEvidenceVariableConstraintState] = useState(JSON.parse(JSON.stringify(startingVariableConstraint || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(evidenceVariableConstraintState).length > 0) {
      let newEvidenceVariableConstraint = {};
      if (evidenceVariableConstraintState.extension) { newEvidenceVariableConstraint.extension = evidenceVariableConstraintState.extension; }
      if (evidenceVariableConstraintState.conditional) { newEvidenceVariableConstraint.conditional = evidenceVariableConstraintState.conditional; }
      if (evidenceVariableConstraintState.minimumQuantity) { newEvidenceVariableConstraint.minimumQuantity = evidenceVariableConstraintState.minimumQuantity; }
      if (evidenceVariableConstraintState.maximumQuantity) { newEvidenceVariableConstraint.maximumQuantity = evidenceVariableConstraintState.maximumQuantity; }
      if (evidenceVariableConstraintState.earliestDateTime) { newEvidenceVariableConstraint.earliestDateTime = evidenceVariableConstraintState.earliestDateTime; }
      if (evidenceVariableConstraintState.latestDateTime) { newEvidenceVariableConstraint.latestDateTime = evidenceVariableConstraintState.latestDateTime; }
      if (evidenceVariableConstraintState.minimumStringLength) { newEvidenceVariableConstraint.minimumStringLength = evidenceVariableConstraintState.minimumStringLength; }
      if (evidenceVariableConstraintState.maximumStringLength) { newEvidenceVariableConstraint.maximumStringLength = evidenceVariableConstraintState.maximumStringLength; }
      if (evidenceVariableConstraintState.code) { newEvidenceVariableConstraint.code = evidenceVariableConstraintState.code; }
      if (evidenceVariableConstraintState.expression) { newEvidenceVariableConstraint.expression = evidenceVariableConstraintState.expression; }
      if (evidenceVariableConstraintState.expectedValueSet) { newEvidenceVariableConstraint.expectedValueSet = evidenceVariableConstraintState.expectedValueSet; }
      if (evidenceVariableConstraintState.expectedUnitsValueSet) { newEvidenceVariableConstraint.expectedUnitsValueSet = evidenceVariableConstraintState.expectedUnitsValueSet; }
      if (typeof evidenceVariableConstraintState.anyValueAllowed === "boolean") {
        newEvidenceVariableConstraint.anyValueAllowed = evidenceVariableConstraintState.anyValueAllowed;
      }
      if (Object.keys(newEvidenceVariableConstraint).length === 0) {
        newEvidenceVariableConstraint = null;
      }
      handleChange(elementName, newEvidenceVariableConstraint, setResourceState);
    }
  }), [evidenceVariableConstraintState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.conditional && <span>
          <b>Conditional (constraint applies if): </b><DisplayFromFHIR codeableConcept={startingValue.conditional} />
        </span>}
        {(startingValue.minimumQuantity) &&
          <p><b>Minimum quantity: </b>{getStringFromFHIR.Quantity(startingValue.minimumQuantity)}</p>}
        {(startingValue.maximumQuantity) &&
          <p><b>Maximum quantity: </b>{getStringFromFHIR.Quantity(startingValue.maximumQuantity)}</p>}
        {(startingValue.earliestDateTime) &&
          <p><b>Earliest time: </b>{getStringFromFHIR.dateTime(startingValue.earliestDateTime)}</p>}
        {(startingValue.latestDateTime) &&
          <p><b>Latest time: </b>{getStringFromFHIR.dateTime(startingValue.latestDateTime)}</p>}
        {(startingValue.minimumStringLength || startingValue.minimumStringLength === 0) &&
          <p><b>Minimum string length: </b>{startingValue.minimumStringLength.toString()} characters</p>}
        {(startingValue.maximumStringLength) &&
          <p><b>Maximum string length: </b>{startingValue.maximumStringLength.toString()} characters</p>}
        {startingValue.code && <span>
          <b>Rule (as CodeableConcept): </b><DisplayFromFHIR codeableConcept={startingValue.code} />
        </span>}
        {startingValue.expression && <span>
          <b>Rule (as Expression): </b><DisplayFromFHIR expression={startingValue.expression} />
        </span>}
        {startingValue.expectedValueSet && <span>
          <b>Expected values: </b><DisplayFromFHIR reference={startingValue.expectedValueSet} />
        </span>}
        {startingValue.expectedUnitsValueSet && <span>
          <b>Expected units: </b><DisplayFromFHIR reference={startingValue.expectedUnitsValueSet} />
        </span>}
        {startingValue.anyValueAllowed === true && <p><b>Any value allowed: </b> TRUE</p>}
        {startingValue.anyValueAllowed === false && <p><b>Any value allowed: </b> False</p>}
      </div >}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div >
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='conditional'
          fieldLabel='Conditional (constraint applies if)'
          startingValue={evidenceVariableConstraintState.conditional}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='Quantity' elementName='minimumQuantity' fieldLabel='Minimum quantity'
          startingValue={evidenceVariableConstraintState.minimumQuantity}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='Quantity' elementName='maximumQuantity' fieldLabel='Maximum quantity'
          startingValue={evidenceVariableConstraintState.maximumQuantity}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='dateTime' elementName='earliestDateTime' fieldLabel='Earliest time'
          startingValue={evidenceVariableConstraintState.earliestDateTime}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='dateTime' elementName='latestDateTime' fieldLabel='Latest time'
          startingValue={evidenceVariableConstraintState.latestDateTime}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='unsignedInt' elementName='minimumStringLength' fieldLabel='Minimum string length'
          startingValue={evidenceVariableConstraintState.minimumStringLength}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='positiveInt' elementName='maximumStringLength' fieldLabel='Maximum string length'
          startingValue={evidenceVariableConstraintState.maximumStringLength}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='CodeableConcept' elementName='code'
          fieldLabel='Rule (as CodeableConcept)'
          startingValue={evidenceVariableConstraintState.code}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='Expression' elementName='expression'
          fieldLabel='Rule (as Expression)'
          startingValue={evidenceVariableConstraintState.expression}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='Reference' elementName='expectedValueSet'
          fieldLabel='Expected values' enableCreation={true}
          startingResourceType="ValueSet"
          referencedResourceTypes={["ValueSet"]} startCollapsed
          startingValue={evidenceVariableConstraintState.expectedValueSet}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='Reference' elementName='expectedUnitsValueSet'
          fieldLabel='Expected units' enableCreation={true}
          startingResourceType="ValueSet"
          referencedResourceTypes={["ValueSet"]} startCollapsed
          startingValue={evidenceVariableConstraintState.expectedUnitsValueSet}
          setResourceState={setEvidenceVariableConstraintState} />
        <DataEntry datatype='boolean' elementName='anyValueAllowed'
          fieldLabel='Any value allowed' storeFalse
          startingValue={evidenceVariableConstraintState.anyValueAllowed ?? null}
          setResourceState={setEvidenceVariableConstraintState} />
      </div>
    </>
  }
})

const EvidenceVariableDataStorageEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed }) => {
  let startingVariableDataStorage = {
    datatype: "", path: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableDataStorage.extension = startingValue.extension; }
    if (startingValue.datatype) { startingVariableDataStorage.datatype = startingValue.datatype; }
    if (startingValue.path) { startingVariableDataStorage.path = startingValue.path; }
  }

  const [evidenceVariableDataStorageState, setEvidenceVariableDataStorageState] = useState(JSON.parse(JSON.stringify(startingVariableDataStorage || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(evidenceVariableDataStorageState).length > 0) {
      let newEvidenceVariableDataStorage = {};
      if (evidenceVariableDataStorageState.extension) { newEvidenceVariableDataStorage.extension = evidenceVariableDataStorageState.extension; }
      if (evidenceVariableDataStorageState.datatype) { newEvidenceVariableDataStorage.datatype = evidenceVariableDataStorageState.datatype; }
      if (evidenceVariableDataStorageState.path) { newEvidenceVariableDataStorage.path = evidenceVariableDataStorageState.path; }
      if (Object.keys(newEvidenceVariableDataStorage).length === 0) {
        newEvidenceVariableDataStorage = null;
      }
      handleChange(elementName, newEvidenceVariableDataStorage, setResourceState);
    }
  }), [evidenceVariableDataStorageState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.datatype && <span><b>Datatype: </b><DisplayFromFHIR codeableConcept={startingValue.datatype} /></span>}
        {(startingValue.path && typeof startingValue.path === "string") && <p><b>Path: </b>{startingValue.path}</p>}
      </div >}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div >
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='datatype' fieldLabel='Datatype'
          startingValue={evidenceVariableDataStorageState.datatype}
          setResourceState={setEvidenceVariableDataStorageState} />
        <DataEntry datatype='string' elementName='path' fieldLabel='Path'
          startingValue={evidenceVariableDataStorageState.path} setResourceState={setEvidenceVariableDataStorageState} />
      </div>
    </>
  }
})

const evidenceDotVariableDefinitionDotVariableRoleCodeSet = ["population", "exposure", "outcome", "covariate",
  "subpopulation", "referenceExposure", "measuredVariable", "confounder"];
const evidenceDotVariableDefinitionDotVariableRoleValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "population", display: "population" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "subpopulation", display: "subpopulation" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "exposure", display: "exposure" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "referenceExposure", display: "reference exposure" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "outcome", display: "outcome" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariable", display: "measured variable" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableA", display: "measured variable A" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableB", display: "measured variable B" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "measuredVariableAB", display: "measured variable A to B" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "covariate", display: "covariate" },
  { system: "http://terminology.hl7.org/CodeSystem/variable-role", code: "confounder", display: "confounder" }
];
const evidenceDotVariableDefinitionDotReferenceResourceTypes = ['Group', 'EvidenceVariable'];
const evidenceDotVariableDefinitionDotDirectnessMatchValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "low", display: "Low quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "moderate", display: "Moderate quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "high", display: "High quality match between observed and intended variable" },
  { system: "http://terminology.hl7.org/CodeSystem/directness", version: "1.0.0", code: "exact", display: "Exact match between observed and intended variable" }
];
const EvidenceVariableDefinitionEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, startCollapsed, fullResourceState, setSourceJsonState }) => {
  let startingVariableDefinition = {
    description: "", note: [], variableRole: "", roleSubtype: "", comparatorCategory: "", observed: "", intended: "", directnessMatch: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingVariableDefinition.extension = startingValue.extension; }
    if (startingValue.description) { startingVariableDefinition.description = startingValue.description; }
    if (startingValue.note) { startingVariableDefinition.note = startingValue.note; }
    if (startingValue.variableRole) { startingVariableDefinition.variableRole = startingValue.variableRole; }
    if (startingValue.roleSubtype) { startingVariableDefinition.roleSubtype = startingValue.roleSubtype; }
    if (startingValue.comparatorCategory) { startingVariableDefinition.comparatorCategory = startingValue.comparatorCategory; }
    if (startingValue.observed) { startingVariableDefinition.observed = startingValue.observed; }
    if (startingValue.intended) { startingVariableDefinition.intended = startingValue.intended; }
    if (startingValue.directnessMatch) { startingVariableDefinition.directnessMatch = startingValue.directnessMatch; }
  }

  const [evidenceVariableDefinitionState, setEvidenceVariableDefinitionState] = useState(JSON.parse(JSON.stringify(startingVariableDefinition || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(evidenceVariableDefinitionState).length > 0) {
      let newEvidenceVariableDefinition = {};
      if (evidenceVariableDefinitionState.extension) { newEvidenceVariableDefinition.extension = evidenceVariableDefinitionState.extension; }
      if (evidenceVariableDefinitionState.description) { newEvidenceVariableDefinition.description = evidenceVariableDefinitionState.description; }
      if (Array.isArray(evidenceVariableDefinitionState.note) && evidenceVariableDefinitionState.note.length > 0) {
        newEvidenceVariableDefinition.note = evidenceVariableDefinitionState.note;
      }
      if (evidenceVariableDefinitionState.variableRole) { newEvidenceVariableDefinition.variableRole = evidenceVariableDefinitionState.variableRole; }
      if (evidenceVariableDefinitionState.roleSubtype && Object.keys(evidenceVariableDefinitionState.roleSubtype).length > 0) {
        newEvidenceVariableDefinition.roleSubtype = evidenceVariableDefinitionState.roleSubtype;
      }
      if (evidenceVariableDefinitionState.comparatorCategory) { newEvidenceVariableDefinition.comparatorCategory = evidenceVariableDefinitionState.comparatorCategory; }

      if (evidenceVariableDefinitionState.observed && Object.keys(evidenceVariableDefinitionState.observed).length > 0) {
        newEvidenceVariableDefinition.observed = evidenceVariableDefinitionState.observed;
      }
      if (evidenceVariableDefinitionState.intended && Object.keys(evidenceVariableDefinitionState.intended).length > 0) {
        newEvidenceVariableDefinition.intended = evidenceVariableDefinitionState.intended;
      }
      if (evidenceVariableDefinitionState.directnessMatch && Object.keys(evidenceVariableDefinitionState.directnessMatch).length > 0) {
        newEvidenceVariableDefinition.directnessMatch = evidenceVariableDefinitionState.directnessMatch;
      }
      if (Object.keys(newEvidenceVariableDefinition).length === 0) {
        newEvidenceVariableDefinition = null;
      }
      handleChange(elementName, newEvidenceVariableDefinition, setResourceState);
    }
  }), [evidenceVariableDefinitionState]);

  if (startCollapsedState) {
    return <div>
      <p><b>{fieldLabel}: </b></p>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.description && <span><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /></span>}
        {Array.isArray(startingValue.note) &&
          startingValue.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note: </b> <DisplayFromFHIR annotation={note} /><br /></span> })}
        {(startingValue.variableRole && typeof startingValue.variableRole === "string") && <><b>VARIABLE ROLE: </b>{startingValue.variableRole}<br /></>}
        {(startingValue.variableRole && typeof startingValue.variableRole === "object") && <><b>VARIABLE ROLE: </b><DisplayFromFHIR codeableConcept={startingValue.variableRole} /><br /></>}
        {startingValue.roleSubtype && <><b>Variable Role Subtype: </b><DisplayFromFHIR codeableConcept={startingValue.roleSubtype} /><br /></>}
        {startingValue.comparatorCategory && <><b>Comparator category: </b>{startingValue.comparatorCategory}<br /></>}
        {startingValue.observed && <><b>Observed: </b><DisplayFromFHIR reference={startingValue.observed} /><br /></>}
        {startingValue.intended && <><b>Intended: </b><DisplayFromFHIR reference={startingValue.intended} /><br /></>}
        {startingValue.directnessMatch && <><b>Directness match: </b><DisplayFromFHIR codeableConcept={startingValue.directnessMatch} /><br /></>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed
          startingValue={evidenceVariableDefinitionState.description} setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed
          startingValue={evidenceVariableDefinitionState.note} setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='code' elementName='variableRole' fieldLabel='Variable Role'
          startingValue={evidenceVariableDefinitionState.variableRole}
          allowedValues={evidenceDotVariableDefinitionDotVariableRoleCodeSet}
          setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='CodeableConcept' elementName='roleSubtype' fieldLabel='Variable Role Subtype'
          startingValue={evidenceVariableDefinitionState.roleSubtype} startCollapsed
          valueSet={evidenceDotVariableDefinitionDotVariableRoleValueSet}
          setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='string' elementName='comparatorCategory' fieldLabel='Comparator Category'
          startingValue={evidenceVariableDefinitionState.comparatorCategory}
          setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='Reference' elementName='observed' fieldLabel='Observed'
          startingValue={evidenceVariableDefinitionState.observed} enableCreation={true}
          startCollapsed fullResourceState={fullResourceState}
          setSourceJsonState={setSourceJsonState}
          referencedResourceTypes={evidenceDotVariableDefinitionDotReferenceResourceTypes}
          setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='Reference' elementName='intended' fieldLabel='Intended'
          startingValue={evidenceVariableDefinitionState.intended} enableCreation={true}
          startCollapsed fullResourceState={fullResourceState}
          setSourceJsonState={setSourceJsonState}
          referencedResourceTypes={evidenceDotVariableDefinitionDotReferenceResourceTypes}
          setResourceState={setEvidenceVariableDefinitionState} />
        <DataEntry datatype='CodeableConcept' elementName='directnessMatch' fieldLabel='Directness Match'
          startingValue={evidenceVariableDefinitionState.directnessMatch}
          startCollapsed
          valueSet={evidenceDotVariableDefinitionDotDirectnessMatchValueSet}
          setResourceState={setEvidenceVariableDefinitionState} />
      </div>
    </>
  }
})

const ListEntryEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingListEntry = {
    flag: "", deleted: "", date: "", item: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingListEntry.extension = startingValue.extension; }
    if (startingValue.flag) { startingListEntry.flag = startingValue.flag; }
    if (typeof startingValue.deleted === "boolean") { startingListEntry.deleted = startingValue.deleted; }
    if (startingValue.date) { startingListEntry.date = startingValue.date; }
    if (startingValue.item) { startingListEntry.item = startingValue.item; }
  }

  const [listEntryState, setListEntryState] = useState(JSON.parse(JSON.stringify(startingListEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(listEntryState).length > 0) {
      let newListEntry = {};
      if (listEntryState.extension) { newListEntry.extension = listEntryState.extension; }
      if (listEntryState.flag && Object.keys(listEntryState.flag).length > 0) {
        newListEntry.flag = listEntryState.flag;
      }
      if (typeof listEntryState.deleted === "boolean") { newListEntry.deleted = listEntryState.deleted; }
      if (listEntryState.date) { newListEntry.date = listEntryState.date; }
      if (listEntryState.item && Object.keys(listEntryState.item).length > 0) {
        newListEntry.item = listEntryState.item;
      }
      handleChange(elementName, newListEntry, setResourceState);
    }
  }), [listEntryState]);

  if (startCollapsedState) {
    return <div>
      <p><b>{fieldLabel}: </b></p>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.flag && <div><b>Flag: </b><DisplayFromFHIR codeableConcept={startingValue.flag} />
          <br /><br /></div>}
        {startingValue.item && <p><DisplayFromFHIR reference={startingValue.item} /></p>}
        {(typeof startingValue.deleted === "boolean") && <p><b>Deleted: </b>{getStringFromFHIR.boolean(startingValue.deleted)}</p>}
        {startingValue.date && <p><b>Date added: </b>{getStringFromFHIR.dateTime(startingValue.date)}</p>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='flag' fieldLabel='Flag'
          startingValue={listEntryState.flag} startCollapsed
          setResourceState={setListEntryState} />
        <DataEntry datatype='boolean' elementName='deleted' fieldLabel='Deleted'
          startingValue={listEntryState.deleted} setResourceState={setListEntryState} />
        <DataEntry datatype='dateTime' elementName='date' fieldLabel='Date added'
          startingValue={listEntryState.date} setResourceState={setListEntryState} />
        <DataEntry datatype='Reference' elementName='item' fieldLabel='The Item'
          startingValue={listEntryState.item} enableCreation={true} startCollapsed
          setResourceState={setListEntryState} />
      </div>
    </>
  }
})

const PicoSectionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  startCollapsed, setSourceJsonState }) => {
  let startingPico = {
    title: "", code: "", focus: "", text: "", section: [], emptyReason: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingPico.extension = startingValue.extension; }
    if (startingValue.title) { startingPico.title = startingValue.title; }
    if (startingValue.code) { startingPico.code = startingValue.code; }
    if (startingValue.focus) { startingPico.focus = startingValue.focus; }
    if (startingValue.text) { startingPico.text = startingValue.text; }
    if (startingValue.section) { startingPico.section = startingValue.section; }
    if (startingValue.emptyReason) { startingPico.emptyReason = startingValue.emptyReason; }
  }

  const [picoEntryState, setPicoEntryState] = useState(JSON.parse(JSON.stringify(startingPico || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(picoEntryState).length > 0) {
      let newPicoEntry = {};
      if (picoEntryState.extension) { newPicoEntry.extension = picoEntryState.extension; }
      if (picoEntryState.title) { newPicoEntry.title = picoEntryState.title; }
      if (picoEntryState.code && Object.keys(picoEntryState.code).length > 0) {
        newPicoEntry.code = picoEntryState.code;
      } else {
        newPicoEntry.code = sectionCodeSummaryOfFindingsForSingleOutcome;
      }
      if (picoEntryState.focus && Object.keys(picoEntryState.focus).length > 0) {
        newPicoEntry.focus = picoEntryState.focus;
      }
      if (picoEntryState.text && Object.keys(picoEntryState.text).length > 0) {
        newPicoEntry.text = picoEntryState.text;
      }
      if (Array.isArray(picoEntryState.section) && picoEntryState.section.length > 0) {
        newPicoEntry.section = picoEntryState.section;
      }
      if (picoEntryState.emptyReason && Object.keys(picoEntryState.emptyReason).length > 0) {
        newPicoEntry.emptyReason = picoEntryState.emptyReason;
      }
      handleChange(elementName, newPicoEntry, setResourceState);
    }
  }), [picoEntryState]);

  if (startCollapsedState) {
    return <div>
      <p><b>{fieldLabel}: </b></p>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.title && <p><b>Title: </b>{startingValue.title}</p>}
        {startingValue.focus && <div><b>Focus: </b><DisplayFromFHIR reference={startingValue.focus} />
          <br /><br /></div>}
        {(startingValue.text && startingValue.text.status !== "empty") &&
          <div><b>Narrative: </b><DisplayFromFHIR xhtml={startingValue.text.div} />
            <br /><br /></div>}
        {startingValue.section?.length && startingValue.section.map((sect, sectIndex) => {
          return <div key={sectIndex} >
            <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
            <div style={{ marginLeft: "24px" }}>
              {sect.code && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
              {(Array.isArray(sect.author) && sect.author.length > 0) &&
                sect.author.map((author, authorIndex) => {
                  return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
                })}
              {sect.focus && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
              {sect.text && <div>
                <p><b>Narrative Status: </b>{sect.text.status}</p>
                <div>
                  <b>Narrative Summary: </b>
                  <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
                    <DisplayFromFHIR xhtml={sect.text.div} />
                  </div>
                </div>
                <br />
              </div>}
              {sect.orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
              {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
                sect.entry.map((entry, entryIndex) => {
                  return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                })}
              {sect.emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
            </div>
          </div>
        })}
        {startingValue.emptyReason && <p><b>Why empty: </b>{getStringFromFHIR.CodeableConcept(startingValue.emptyReason)}</p>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={picoEntryState.title} setResourceState={setPicoEntryState} />
        <DataEntry datatype='Reference' elementName='focus' fieldLabel='Focus'
          startingValue={picoEntryState.focus} enableCreation={true} startCollapsed
          startingResourceType="EvidenceVariable" referencedResourceTypes={['EvidenceVariable']}
          setProfile="VariableDefinition"
          setResourceState={setPicoEntryState} />
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          startingValue={picoEntryState.text} setResourceState={setPicoEntryState} />
        <PicoSectionTableCellArrayEntry elementName='section'
          startingValue={picoEntryState.section}
          setResourceState={setPicoEntryState} />
        <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Why Empty'
          startingValue={picoEntryState.emptyReason} startCollapsed
          setResourceState={setPicoEntryState} />
      </div>
    </>
  }
})

const PicoSectionTableCellArrayEntry = ({ elementName, fieldLabel, startingValue, setResourceState,
  columnHeaders, dataEntryStyle, addTableRowModalState }) => {
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
    }
  } else {
    startingArrayAsObject['0'] = {
      "title": "Population",
      "code": sectionCodePopulation
    };
    startingArrayAsObject['1'] = {
      "title": "Intervention Group",
      "code": sectionCodeInterventionGroup
    };
    startingArrayAsObject['2'] = {
      "title": "Comparator Group",
      "code": sectionCodeComparatorGroup
    };
    startingArrayAsObject['3'] = {
      "title": "Result with comparator alone",
      "code": sectionCodeResultWithComparatorAlone
    };
    startingArrayAsObject['4'] = {
      "title": "Result with intervention alone",
      "code": sectionCodeResultWithInterventionAlone
    };
    startingArrayAsObject['5'] = {
      "title": "Result with intervention alone (calculated)",
      "code": sectionCodeResultWithInterventionAloneCalculated
    };
    startingArrayAsObject['6'] = {
      "title": "Result with intervention vs. comparator",
      "code": sectionCodeResultWithInterventionAloneCalculated
    };
  }

  const [arrayState, setArrayState] = useState(JSON.parse(JSON.stringify(startingArrayAsObject || {})));

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) &&
          !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      setResourceState(prevState => { return { ...prevState, [elementName]: newArray } });
    }
  }), [arrayState]);

  return <div>
    {fieldLabel && <p><b>{fieldLabel}: </b></p>}
    <div style={{ marginLeft: "24px" }}>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        let title = keyValuePair[1].title || keyValuePair[1].code?.coding?.[0]?.display;
        return <div key={keyValuePairIndex}>
          <PicoSectionTableCellEntry elementName={keyValuePair[0]}
            fieldLabel={title}
            startingValue={keyValuePair[1]} setResourceState={setArrayState} />
        </div>
      })}
    </div>
  </div>
};

const PicoSectionTableCellEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState,
  setSourceJsonState }) => {
  let startingTableCell = {
    "title": "",
    "code": "",
    "textStatus": "",
    "textDiv": "",
    "entry": [],
    "emptyReason": ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.title) { startingTableCell.title = startingValue.title; }
    if (startingValue.code) { startingTableCell.code = startingValue.code; }
    if (startingValue.text) {
      startingTableCell.textStatus = startingValue.text.status;
      startingTableCell.textDiv = startingValue.text.div;
    }
    if (startingValue.entry) { startingTableCell.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingTableCell.emptyReason = startingValue.emptyReason; }
  }
  if ((startingTableCell.textStatus === "" || startingTableCell.textStatus === "empty") &&
    !startingTableCell.textDiv && startingTableCell.entry.length === 0 && !startingTableCell.emptyReason) {
    startingTableCell.emptyReason = emptyReasonNotStarted;
  }

  const [compositionTableCellState, setCompositionTableCellState] = useState(JSON.parse(JSON.stringify(startingTableCell || {})));

  useEffect((() => {
    if (Object.keys(compositionTableCellState).length > 0) {
      let newTableCell = {};
      if (compositionTableCellState.title) { newTableCell.title = compositionTableCellState.title; }
      if (compositionTableCellState.code) { newTableCell.code = compositionTableCellState.code; }
      if (compositionTableCellState.textDiv) {
        newTableCell.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + compositionTableCellState.textDiv + "</div>"
        }
      } else {
        newTableCell.text = JSON.parse(JSON.stringify(emptyTextNoData));
      }
      if (Array.isArray(compositionTableCellState.entry) && compositionTableCellState.entry.length > 0) {
        newTableCell.entry = compositionTableCellState.entry;
      }
      if ((!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) {
        newTableCell.text.status = "empty";
        if (compositionTableCellState.emptyReason && Object.keys(compositionTableCellState.emptyReason).length > 0) {
          newTableCell.emptyReason = compositionTableCellState.emptyReason;
        } else {
          newTableCell.emptyReason = { "text": "[Data deleted]" };
        }
      } else if (newTableCell.emptyReason) {
        delete newTableCell.emptyReason;
      }
      if (Object.keys(newTableCell).length === 0) {
        newTableCell = null;
      }
      handleChange(elementName, newTableCell, setResourceState);
    }
  }), [compositionTableCellState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='textDiv' fieldLabel='Narrative Summary'
        startingValue={compositionTableCellState.textDiv} setResourceState={setCompositionTableCellState} />
      <DataEntry asArray={true} datatype='Reference' elementName='entry'
        fieldLabel='Source Content (as Resource Reference)' enableCreation={true}
        setSourceJsonState={setSourceJsonState}
        startingValue={compositionTableCellState.entry} setResourceState={setCompositionTableCellState} />
      {((!compositionTableCellState.textDiv || compositionTableCellState.textDiv === "[No data]") &&
        (!compositionTableCellState.entry || compositionTableCellState.entry.length === 0)) && <>
          <br />
          <p><b>Explain why this Table Cell is empty</b></p>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={compositionTableCellState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            setResourceState={setCompositionTableCellState} />
        </>}
      <br />
    </div>
  </>
})

const PlanDefinitionActionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  return <p>Data entry form not yet created.</p>
})

const PlanDefinitionActorEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  return <p>Data entry form not yet created.</p>
})

const PlanDefinitionGoalEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  return <p>Data entry form not yet created.</p>
})

const researchStudyDotAssociatedPartyRoleValueSet = [
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sponsor", display: "sponsor" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "lead-sponsor", display: "lead-sponsor" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sponsor-investigator", display: "sponsor-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "primary-investigator", display: "primary-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "collaborator", display: "collaborator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "funding-source", display: "funding-source" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "general-contact", display: "general-contact" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "recruitment-contact", display: "recruitment-contact" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "sub-investigator", display: "sub-investigator" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "study-director", display: "study-director" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "study-chair", display: "study-chair" },
  { system: "http://hl7.org/fhir/research-study-party-role", code: "irb", display: "irb" }

];
const ResearchStudyAssociatedPartyEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingPartyEntry = {
    name: "", role: "", period: [], classifier: [], party: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingPartyEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingPartyEntry.name = startingValue.name; }
    if (startingValue.role) { startingPartyEntry.role = startingValue.role; }
    if (startingValue.period) { startingPartyEntry.period = startingValue.period; }
    if (startingValue.classifier) { startingPartyEntry.classifier = startingValue.classifier; }
    if (startingValue.party) { startingPartyEntry.party = startingValue.party; }
  }

  const [partyEntryState, setPartyEntryState] = useState(JSON.parse(JSON.stringify(startingPartyEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(partyEntryState).length > 0) {
      let newPartyEntry = {};
      if (partyEntryState.extension) { newPartyEntry.extension = partyEntryState.extension; }
      if (partyEntryState.name) { newPartyEntry.name = partyEntryState.name; }
      if (partyEntryState.role && Object.keys(partyEntryState.role).length > 0) {
        newPartyEntry.role = partyEntryState.role;
      }
      if (Array.isArray(partyEntryState.period) && partyEntryState.period.length > 0) {
        newPartyEntry.period = partyEntryState.period;
      }
      if (Array.isArray(partyEntryState.classifier) && partyEntryState.classifier.length > 0) {
        newPartyEntry.classifier = partyEntryState.classifier;
      }
      if (partyEntryState.party && Object.keys(partyEntryState.party).length > 0) {
        newPartyEntry.party = partyEntryState.party;
      }
      handleChange(elementName, newPartyEntry, setResourceState);
    }
  }), [partyEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.name && <p><b>Name: </b>{startingValue.name}</p>}
        {startingValue.role && <span><b>Role: </b><DisplayFromFHIR codeableConcept={startingValue.role} /><br /></span>}
        {Array.isArray(startingValue.period) &&
          startingValue.period.map((period, periodIndex) => {
            return <div key={periodIndex}><b>Period: </b>{getStringFromFHIR.Period(period)}</div>
          })}
        {Array.isArray(startingValue.classifier) &&
          startingValue.classifier.map((classifier, classifierIndex) => {
            return <div key={classifierIndex}><b>Classifier: </b><DisplayFromFHIR codeableConcept={classifier} /></div>
          })}
        {startingValue.party && <div><b>Party: </b><DisplayFromFHIR reference={startingValue.party} /><br /></div>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={partyEntryState.name} setResourceState={setPartyEntryState} />
        <DataEntry datatype='CodeableConcept' elementName='role' fieldLabel='Role'
          valueSet={researchStudyDotAssociatedPartyRoleValueSet} startCollapsed={startCollapsed}
          startingValue={partyEntryState.role} setResourceState={setPartyEntryState} />
        <DataEntry asArray={true} datatype='Period' elementName='period' fieldLabel='Period'
          startCollapsed={startCollapsed}
          startingValue={partyEntryState.period} setResourceState={setPartyEntryState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='classifier' fieldLabel='Classifier'
          startCollapsed={startCollapsed}
          startingValue={partyEntryState.classifier} setResourceState={setPartyEntryState} />
        <DataEntry datatype='Reference' elementName='party' fieldLabel='Party (as Reference)'
          referencedResourceTypes={['Practitioner', 'PractitionerRole', 'Organization']}
          startingValue={partyEntryState.party} setResourceState={setPartyEntryState} />
      </div>
    </>
  }
})

const ResearchStudyComparisonGroupEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingComparisonGroupEntry = {
    targetNumber: "", actualNumber: "", eligibility: "", observedGroup: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingComparisonGroupEntry.extension = startingValue.extension; }
    if (startingValue.targetNumber || startingValue.targetNumber === 0) { startingComparisonGroupEntry.targetNumber = startingValue.targetNumber; }
    if (startingValue.actualNumber || startingValue.actualNumber === 0) { startingComparisonGroupEntry.actualNumber = startingValue.actualNumber; }
    if (startingValue.eligibility) {
      startingComparisonGroupEntry.eligibility = startingValue.eligibility;
    } else {
      let newEligibility = {};
      let newEligibilityDisplay = "";
      if (startingValue.name) { newEligibilityDisplay = startingValue.name; }
      if (startingValue.type) { newEligibilityDisplay += " " + getStringFromFHIR.CodeableConcept(startingValue.type); }
      if (startingValue.description) { newEligibilityDisplay += " " + startingValue.description; }
      if (newEligibilityDisplay) {
        newEligibility.display = newEligibilityDisplay;
        startingComparisonGroupEntry.eligibility = newEligibility;
      }
      if (Array.isArray(startingValue.intendedExposure) && startingValue.intendedExposure.length) {
        if (!startingComparisonGroupEntry.extension) {
          startingComparisonGroupEntry.extension = [];
        }
        for (const instance of startingValue.intendedExposure) {
          startingComparisonGroupEntry.extension.push({
            "url": "http://hl7.org/fhir/5.0/StructureDefinition/extension-ResearchStudy.comparisonGroup.intendedExposure",
            "valueReference": instance
          })
        }
      }
    }
    if (startingValue.observedGroup) { startingComparisonGroupEntry.observedGroup = startingValue.observedGroup; }
  }

  const [comparisonGroupEntryState, setComparisonGroupEntryState] = useState(JSON.parse(JSON.stringify(startingComparisonGroupEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(comparisonGroupEntryState).length > 0) {
      let newGroupEntry = {};
      if (comparisonGroupEntryState.extension) { newGroupEntry.extension = comparisonGroupEntryState.extension; }
      if (comparisonGroupEntryState.targetNumber || comparisonGroupEntryState.targetNumber === 0) {
        newGroupEntry.targetNumber = comparisonGroupEntryState.targetNumber;
      }
      if (comparisonGroupEntryState.actualNumber || comparisonGroupEntryState.actualNumber === 0) {
        newGroupEntry.actualNumber = comparisonGroupEntryState.actualNumber;
      }
      if (comparisonGroupEntryState.eligibility && Object.keys(comparisonGroupEntryState.eligibility).length > 0) {
        newGroupEntry.eligibility = comparisonGroupEntryState.eligibility;
      }
      if (comparisonGroupEntryState.observedGroup && Object.keys(comparisonGroupEntryState.observedGroup).length > 0) {
        newGroupEntry.observedGroup = comparisonGroupEntryState.observedGroup;
      }
      handleChange(elementName, newGroupEntry, setResourceState);
    }
  }), [comparisonGroupEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.linkId && <p><b>Id: </b>{startingValue.linkId}</p>}
        {startingValue.name && <p><b>Name: </b>{startingValue.name}</p>}
        {startingValue.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={startingValue.type} /><br /></span>}
        {startingValue.description && <div><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /><br /></div>}
        {Array.isArray(startingValue.intendedExposure) &&
          startingValue.intendedExposure.map((intended, intendedIndex) => {
            return <div key={intendedIndex}><b>Exposure: </b><DisplayFromFHIR reference={intended} /></div>
          })}
        {startingValue.observedGroup && <span><b>Observed Group: </b><DisplayFromFHIR reference={startingValue.observedGroup} /><br /></span>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='unsignedInt' elementName='targetNumber' fieldLabel='Target number of participants'
          startingValue={comparisonGroupEntryState.targetNumber} setResourceState={setComparisonGroupEntryState} />
        <DataEntry datatype='unsignedInt' elementName='actualNumber' fieldLabel='Actual number of participants'
          startingValue={comparisonGroupEntryState.actualNumber} setResourceState={setComparisonGroupEntryState} />
        <DataEntry datatype='Reference'
          elementName='eligibility' fieldLabel='Eligibility Criteria' startCollapsed={startCollapsed}
          referencedResourceTypes={['Group']} enableCreation={true}
          startingValue={comparisonGroupEntryState.eligibility} setResourceState={setComparisonGroupEntryState} />
        <DataEntry datatype='Reference' elementName='observedGroup' fieldLabel='Observed Group'
          referencedResourceTypes={['Group']} startCollapsed={startCollapsed}
          startingValue={comparisonGroupEntryState.observedGroup} setResourceState={setComparisonGroupEntryState} />
      </div>
    </>
  }
})

const researchStudyDotLabelValueSet = [
  { system: "http://hl7.org/fhir/title-type", code: "primary", display: "Primary title" },
  { system: "http://hl7.org/fhir/title-type", code: "official", display: "Official title" },
  { system: "http://hl7.org/fhir/title-type", code: "scientific", display: "Scientific title" },
  { system: "http://hl7.org/fhir/title-type", code: "plain-language", display: "Plain language title" },
  { system: "http://hl7.org/fhir/title-type", code: "subtitle", display: "Subtitle" },
  { system: "http://hl7.org/fhir/title-type", code: "short-title", display: "Short title" },
  { system: "http://hl7.org/fhir/title-type", code: "acronym", display: "Acronym" },
  { system: "http://hl7.org/fhir/title-type", code: "earlier-title", display: "Different text in an earlier version" },
  { system: "http://hl7.org/fhir/title-type", code: "language", display: "Different language" },
  { system: "http://hl7.org/fhir/title-type", code: "autotranslated", display: "Different language derived from autotranslation" },
  { system: "http://hl7.org/fhir/title-type", code: "human-use", display: "Human use" },
  { system: "http://hl7.org/fhir/title-type", code: "machine-use", display: "Machine use" },
  { system: "http://hl7.org/fhir/title-type", code: "duplicate-uid", display: "Different text for the same object with a different identifier" }
];
const ResearchStudyLabelEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingLabelEntry = {
    type: "", value: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingLabelEntry.extension = startingValue.extension; }
    if (startingValue.type) { startingLabelEntry.type = startingValue.type; }
    if (startingValue.value) { startingLabelEntry.value = startingValue.value; }
  }

  const [labelEntryState, setLabelEntryState] = useState(JSON.parse(JSON.stringify(startingLabelEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(labelEntryState).length > 0) {
      let newLabelEntry = {};
      if (labelEntryState.extension) { newLabelEntry.extension = labelEntryState.extension; }
      if (labelEntryState.type && Object.keys(labelEntryState.type).length > 0) {
        newLabelEntry.type = labelEntryState.type;
      }
      if (labelEntryState.value) { newLabelEntry.value = labelEntryState.value; }
      handleChange(elementName, newLabelEntry, setResourceState);
    }
  }), [labelEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <p>
        <b>{getStringFromFHIR.CodeableConcept(startingValue.type) || "Label"}: </b>{startingValue.value}
      </p>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Label type'
          valueSet={researchStudyDotLabelValueSet} startCollapsed={startCollapsed}
          startingValue={labelEntryState.type} setResourceState={setLabelEntryState} />
        <DataEntry datatype='string' elementName='value' fieldLabel='Label value'
          startingValue={labelEntryState.value} setResourceState={setLabelEntryState} />
      </div>
    </>
  }
})

const EventHandlingEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingEventHandlingEntry = {
    event: "", group: "", handling: "", description: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      startingEventHandlingEntry.extension = startingValue.extension;
    }
    if (startingValue.event) {
      startingEventHandlingEntry.event = startingValue.event;
    }
    if (startingValue.group) {
      startingEventHandlingEntry.group = startingValue.group;
    }
    if (startingValue.handling) {
      startingEventHandlingEntry.handling = startingValue.handling;
    }
    if (startingValue.description) {
      startingEventHandlingEntry.description = startingValue.description;
    }
  }

  const [eventHandlingState, setEventHandlingState] = useState(JSON.parse(JSON.stringify(startingEventHandlingEntry || {})));

  useEffect((() => {
    if (Object.keys(eventHandlingState).length > 0) {
      let newEventHandling = {};
      if (eventHandlingState.extension) { newEventHandling.extension = eventHandlingState.extension; }
      if (eventHandlingState.event && Object.keys(eventHandlingState.event).length > 0) {
        newEventHandling.event = eventHandlingState.event;
      }
      if (eventHandlingState.group && Object.keys(eventHandlingState.group).length > 0) {
        newEventHandling.group = eventHandlingState.group;
      }
      if (eventHandlingState.handling && Object.keys(eventHandlingState.handling).length > 0) {
        newEventHandling.handling = eventHandlingState.handling;
      }
      if (eventHandlingState.description && Object.keys(eventHandlingState.description).length > 0) {
        newEventHandling.description = eventHandlingState.description;
      }
      handleChange(elementName, newEventHandling, setResourceState);
    }
  }), [eventHandlingState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='CodeableConcept' elementName='event' fieldLabel='Event'
        startingValue={eventHandlingState.event} setResourceState={setEventHandlingState} />
      <DataEntry datatype='CodeableConcept' elementName='group' fieldLabel='Group'
        startingValue={eventHandlingState.group} setResourceState={setEventHandlingState} />
      <DataEntry datatype='CodeableConcept' elementName='handling' fieldLabel='Handling'
        startingValue={eventHandlingState.handling} setResourceState={setEventHandlingState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={eventHandlingState.description} setResourceState={setEventHandlingState} />
    </div>
  </>
})

const StudyAmendmentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingStudyAmendment = {
    details: "",
    scope: "",
    description: "",
    rationale: "",
    substantialImpactSafety: "",
    substantialImpactSafetyComment: "",
    substantialImpactReliability: "",
    substantialImpactReliabilityComment: "",
    primaryReason: ""
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      for (const ext of startingValue.extension) {
        if (ext.url === "details") {
          startingStudyAmendment.details = ext.valueString;
        } else if (ext.url === "scope") {
          startingStudyAmendment.scope = ext.valueString;
        } else if (ext.url === "description") {
          startingStudyAmendment.description = ext.valueString;
        } else if (ext.url === "rationale") {
          startingStudyAmendment.rationale = ext.valueString;
        } else if (ext.url === "substantialImpactSafety") {
          startingStudyAmendment.substantialImpactSafety = ext.valueBoolean;
        } else if (ext.url === "substantialImpactSafetyComment") {
          startingStudyAmendment.substantialImpactSafetyComment = ext.valueString;
        } else if (ext.url === "substantialImpactReliability") {
          startingStudyAmendment.substantialImpactReliability = ext.valueBoolean;
        } else if (ext.url === "substantialImpactReliabilityComment") {
          startingStudyAmendment.substantialImpactReliabilityComment = ext.valueString;
        } else if (ext.url === "primaryReason") {
          startingStudyAmendment.primaryReason = ext.valueCodeableConcept;
        }
      }
    }
  }

  const [studyAmendmentState, setStudyAmendmentState] = useState(JSON.parse(JSON.stringify(startingStudyAmendment || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(studyAmendmentState).length > 0) {
      let newStudyAmendment = {
        extension: [], url: "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-study-amendment"
      };
      if (studyAmendmentState.details) {
        newStudyAmendment.extension.push({ "url": "details", "valueString": studyAmendmentState.details });
      }
      if (studyAmendmentState.scope) {
        newStudyAmendment.extension.push({ "url": "scope", "valueId": studyAmendmentState.scope });
      }
      if (studyAmendmentState.description) {
        newStudyAmendment.extension.push({ "url": "description", "valueString": studyAmendmentState.description });
      }
      if (studyAmendmentState.rationale) {
        newStudyAmendment.extension.push({ "url": "rationale", "valueString": studyAmendmentState.rationale });
      }
      if (studyAmendmentState.substantialImpactSafety === true || studyAmendmentState.substantialImpactSafety === false) {
        newStudyAmendment.extension.push({ "url": "substantialImpactSafety", "valueBoolean": studyAmendmentState.substantialImpactSafety });
      }
      if (studyAmendmentState.substantialImpactSafetyComment) {
        newStudyAmendment.extension.push({ "url": "substantialImpactSafetyComment", "valueString": studyAmendmentState.substantialImpactSafetyComment });
      }
      if (studyAmendmentState.substantialImpactReliability === true || studyAmendmentState.substantialImpactReliability === false) {
        newStudyAmendment.extension.push({ "url": "substantialImpactReliability", "valueBoolean": studyAmendmentState.substantialImpactReliability });
      }
      if (studyAmendmentState.substantialImpactReliabilityComment) {
        newStudyAmendment.extension.push({ "url": "substantialImpactReliabilityComment", "valueString": studyAmendmentState.substantialImpactReliabilityComment });
      }
      if (studyAmendmentState.primaryReason) {
        newStudyAmendment.extension.push({ "url": "primaryReason", "valueCodeableConcept": studyAmendmentState.primaryReason });
      }
      handleChange(elementName, newStudyAmendment, setResourceState);
    }
  }), [studyAmendmentState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {studyAmendmentState.details && <p><b>Details: </b>{studyAmendmentState.details}</p>}
        {studyAmendmentState.scope && <p><b>Scope: </b>{studyAmendmentState.scope}</p>}
        {studyAmendmentState.description && <p><b>Description: </b>{studyAmendmentState.description}</p>}
        {studyAmendmentState.rationale && <p><b>Rationale: </b>{studyAmendmentState.rationale}</p>}
        {studyAmendmentState.substantialImpactSafety === true && <p><b>Substantial Impact on Safety: </b>YES</p>}
        {studyAmendmentState.substantialImpactSafety === false && <p><b>Substantial Impact on Safety: </b>No</p>}
        {studyAmendmentState.substantialImpactSafetyComment && <p><b>Substantial Impact on Safety Comment: </b>{studyAmendmentState.substantialImpactSafetyComment}</p>}
        {studyAmendmentState.substantialImpactReliability === true && <p><b>Substantial Impact on Reliability: </b>YES</p>}
        {studyAmendmentState.substantialImpactReliability === false && <p><b>Substantial Impact on Reliability: </b>No</p>}
        {studyAmendmentState.substantialImpactReliabilityComment && <p><b>Substantial Impact on Reliability Comment: </b>{studyAmendmentState.substantialImpactReliabilityComment}</p>}
        {studyAmendmentState.primaryReason && <p><b>Primary Reason: </b>{getStringFromFHIR.CodeableConcept(studyAmendmentState.primaryReason)}</p>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='details' fieldLabel='Details'
          startingValue={studyAmendmentState.details} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='string' elementName='scope' fieldLabel='Scope'
          startingValue={studyAmendmentState.scope} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='string' elementName='description' fieldLabel='Description'
          startingValue={studyAmendmentState.description} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='string' elementName='rationale' fieldLabel='Rationale'
          startingValue={studyAmendmentState.rationale} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='boolean' elementName='substantialImpactSafety' fieldLabel='Substantial Impact on Safety'
          storeFalse
          startingValue={studyAmendmentState.substantialImpactSafety} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='string' elementName='substantialImpactSafetyComment' fieldLabel='Substantial Impact on Safety Comment'
          startingValue={studyAmendmentState.substantialImpactSafetyComment} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='boolean' elementName='substantialImpactReliability' fieldLabel='Substantial Impact on Reliability'
          storeFalse
          startingValue={studyAmendmentState.substantialImpactReliability} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='string' elementName='substantialImpactReliabilityComment' fieldLabel='Substantial Impact on Reliability Comment'
          startingValue={studyAmendmentState.substantialImpactReliabilityComment} setResourceState={setStudyAmendmentState} />
        <DataEntry datatype='CodeableConcept' elementName='primaryReason' fieldLabel='Primary Reason'
          startingValue={studyAmendmentState.primaryReason} setResourceState={setStudyAmendmentState} />
      </div>
    </>
  }
})

const EstimandEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingEstimandEntry = {
    label: "", type: "", endpoint: "", population: "", interventionGroup: "", comparatorGroup: "", summaryMeasure: "", eventHandling: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) {
      for (const ext of startingValue.extension) {
        if (ext.url === "label") {
          startingEstimandEntry.label = ext.valueString;
        } else if (ext.url === "type") {
          startingEstimandEntry.type = ext.valueCodeableConcept;
        } else if (ext.url === "endpoint") {
          startingEstimandEntry.endpoint = ext.valueReference;
        } else if (ext.url === "population") {
          startingEstimandEntry.population = ext.valueReference;
        } else if (ext.url === "interventionGroup" || ext.url === "intervention") {
          startingEstimandEntry.interventionGroup = ext.valueId;
        } else if (ext.url === "comparatorGroup" || ext.url === "comparator") {
          startingEstimandEntry.comparatorGroup = ext.valueId;
        } else if (ext.url === "summaryMeasure") {
          startingEstimandEntry.summaryMeasure = ext.valueCodeableConcept;
        } else if (ext.url === "eventHandling") {
          startingEstimandEntry.eventHandling.push(ext);
        }
      }
    }
  }

  const [estimandState, setEstimandState] = useState(JSON.parse(JSON.stringify(startingEstimandEntry || {})));

  useEffect((() => {
    if (Object.keys(estimandState).length > 0) {
      let newEndpointExtension = {
        extension: [], url: "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand"
      };
      if (estimandState.label) {
        newEndpointExtension.extension.push({ "url": "label", "valueString": estimandState.label });
      }
      if (estimandState.type) {
        newEndpointExtension.extension.push({ "url": "type", "valueCodeableConcept": estimandState.type });
      }
      if (estimandState.endpoint) {
        newEndpointExtension.extension.push({ "url": "endpoint", "valueReference": estimandState.endpoint });
      }
      if (estimandState.population) {
        newEndpointExtension.extension.push({ "url": "population", "valueReference": estimandState.population });
      }
      if (estimandState.interventionGroup) {
        newEndpointExtension.extension.push({ "url": "interventionGroup", "valueId": estimandState.interventionGroup });
      }
      if (estimandState.comparatorGroup) {
        newEndpointExtension.extension.push({ "url": "comparatorGroup", "valueId": estimandState.comparatorGroup });
      }
      if (estimandState.summaryMeasure) {
        newEndpointExtension.extension.push({ "url": "summaryMeasure", "valueCodeableConcept": estimandState.summaryMeasure });
      }
      if (Array.isArray(estimandState.eventHandling) && estimandState.eventHandling.length > 0) {
        newEndpointExtension.extension = newEndpointExtension.extension.concat(estimandState.eventHandling);
      }
      handleChange(elementName, newEndpointExtension, setResourceState);
    }
  }), [estimandState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='label' fieldLabel='Short name or identifier'
        startingValue={estimandState.label} setResourceState={setEstimandState} />
      <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
        valueSet={researchStudyDotObjectiveTypeValueSet}
        startingValue={estimandState.type} setResourceState={setEstimandState} />
      <DataEntry datatype='Reference' elementName='endpoint' fieldLabel='Endpoint'
        referencedResourceTypes={['EvidenceVariable', 'ObservationDefinition', 'PlanDefinition']}
        startingValue={estimandState.endpoint} setResourceState={setEstimandState} />
      <DataEntry datatype='Reference' elementName='population' fieldLabel='Population'
        referencedResourceTypes={['Group']}
        startingValue={estimandState.population} setResourceState={setEstimandState} />
      <DataEntry datatype='id' elementName='interventionGroup' fieldLabel='Intervention Group'
        startingValue={estimandState.interventionGroup} setResourceState={setEstimandState} />
      <DataEntry datatype='id' elementName='comparatorGroup' fieldLabel='Comparator Group'
        startingValue={estimandState.comparatorGroup} setResourceState={setEstimandState} />
      <DataEntry datatype='CodeableConcept' elementName='summaryMeasure' fieldLabel='Summary Measure'
        valueSet={SEVCO.statisticType}
        startingValue={estimandState.summaryMeasure} setResourceState={setEstimandState} />
      <DataEntry asArray={true} datatype='EventHandling' elementName='eventHandling' fieldLabel='Handling of Intercurrent Events'
        startingValue={estimandState.eventHandling} setResourceState={setEstimandState} />
    </div>
  </>
})

const researchStudyDotObjectiveTypeValueSet = [
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "primary", display: "Primary" },
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "secondary", display: "Secondary" },
  { system: "http://hl7.org/fhir/research-study-objective-type", code: "exploratory", display: "Exploratory" }
];
const ResearchStudyObjectiveEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingObjectiveEntry = {
    name: "", type: "", description: "", outcomeMeasure: []
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingObjectiveEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingObjectiveEntry.name = startingValue.name; }
    if (startingValue.type) { startingObjectiveEntry.type = startingValue.type; }
    if (startingValue.description) { startingObjectiveEntry.description = startingValue.description; }
    if (startingValue.outcomeMeasure) { startingObjectiveEntry.outcomeMeasure = startingValue.outcomeMeasure; }
  }

  const [objectiveEntryState, setObjectiveEntryState] = useState(JSON.parse(JSON.stringify(startingObjectiveEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(objectiveEntryState).length > 0) {
      let newObjectiveEntry = {};
      if (objectiveEntryState.extension) { newObjectiveEntry.extension = objectiveEntryState.extension; }
      if (objectiveEntryState.name) { newObjectiveEntry.name = objectiveEntryState.name; }
      if (objectiveEntryState.type && Object.keys(objectiveEntryState.type).length > 0) {
        newObjectiveEntry.type = objectiveEntryState.type;
      }
      if (objectiveEntryState.description) { newObjectiveEntry.description = objectiveEntryState.description; }
      if (Array.isArray(objectiveEntryState.outcomeMeasure) && objectiveEntryState.outcomeMeasure.length) {
        newObjectiveEntry.outcomeMeasure = objectiveEntryState.outcomeMeasure;
      }
      handleChange(elementName, newObjectiveEntry, setResourceState);
    }
  }), [objectiveEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.name && <p><b>Name: </b>{startingValue.name}</p>}
        {startingValue.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={startingValue.type} /><br /></span>}
        {startingValue.description && <div><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /><br /></div>}
        {Array.isArray(startingValue.outcomeMeasure) &&
          startingValue.outcomeMeasure.map((outcomeMeasure, outcomeMeasureIndex) => {
            let name = outcomeMeasure.name || "";
            let type = outcomeMeasure.type || "";
            let description = outcomeMeasure.description || "";
            let endpoint = outcomeMeasure.endpoint || "";
            let population = outcomeMeasure.population || "";
            let intervention = outcomeMeasure.intervention || "";
            let comparator = outcomeMeasure.comparator || "";
            let summaryMeasure = outcomeMeasure.summaryMeasure || "";
            let eventHandling = outcomeMeasure.eventHandling || [];
            return <div key={outcomeMeasureIndex} >
              <h4>Outcome Measure {outcomeMeasureIndex + 1}</h4>
              <div style={{ marginLeft: "24px" }}>
                {name && <p><b>Name: </b>{name}</p>}
                {type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={type} /><br /></span>}
                {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
                {endpoint && <span><b>Endpoint: </b><DisplayFromFHIR reference={endpoint} /><br /></span>}
                {population && <span><b>Population: </b><DisplayFromFHIR reference={population} /><br /></span>}
                {intervention && <span><b>Intervention Group: </b><DisplayFromFHIR reference={intervention} /><br /></span>}
                {comparator && <span><b>Comparator Group: </b><DisplayFromFHIR reference={comparator} /><br /></span>}
                {summaryMeasure && <span><b>Summary measure: </b><DisplayFromFHIR codeableConcept={summaryMeasure} /><br /></span>}
                {Array.isArray(eventHandling) &&
                  eventHandling.map((ice, iceIndex) => {
                    let event = ice.event || "";
                    let group = ice.group || "";
                    let handling = ice.handling || "";
                    let description = ice.description || "";
                    return <div key={iceIndex}>
                      <h4>Handling of Intercurrent Event {ice.id || (iceIndex + 1)}</h4>
                      <div style={{ marginLeft: "24px" }}>
                        {event && <span><b>Event: </b><DisplayFromFHIR codeableConcept={event} /><br /></span>}
                        {group && <span><b>Group: </b><DisplayFromFHIR codeableConcept={group} /><br /></span>}
                        {handling && <span><b>Handling: </b><DisplayFromFHIR codeableConcept={handling} /><br /></span>}
                        {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
                      </div>
                    </div>
                  })}
              </div>
            </div>
          })}
        {Array.isArray(startingValue.extension) &&
          startingValue.extension.map((extension, extensionIndex) => {
            if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand") {
              let label;
              let type
              let endpoint;
              let population;
              let intervention;
              let comparator;
              let summaryMeasure;
              let eventHandling = [];
              for (const ext of extension.extension) {
                if (ext.url === "label") {
                  label = ext.valueString;
                } else if (ext.url === "type") {
                  type = ext.valueCodeableConcept;
                } else if (ext.url === "endpoint") {
                  endpoint = ext.valueReference;
                } else if (ext.url === "population") {
                  population = ext.valueReference;
                } else if (ext.url === "interventionGroup" || ext.url === "intervention") {
                  intervention = ext.valueId;
                } else if (ext.url === "comparatorGroup" || ext.url === "comparator") {
                  comparator = ext.valueId;
                } else if (ext.url === "summaryMeasure") {
                  summaryMeasure = ext.valueCodeableConcept;
                } else if (ext.url === "eventHandling") {
                  eventHandling.push(ext);
                }
              }
              return <div key={extensionIndex} >
                <h4>Estimand {extensionIndex + 1}</h4>
                <div style={{ marginLeft: "24px" }}>
                  {label && <p><b>Label: </b>{label}</p>}
                  {type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={type} /><br /></span>}
                  {endpoint && <span><b>Endpoint: </b><DisplayFromFHIR reference={endpoint} /><br /></span>}
                  {population && <span><b>Population: </b><DisplayFromFHIR reference={population} /><br /></span>}
                  {intervention && <p><b>Intervention Group: </b>Comparison group {intervention}</p>}
                  {comparator && <p><b>Comparator Group: </b>Comparison group {comparator}</p>}
                  {summaryMeasure && <span><b>Summary measure: </b><DisplayFromFHIR codeableConcept={summaryMeasure} /><br /></span>}
                  {Array.isArray(eventHandling) &&
                    eventHandling.map((ice, iceIndex) => {
                      let event;
                      let group;
                      let handling;
                      let description;
                      for (const extension of ice.extension) {
                        if (extension.url === "event") {
                          event = extension.valueCodeableConcept;
                        } else if (extension.url === "group") {
                          group = extension.valueCodeableConcept;
                        } else if (extension.url === "handling") {
                          handling = extension.valueCodeableConcept;
                        } else if (extension.url === "description") {
                          description = extension.valueMarkdown;
                        }
                      }
                      return <div key={iceIndex}>
                        <h4>Handling of Intercurrent Event {ice.id || (iceIndex + 1)}</h4>
                        <div style={{ marginLeft: "24px" }}>
                          {event && <span><b>Event: </b><DisplayFromFHIR codeableConcept={event} /><br /></span>}
                          {group && <span><b>Group: </b><DisplayFromFHIR codeableConcept={group} /><br /></span>}
                          {handling && <span><b>Handling: </b><DisplayFromFHIR codeableConcept={handling} /><br /></span>}
                          {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
                        </div>
                      </div>
                    })}
                </div>
              </div>
            } else {
              return <></>;
            }
          })}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={objectiveEntryState.name} setResourceState={setObjectiveEntryState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          valueSet={researchStudyDotObjectiveTypeValueSet} startCollapsed={startCollapsed}
          startingValue={objectiveEntryState.type} setResourceState={setObjectiveEntryState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed={startCollapsed}
          startingValue={objectiveEntryState.description} setResourceState={setObjectiveEntryState} />
        <DataEntry asArray={true} datatype="ResearchStudyObjectiveOutcomeMeasure"
          elementName='outcomeMeasure' fieldLabel='Outcome Measure'
          startingValue={objectiveEntryState.outcomeMeasure} setResourceState={setObjectiveEntryState}
          startCollapsed={true} />
      </div>
    </>
  }
})

const ResearchStudyObjectiveOutcomeMeasureEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingOutcomeEntry = {
    name: "", type: "", description: "", endpoint: "",
    population: "", intervention: "", comparator: "", summaryMeasure: "", eventHandling: []
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingOutcomeEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingOutcomeEntry.name = startingValue.name; }
    if (startingValue.type) { startingOutcomeEntry.type = startingValue.type; }
    if (startingValue.description) { startingOutcomeEntry.description = startingValue.description; }
    if (startingValue.endpoint) { startingOutcomeEntry.endpoint = startingValue.endpoint; }
    if (startingValue.population) { startingOutcomeEntry.population = startingValue.population; }
    if (startingValue.intervention) { startingOutcomeEntry.intervention = startingValue.intervention; }
    if (startingValue.comparator) { startingOutcomeEntry.comparator = startingValue.comparator; }
    if (startingValue.summaryMeasure) { startingOutcomeEntry.summaryMeasure = startingValue.summaryMeasure; }
    if (startingValue.eventHandling) { startingOutcomeEntry.eventHandling = startingValue.eventHandling; }
  }

  const [outcomeMeasureEntryState, setOutcomeMeasureEntryState] = useState(JSON.parse(JSON.stringify(startingOutcomeEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(outcomeMeasureEntryState).length > 0) {
      let newOutcomeEntry = {};
      if (outcomeMeasureEntryState.extension) { newOutcomeEntry.extension = outcomeMeasureEntryState.extension; }
      if (outcomeMeasureEntryState.name) { newOutcomeEntry.name = outcomeMeasureEntryState.name; }
      if (outcomeMeasureEntryState.type && Object.keys(outcomeMeasureEntryState.type).length > 0) {
        newOutcomeEntry.type = outcomeMeasureEntryState.type;
      }
      if (outcomeMeasureEntryState.description) { newOutcomeEntry.description = outcomeMeasureEntryState.description; }
      if (outcomeMeasureEntryState.endpoint && Object.keys(outcomeMeasureEntryState.endpoint).length > 0) {
        newOutcomeEntry.endpoint = outcomeMeasureEntryState.endpoint;
      }
      if (outcomeMeasureEntryState.population && Object.keys(outcomeMeasureEntryState.population).length > 0) {
        newOutcomeEntry.population = outcomeMeasureEntryState.population;
      }
      if (outcomeMeasureEntryState.intervention && Object.keys(outcomeMeasureEntryState.intervention).length > 0) {
        newOutcomeEntry.intervention = outcomeMeasureEntryState.intervention;
      }
      if (outcomeMeasureEntryState.comparator && Object.keys(outcomeMeasureEntryState.comparator).length > 0) {
        newOutcomeEntry.comparator = outcomeMeasureEntryState.comparator;
      }
      if (outcomeMeasureEntryState.summaryMeasure && Object.keys(outcomeMeasureEntryState.summaryMeasure).length > 0) {
        newOutcomeEntry.summaryMeasure = outcomeMeasureEntryState.summaryMeasure;
      }
      if (Array.isArray(outcomeMeasureEntryState.eventHandling) && outcomeMeasureEntryState.eventHandling.length > 0) {
        newOutcomeEntry.eventHandling = outcomeMeasureEntryState.eventHandling;
      }
      handleChange(elementName, newOutcomeEntry, setResourceState);
    }
  }), [outcomeMeasureEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.name && <p><b>Name: </b>{startingValue.name}</p>}
        {startingValue.type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={startingValue.type} /><br /></span>}
        {startingValue.description && <div><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /><br /></div>}
        {startingValue.endpoint && <span><b>Endpoint: </b><DisplayFromFHIR reference={startingValue.endpoint} /><br /></span>}
        {startingValue.population && <span><b>Population: </b><DisplayFromFHIR reference={startingValue.population} /><br /></span>}
        {startingValue.intervention && <span><b>Intervention Group: </b><DisplayFromFHIR reference={startingValue.intervention} /><br /></span>}
        {startingValue.comparator && <span><b>Comparator Group: </b><DisplayFromFHIR reference={startingValue.comparator} /><br /></span>}
        {startingValue.summaryMeasure && <span><b>Summary measure: </b><DisplayFromFHIR codeableConcept={startingValue.summaryMeasure} /><br /></span>}
        {Array.isArray(startingValue.eventHandling) &&
          startingValue.eventHandling.map((ice, iceIndex) => {
            let event = ice.event || "";
            let group = ice.group || "";
            let handling = ice.handling || "";
            let description = ice.description || "";
            return <div key={iceIndex}>
              <h4>Handling of Intercurrent Event {ice.id || (iceIndex + 1)}</h4>
              <div style={{ marginLeft: "24px" }}>
                {event && <span><b>Event: </b><DisplayFromFHIR codeableConcept={event} /><br /></span>}
                {group && <span><b>Group: </b><DisplayFromFHIR codeableConcept={group} /><br /></span>}
                {handling && <span><b>Handling: </b><DisplayFromFHIR codeableConcept={handling} /><br /></span>}
                {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
              </div>
            </div>
          })}
        {Array.isArray(startingValue.extension) &&
          startingValue.extension.map((extension, extensionIndex) => {
            if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/research-study-estimand") {
              let label;
              let type
              let endpoint;
              let population;
              let intervention;
              let comparator;
              let summaryMeasure;
              let eventHandling = [];
              for (const ext of extension.extension) {
                if (ext.url === "label") {
                  label = ext.valueString;
                } else if (ext.url === "type") {
                  type = ext.valueCodeableConcept;
                } else if (ext.url === "endpoint") {
                  endpoint = ext.valueReference;
                } else if (ext.url === "population") {
                  population = ext.valueReference;
                } else if (ext.url === "interventionGroup" || ext.url === "intervention") {
                  intervention = ext.valueId;
                } else if (ext.url === "comparatorGroup" || ext.url === "comparator") {
                  comparator = ext.valueId;
                } else if (ext.url === "summaryMeasure") {
                  summaryMeasure = ext.valueCodeableConcept;
                } else if (ext.url === "eventHandling") {
                  eventHandling.push(ext);
                }
              }
              return <div key={extensionIndex} >
                <h4>Estimand {extensionIndex + 1}</h4>
                <div style={{ marginLeft: "24px" }}>
                  {label && <p><b>Label: </b>{label}</p>}
                  {type && <span><b>Type: </b><DisplayFromFHIR codeableConcept={type} /><br /></span>}
                  {endpoint && <span><b>Endpoint: </b><DisplayFromFHIR reference={endpoint} /><br /></span>}
                  {population && <span><b>Population: </b><DisplayFromFHIR reference={population} /><br /></span>}
                  {intervention && <p><b>Intervention Group: </b>Comparison group {intervention}</p>}
                  {comparator && <p><b>Comparator Group: </b>Comparison group {comparator}</p>}
                  {summaryMeasure && <span><b>Summary measure: </b><DisplayFromFHIR codeableConcept={summaryMeasure} /><br /></span>}
                  {Array.isArray(eventHandling) &&
                    eventHandling.map((ice, iceIndex) => {
                      let event;
                      let group;
                      let handling;
                      let description;
                      for (const extension of ice.extension) {
                        if (extension.url === "event") {
                          event = extension.valueCodeableConcept;
                        } else if (extension.url === "group") {
                          group = extension.valueCodeableConcept;
                        } else if (extension.url === "handling") {
                          handling = extension.valueCodeableConcept;
                        } else if (extension.url === "description") {
                          description = extension.valueMarkdown;
                        }
                      }
                      return <div key={iceIndex}>
                        <h4>Handling of Intercurrent Event {ice.id || (iceIndex + 1)}</h4>
                        <div style={{ marginLeft: "24px" }}>
                          {event && <span><b>Event: </b><DisplayFromFHIR codeableConcept={event} /><br /></span>}
                          {group && <span><b>Group: </b><DisplayFromFHIR codeableConcept={group} /><br /></span>}
                          {handling && <span><b>Handling: </b><DisplayFromFHIR codeableConcept={handling} /><br /></span>}
                          {description && <span><b>Description: </b><DisplayFromFHIR markdown={description} /><br /></span>}
                        </div>
                      </div>
                    })}
                </div>
              </div>
            } else {
              return <></>;
            }
          })}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={outcomeMeasureEntryState.name} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          valueSet={researchStudyDotObjectiveTypeValueSet} startCollapsed={startCollapsed}
          startingValue={outcomeMeasureEntryState.type} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed={startCollapsed}
          startingValue={outcomeMeasureEntryState.description} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='Reference' elementName='endpoint' fieldLabel='Endpoint'
          referencedResourceTypes={['EvidenceVariable']}
          startingValue={outcomeMeasureEntryState.endpoint} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='Reference' elementName='population' fieldLabel='Population'
          referencedResourceTypes={['Group']}
          startingValue={outcomeMeasureEntryState.population} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='Reference' elementName='intervention' fieldLabel='Intervention Group'
          referencedResourceTypes={['Group']}
          startingValue={outcomeMeasureEntryState.population} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='Reference' elementName='comparator' fieldLabel='Comparator Group'
          referencedResourceTypes={['Group']}
          startingValue={outcomeMeasureEntryState.population} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='CodeableConcept' elementName='summaryMeasure' fieldLabel='Summary Measure'
          valueSet={SEVCO.statisticType}
          startingValue={outcomeMeasureEntryState.summaryMeasure} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry asArray={true} datatype='EventHandling' elementName='eventHandling' fieldLabel='Handling of Intercurrent Events'
          startingValue={outcomeMeasureEntryState.eventHandling} setResourceState={setOutcomeMeasureEntryState} />
      </div>
    </>
  }
})

const ResearchStudyOutcomeMeasureEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingOutcomeEntry = {
    name: "", type: "", description: "", reference: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingOutcomeEntry.extension = startingValue.extension; }
    if (startingValue.name) { startingOutcomeEntry.name = startingValue.name; }
    if (startingValue.type) { startingOutcomeEntry.type = startingValue.type; }
    if (startingValue.description) { startingOutcomeEntry.description = startingValue.description; }
    if (startingValue.reference) { startingOutcomeEntry.reference = startingValue.reference; }
  }

  const [outcomeMeasureEntryState, setOutcomeMeasureEntryState] = useState(JSON.parse(JSON.stringify(startingOutcomeEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(outcomeMeasureEntryState).length > 0) {
      let newOutcomeEntry = {};
      if (outcomeMeasureEntryState.extension) { newOutcomeEntry.extension = outcomeMeasureEntryState.extension; }
      if (outcomeMeasureEntryState.name) { newOutcomeEntry.name = outcomeMeasureEntryState.name; }
      if (outcomeMeasureEntryState.type && Object.keys(outcomeMeasureEntryState.type).length > 0) {
        newOutcomeEntry.type = outcomeMeasureEntryState.type;
      }
      if (outcomeMeasureEntryState.description) { newOutcomeEntry.description = outcomeMeasureEntryState.description; }
      if (outcomeMeasureEntryState.reference && Object.keys(outcomeMeasureEntryState.reference).length > 0) {
        newOutcomeEntry.reference = outcomeMeasureEntryState.reference;
      }
      handleChange(elementName, newOutcomeEntry, setResourceState);
    }
  }), [outcomeMeasureEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingValue.name && <p><b>Name: </b>{startingValue.name}</p>}
        {Array.isArray(startingValue.type) &&
          startingValue.type.map((type, typeIndex) => {
            return <div key={typeIndex}><b>Type: </b><DisplayFromFHIR codeableConcept={type} /></div>
          })}
        {startingValue.description && <div><b>Description: </b><DisplayFromFHIR markdown={startingValue.description} /><br /><br /></div>}
        {startingValue.reference && <span><b>Outcome Definition: </b><DisplayFromFHIR reference={startingValue.reference} /><br /></span>}
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={outcomeMeasureEntryState.name} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          valueSet={researchStudyDotObjectiveTypeValueSet} startCollapsed={startCollapsed}
          startingValue={outcomeMeasureEntryState.type} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed={startCollapsed}
          startingValue={outcomeMeasureEntryState.description} setResourceState={setOutcomeMeasureEntryState} />
        <DataEntry datatype='Reference' elementName='reference' fieldLabel='Outcome Definition'
          referencedResourceTypes={['EvidenceVariable']} startCollapsed={startCollapsed}
          startingValue={outcomeMeasureEntryState.reference} setResourceState={setOutcomeMeasureEntryState} />
      </div>
    </>
  }
})

const researchStudyDotProgressStatusValueSet = [
  { system: "http://hl7.org/fhir/research-study-status", code: "overall-study", display: "Overall study" },
  { system: "http://hl7.org/fhir/research-study-status", code: "active", display: "Active" },
  { system: "http://hl7.org/fhir/research-study-status", code: "active-but-not-recruiting", display: "Active, not recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "administratively-completed", display: "Administratively Completed" },
  { system: "http://hl7.org/fhir/research-study-status", code: "approved", display: "Approved" },
  { system: "http://hl7.org/fhir/research-study-status", code: "closed-to-accrual", display: "Closed to Accrual" },
  { system: "http://hl7.org/fhir/research-study-status", code: "closed-to-accrual-and-intervention", display: "Closed to Accrual and Intervention" },
  { system: "http://hl7.org/fhir/research-study-status", code: "completed", display: "Completed" },
  { system: "http://hl7.org/fhir/research-study-status", code: "disapproved", display: "Disapproved" },
  { system: "http://hl7.org/fhir/research-study-status", code: "enrolling-by-invitation", display: "Enrolling by invitation" },
  { system: "http://hl7.org/fhir/research-study-status", code: "in-review", display: "In Review" },
  { system: "http://hl7.org/fhir/research-study-status", code: "not-yet-recruiting", display: "Not yet recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "recruiting", display: "Recruiting" },
  { system: "http://hl7.org/fhir/research-study-status", code: "temporarily-closed-to-accrual", display: "Temporarily Closed to Accrual" },
  { system: "http://hl7.org/fhir/research-study-status", code: "temporarily-closed-to-accrual-and-intervention", display: "Temporarily Closed to Accrual and Intervention" },
  { system: "http://hl7.org/fhir/research-study-status", code: "terminated", display: "Terminated" },
  { system: "http://hl7.org/fhir/research-study-status", code: "withdrawn", display: "Withdrawn" }
];
const ResearchStudyProgressStatusEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingProgressStatusEntry = {
    state: "", actual: "", period: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingProgressStatusEntry.extension = startingValue.extension; }
    if (startingValue.state) { startingProgressStatusEntry.state = startingValue.state; }
    if (typeof startingValue.actual === "boolean") { startingProgressStatusEntry.actual = startingValue.actual; }
    if (startingValue.period) { startingProgressStatusEntry.period = startingValue.period; }
  }

  const [progressStatusEntryState, setProgressStatusEntryState] = useState(JSON.parse(JSON.stringify(startingProgressStatusEntry || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(progressStatusEntryState).length > 0) {
      let newProgressEntry = {};
      if (progressStatusEntryState.extension) { newProgressEntry.extension = progressStatusEntryState.extension; }
      if (progressStatusEntryState.state && Object.keys(progressStatusEntryState.state).length > 0) {
        newProgressEntry.state = progressStatusEntryState.state;
      }
      if (typeof progressStatusEntryState.actual === "boolean") {
        newProgressEntry.actual = progressStatusEntryState.actual;
      }
      if (progressStatusEntryState.period && Object.keys(progressStatusEntryState.period).length > 0) {
        newProgressEntry.period = progressStatusEntryState.period;
      }
      handleChange(elementName, newProgressEntry, setResourceState);
    }
  }), [progressStatusEntryState]);

  if (startCollapsedState) {
    return <div>
      <b>{fieldLabel}: </b>
      {startingValue && <div>
        <div style={{ marginLeft: "24px" }}>
          {startingValue.state && <span><b>State: </b><DisplayFromFHIR codeableConcept={startingValue.state} /><br /></span>}
          {startingValue.actual === true && <p><b>Actual</b></p>}
          {startingValue.actual === false && <p><b>Anticipated</b></p>}
          {startingValue.period && <p><b>Period: </b>{getStringFromFHIR.Period(startingValue.period)}</p>}
        </div>
      </div>}
      &nbsp;&nbsp;
      <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
    </div>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='state' fieldLabel='Progress state'
          valueSet={researchStudyDotProgressStatusValueSet}
          startingValue={progressStatusEntryState.state} setResourceState={setProgressStatusEntryState} />
        <DataEntry datatype='boolean' elementName='actual' fieldLabel='Actual (false if Anticipated)'
          storeFalse={true}
          startingValue={progressStatusEntryState.actual} setResourceState={setProgressStatusEntryState} />
        <DataEntry datatype='Period' elementName='period' fieldLabel='Period (date range)'
          startingValue={progressStatusEntryState.period} setResourceState={setProgressStatusEntryState} />
      </div>
    </>
  }
})

const ResearchStudyRecruitmentEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, startCollapsed }) => {
  let startingRecruitmentEntry = {
    targetNumber: "", actualNumber: "", eligibility: "", actualGroup: ""
  };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingRecruitmentEntry.extension = startingValue.extension; }
    if (startingValue.targetNumber || startingValue.targetNumber === 0) { startingRecruitmentEntry.targetNumber = startingValue.targetNumber; }
    if (startingValue.actualNumber || startingValue.actualNumber === 0) { startingRecruitmentEntry.actualNumber = startingValue.actualNumber; }
    if (startingValue.eligibility) { startingRecruitmentEntry.eligibility = startingValue.eligibility; }
    if (startingValue.actualGroup) { startingRecruitmentEntry.actualGroup = startingValue.actualGroup; }
  }

  const [recruitmentEntryState, setRecruitmentEntryState] = useState(JSON.parse(JSON.stringify(startingRecruitmentEntry || {})));

  useEffect((() => {
    if (Object.keys(recruitmentEntryState).length > 0) {
      let newRecruitmentEntry = {};
      if (recruitmentEntryState.extension) { newRecruitmentEntry.extension = recruitmentEntryState.extension; }
      if (recruitmentEntryState.targetNumber || recruitmentEntryState.targetNumber === 0) { newRecruitmentEntry.targetNumber = recruitmentEntryState.targetNumber; }
      if (recruitmentEntryState.actualNumber || recruitmentEntryState.actualNumber === 0) { newRecruitmentEntry.actualNumber = recruitmentEntryState.actualNumber; }
      if (recruitmentEntryState.eligibility && Object.keys(recruitmentEntryState.eligibility).length > 0) {
        newRecruitmentEntry.eligibility = recruitmentEntryState.eligibility;
      }
      if (recruitmentEntryState.actualGroup && Object.keys(recruitmentEntryState.actualGroup).length > 0) {
        newRecruitmentEntry.actualGroup = recruitmentEntryState.actualGroup;
      }
      handleChange(elementName, newRecruitmentEntry, setResourceState);
    }
  }), [recruitmentEntryState]);

  return <>
    <div style={{ marginLeft: "2px" }}>
      <DataEntry datatype='unsignedInt' elementName='targetNumber' fieldLabel='Target number of participants'
        startingValue={recruitmentEntryState.targetNumber} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='unsignedInt' elementName='actualNumber' fieldLabel='Actual number of participants'
        startingValue={recruitmentEntryState.actualNumber} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='Reference'
        elementName='eligibility' fieldLabel='Eligibility Criteria' startCollapsed={startCollapsed}
        referencedResourceTypes={['Group']} enableCreation={true}
        startingValue={recruitmentEntryState.eligibility} setResourceState={setRecruitmentEntryState} />
      <DataEntry datatype='Reference'
        elementName='actualGroup' fieldLabel='Enrollment Group'
        referencedResourceTypes={['Group']} enableCreation={true} startCollapsed={startCollapsed}
        startingValue={recruitmentEntryState.actualGroup} setResourceState={setRecruitmentEntryState} />
    </div>
  </>
})

const ValueSetComposeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetCompose = { lockedDate: "", inactive: "", include: [], exclude: [], property: [] };
  if (!startingValue) {
    startingValueSetCompose = {};
  } else {
    if (startingValue.extension) { startingValueSetCompose.extension = startingValue.extension; }
    if (startingValue.lockedDate) { startingValueSetCompose.lockedDate = startingValue.lockedDate; }
    if (typeof startingValue.inactive === "boolean") { startingValueSetCompose.inactive = startingValue.inactive; }
    if (startingValue.include) { startingValueSetCompose.include = startingValue.include; }
    if (startingValue.exclude) { startingValueSetCompose.exclude = startingValue.exclude; }
    if (startingValue.property) { startingValueSetCompose.property = startingValue.property; }
  }

  const [valueSetComposeState, setValueSetComposeState] = useState(JSON.parse(JSON.stringify(startingValueSetCompose || {})));

  useEffect(() => {
    if (Object.keys(valueSetComposeState).length) {
      let newValueSetCompose = {};
      if (valueSetComposeState.extension) { newValueSetCompose.extension = valueSetComposeState.extension; }
      if (valueSetComposeState.lockedDate) { newValueSetCompose.lockedDate = valueSetComposeState.lockedDate; }
      if (typeof valueSetComposeState.inactive === "boolean") { newValueSetCompose.inactive = valueSetComposeState.inactive; }
      if (Array.isArray(valueSetComposeState.include) && valueSetComposeState.include.length > 0) {
        newValueSetCompose.include = valueSetComposeState.include;
      }
      if (Array.isArray(valueSetComposeState.exclude) && valueSetComposeState.exclude.length > 0) {
        newValueSetCompose.exclude = valueSetComposeState.exclude;
      }
      if (Array.isArray(valueSetComposeState.property) && valueSetComposeState.property.length > 0) {
        newValueSetCompose.property = valueSetComposeState.property;
      }
      if (Object.keys(newValueSetCompose).length === 0) {
        newValueSetCompose = null;
      }
      handleChange(elementName, newValueSetCompose, setResourceState);
    }
  }, [valueSetComposeState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry asArray={true} datatype='ValueSetComposeInclude' elementName='include' fieldLabel='Include'
        deletable={true}
        startingValue={valueSetComposeState.include} setResourceState={setValueSetComposeState} />
      <DataEntry asArray={true} datatype='ValueSetComposeInclude' elementName='exclude' fieldLabel='Exclude'
        deletable={true} deletableArray={true}
        startingValue={valueSetComposeState.exclude} setResourceState={setValueSetComposeState} />
    </div>
  </>
})

const importTermsToValueSet = async (globalContext, valueSetComposeIncludeState, codeSystemTermConversionState, setCodeSystemTermConversionState) => {

  if (valueSetComposeIncludeState.system?.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
    let codeSystemFevirId = valueSetComposeIncludeState.system.substring(39);

    const body = {
      'functionid': "codesystemtermstovaluesetconcept",
      'idToken': "",
      'resourceid': codeSystemFevirId,
      'termcode': codeSystemTermConversionState.termcode,
      'recursive': codeSystemTermConversionState.recursive
    };

    let response = await submitToFevirServer(globalContext, 10000, body, true, false);

    let codecode = codeSystemTermConversionState.termcode;
    if (codecode === "") {
      codecode = "TOP";
    }
    if (response?.success && response.valuesetincludeinstance) {
      if (response.valuesetincludeinstance.concept?.length > 0) {
        setCodeSystemTermConversionState(prevState => {
          return {
            ...prevState,
            system: response.valuesetincludeinstance.system,
            version: response.valuesetincludeinstance.version,
            concept: response.valuesetincludeinstance.concept
          };
        });
      } else {
        alert("That term doesn't have any child terms.")
      }
    } else {
      alert("Term import failed. Perhaps due to not finding the CodeSystem or code.");
    }
  } else {
    alert("System URL field can't be blank and it needs to start with https://fevir.net/resources/CodeSystem/.");
  }
}

const ValueSetComposeIncludeEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetComposeInclude = { system: "", version: "", concept: [], filter: [], valueSet: [], copyright: "" };
  let containsConcepts = false;
  if (!startingValue) {
    startingValueSetComposeInclude = { concept: [] };
  } else {
    if (startingValue.extension) { startingValueSetComposeInclude.extension = startingValue.extension; }
    if (startingValue.system) { startingValueSetComposeInclude.system = startingValue.system; }
    if (startingValue.version) { startingValueSetComposeInclude.version = startingValue.version; }
    if (startingValue.concept) {
      startingValueSetComposeInclude.concept = startingValue.concept;
      if (startingValue.concept.length > 0) {
        containsConcepts = true;
      }
    }
    if (startingValue.filter) { startingValueSetComposeInclude.filter = startingValue.filter; }
    if (startingValue.valueSet) { startingValueSetComposeInclude.valueSet = startingValue.valueSet; }
    if (startingValue.copyright) { startingValueSetComposeInclude.copyright = startingValue.copyright; }
  }

  const globalContext = useContext(FevirContext);
  const [valueSetComposeIncludeState, setValueSetComposeIncludeState] = useState(JSON.parse(JSON.stringify(startingValueSetComposeInclude || {})));

  let startingCodeSystemTermConversionState = {
    'resourceid': "",
    'system': "",
    'version': "",
    'concept': [],
    'termcode': "",
    'recursive': false
  }
  if (valueSetComposeIncludeState.system?.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
    let codeSystemFevirId = valueSetComposeIncludeState.system.substring(39);
    startingCodeSystemTermConversionState["resourceid"] = codeSystemFevirId;
  }
  if (valueSetComposeIncludeState.version) {
    startingCodeSystemTermConversionState["version"] = valueSetComposeIncludeState.version;
  }
  const [codeSystemTermConversionState, setCodeSystemTermConversionState] = useState(JSON.parse(JSON.stringify(startingCodeSystemTermConversionState || {})));
  const [directConceptEntryState, setDirectConceptEntryState] = useState(containsConcepts);

  useEffect(() => {
    if (Object.keys(valueSetComposeIncludeState).length) {
      let newValueSetComposeInclude = {};
      if (valueSetComposeIncludeState.extension) { newValueSetComposeInclude.extension = valueSetComposeIncludeState.extension; }
      if (valueSetComposeIncludeState.system) {
        newValueSetComposeInclude.system = valueSetComposeIncludeState.system;
      }
      if (valueSetComposeIncludeState.version) {
        newValueSetComposeInclude.version = valueSetComposeIncludeState.version;
      }
      if (Array.isArray(valueSetComposeIncludeState.concept) && valueSetComposeIncludeState.concept.length > 0) {
        newValueSetComposeInclude.concept = valueSetComposeIncludeState.concept;
      }
      if (Array.isArray(valueSetComposeIncludeState.filter) && valueSetComposeIncludeState.filter.length > 0) {
        newValueSetComposeInclude.filter = valueSetComposeIncludeState.filter;
      }
      if (Array.isArray(valueSetComposeIncludeState.valueSet) && valueSetComposeIncludeState.valueSet.length > 0) {
        newValueSetComposeInclude.valueSet = valueSetComposeIncludeState.valueSet;
      }
      if (valueSetComposeIncludeState.copyright) { newValueSetComposeInclude.copyright = valueSetComposeIncludeState.copyright; }
      if (Object.keys(newValueSetComposeInclude).length === 0) {
        newValueSetComposeInclude = null;
      }
      handleChange(elementName, newValueSetComposeInclude, setResourceState);
    }
  }, [valueSetComposeIncludeState]);

  useEffect(() => {
    if (codeSystemTermConversionState.system && codeSystemTermConversionState.concept.length > 0) {
      setDirectConceptEntryState(true);
      setValueSetComposeIncludeState(prevState => {
        return {
          ...prevState,
          version: codeSystemTermConversionState.version,
          concept: codeSystemTermConversionState.concept
        };
      });
    }
  }, [codeSystemTermConversionState])

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='uri' elementName='system' fieldLabel='Code System'
        startingValue={valueSetComposeIncludeState.system} setResourceState={setValueSetComposeIncludeState} />
      <DataEntry datatype='string' elementName='version' fieldLabel='Version'
        startingValue={valueSetComposeIncludeState.version} setResourceState={setValueSetComposeIncludeState} />
      {(!valueSetComposeIncludeState.concept || valueSetComposeIncludeState.concept.length === 0 ||
        (valueSetComposeIncludeState.concept.length === 1 && valueSetComposeIncludeState.concept[0].code === ""))
        &&
        <>
          <div>
            <br /><br />
            <span>
              <b>Term Code: </b>
              <TextField style={{ width: "160px" }} multiline className="inputField" type='text' label={''} size="small"
                variant='outlined' value={codeSystemTermConversionState.termcode}
                onChange={(e) => {
                  setCodeSystemTermConversionState(prevState => { return { ...prevState, "termcode": e.target.value } });
                }} />
            </span>
            <span style={{ paddingLeft: "12px" }}>
              <Checkbox checked={codeSystemTermConversionState.recursive}
                onChange={(e) => {
                  setCodeSystemTermConversionState(prevState => { return { ...prevState, "recursive": e.target.checked } });
                }}
                color="primary"
                style={{ paddingLeft: "0px", paddingRight: "4px" }}
              /><>Recursive Children</>
            </span>
            <span style={{ paddingLeft: "12px" }}>
              <Button className="formButton" style={{ color: "#000000" }}
                content="Import Terms From CodeSystem"
                onClick={() => {
                  importTermsToValueSet(globalContext, valueSetComposeIncludeState, codeSystemTermConversionState, setCodeSystemTermConversionState);
                }}
              />
              <>&nbsp;&nbsp;</>
              <Button className="formButton" style={{ color: "#000000" }}
                content="Add Concepts Individually"
                onClick={() => { setDirectConceptEntryState(true); }}
              />
            </span>
          </div>
        </>
      }
      {directConceptEntryState &&
        <DataEntry asArray={true} datatype='ValueSetComposeIncludeConcept' elementName='concept' fieldLabel='Concept'
          startingValue={valueSetComposeIncludeState.concept ?? []} setResourceState={setValueSetComposeIncludeState} />
      }
    </div>
  </>
})

const ValueSetComposeIncludeConceptEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingValueSetConcept = { code: "", display: "", designation: [] };
  if (!startingValue) {
    startingValueSetConcept = {};
  } else {
    if (startingValue.extension) { startingValueSetConcept.extension = startingValue.extension; }
    if (startingValue.code) { startingValueSetConcept.code = startingValue.code; }
    if (startingValue.display) { startingValueSetConcept.display = startingValue.display; }
    if (startingValue.designation) { startingValueSetConcept.designation = startingValue.designation; }
  }

  const [valueSetComposeIncludeConceptState, setValueSetComposeIncludeConceptState] = useState(JSON.parse(JSON.stringify(startingValueSetConcept || {})));

  useEffect(() => {
    if (Object.keys(valueSetComposeIncludeConceptState).length > 0) {
      let newValueSetConcept = {};
      if (valueSetComposeIncludeConceptState.extension) { newValueSetConcept.extension = valueSetComposeIncludeConceptState.extension; }
      if (valueSetComposeIncludeConceptState.code) { newValueSetConcept.code = valueSetComposeIncludeConceptState.code; }
      if (valueSetComposeIncludeConceptState.display) { newValueSetConcept.display = valueSetComposeIncludeConceptState.display; }
      if (Array.isArray(valueSetComposeIncludeConceptState.designation) && valueSetComposeIncludeConceptState.designation.length > 0) {
        newValueSetConcept.designation = valueSetComposeIncludeConceptState.designation;
      }
      if (Object.keys(newValueSetConcept).length === 0) {
        newValueSetConcept = null;
      }
      handleChange(elementName, newValueSetConcept, setResourceState);
    }
  }, [valueSetComposeIncludeConceptState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='code' fieldLabel='Code'
        startingValue={valueSetComposeIncludeConceptState.code} setResourceState={setValueSetComposeIncludeConceptState} />
      <DataEntry datatype='string' elementName='display' fieldLabel='Display'
        startingValue={valueSetComposeIncludeConceptState.display} setResourceState={setValueSetComposeIncludeConceptState} />
      <DataEntry asArray={true} datatype='ConceptDesignation' elementName='designation' fieldLabel='Designation'
        startingValue={valueSetComposeIncludeConceptState.designation} setResourceState={setValueSetComposeIncludeConceptState} />
    </div>
  </>
})

const conceptDesignationDotUseValueSet = [
  { system: "http://snomed.info/sct", code: "900000000000550004", display: "Definition (core metadata concept)" },
  { system: "http://snomed.info/sct", code: "900000000000013009", display: "Synonym (core metadata concept)" }
]
const ConceptDesignationEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState, dataEntryStyle }) => {
  let startingConceptDesignation = { language: "", use: "", additionalUse: [], value: "" };
  if (!startingValue) {
    startingConceptDesignation = {};
  } else {
    if (startingValue.extension) { startingConceptDesignation.extension = startingValue.extension; }
    if (startingValue.language) { startingConceptDesignation.language = startingValue.language; }
    if (startingValue.use) { startingConceptDesignation.use = startingValue.use; }
    if (startingValue.additionalUse) { startingConceptDesignation.additionalUse = startingValue.additionalUse; }
    if (startingValue.value) { startingConceptDesignation.value = startingValue.value; }
  }

  const [conceptDesignationState, setConceptDesignationState] = useState(JSON.parse(JSON.stringify(startingConceptDesignation || {})));

  useEffect(() => {
    if (Object.keys(conceptDesignationState).length) {
      let newConceptDesignation = {};
      if (conceptDesignationState.extension) { newConceptDesignation.extension = conceptDesignationState.extension; }
      if (conceptDesignationState.language) { newConceptDesignation.language = conceptDesignationState.language; }
      if (conceptDesignationState.use && Object.keys(conceptDesignationState.use).length > 0) { newConceptDesignation.use = conceptDesignationState.use; }
      if (Array.isArray(conceptDesignationState.additionalUse) && conceptDesignationState.additionalUse.length > 0) {
        newConceptDesignation.additionalUse = conceptDesignationState.additionalUse;
      }
      if (conceptDesignationState.value) { newConceptDesignation.value = conceptDesignationState.value; }
      if (Object.keys(newConceptDesignation).length === 0) {
        newConceptDesignation = null;
      }
      handleChange(elementName, newConceptDesignation, setResourceState);
    }
  }, [conceptDesignationState]);

  return <>
    <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='Coding' elementName='use' fieldLabel='Use'
        valueSet={conceptDesignationDotUseValueSet}
        startingValue={conceptDesignationState.use} setResourceState={setConceptDesignationState} />
      <DataEntry datatype='string' elementName='value' fieldLabel='Value'
        startingValue={conceptDesignationState.value} setResourceState={setConceptDesignationState} />
    </div>
  </>
})

export {
  ActivityDefinitionDynamicValueEntry, ActivityDefinitionParticipantEntry, SearchPieceEntry,
  CodeSystemPropertyEntry, CodeSystemConceptDesignationEntry,
  EvidenceStatisticEntry, EvidenceStatisticSampleSizeEntry, EvidenceVariableCategoryEntry, EvidenceVariableConstraintEntry,
  EvidenceVariableDataStorageEntry, EvidenceVariableDefinitionEntry, EvidenceStatisticAttributeEstimateEntry,
  EvidenceStatisticModelCharacteristicEntry, EvidenceStatisticModelCharacteristicVariableEntry,
  ListEntryEntry, PicoSectionEntry,
  PlanDefinitionActionEntry, PlanDefinitionActorEntry, PlanDefinitionGoalEntry,
  ResearchStudyAssociatedPartyEntry, ResearchStudyComparisonGroupEntry, ResearchStudyLabelEntry,
  ResearchStudyObjectiveEntry, ResearchStudyObjectiveOutcomeMeasureEntry, EstimandEntry, EventHandlingEntry,
  ResearchStudyOutcomeMeasureEntry, ResearchStudyProgressStatusEntry,
  ResearchStudyRecruitmentEntry, StudyAmendmentEntry,
  ValueSetComposeEntry, ValueSetComposeIncludeEntry, ValueSetComposeIncludeConceptEntry, ConceptDesignationEntry,
  CompositionTableCellEntry
};