import React, { useEffect, useImperativeHandle, useState } from 'react';
import { AssociatedResourcesDisplay, ReorganizeSectionsModal, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportMetadataPatternEdit } from './EvidenceReportMetadataDataEntry';
import { Button } from 'semantic-ui-react';
import ActivityDefinitionBuilder from './ActivityDefinitionBuilder';
import BundleBuilder from './BundleBuilder';
import CodeSystemBuilder from './CodeSystemBuilder';
import EvidenceBuilder from './EvidenceBuilder';
import EvidenceVariableBuilder from './EvidenceVariableBuilder';
import GroupBuilder from './GroupBuilder';
import LibraryBuilder from './LibraryBuilder';
import ListBuilder from './ListBuilder';
import MeasureBuilder from './MeasureBuilder';
import PlanDefinitionBuilder from './PlanDefinitionBuilder';
import ResearchStudyBuilder from './ResearchStudyBuilder';
import ValueSetBuilder from './ValueSetBuilder';
import resourceElementNames from './resourceElementNames';
import CompositionAuthor from './CompositionAuthor';
import { CompositionTableReportAuthor } from './CompositionEvidenceTableAuthor';
import OutcomeMeasureSynthesisReportAuthor from './CompositionEvidenceSynthesisTableAuthor';
import { ComparativeEvidenceSynthesisReportAuthor } from './ComparativeEvidenceSynthesisReportAuthor';
import { ComparativeEvidenceReportAuthor } from './ComparativeEvidenceReportAuthor';
import { SummaryOfFindingsAuthor } from './SummaryOfFindingsAuthoringTool';
import { EvidenceReportPackageAuthor } from './EvidenceReportPackageAuthoringTool';
import { M11ReportAuthor } from './M11ReportAuthoringTool';
import { GuidelineAuthor } from './GuidelineAuthoringTool';
import { RecommendationAuthor } from './RecommendationAuthoringTool';


const loadResourceStateByType = (fhirJson) => {
  if (fhirJson) {
    let resourceType = fhirJson.resourceType;
    if (resourceType === "Evidence") {
      if (!fhirJson.studyDesign && fhirJson.studyType) {
        if (Array.isArray(fhirJson.studyType)) {
          fhirJson.studyDesign = fhirJson.studyType;
        } else {
          fhirJson.studyDesign = [fhirJson.studyType];
        }
      }
    }

    let starterResourceStateValues = { "resourceJson": fhirJson, "newClassifications": null };
    let resourceElements = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension"];

    if (resourceType && resourceElementNames[resourceType]) {
      resourceElements = resourceElementNames[resourceType];
    }

    for (let element of resourceElements) {
      starterResourceStateValues[element] = fhirJson[element];
    }

    return starterResourceStateValues;
  }
};

const SimpleResourceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState,
  classificationsArrayState, classificationsLoadedState, globalContext }) => {
  let resourceType = fhirJson.resourceType;
  if (resourceType && !resourceState.resourceJson) {
    let starterResourceStateValues = loadResourceStateByType(fhirJson);
    if (starterResourceStateValues) {
      setResourceState(starterResourceStateValues);
    }
  }

  useImperativeHandle(formInputsStateRef, () => ({
    activityDefinitionState: resourceType === "ActivityDefinition" ? resourceState : null,
    bundleState: resourceType === "Bundle" ? resourceState : null,
    codeSystemState: resourceType === "CodeSystem" ? resourceState : null,
    evidenceState: resourceType === "Evidence" ? resourceState : null,
    evidenceVariableState: resourceType === "EvidenceVariable" ? resourceState : null,
    groupState: resourceType === "Group" ? resourceState : null,
    libraryState: resourceType === "Library" ? resourceState : null,
    listState: resourceType === "List" ? resourceState : null,
    measureState: resourceType === "Measure" ? resourceState : null,
    planDefinitionState: resourceType === "PlanDefinition" ? resourceState : null,
    researchStudyState: resourceType === "ResearchStudy" ? resourceState : null,
    valueSetState: resourceType === "ValueSet" ? resourceState : null
  }), [resourceState]);

  return <div>
    {resourceState.resourceJson ?
      <div style={{ marginTop: "12px" }}>
        {resourceType === "ActivityDefinition" && <ActivityDefinitionBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Bundle" && <BundleBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "CodeSystem" && <CodeSystemBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Evidence" && <EvidenceBuilder resourceState={resourceState} setResourceState={setResourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
        {resourceType === "EvidenceVariable" && <EvidenceVariableBuilder resourceState={resourceState} setResourceState={setResourceState}
          setSourceJsonState={setSourceJsonState} />}
        {resourceType === "Group" && <GroupBuilder resourceState={resourceState} setResourceState={setResourceState}
          globalContext={globalContext} setSourceJsonState={setSourceJsonState} />}
        {resourceType === "Library" && <LibraryBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "List" && <ListBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "Measure" && <MeasureBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "PlanDefinition" && <PlanDefinitionBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "ResearchStudy" && <ResearchStudyBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType === "ValueSet" && <ValueSetBuilder resourceState={resourceState} setResourceState={setResourceState} />}
        {resourceType !== "Bundle" && <div>
          <h3 id="how-to-cite">How to Cite</h3>
          <div style={{ marginLeft: "24px" }}>
            <DisplayHowToCite citationSummary={citationSummary}
              citationJson={citationJson} />
          </div>
        </div>}
        <h3 id="metadata">Metadata</h3>
        <div style={{ marginLeft: "24px" }}>
          <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
        </div>
        <h3 id="associated-resources">Associated Resources</h3>
        <div style={{ marginLeft: "24px" }}>
          <AssociatedResourcesDisplay fhirJson={fhirJson} />
        </div>
        <h3 id="classifiers">Classifiers</h3>
        <div style={{ marginLeft: "24px" }}>
          <div>
            <p>Add Classifiers:</p>
            <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
              fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
          </div>
          {(classificationsArrayState) && <div>
            <p>Existing Classifiers:</p>
            {classificationsLoadedState ?
              <DisplayClassifiers classificationsArray={classificationsArrayState} />
              :
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
          </div>}
        </div>
        <h3 id="json-outline">JSON Outline</h3>
        <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
        <br /><br />
      </div>
      :
      <>Loading...</>}
  </div>
}

const CompositionResourceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState,
  classificationsArrayState, classificationsLoadedState, globalContext, profile, update, previousVersionLoaded,
  fhirEntryState, setFhirEntryState, history, changeFormState, setChangeAvailableToSaveState }) => {
  if (Array.isArray(fhirJson.author) && fhirJson.author.length === 1 && fhirJson.author[0].name) {
    fhirJson.author[0].display = fhirJson.author[0].name;
    delete fhirJson.author[0].name;
  }
  let resourceType = fhirJson.resourceType;
  const [reorganizeSectionsModalState, setReorganizeSectionsModalState] = useState({});

  const [adaptationReportState, setAdaptationReportState] = useState({
    "adaptOn": fhirEntryState.adaptOn,
    "adaptationDictionary": "", "pathList": ""
  });

  const loadAdaptationStateFunction = () => {
    if (!adaptationReportState.adaptationDictionary && fhirJson.section?.length) {
      let startingAdaptationDictionary = {};
      let startingPathList = [];
      let alertMessage = "";
      for (let sectionIndex in fhirJson?.section) {
        const section = fhirJson.section[sectionIndex];
        if (section?.code) {
          let sectionCode;
          if (section.code.text) {
            sectionCode = "section[" + sectionIndex + "]:" + section.code.text;
          } else if (section.code.coding?.[0]) {
            sectionCode = "section[" + sectionIndex + "]:" + section.code.coding[0].system + "#" + section.code.coding[0].code;
          } else {
            sectionCode = "section[" + sectionIndex + "]:" + JSON.stringify(section.code);
          }
          if (startingPathList.includes(sectionCode)) {
            let addMessage = "Duplicate sections wtih the same code: " + sectionCode + " at top section level";
            if (alertMessage) {
              alertMessage += "; " + addMessage;
            } else {
              alertMessage = addMessage;
            }
          }
          startingPathList.push(sectionCode);
          startingPathList.push(sectionCode + ".title");
          startingPathList.push(sectionCode + ".author");
          startingPathList.push(sectionCode + ".focus");
          startingPathList.push(sectionCode + ".orderedBy");
          startingPathList.push(sectionCode + ".entry");
          startingPathList.push(sectionCode + ".emptyReason");
          startingAdaptationDictionary[sectionCode] = {
            "initialValue": section.text || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".title"] = {
            "initialValue": section.title || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".author"] = {
            "initialValue": section.author || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".focus"] = {
            "initialValue": section.focus || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
            "initialValue": section.orderedBy || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".entry"] = {
            "initialValue": section.entry || null,
            "itemChanged": false
          };
          startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
            "initialValue": section.emptyReason || null,
            "itemChanged": false
          };
        }
        if (section?.section?.length) {
          for (let subsectionIndex in section.section) {
            const subsection = section.section[subsectionIndex];
            if (subsection.code) {
              let sectionCode;
              if (subsection.code.text) {
                sectionCode = "section[" + sectionIndex + "].sectionn[" + subsectionIndex + "]:" + subsection.code.text;
              } else if (subsection.code.coding?.[0]) {
                sectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "]:" + subsection.code.coding[0].system + "#" + subsection.code.coding[0].code;
              } else {
                sectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "]:" + JSON.stringify(subsection.code);
              }
              if (startingPathList.includes(sectionCode)) {
                let addMessage = "Duplicate sections wtih the same code: " + sectionCode + " at section.section level";
                if (alertMessage) {
                  alertMessage += "; " + addMessage;
                } else {
                  alertMessage = addMessage;
                }
              }
              startingPathList.push(sectionCode);
              startingPathList.push(sectionCode + ".title");
              startingPathList.push(sectionCode + ".author");
              startingPathList.push(sectionCode + ".focus");
              startingPathList.push(sectionCode + ".orderedBy");
              startingPathList.push(sectionCode + ".entry");
              startingPathList.push(sectionCode + ".emptyReason");
              startingAdaptationDictionary[sectionCode] = {
                "initialValue": subsection.text || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".title"] = {
                "initialValue": subsection.title || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".author"] = {
                "initialValue": subsection.author || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".focus"] = {
                "initialValue": subsection.focus || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                "initialValue": subsection.orderedBy || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".entry"] = {
                "initialValue": subsection.entry || null,
                "itemChanged": false
              };
              startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                "initialValue": subsection.emptyReason || null,
                "itemChanged": false
              };
            }
            if (subsection.section?.length) {
              for (let subsubsectionIndex in subsection.section) {
                const subsubsection = subsection.section[subsubsectionIndex];
                if (subsubsection.code) {
                  let sectionCode;
                  if (subsubsection.code.text) {
                    sectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + subsubsection.code.text;
                  } else if (subsubsection.code.coding?.[0]) {
                    sectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + subsubsection.code.coding[0].system + "#" + subsubsection.code.coding[0].code;
                  } else {
                    sectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + JSON.stringify(subsubsection.code);
                  }
                  if (startingPathList.includes(sectionCode)) {
                    let addMessage = "Duplicate sections wtih the same code: " + sectionCode + " at section.section.section level";
                    if (alertMessage) {
                      alertMessage += "; " + addMessage;
                    } else {
                      alertMessage = addMessage;
                    }
                  }
                  startingPathList.push(sectionCode);
                  startingPathList.push(sectionCode + ".title");
                  startingPathList.push(sectionCode + ".author");
                  startingPathList.push(sectionCode + ".focus");
                  startingPathList.push(sectionCode + ".orderedBy");
                  startingPathList.push(sectionCode + ".entry");
                  startingPathList.push(sectionCode + ".emptyReason");
                  startingAdaptationDictionary[sectionCode] = {
                    "initialValue": subsubsection.text || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".title"] = {
                    "initialValue": subsubsection.title || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".author"] = {
                    "initialValue": subsubsection.author || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".focus"] = {
                    "initialValue": subsubsection.focus || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".orderedBy"] = {
                    "initialValue": subsubsection.orderedBy || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".entry"] = {
                    "initialValue": subsubsection.entry || null,
                    "itemChanged": false
                  };
                  startingAdaptationDictionary[sectionCode + ".emptyReason"] = {
                    "initialValue": subsubsection.emptyReason || null,
                    "itemChanged": false
                  };
                }
              }
            }
          }
        }
      }
      if (alertMessage) {
        alert(alertMessage);
      }
      setAdaptationReportState(prevState => {
        return {
          ...prevState,
          "adaptationDictionary": startingAdaptationDictionary,
          "pathList": startingPathList
        }
      })
    }
  }

  useEffect(() => {
    if (resourceType && !resourceState.resourceJson) {
      let starterResourceStateValues = loadResourceStateByType(fhirJson);
      if (starterResourceStateValues) {
        setResourceState(starterResourceStateValues);
        console.log("setResourceState from ResourceBuilder CompositionResourceEdit")
      }
    }
  }, []);

  useEffect(() => {
    if (adaptationReportState.adaptOn) {
      console.log(adaptationReportState)
      if (adaptationReportState.adaptationDictionary || adaptationReportState.pathList) {
        setFhirEntryState(prevState => {
          return {
            ...prevState,
            adaptationDictionary: adaptationReportState.adaptationDictionary,
            pathList: adaptationReportState.pathList
          }
        })
      } else {
        loadAdaptationStateFunction();
      }
    }
  }, [adaptationReportState]);

  useImperativeHandle(formInputsStateRef, () => ({
    compositionDocumentState: resourceState
  }), [resourceState]);

  //TODO - address loadSourceJson functions but they are currently different variations across authoring tools

  return <div>
    {resourceState.resourceJson ?
      <div style={{ marginTop: "12px" }}>
        {reorganizeSectionsModalState.modalOpen && <ReorganizeSectionsModal resourceState={resourceState}
          setResourceState={setResourceState} reorganizeSectionsModalState={reorganizeSectionsModalState}
          setReorganizeSectionsModalState={setReorganizeSectionsModalState} update={update} elementName={"section"} />}
        <Button style={{ color: "#000000", marginTop: "18px", marginLeft: "6px", fontSize: "18px", padding: "4px" }}
          className="formButton sectionReorganizeButton"
          content="Reorganize Sections"
          onClick={() => { setReorganizeSectionsModalState(prevState => { return { ...prevState, modalOpen: true } }); }} />
        <br />
        {(adaptationReportState.adaptOn && profile !== "Recommendation" && profile !== "Guideline" &&
          profile !== "ComparativeEvidenceReport" && profile !== "ComparativeEvidenceSynthesisReport" &&
          profile !== "BaselineMeasureReport" && profile !== "ParticipantFlowReport" &&
          profile !== "OutcomeMeasureReport") && <div>
            <h2>The Adapt feature is not yet developed for this Profile.</h2></div>}
        {profile === "none" && <CompositionAuthor resourceState={resourceState} setResourceState={setResourceState} />}
        {(profile === "OutcomeMeasureReport" || profile === "BaselineMeasureReport" || profile === "ParticipantFlowReport") &&
          <CompositionTableReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            profile={profile}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {profile === "OutcomeMeasureSynthesisReport" &&
          <OutcomeMeasureSynthesisReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            setFhirEntryState={setFhirEntryState}
            previousVersionLoaded={previousVersionLoaded} history={history}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {profile === "ComparativeEvidenceSynthesisReport" &&
          <ComparativeEvidenceSynthesisReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            setFhirEntryState={setFhirEntryState}
            previousVersionLoaded={previousVersionLoaded} history={history}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {profile === "ComparativeEvidenceReport" &&
          <ComparativeEvidenceReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            setFhirEntryState={setFhirEntryState}
            previousVersionLoaded={previousVersionLoaded} history={history}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {profile === "SummaryOfFindings" &&
          <SummaryOfFindingsAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            formInputsStateRef={formInputsStateRef} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
            history={history} changeFormState={changeFormState}
          />}
        {profile === "EvidenceReportPackage" &&
          <EvidenceReportPackageAuthor resourceState={resourceState} setResourceState={setResourceState}
            previousVersionLoaded={previousVersionLoaded} />}
        {profile === "M11Report" &&
          <M11ReportAuthor resourceState={resourceState} setResourceState={setResourceState}
            setFhirEntryState={setFhirEntryState} history={history} />}
        {profile === "Guideline" &&
          <GuidelineAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            setFhirEntryState={setFhirEntryState} history={history}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        {profile === "Recommendation" &&
          <RecommendationAuthor resourceState={resourceState} setResourceState={setResourceState}
            globalContext={globalContext} sourceJsonState={sourceJsonState} setSourceJsonState={setSourceJsonState}
            setFhirEntryState={setFhirEntryState} history={history}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />}
        <div>
          <h3 id="how-to-cite">How to Cite</h3>
          <div style={{ marginLeft: "24px" }}>
            <DisplayHowToCite citationSummary={citationSummary}
              citationJson={citationJson} />
          </div>
          <div id="edit-metadata"></div>
          <h3 id="metadata">Metadata</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceReportMetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
          </div>
          <h3 id="associated-resources">Associated Resources</h3>
          <div style={{ marginLeft: "24px" }}>
            <AssociatedResourcesDisplay fhirJson={fhirJson} />
          </div>
          <h3 id="classifiers">Classifiers</h3>
          <div style={{ marginLeft: "24px" }}>
            <div>
              <p>Add Classifiers:</p>
              <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
                fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
            </div>
            {(classificationsArrayState) && <div>
              <p>Existing Classifiers:</p>
              {classificationsLoadedState ?
                <DisplayClassifiers classificationsArray={classificationsArrayState} />
                :
                <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
              }
            </div>}
          </div>
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
          <br /><br />
        </div>
      </div>
      :
      <>Loading...</>}
  </div>
}

export { SimpleResourceEdit, CompositionResourceEdit, loadResourceStateByType };
