import React, { useState, useContext } from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { retrieveUserFevirApiToken } from "./UserAccountFunctions";
import FevirContext from './FevirContext';
import './App.css';

const ApiDocumentationPage = ({ useTitle }) => {
  useTitle("FEvIR API Documentation");

  const globalContext = useContext(FevirContext);

  const [showPostmanSettings, setShowPostmanSettings] = useState(false);
  const [fevirApiState, setFevirApiState] = useState("");

  let headerStyle = {backgroundColor: "#D1D7EF"};
  let cellStyle = {backgroundColor: "#ECF4FC"};
  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <p style={{fontSize: "20px"}}>
        The API can be accessed by using a POST request on <a href="https://api.fevir.net">https://api.fevir.net</a>
        <br/>
        {/*Or via a GET request with query parameters, example: <a href={"https://api.fevir.net?functionid=getresources&resourceids=123,234,345&apiToken="+fevirApiState}>https://api.fevir.net?functionid=getresources&resourceids=123,234,345&apiToken=<span style={{backgroundColor: "#000000"}}>███████████</span></a>*/}
        Or via a GET request with query parameters, example: <a href={"https://api.fevir.net?functionid=getresources&resourceids=123,234,345&apiToken=APITOKEN"}>https://api.fevir.net?functionid=getresources&resourceids=123,234,345&apiToken=APITOKEN</a>
        <br/>
        <span style={{fontSize: "16px"}}>An API request requires a functionid and your apiToken.</span>
        <br/>
      </p>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={headerStyle}>API Function</Table.HeaderCell>
            <Table.HeaderCell style={headerStyle}>Body Parameters</Table.HeaderCell>
            <Table.HeaderCell style={headerStyle}>Example Body</Table.HeaderCell>
            <Table.HeaderCell style={headerStyle}>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={cellStyle}>FEvIR Resource JSON Request</Table.Cell>
            <Table.Cell><b>functionid</b>: "getresourcejson"<br/><b>resourceid</b>: Resource ID<br/><b>apiToken</b>: {"Your API Token"}</Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"getresourcejson\",\n\t\"resourceid\": \"12345\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\"\n}"}
            </Table.Cell>
            <Table.Cell>Retrieve publicly viewable resource JSON by resource id.<br/>The response will be a JSON object of the resource.<br/>If unsuccessful the response will be an error message as a string.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>FEvIR Multiple Resource JSON Request</Table.Cell>
            <Table.Cell>
              <b>functionid</b>: "getresources"<br/>
              <b>resourceids</b>: Multiple IDs<br/>
              <b>apiToken</b>: {"Your API Token"}<br/>
              <b>projectfoi</b>: Project ID<br/>
              <b>publiconly</b>: BOOLEAN
            </Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"getresources\",\n\t\"resourceids\": [123,234,345],\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\",\n\t\"projectfoi\": \"27845\", \n\t\"publiconly\": true"}
              {"\n}"}
            </Table.Cell>
            <Table.Cell>Retrieve multiple resources' JSON by Resource IDs that you're allowed to view.<br/>The <i>resourceids</i> can also be in this format ["Citation/123", "Evidence/234", "Citation/345"].<br/>The response will be an array of JSON objects of each resource.<br/>You can also load resources associated with a Project, in which case use the <i>projectfoi</i> and leave <i>resourceids</i> empty.<br/>You can also choose to only return resources that are publicly viewable.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>FEvIR Search Request</Table.Cell>
            <Table.Cell>
              <b>functionid</b>: "search"<br/>
              <b>apiToken</b>: {"Your API Token"}<br/>
              <br/><u>Optional</u><br/>
              <b>searchstring</b>: Searching by string<br/>
              <b>resourcetype</b>: Comma-separated<br/>
              <b>searchparameters</b>: Objects with type & classifier<br/>
              <b>getresourcesjson</b>: BOOLEAN<br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"search\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\",\n\n"}
              {"\n\t\"searchstring\": \"cholesterol\",\n\t\"resourcetype\": [\"Composition\",\"Evidence\"],"}
              {"\n\t\"searchparameters\": [\n\t\t{\n\t\t\t\"type\": \"Population Condition\",\n\t\t\t\"classifier\": [\"Coronary Disease\"]\n\t\t},\n\t\t{\n\t\t\t\"type\": \"Intervention\",\n\t\t\t\"classifier\": [\n\t\t\t\t\"Atorvastatin\",\n\t\t\t\t\"Simvastatin\"\n\t\t\t]\n\t\t}\n\t],\n\t\"getresourcesjson\": true"}
              {"\n}"}
            </Table.Cell>
            <Table.Cell>Retrieve search results filtered by <i>searchstring</i>, <i>searchparameters</i>, and/or <i>resourcetype</i>.<br/>The response will have a <i>searchResults</i> array in the return.<br/>The <i>searchResults</i> array will have JSON objects that have <i>title</i>, <i>url</i>, and <i>foi</i> elements.<br/>If the request has <i>getresourcesjson</i> then it will return the JSON of the resources.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>CodeSystem Term JSON Request</Table.Cell>
            <Table.Cell><b>functionid</b>: "gettermjsonfromcodesystem"<br/><b>resourceid</b>: Resource ID<br/><b>termcode</b>: Term Code<br/><b>apiToken</b>: {"Your API Token"}</Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"gettermjsonfromcodesystem\",\n\t\"resourceid\": \"27270\",\n\t\"termcode\": \"SEVCO:00103\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\"\n}"}
            </Table.Cell>
            <Table.Cell>Retrieve the JSON of a CodeSystem term.<br/>The response will have a <i>termJson</i> element which contains the JSON of the term by itself.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>ClinicalTrials.gov-to-FEvIR Converter</Table.Cell>
            <Table.Cell>
              <b>functionid</b>: "submitnctid"<br/>
              <b>nctid</b>: {"NCTID"}<br/>
              <b>apiToken</b>: {"Your API Token"}
              <br/><br/><u>Optional</u><br/>
              <b>addtodatabase</b>: BOOLEAN<br/>{/*Optional or Alternative */}
              {/*<b>ctgovJson</b>: SOME EXPLANATION<br/><b>originalUrl</b>: SOME EXPLANATION<br/>*/}
            </Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"submitnctid\",\n\t\"nctid\": \"NCT01735981\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\",\n\n\n\t\"addtodatabase\": false\n}"}
              {/*,\n\t\"originalUrl\": \"https://clinicaltrials.gov/api/study/NCT04280705\",\n\t\"ctgovJSON\": JSON BLOCK*/}
            </Table.Cell>
            <Table.Cell>Returns a JSON object that contains a Bundle of FHIR Resources.<br/>If <i>addtodatabase</i> is true, then the resources will be saved to the FEvIR Platform and the ResearchStudy ID will be returned instead.</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>FEvIR Search for Clinical Trials</Table.Cell>
            <Table.Cell>
              <b>functionid</b>: "searchclinicaltrials"<br/>
              <b>apiToken</b>: {"Your API Token"}<br/>
              <b>searchstring</b>: Searching by string<br/>
            </Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"searchclinicaltrials\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\","}
              {"\n\t\"searchstring\": \"lateral sclerosis\""}
              {"\n}"}
            </Table.Cell>
            <Table.Cell>Retrieve search results filtered by <i>searchstring</i> and limited to <i>resourcetype</i> of ResearchStudy.<br/>The response will have a <i>resources</i> array in the return and will include all the matched ResearchStudy FHIR JSON as well as the Group FHIR JSON for the eligibility criteria for each ResearchStudy.<br/></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={cellStyle}>FEvIR Resource Submission</Table.Cell>
            <Table.Cell>
              <b>functionid</b>: "submitfhirresource"<br/>
              <b>tool</b>: "directfhirentry"<br/>
              <b>fhirEntry</b>: {"Your Resource JSON"} <br/>
              <b>resourceType</b>: {"The Resource Type"} <br/>
              <b>status</b>: {'"active" or "draft"'} <br/>
              <b>apiToken</b>: {"Your API Token"}<br/>
              <br/>
              <br/>
              <br/>
            </Table.Cell>
            <Table.Cell style={{"whiteSpace": "pre-wrap"}}>
              {"{\n\t\"functionid\": \"submitfhirresource\",\n\t\"tool\": \"directfhirentry\",\n\t\"fhirEntry\": {\n\t\t\"resourceType\": \"Evidence\",\n\t\t\"description\": \"example\"\n\t},\n\t\"resourceType\": \"Evidence\",\n\t\"status\": \"active\",\n\t\"apiToken\": \""}
              {fevirApiState ? fevirApiState : <span style={{cursor: "pointer", backgroundColor: "#000000"}} onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }}>███████████</span>}
              {"\"\n}"}
            </Table.Cell>
            <Table.Cell>Needs to be a POST request.<br/><i>fhirEntry</i> can either be a JSON string or a JSON object.<br/>Returns a JSON object that contains the ID of the newly created resource.<br/>If the resource is a Bundle of <i>type</i> "transaction" then the return is a JSON object with an array of IDs for each of the resource entries.</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {/*<br/><br/>Maybe have a short hyperlink to a Postman file for each call.*/}
      {/*<br/><br/>Some instructions on how to access in Postman and wget, with some screenshots*/}
      <br/><br/>
      <span style={{fontSize: "18px"}}>Send a POST request to <a href="https://api.fevir.net">https://api.fevir.net</a> to use the live FEvIR site and data.</span>
      <br/>
      <span>But if you want to do development work and testing you may also use <a href="https://api.fevir.net/devapi">https://api.fevir.net/devapi</a> as the test server. Keep in mind the devapi uses a different database and the data on there is routinely wiped out.</span>
      <br/><br/>
      {showPostmanSettings ?
        <span>
          <span style={{fontSize: "18px"}}>Postman settings for FEvIR API</span>      
          <br/>
          <img style={{align: "left", maxWidth: "100%" }} alt="FEvIR API Postman settings"
          src="/FEvIR API Postman settings.png" title="FEvIR API Postman settings"/>
        </span>
      :
        <span style={{fontSize: "18px", cursor: "pointer", color: "#4183c4"}} onClick={() => { setShowPostmanSettings(true); }}>Show Postman settings for FEvIR API</span>      
      }
     </Segment>
  </div>

};

export default ApiDocumentationPage;