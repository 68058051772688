import submitToFevirServer from './SubmitToFevirServer';
import { compositionTypeCodeSummaryOfFindings, emptyReasonNotStarted, emptyTextNoData, sectionCodeAssertion, sectionCodeCertaintyOfEvidence, sectionCodeColumnHeaders, sectionCodeComparatorGroup, sectionCodeGroupAssignment, sectionCodeGroups, sectionCodeInterventionGroup, sectionCodeOutcomeMeasure, sectionCodePopulation, sectionCodeResultWithComparatorAlone, sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionAloneCalculated, sectionCodeResultWithInterventionVsComparator, sectionCodeSampleSize, sectionCodeSummaryOfFindings, sectionCodeSummaryOfFindingsForSingleOutcome, sectionCodeVariables } from './CodeSystemLookup';

const createSummaryOfFindingsReport = async (sourceTitle, summaryOfFindingsStarter, globalContext) => {
  let titleStarter = sourceTitle || "[Untitled Outcome Measure Report]";
  let title = "Summary of Findings from " + titleStarter;
  let newSummaryofFindings = {
    "resourceType": "Composition",
    "meta": {
      "profile": [
        "http://hl7.org/fhir/uv/ebm/StructureDefinition/summary-of-findings"
      ]
    },
    "status": "final",
    "type": compositionTypeCodeSummaryOfFindings,
    "subject": summaryOfFindingsStarter.subject || "",
    "author": [
      {
        "display": "Computable Publishing®: Summary of Findings Report Authoring Tool"
      },
      {
        "display": globalContext.userState.name
      }
    ],
    "title": title,
    "custodian": {
      "reference": "Organization/118079",
      "type": "Organization",
      "display": "Computable Publishing LLC"
    },
    "relatesTo": [
      summaryOfFindingsStarter.derivedFrom
    ],
    "section": [
      {
        "title": "Column Headers",
        "code": sectionCodeColumnHeaders,
        "section": [
          {
            "title": "Outcome",
            "code": sectionCodeOutcomeMeasure,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Outcome</p></div>"
            }
          },
          {
            "title": "Sample Size",
            "code": sectionCodeSampleSize,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample size: # studies, # participants, # counted, # events</p></div>"
            }
          },
          {
            "title": "Result Without Treatment",
            "code": sectionCodeResultWithComparatorAlone,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
            }
          },
          {
            "title": "Result With Treatment--Observed",
            "code": sectionCodeResultWithInterventionAlone,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
            }
          },
          {
            "title": "Result With Treatment--Derived",
            "code": sectionCodeResultWithInterventionAloneCalculated,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
            }
          },
          {
            "title": "Effect Estimate",
            "code": sectionCodeResultWithInterventionVsComparator,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
            }
          },
          {
            "title": "Certainty of Evidence",
            "code":sectionCodeCertaintyOfEvidence,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of finding (Quality of evidence)</p></div>"
            }
          },
          {
            "title": "Assertion",
            "code": sectionCodeAssertion,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
            }
          }
        ]
      },
      {
        "title": "Summary of Findings",
        "code": sectionCodeSummaryOfFindings,
        "text": emptyTextNoData,
        "section": [],
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Groups",
        "code": sectionCodeGroups,
        "section": [
          {
            "title": "Total Group",
            "code": sectionCodePopulation,
            "text": emptyTextNoData,
            "emptyReason": emptyReasonNotStarted
          },
          {
            "title": "Intervention Group",
            "code": sectionCodeInterventionGroup,
            "text": emptyTextNoData,
            "emptyReason": emptyReasonNotStarted
          },
          {
            "title": "Comparator Group",
            "code": sectionCodeComparatorGroup,
            "text": emptyTextNoData,
            "emptyReason": emptyReasonNotStarted
          },
          {
            "title": "Group Assignment",
            "code": sectionCodeGroupAssignment,
            "text": emptyTextNoData,
            "emptyReason": emptyReasonNotStarted
          }
        ]
      },
      {
        "title": "Variables (Measures)",
        "code": sectionCodeVariables,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  if (summaryOfFindingsStarter.groupsSection) {
    newSummaryofFindings.section[2] = summaryOfFindingsStarter.groupsSection;
  }
  if (summaryOfFindingsStarter.variablesSection) {
    newSummaryofFindings.section[3] = summaryOfFindingsStarter.variablesSection;
  }
  if (!newSummaryofFindings.section[2].section[1].entry && summaryOfFindingsStarter.interventionGroup?.resourceReference) {
    newSummaryofFindings.section[2].section[1].entry = [summaryOfFindingsStarter.interventionGroup.resourceReference];
    delete newSummaryofFindings.section[2].section[1].emptyReason;
  }
  if (!newSummaryofFindings.section[2].section[2].entry && summaryOfFindingsStarter.comparatorGroup?.resourceReference) {
    newSummaryofFindings.section[2].section[2].entry = [summaryOfFindingsStarter.comparatorGroup.resourceReference];
    delete newSummaryofFindings.section[2].section[2].emptyReason;
  }
  if (!newSummaryofFindings.section[3].entry && summaryOfFindingsStarter.outcomesRelatesTo) {
    newSummaryofFindings.section[3].entry = summaryOfFindingsStarter.outcomesRelatesTo.map(item => {return item.resourceReference;});
    delete newSummaryofFindings.section[3].emptyReason;
  }
  if (Array.isArray(summaryOfFindingsStarter.outcomesSection) && summaryOfFindingsStarter.outcomesSection.length > 0) {
    for (const section of summaryOfFindingsStarter.outcomesSection) {
      let outcomeFocus = section.focus || "";
      let outcomeTitle = section.title || outcomeFocus?.display || outcomeFocus?.reference || "Outcome Name Missing";
      let comparativeEvidenceReference;
      let comparatorOnlyEvidenceReference;
      let interventionOnlyEvidenceReference;
      if (section.section?.length > 0) {
        for (const subsection of section.section) {
          let subsectionCode = subsection?.code?.text || subsection.code?.coding?.[0]?.code;
          if (subsectionCode === "Outcome with intervention vs. comparator" || subsectionCode === "evidence-with-intervention-vs-comparator" || 
            subsectionCode === "result-with-intervention-vs-comparator") {
            comparativeEvidenceReference = subsection.entry;
          }
          if (subsectionCode === "Outcome with intervention alone" || subsectionCode === "evidence-with-intervention-alone" || 
            subsectionCode === "result-with-intervention-alone") {
            interventionOnlyEvidenceReference = subsection.entry;
          }
          if (subsectionCode === "Outcome with comparator alone" || subsectionCode === "evidence-with-comparator-alone" || 
            subsectionCode === "result-with-comparator-alone") {
            comparatorOnlyEvidenceReference = subsection.entry;
          }
        }
      }
      let combinedReference;
      if (Array.isArray(comparativeEvidenceReference)) {
        combinedReference = [];
        for (const entry of comparativeEvidenceReference) {
          combinedReference.push(entry);
        }
      }
      if (Array.isArray(comparatorOnlyEvidenceReference)) {
        if (!combinedReference) {
          combinedReference = [];
        }
        for (const entry of comparatorOnlyEvidenceReference) {
          combinedReference.push(entry);
        }
      }

      let nextSection = {
        "title": outcomeTitle,
        "code": sectionCodeSummaryOfFindingsForSingleOutcome,
        "focus": outcomeFocus,
        "section": [
          {
            "title": outcomeTitle,
            "code": sectionCodeOutcomeMeasure,
            "text": {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeTitle + "</p></div>"
            },
            "entry": [outcomeFocus]
          },
          {
            "title": "Sample Size",
            "code": sectionCodeSampleSize,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Sample size: # studies, # participants, # counted, # events</p></div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Result Without Treatment",
            "code": sectionCodeResultWithComparatorAlone,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result Without Treatment</p></div>"
            },
            "entry": comparatorOnlyEvidenceReference
          },
          {
            "title": "Result With Treatment--Observed",
            "code": sectionCodeResultWithInterventionAlone,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Observed)</p></div>"
            },
            "entry": interventionOnlyEvidenceReference
          },
          {
            "title": "Result With Treatment--Derived",
            "code": sectionCodeResultWithInterventionAloneCalculated,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Result With Treatment (Calculated)</p></div>"
            },
            "entry": combinedReference
          },
          {
            "title": "Effect Estimate",
            "code": sectionCodeResultWithInterventionVsComparator,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Effect Estimate</p></div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Certainty of Evidence",
            "code": sectionCodeCertaintyOfEvidence,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>Certainty of finding (Quality of evidence)</p></div>"
            },
            "entry": comparativeEvidenceReference
          },
          {
            "title": "Assertion",
            "code": sectionCodeAssertion,
            "text": {
              "status": "empty",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>What this means</p></div>"
            },
            "entry": comparativeEvidenceReference
          }
        ]
      }
      newSummaryofFindings.section[1].section.push(nextSection);
    }
  }
  if (newSummaryofFindings.section[1].section.length) {
    delete newSummaryofFindings.section[1].emptyReason;
  }

  let fhirEntryString = JSON.stringify(newSummaryofFindings, null, 2);

  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': fhirEntryString,
    'title': newSummaryofFindings.title,
    'status': 'active',
  };

  let response = await submitToFevirServer(globalContext, 5000, body, true, false);

  if (response.success) {
    return response.formstateid;
  }
};

const addFoisFromFlis = async (globalContext, targetResources) => {
  let fliList = [];
  for (let resource of targetResources) {
    if (!resource?.id && resource?.fli) {
      fliList.push(resource.fli);
    }
  }

  if (fliList.length > 0) {
    const body = {
      'functionid': 'getfoilistfromflilist',
      'fliList': fliList,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 100000, body, true, false);
    if (response?.success && response.foilist?.length && response.flitofoi) {
      for (let resource of targetResources) {
        if (!resource?.id && resource?.fli && response.flitofoi[resource.fli]) {
          resource.id = response.flitofoi[resource.fli];
        }
      }
    }
  }

  return targetResources;
}

const getTargetResourceRatingDictionary = async (targetResources, globalContext) => {
  let getBody = {
    'functionid': 'gettargetresourceratingdictionary',
    'idToken': '',
    'targetResources': targetResources,
    'userid': globalContext.userState.id, //TODO probably DELETE THIS, why is this here, this should be deleted
    'tool': 'SummaryOfFindingsAuthoringTool'
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}

const submitResource = async (globalContext, fhirJson) => {
  let fhirEntryString = JSON.stringify(fhirJson, null, 2);
  const body = {
    'functionid': "submitfhirresource",
    'idToken': "",
    'fhirEntry': fhirEntryString,
    'title': fhirJson.title,
    'status': "active",
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  if (response?.success) {
    return response.formstateid;
  }
};

export {
  addFoisFromFlis, createSummaryOfFindingsReport,
  getTargetResourceRatingDictionary, submitResource
}