const addSystemAndCodesToOptions = (type, valueSet, options) => {
  if (options[type] === undefined) {
    options[type] = [];
  }
  let index = options[type].length;
  for (let system in valueSet) {
    for (let code in valueSet[system]) {
      options[type].push({ key: index, value: index, code: code, text: valueSet[system][code].shortName, display: valueSet[system][code].display, system: system });
      index++;
    }
  }
}

const unlimitedStyleTypes = { "[Enter Style]": true };
const customStyleNames = { "[Enter Style]": true, "[Not entered]": true, "[Not Entered]": true };
const generatedSummaryTexts = { "Computable Publishing": true, "Identifier Only": true, "Title Only": true };

const monthLookup = {
  "01": "January",
  "1": "January",
  "02": "February",
  "2": "February",
  "03": "March",
  "3": "March",
  "04": "April",
  "4": "April",
  "05": "May",
  "5": "May",
  "06": "June",
  "6": "June",
  "07": "July",
  "7": "July",
  "08": "August",
  "8": "August",
  "09": "September",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

const emptyTextNoData = {
  "status": "empty",
  "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>"
};

const emptyReasonNotStarted = {
  "coding": [
    {
      "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason",
      "code": "notstarted",
      "display": "Not Started"
    }
  ]
};

const emptyReasonValueSet = [
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "nilknown", "display": "Nil Known" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notasked", "display": "Not Asked" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "withheld", "display": "Information Withheld" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "unavailable", "display": "Unavailable" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "notstarted", "display": "Not Started" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-empty-reason", "code": "closed", "display": "Closed" }
];

const emptyReasonCodeableConceptSet = emptyReasonValueSet.map((entry) => {
  return { "coding": [{ "system": entry.system, "code": entry.code, "display": entry.display }] };
});

emptyReasonCodeableConceptSet.push({ "coding": [{ "system": "https://fevir.net/resources/CodeSystem/179423", "code": "not-relevant", "display": "Not Relevant" }] });

const identifierSystemLookup = {
  "https://clinicaltrials.gov": { "display": "ClinicalTrials.gov", "typeText": "", "assignerDisplay": "NLM", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "https://doi.org": { "display": "DOI", "typeText": "", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": false },
  "https://fevir.net": { "display": "FOI (FEvIR)", "typeText": "FEvIR Object Identifier", "assignerDisplay": "Computable Publishing LLC", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "urn:ietf:rfc:3986": { "display": "FOI", "typeText": "FEvIR Object Identifier", "assignerDisplay": "Computable Publishing LLC", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "https://www.ncbi.nlm.nih.gov/pmc/": { "display": "PMCID", "typeText": "", "assignerDisplay": "NLM", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "https://pubmed.ncbi.nlm.nih.gov": { "display": "PMID", "typeText": "", "assignerDisplay": "NLM", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "https://www.crd.york.ac.uk/prospero/": { "display": "PROSPERO", "typeText": "", "assignerDisplay": "PROSPERO", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true }
};


const covidSMRdiseaseValueSet = {
  "http://snomed.info/sct": {
    "840539006": {
      "display": "Disease caused by severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "COVID-19"
    },
    "840544004": {
      "display": "Suspected disease caused by severe acute respiratory coronavirus 2 (situation)",
      "shortName": "Suspected COVID-19"
    },
    "1119302008": {
      "display": "Acute disease caused by Severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "Acute COVID-19"
    },
    "882784691000119100": {
      "display": "Pneumonia caused by severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "COVID-19 pneumonia"
    },
    "870590002": {
      "display": "Acute hypoxemic respiratory failure due to disease caused by Severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "Acute hypoxemic respiratory failure due to COVID-19"
    },
    "674814021000119106": {
      "display": "Acute respiratory distress syndrome due to disease caused by Severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "ARDS due to COVID-19"
    },
    "880529761000119102": {
      "display": "Lower respiratory infection caused by Severe acute respiratory syndrome coronavirus 2 (disorder)",
      "shortName": "Lower respiratory tract infection due to COVID-19"
    }
  },
  "https://icd.who.int/browse11": {
    "RA01.0": {
      "display": "COVID-19, virus identified",
      "shortName": "COVID-19, virus identified"
    },
    "RA01.1": {
      "display": "COVID-19, virus not identified",
      "shortName": "COVID-19, virus not identified"
    }
  }
};
const otherDiseasesValueSet = {
  "http://snomed.info/sct": {
    "197663003": {
      "display": "Impaired renal function disorder (disorder)",
      "shortName": "Impaired renal function disorder (disorder)"
    },
    "64779008": {
      "display": "Coagulopathy",
      "shortName": "Coagulopathy"
    },
    "371039008": {
      "display": "Thromboembolic disorder",
      "shortName": "Thromboembolic disorder"
    }
  }
}

const otherConditionsValueSet = {
  "http://snomed.info/sct": {
    "77386006": {
      "display": "Pregnant (finding)",
      "shortName": "Pregnant (finding)"
    }
  }
}

const otherSeverityValueSet = {
  "http://snomed.info/sct": {
    "255604002": {
      "display": "Mild",
      "shortName": "Mild"
    },
    "6736007": {
      "display": "Moderate",
      "shortName": "Moderate"
    },
    "24484000": {
      "display": "Severe",
      "shortName": "Severe"
    },
    "371923003": {
      "display": "Mild to moderate",
      "shortName": "Mild to moderate"
    },
    "371924009": {
      "display": "Moderate to severe",
      "shortName": "Moderate to severe"
    },
    "442452003": {
      "display": "Life threatening severity",
      "shortName": "Life threatening severity"
    }
  }
};

const otherAdmissionToEstablishmentValueSet = {
  "http://snomed.info/sct": {
    "32485007": {
      "display": "Hospital admission",
      "shortName": "Hospital admission"
    }
  }
};

let characteristicValuePresetOptions = {};
addSystemAndCodesToOptions("64572001", covidSMRdiseaseValueSet, characteristicValuePresetOptions);
addSystemAndCodesToOptions("64572001", otherDiseasesValueSet, characteristicValuePresetOptions);
addSystemAndCodesToOptions("260905004", otherConditionsValueSet, characteristicValuePresetOptions);
addSystemAndCodesToOptions("246112005", otherSeverityValueSet, characteristicValuePresetOptions);
addSystemAndCodesToOptions("305335007", otherAdmissionToEstablishmentValueSet, characteristicValuePresetOptions);

const publicationFormIdentifierSystemLookup = {
  "https://www.issn.org/services/online-services/access-to-the-ltwa/": { "display": "ISO Abbreviation", "typeText": "ISOAbbreviation", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": false },
  "https://www.issn.org/understanding-the-issn/assignment-rules/the-issn-l-for-publications-on-multiple-media/": { "display": "ISSN Linking", "typeText": "ISSN Linking", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": false },
  "https://www.nlm.nih.gov/tsd/cataloging/contructitleabbre.html": { "display": "Medline Title Abbreviation", "typeText": "Medline Title Abbreviation", "assignerDisplay": "NLM", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
  "https://locatorplus.gov/cgi-bin/Pwebrecon.cgi?DB=local": { "display": "NLM Unique ID", "typeText": "NLM Unique ID", "assignerDisplay": "NLM", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
};

const titleTypeLookup = {
  "primary": {
    "display": "Primary title",
    "definition": "Main title for common use. The primary title used for representation if multiple titles exist.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "official": {
    "display": "Official title",
    "definition": "The official or authoritative title.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "scientific": {
    "display": "Scientific title",
    "definition": "Title using scientific terminology.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "plain-language": {
    "display": "Plain language title",
    "definition": "Title using language common to lay public discourse.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "subtitle": {
    "display": "Subtitle",
    "definition": "Subtitle or secondary title.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "short-title": {
    "display": "Short title",
    "definition": "Brief title (e.g. 'running title' or title used in page headers)",
    "system": "http://hl7.org/fhir/title-type"
  },
  "acronym": {
    "display": "Acronym",
    "definition": "Abbreviation used as title",
    "system": "http://hl7.org/fhir/title-type"
  },
  "earlier-title": {
    "display": "Different text in an earlier version",
    "definition": "Alternative form of title in an earlier version such as epub ahead of print.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "language": {
    "display": "Different language",
    "definition": "Additional form of title in a different language.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "autotranslated": {
    "display": "Different language derived from autotranslation",
    "definition": "Machine translated form of title in a different language, language element codes the language into which it was translated by machine.",
    "system": "http://hl7.org/fhir/title-type"
  },
  "human-use": {
    "display": "Human use",
    "definition": "Human-friendly title",
    "system": "http://hl7.org/fhir/title-type"
  },
  "machine-use": {
    "display": "Machine use",
    "definition": "Machine-friendly title",
    "system": "http://hl7.org/fhir/title-type"
  },
  "duplicate-uid": {
    "display": "Different text for the same object with a different identifier",
    "definition": "An alternative form of the title in two or more entries, e.g. in multiple medline entries",
    "system": "http://hl7.org/fhir/title-type"
  }
};

const abstractTypeLookup = {
  "primary-human-use": {
    "display": "Primary human use",
    "definition": "Human-friendly main or official abstract",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "primary-machine-use": {
    "display": "Primary machine use",
    "definition": "Machine-friendly main or official abstract",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "truncated": {
    "display": "Truncated",
    "definition": "Truncated abstract",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "short-abstract": {
    "display": "Short abstract",
    "definition": "Brief abstract, for use when abstracts are provided in different sizes or lengths",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "long-abstract": {
    "display": "Long abstract",
    "definition": "Long version of the abstract, for use when abstracts are provided in different sizes or lengths",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "plain-language": {
    "display": "Plain language",
    "definition": "Additional form of abstract written for the general public",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "different-publisher": {
    "display": "Different publisher for abstract",
    "definition": "Abstract produced by a different publisher than the cited artifact",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "language": {
    "display": "Different language",
    "definition": "Additional form of abstract in a different language",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "autotranslated": {
    "display": "Different language derived from autotranslation",
    "definition": "Machine translated form of abstract in a different language, language element codes the language into which it was translated by machine",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "duplicate-pmid": {
    "display": "Different text in additional Medline entry",
    "definition": "Alternative form of abstract in two or more Medline entries",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  },
  "earlier-abstract": {
    "display": "Different text in an earlier version",
    "definition": "Alternative form of abstract in an earlier version such as epub ahead of print",
    "system": "http://hl7.org/fhir/cited-artifact-abstract-type"
  }
};

const summaryTypeLookup = {
  "author-string": {
    "display": "Author string",
    "definition": "Display of the author list as a complete string.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "contributorship-list": {
    "display": "Contributorship list",
    "definition": "Display of the list of contributors as a complete string",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "contributorship-statement": {
    "display": "Contributorship statement",
    "definition": "Compiled summary of contributions.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "acknowledgment-list": {
    "display": "Acknowledgment list",
    "definition": "Display of the list of acknowledged parties as a complete string.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "acknowledgment-statement": {
    "display": "Acknowledgment statement",
    "definition": "Statement of acknowledgment of contributions beyond those compiled for formal contributorship statements.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "funding-statement": {
    "display": "Funding statement",
    "definition": "Statement of financial support for the creation of the cited artifact.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  },
  "competing-interests-statement": {
    "display": "Competing interests statement",
    "definition": "Statement of completing interests related to the creation of the cited artifact. Also called conflicts of interest or declaration of interests.",
    "system": "http://hl7.org/fhir/contributor-summary-type"
  }
};

const artifactRelationshipTypeLookup = {
  "amended-with": {
    "display": "Amended With",
    "definition": "This artifact is amended with or changed by the target artifact. There is information in this artifact that should be functionally replaced with information in the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "amends": {
    "display": "Amends",
    "definition": "This artifact amends or changes the target artifact. This artifact adds additional information that is functionally expected to replace information in the target artifact. This artifact replaces a part but not all of the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "appended-with": {
    "display": "Appended With",
    "definition": "This artifact has additional information in the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "appends": {
    "display": "Appends",
    "definition": "This artifact adds additional information to the target artifact. The additional information does not replace or change information in the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "cited-by": {
    "display": "Cited By",
    "definition": "This artifact is cited by the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "cites": {
    "display": "Cites",
    "definition": "This artifact cites the target artifact. This may be a bibliographic citation for papers, references, or other relevant material for the knowledge resource. This is intended to allow for citation of related material, but that was not necessarily specifically prepared in connection with this knowledge resource.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "comment-in": {
    "display": "Comment In",
    "definition": "This artifact has comments about it in the target artifact. The type of comments may be expressed in the targetClassifier element such as reply, review, editorial, feedback, solicited, unsolicited, structured, unstructured.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "comments-on": {
    "display": "Comments On",
    "definition": "This artifact contains comments about the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "composed-of": {
    "display": "Composed Of",
    "definition": "This artifact is composed of the target artifact. This artifact is constructed with the target artifact as a component. The target artifact is a part of this artifact. (A dataset is composed of data.).",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "contains": {
    "display": "Contains",
    "definition": "This artifact is a container in which the target artifact is contained. A container is a data structure whose instances are collections of other objects. (A database contains the dataset.).",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "contained-in": {
    "display": "Contained In",
    "definition": "This artifact is contained in the target artifact. The target artifact is a data structure whose instances are collections of other objects.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "corrects": {
    "display": "Corrects",
    "definition": "This artifact identifies errors and replacement content for the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "correction-in": {
    "display": "Correction In",
    "definition": "	This artifact has corrections to it in the target artifact. The target artifact identifies errors and replacement content for this artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "depends-on": {
    "display": "Depends On",
    "definition": "This artifact depends on the target artifact. There is a requirement to use the target artifact in the creation or interpretation of this artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "derived-from": {
    "display": "Derived From",
    "definition": "This artifact is derived from the target artifact. This is intended to capture the relationship in which a particular knowledge resource is based on the content of another artifact, but is modified to capture either a different set of overall requirements, or a more specific set of requirements such as those involved in a particular institution or clinical setting. The artifact may be derived from one or more target artifacts.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "documentation": {
    "display": "Documentation",
    "definition": "Additional documentation for the knowledge resource. This would include additional instructions on usage as well as additional information on clinical context or appropriateness.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "justification": {
    "display": "Justification",
    "definition": "	The target artifact is a summary of the justification for the knowledge resource including supporting evidence, relevant guidelines, or other clinically important information. This information is intended to provide a way to make the justification for the knowledge resource available to the consumer of interventions or results produced by the knowledge resource.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "part-of": {
    "display": "Part Of",
    "definition": "This artifact is a part of the target artifact. The target artifact is composed of this artifact (and possibly other artifacts).",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "predecessor": {
    "display": "Predecessor",
    "definition": "The previous version of the knowledge resource.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "reprint": {
    "display": "Reprint",
    "definition": "A copy of the artifact in a publication with a different artifact identifier.",
    "system": "http://hl7.org/fhir/related-artifact-type-expanded"
  },
  "reprint-of": {
    "display": "Reprint Of",
    "definition": "The original version of record for which the current artifact is a copy.",
    "system": "http://hl7.org/fhir/related-artifact-type-expanded"
  },
  "replaced-with": {
    "display": "Replaced With",
    "definition": "This artifact is replaced with or superseded by the target artifact. This artifact may be considered deprecated.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "replaces": {
    "display": "Replaces",
    "definition": "This artifact replaces or supersedes the target artifact. The target artifact may be considered deprecated.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "retracted-by": {
    "display": "Retracted By",
    "definition": "This artifact is retracted by the target artifact. The content that was published in this artifact should be considered removed from publication and should no longer be considered part of the public record.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "retracts": {
    "display": "Retracts",
    "definition": "This artifact retracts the target artifact. The content that was published in the target artifact should be considered removed from publication and should no longer be considered part of the public record.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "signs": {
    "display": "Signs",
    "definition": "This artifact is a signature of the target artifact.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "similar-to": {
    "display": "Similar To",
    "definition": "	This artifact has characteristics in common with the target artifact. This relationship may be used in systems to “deduplicate” knowledge artifacts from different sources, or in systems to show “similar items”.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "successor": {
    "display": "Successor",
    "definition": "The next version of the knowledge resource.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "supports": {
    "display": "Supports",
    "definition": "This artifact provides additional documentation for the target artifact. This could include additional instructions on usage as well as additional information on clinical context or appropriateness.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "supported-with": {
    "display": "Supported With",
    "definition": "	The target artifact contains additional documentation for the knowledge resource. This could include additional instructions on usage as well as additional information on clinical context or appropriateness.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "transformed-into": {
    "display": "Transformed Into",
    "definition": "This artifact was transformed into the target artifact (e.g., by format or language conversion).",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "transformed-with": {
    "display": "Transformed With",
    "definition": "This artifact was generated by transforming a related artifact (e.g., format or language conversion), noted separately with the “transforms” relationship type. This transformation used the target artifact to inform the transformation. The target artifact may be a conversion script or translation guide.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
  "transforms": {
    "display": "Transforms",
    "definition": "This artifact was generated by transforming the target artifact (e.g., format or language conversion). This is intended to capture the relationship in which a particular knowledge resource is based on the content of another artifact, but changes are only apparent in form and there is only one target artifact with the “transforms” relationship type.",
    "system": "http://hl7.org/fhir/related-artifact-type"
  },
};

const artifactClassifierTypeLookup = {
  "audio": {
    "display": "Audio file",
    "definition": "The article cited is an audio file.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D001877": {
    "display": "Book",
    "definition": "Non-periodical written or printed works consisting of sheets of pages fastened or bound together within covers.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D016420": {
    "display": "Comment",
    "definition": "Comment",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "cds-artifact": {
    "display": "Clinical Decision Support Artifact",
    "definition": "The artifact is used for decision support for healthcare decisions.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "common-share": {
    "display": "Common Share",
    "definition": "Citation Resource containing value added data that is openly shared",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D019991": {
    "display": "Database",
    "definition": "A structured file of information or a set of logically related data stored and retrieved using computer-based means.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D064886": {
    "display": "Dataset",
    "definition": "Works consisting of organized collections of data, which have been stored permanently in a formalized manner suitable for communication, interpretation, or processing.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "dataset-unpublished": {
    "display": "Dataset Unpublished",
    "definition": "An organized collection of data that is not stored permanently for communication.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "Electronic": {
    "display": "Electronic",
    "definition": "the journal is published in electronic format only",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "Electronic-eCollection": {
    "display": "Electronic-eCollection",
    "definition": "used for electronic-only journals that publish individual articles first and then later collect them into an \"issue\" date that is typically called an eCollection.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "Electronic-Print": {
    "display": "Electronic-Print",
    "definition": "the journal is published first in electronic format followed by print (this value is currently used for just one journal, Nucleic Acids Research)",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "executable-app": {
    "display": "Executable app",
    "definition": "Executable app",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "fhir-resource": {
    "display": "FHIR Resource",
    "definition": "The article cited is a FHIR resource.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "image": {
    "display": "Image file",
    "definition": "The article cited is an image file.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "interactive-form": {
    "display": "Interactive Form",
    "definition": "A user interface that supports data entry and data display.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D016428": {
    "display": "Journal Article",
    "definition": "Journal Article",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D016422": {
    "display": "Letter",
    "definition": "Letter",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "machine-code": {
    "display": "Machine code",
    "definition": "The article cited is machine code.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "medline-base": {
    "display": "Medline Base",
    "definition": "Citation Resource containing only data from Medline",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "prediction-model": {
    "display": "Prediction Model",
    "definition": "A formula or expression used to calculate an outcome representing a predicted result.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D000076942": {
    "display": "Preprint",
    "definition": "Scientific manuscript made available prior to PEER REVIEW.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "Print": {
    "display": "Print",
    "definition": "the journal is published in print format only",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "Print-Electronic": {
    "display": "Print Electronic",
    "definition": "the journal is published in both print and electronic format",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "project-specific": {
    "display": "Project Specific",
    "definition": "Citation Resource containing value added data specific to a project",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "protocol": {
    "display": "Protocol",
    "definition": "The article cited is the protocol of an activity and not the results or findings.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "pseudocode": {
    "display": "PseudoCode",
    "definition": "A non-executable, human-readable representation of software code.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D016425": {
    "display": "Published Erratum",
    "definition": "Published Erratum",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "standard-specification": {
    "display": "Standard Specification",
    "definition": "An explicit set of requirements for an item, material, component, system or service, often used to define a technical standard which is an established norm or requirement for a repeatable technical task.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "terminology": {
    "display": "Terminology",
    "definition": "A structured set of codes and display values, which may be subtyped as a code system, value set, taxonomy, or ontology.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "D059040": {
    "display": "Video-Audio Media",
    "definition": "Used with articles which include video files or clips, or for articles which are entirely video.",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  },
  "webpage": {
    "display": "Webpage",
    "definition": "Webpage",
    "system": "http://hl7.org/fhir/citation-artifact-classifier"
  }
};

let recommendationJustificationClassifierLookup = {
  "RJCS-2001": { "display": "Strong expectation", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2002": { "display": "Weak expectation", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2003": { "display": "High certainty", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2004": { "display": "Moderate certainty", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2005": { "display": "Low certainty", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2006": { "display": "Very low certainty", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2007": { "display": "Yes", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2008": { "display": "Probably yes", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2009": { "display": "Probably no", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2010": { "display": "No", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2011": { "display": "Varies", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2012": { "display": "Unknown", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2013": { "display": "Large size", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2014": { "display": "Moderate size", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2015": { "display": "Small size", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2016": { "display": "Trivial size", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2006.0": { "display": "No evidence", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2017": { "display": "Important uncertainty or variability", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2018": { "display": "Possibly important uncertainty or variability", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2019": { "display": "Possibly no important uncertainty or variability", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2020": { "display": "No important uncertainty or variability", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2021": { "display": "Favors intervention", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2022": { "display": "Possibly favors intervention", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2023": { "display": "Does not favor intervention or comparison", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2024": { "display": "Possibly favors comparison", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2025": { "display": "Favors comparison", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2026": { "display": "Large costs", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2027": { "display": "Moderate costs", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2028": { "display": "Negligible costs or savings", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2029": { "display": "Moderate savings", "system": "https://fevir.net/resources/CodeSystem/27834" },
  "RJCS-2030": { "display": "Large savings", "system": "https://fevir.net/resources/CodeSystem/27834" }
};

const recommendationJustificationClassifierOptions = Object.keys(recommendationJustificationClassifierLookup).map((classifier, index) => {
  let classifierObj = {
    key: classifier,
    text: recommendationJustificationClassifierLookup[classifier].display,
    value: classifier,
    definition: recommendationJustificationClassifierLookup[classifier].definition,
    system: recommendationJustificationClassifierLookup[classifier].system
  };
  return classifierObj;
});

const partTypeLookup = {
  "pages": {
    "display": "pages",
    "definition": "Denotes specific page or pages of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "section": {
    "display": "section",
    "definition": "Denotes specific section or sections of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "paragraphs": {
    "display": "paragraphs",
    "definition": "	Denotes specific paragraph or paragraphs of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "lines": {
    "display": "lines",
    "definition": "Denotes specific line or lines of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "tables": {
    "display": "tables",
    "definition": "Denotes specific table or tables of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "figures": {
    "display": "figures",
    "definition": "Denotes specific figure or figures of an article or artifact.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "supplement": {
    "display": "Supplement or Appendix",
    "definition": "Used to denote a supplementary file, appendix, or additional part that is not a subpart of the primary article.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "supplement-subpart": {
    "display": "Supplement or Appendix Subpart",
    "definition": "Used to denote a subpart within a supplementary file or appendix.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
  "article-set": {
    "display": "Part of an article set",
    "definition": "Used to distinguish an individual article within an article set where the article set is a base citation.",
    "system": "http://hl7.org/fhir/cited-artifact-part-type"
  },
};

const summarySourceLookup = {
  "publisher-data": {
    "display": "Publisher provided",
    "definition": "Data copied by machine from publisher data.",
    "system": "http://hl7.org/fhir/contributor-summary-source"
  },
  "article-copy": {
    "display": "Copied from article",
    "definition": "Data copied by human from article text.",
    "system": "http://hl7.org/fhir/contributor-summary-source"
  },
  "citation-manager": {
    "display": "Reported by citation manager",
    "definition": "Data copied by machine from citation manager data.",
    "system": "http://hl7.org/fhir/contributor-summary-source"
  },
  "custom": {
    "display": "Custom format",
    "definition": "Custom format (may be described in text note).",
    "system": "http://hl7.org/fhir/contributor-summary-source"
  }
};

const groupCharacteristicKindOrder = ["64572001", "260905004", "246112005", "397669002", "263495000", "103579009", "186034007", "305335007", "246267002"];
const groupCharacteristicKindLookup = {
  "64572001": {
    "display": "Disease (disorder)",
    "definition": "e.g. COVID-19",
    "system": "http://snomed.info/sct"
  },
  "260905004": {
    "display": "Condition",
    "definition": "e.g. Pregnant",
    "system": "http://snomed.info/sct"
  },
  "246112005": {
    "display": "Severity",
    "definition": "e.g. life threatening severity",
    "system": "http://snomed.info/sct"
  },
  "397669002": {
    "display": "Age",
    "definition": "",
    "system": "http://snomed.info/sct"
  },
  "263495000": {
    "display": "Gender",
    "definition": "",
    "system": "http://snomed.info/sct"
  },
  "103579009": {
    "display": "Race",
    "definition": "",
    "system": "http://snomed.info/sct"
  },
  "186034007": {
    "display": "Ethnicity / related nationality data",
    "definition": "",
    "system": "http://snomed.info/sct"
  },
  "305335007": {
    "display": "Admission to establishment (procedure)",
    "definition": "e.g. admission to hospital",
    "system": "http://snomed.info/sct"
  },
  "246267002": {
    "display": "Location",
    "definition": "e.g. Ireland, or Good Samaritan Clinic",
    "system": ""
  }
};

const contributorRoleLookup = {
  "author": {
    "display": "Author",
    "definition": "Author",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Authors",
    "priority": 1
  },
  "publisher": {
    "display": "Publisher",
    "definition": "Publisher",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Publishers",
    "priority": 7
  },
  "reviewer": {
    "display": "Reviewer",
    "definition": "Reviewer",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Reviewers",
    "priority": 4
  },
  "endorser": {
    "display": "Endorser",
    "definition": "Endorser",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Endorsers",
    "priority": 3
  },
  "editor": {
    "display": "Editor",
    "definition": "Editor",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Editors",
    "priority": 2
  },
  "informant": {
    "display": "Informant",
    "definition": "Informant",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Informants",
    "priority": 5
  },
  "funder": {
    "display": "Funder",
    "definition": "Funder",
    "system": "http://hl7.org/fhir/contributor-role",
    "plural": "Funders",
    "priority": 6
  }
};

const contributionInstanceTypeLookup = {
  "reviewed": {
    "display": "Reviewed",
    "definition": "Reviewed",
    "system": "http://hl7.org/fhir/artifact-contribution-instance-type"
  },
  "approved": {
    "display": "Approved",
    "definition": "Approved",
    "system": "http://hl7.org/fhir/artifact-contribution-instance-type"
  },
  "edited": {
    "display": "Edited",
    "definition": "Edited",
    "system": "http://hl7.org/fhir/artifact-contribution-instance-type"
  }
};


const publishedInTypeLookup = {
  "D020492": {
    "display": "Periodical",
    "definition": "Publication intended to be issued on an ongoing basis, generally more frequently than annually, containing separate articles, stories, or writings.",
    "system": "http://hl7.org/fhir/published-in-type"
  },
  "D019991": {
    "display": "Database",
    "definition": "A structured file of information or a set of logically related data stored and retrieved using computer-based means.",
    "system": "http://hl7.org/fhir/published-in-type"
  },
  "D001877": {
    "display": "Book",
    "definition": "Non-periodical written or printed works consisting of sheets of pages fastened or bound together within covers.",
    "system": "http://hl7.org/fhir/published-in-type"
  },
  "D064886": {
    "display": "Dataset",
    "definition": "Works consisting of organized collections of data, which have been stored permanently in a formalized manner suitable for communication, interpretation, or processing.",
    "system": "http://hl7.org/fhir/published-in-type"
  }
};

const journalIssueMediumLookup = {
  "Internet": {
    "display": "Internet",
    "definition": "NLM JournalIssue CitedMedium code for online version.",
    //"system": "http://terminology.hl7.org/CodeSystem/journal-issue-medium"
    "system": "http://hl7.org/fhir/cited-medium"
  },
  "Print": {
    "display": "Print",
    "definition": "NLM JournalIssue CitedMedium code for print version.",
    //"system": "http://terminology.hl7.org/CodeSystem/journal-issue-medium"
    "system": "http://hl7.org/fhir/cited-medium"
  },
  /*
  "Internet-Without-Issue": {
  "display": "Internet Without Issue (Article Specific Online Publication)",
  "definition": "Used for article specific publication date which could be the same as or different from journal issue publication date.",
  //"system": "http://terminology.hl7.org/CodeSystem/journal-issue-medium"
  "system": "http://hl7.org/fhir/cited-medium"
  }
  */
};

const classificationTypeLookup = {
  "[Undeclared]": {
    "display": "[Undeclared]",
    "definition": "[Undeclared]",
    "system": "[Undeclared]"
  },
  "publication-type": {
    "display": "Publication type",
    "definition": "Publication type",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "mesh-heading": {
    "display": "MeSH heading",
    "definition": "MeSH heading",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "supplemental-mesh-protocol": {
    "display": "Supplemental MeSH for Protocol",
    "definition": "Supplemental MeSH for Protocol",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "supplemental-mesh-disease": {
    "display": "Supplemental MeSH for Disease",
    "definition": "Supplemental MeSH for Disease",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "supplemental-mesh-organism": {
    "display": "Supplemental MeSH for Organism",
    "definition": "Supplemental MeSH for Organism",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "keyword": {
    "display": "Keyword",
    "definition": "Keyword",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "citation-subset": {
    "display": "Citation subset",
    "definition": "Citation subset",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "chemical": {
    "display": "Chemical",
    "definition": "Chemical",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "publishing-model": {
    "display": "Publishing Model",
    "definition": "Used for PubModel attribute in Medline",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  },
  "knowledge-artifact-type": {
    "display": "Knowledge Artifact Type",
    "definition": "Knowledge Artifact Type",
    "system": "http://hl7.org/fhir/cited-artifact-classification-type"
  }
};

const compositionTypeCodeBaselineMeasureReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "BaselineMeasureReport",
    display: "BaselineMeasureReport"
  }],
  text: 'Baseline Measure Report'
};

const compositionTypeCodeComparativeEvidenceReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "ComparativeEvidenceReport",
    display: "ComparativeEvidenceReport"
  }],
  text: 'Comparative Evidence Report'
};

const compositionTypeCodeComparativeEvidenceSynthesisReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "ComparativeEvidenceSynthesisReport",
    display: "ComparativeEvidenceSynthesisReport"
  }],
  text: 'Comparative Evidence Synthesis Report'
};

const compositionTypeCodeEvidenceReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "EvidenceReport",
    display: "EvidenceReport"
  }]
};

const compositionTypeCodeEvidenceReportPackage = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "EvidenceReportPackage",
    display: "EvidenceReportPackage"
  }]
};

const compositionTypeCodeGuideline = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "Guideline",
    display: "Guideline"
  }]
};

const compositionTypeCodeOutcomeMeasureReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "OutcomeMeasureReport",
    display: "OutcomeMeasureReport"
  }],
  text: 'Outcome Measure Report'
};

const compositionTypeCodeOutcomeMeasureSynthesisReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "OutcomeMeasureSynthesisReport",
    display: "OutcomeMeasureSynthesisReport"
  }],
  text: 'Outcome Measure Report'
};

const compositionTypeCodeParticipantFlowReport = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "ParticipantFlowReport",
    display: "ParticipantFlowReport"
  }],
  text: 'Participant Flow Report'
};

const compositionTypeCodeRecommendation = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "Recommendation",
    display: "Recommendation"
  }]
};

const compositionTypeCodeResearchStudyDataDictionary = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "ResearchStudyDataDictionary",
    display: "ResearchStudyDataDictionary"
  }],
  text: 'Research Study Data Dictionary'
};

const compositionTypeCodeSummaryOfFindings = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "SummaryOfFindings",
    display: "SummaryOfFindings"
  }],
  text: 'Summary of Findings'
};

const compositionTypeCodeSummaryOfNetEffect = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "SummaryOfNetEffect",
    display: "SummaryOfNetEffect"
  }],
  text: 'Summary of Net Effect Contributions'
};

const knowledgeArtifactTypeCoding = {
  coding: [
    {
      system: "http://hl7.org/fhir/cited-artifact-classification-type",
      code: "knowledge-artifact-type",
      display: "Knowledge Artifact Type"
    }
  ]
};

const articleUrlTypeLookup = {
  "abstract": {
    "display": "Abstract",
    "definition": "The URL will reach a brief summary for the article.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "full-text": {
    "display": "Full-Text",
    "definition": "The URL will reach the full-text of the article.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "doi-based": {
    "display": "DOI Based",
    "definition": "The URL is derived from the Digital Object Identifier (DOI).",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "version-specific": {
    "display": "Version Specific",
    "definition": "The URL will reach content that is a specific version of the article.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "pdf": {
    "display": "PDF",
    "definition": "The URL will reach content in XML format.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "webpage": {
    "display": "Webpage",
    "definition": "The URL will reach a webpage related to the article, where the content is not easily classified as abstract, full-text or supplement.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "json": {
    "display": "JSON",
    "definition": "The URL will reach content in JSON format.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "xml": {
    "display": "XML",
    "definition": "The URL will reach content in XML format.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "restricted": {
    "display": "Restricted",
    "definition": "The URL content has restricted access (e.g. subcription required).",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "supplement": {
    "display": "Supplement",
    "definition": "The URL will reach a supplement, appendix, or additional supporting information for the article.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "file-directory": {
    "display": "File directory",
    "definition": "The URL will reach a file directory.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "code-repository": {
    "display": "Code repository",
    "definition": "File archive and web hosting facility for source code of software, documentation, web pages, and other works.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "compressed-file": {
    "display": "Compressed file",
    "definition": "Compressed archive file (e.g. a zip file) that contains multiple files",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "computable-resource": {
    "display": "Computable resource",
    "definition": "The URL will reach content that is machine-interpretable.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  },
  "not-specified": {
    "display": "Not Specified",
    "definition": "Used when URL classifier is not specified but expected in a system.",
    "system": "http://hl7.org/fhir/artifact-url-classifier"
  }
};

const contributionTypeLookup = {
  "conceptualization": {
    "display": "Conceptualization",
    "definition": "Conceptualization (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "data-curation": {
    "display": "Data curation",
    "definition": "Data curation (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "formal-analysis": {
    "display": "Formal analysis",
    "definition": "Formal analysis (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "funding-acquisition": {
    "display": "Funding acquisition",
    "definition": "Funding acquisition (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "investigation": {
    "display": "Investigation",
    "definition": "Investigation (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "methodology": {
    "display": "Methodology",
    "definition": "Methodology (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "project-administration": {
    "display": "Project administration",
    "definition": "Project administration (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "resources": {
    "display": "Resources",
    "definition": "Resources (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "software": {
    "display": "Software",
    "definition": "Software (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "supervision": {
    "display": "Supervision",
    "definition": "Supervision (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "validation": {
    "display": "Validation",
    "definition": "Validation (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "visualization": {
    "display": "Visualization",
    "definition": "Visualization (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "writing-original-draft": {
    "display": "Writing - original draft",
    "definition": "Writing - original draft (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  },
  "writing-review-editing": {
    "display": "Writing - review & editing",
    "definition": "Writing - review & editing (see https://jats4r.org/credit-taxonomy).",
    "system": "http://hl7.org/fhir/artifact-contribution-type"
  }
};

const citedArtifactStatusTypeLookup = {
  "submitted": {
    "display": "Submitted",
    "definition": "Submitted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "pre-review": {
    "display": "Pre review",
    "definition": "Pre review",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "under-review": {
    "display": "Under review",
    "definition": "Under review",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "post-review-pre-published": {
    "display": "Post review pre published",
    "definition": "Post review pre published",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "published-early-form": {
    "display": "Published early form",
    "definition": "Published early form",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "published-final-form": {
    "display": "Published final form",
    "definition": "Published final form",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "created": {
    "display": "Created",
    "definition": "Created",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "accepted": {
    "display": "Accepted",
    "definition": "Accepted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "approved": {
    "display": "Approved",
    "definition": "Approved",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "rejected": {
    "display": "Rejected",
    "definition": "Rejected",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "withdrawn": {
    "display": "Withdrawn",
    "definition": "Withdrawn",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "retracted": {
    "display": "Retracted",
    "definition": "Retracted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "draft": {
    "display": "Draft",
    "definition": "Draft",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "active": {
    "display": "Active",
    "definition": "Active",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "archived": {
    "display": "Archived",
    "definition": "Archived",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  }
};

const citationStatusTypeLookup = {
  "submitted": {
    "display": "Submitted",
    "definition": "Submitted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "pre-review": {
    "display": "Pre review",
    "definition": "Pre review",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "under-review": {
    "display": "Under review",
    "definition": "Under review",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "post-review-pre-published": {
    "display": "Post review pre published",
    "definition": "Post review pre published",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "published-early-form": {
    "display": "Published early form",
    "definition": "Published early form",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "published-final-form": {
    "display": "Published final form",
    "definition": "Published final form",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "created": {
    "display": "Created",
    "definition": "Created",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "accepted": {
    "display": "Accepted",
    "definition": "Accepted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "approved": {
    "display": "Approved",
    "definition": "Approved",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "rejected": {
    "display": "Rejected",
    "definition": "Rejected",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "withdrawn": {
    "display": "Withdrawn",
    "definition": "Withdrawn",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "retracted": {
    "display": "Retracted",
    "definition": "Retracted",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "draft": {
    "display": "Draft",
    "definition": "Draft",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "active": {
    "display": "Active",
    "definition": "Active",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "archived": {
    "display": "Archived",
    "definition": "Archived",
    "system": "http://hl7.org/fhir/cited-artifact-status-type"
  },
  "pubmed-pubstatus-received": {
    "display": "PubMed Pubstatus of Received",
    "definition": "PubMed Pubstatus of Received",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-accepted": {
    "display": "PubMed Pubstatus of Accepted",
    "definition": "PubMed Pubstatus of Accepted",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-epublish": {
    "display": "PubMed Pubstatus of Epublish",
    "definition": "PubMed Pubstatus of Epublish",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-ppublish": {
    "display": "PubMed Pubstatus of Ppublish",
    "definition": "PubMed Pubstatus of Ppublish",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-revised": {
    "display": "PubMed Pubstatus of Revised",
    "definition": "PubMed Pubstatus of Revised",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-aheadofprint": {
    "display": "PubMed Pubstatus of aheadofprint",
    "definition": "PubMed Pubstatus of aheadofprint",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-retracted": {
    "display": "PubMed Pubstatus of Retracted",
    "definition": "PubMed Pubstatus of Retracted",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-ecollection": {
    "display": "PubMed Pubstatus of Ecollection",
    "definition": "PubMed Pubstatus of Ecollection",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-pmc": {
    "display": "PubMed Pubstatus of PMC",
    "definition": "PubMed Pubstatus of PMC",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-pmcr": {
    "display": "PubMed Pubstatus of PMCr",
    "definition": "PubMed Pubstatus of PMCr",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-pubmed": {
    "display": "PubMed Pubstatus of PubMed",
    "definition": "PubMed Pubstatus of PubMed",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-pubmedr": {
    "display": "PubMed Pubstatus of PubMedr",
    "definition": "PubMed Pubstatus of PubMedr",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-premedline": {
    "display": "PubMed Pubstatus of Premedline",
    "definition": "PubMed Pubstatus of Premedline",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-medline": {
    "display": "PubMed Pubstatus of Medline",
    "definition": "PubMed Pubstatus of Medline",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-medliner": {
    "display": "PubMed Pubstatus of Medliner",
    "definition": "PubMed Pubstatus of Medliner",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-entrez": {
    "display": "PubMed Pubstatus of Entrez",
    "definition": "PubMed Pubstatus of Entrez",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-pubstatus-pmc-release": {
    "display": "PubMed Pubstatus of PMC release",
    "definition": "PubMed Pubstatus of PMC release",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-completed": {
    "display": "Medline Citation Status of Completed",
    "definition": "Medline Citation Status of Completed",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-in-process": {
    "display": "Medline Citation Status of In-Process",
    "definition": "Medline Citation Status of In-Process",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-pubmed-not-medline": {
    "display": "Medline Citation Status of PubMed-not-MEDLINE",
    "definition": "Medline Citation Status of PubMed-not-MEDLINE",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-in-data-review": {
    "display": "Medline Citation Status of In-Data-Review",
    "definition": "Medline Citation Status of In-Data-Review",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-publisher": {
    "display": "Medline Citation Status of Publisher",
    "definition": "Medline Citation Status of Publisher",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-medline": {
    "display": "Medline Citation Status of MEDLINE",
    "definition": "Medline Citation Status of MEDLINE",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "medline-oldmedline": {
    "display": "Medline Citation Status of OLDMEDLINE",
    "definition": "Medline Citation Status of OLDMEDLINE",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-publication-status-ppublish": {
    "display": "PubMed PublicationStatus of ppublish",
    "definition": "PubMed PublicationStatus of ppublish",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-publication-status-epublish": {
    "display": "PubMed PublicationStatus of epublish",
    "definition": "PubMed PublicationStatus of epublish",
    "system": "http://hl7.org/fhir/citation-status-type"
  },
  "pubmed-publication-status-aheadofprint": {
    "display": "PubMed PublicationStatus of aheadofprint",
    "definition": "PubMed PublicationStatus of aheadofprint",
    "system": "http://hl7.org/fhir/citation-status-type"
  }
};

const evidenceCertaintyTypeLookup = {
  "Overall": {
    "display": "Overall certainty",
    "definition": "Overall certainty of evidence (quality of evidence).",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "RiskOfBias": {
    "display": "Risk of bias",
    "definition": "methodologic concerns reducing internal validity.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "Inconsistency": {
    "display": "Inconsistency",
    "definition": "concerns that findings are not similar enough to support certainty.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "Indirectness": {
    "display": "Indirectness",
    "definition": "concerns reducing external validity.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "Imprecision": {
    "display": "Imprecision",
    "definition": "fuzzy or wide variability.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "PublicationBias": {
    "display": "Publication bias",
    "definition": "likelihood that what is published misrepresents what is available to publish.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "DoseResponseGradient": {
    "display": "Dose response gradient",
    "definition": "higher certainty due to dose response relationship.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "PlausibleConfounding": {
    "display": "Plausible confounding",
    "definition": "higher certainty due to risk of bias in opposite direction.",
    "system": "http://hl7.org/fhir/certainty-type"
  },
  "LargeEffect": {
    "display": "Large effect",
    "definition": "higher certainty due to large effect size.",
    "system": "http://hl7.org/fhir/certainty-type"
  }
};

const evidenceCertaintyRatingLookup = {
  "high": {
    "display": "High quality",
    "definition": "High quality evidence.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "moderate": {
    "display": "Moderate quality",
    "definition": "Moderate quality evidence.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "low": {
    "display": "Low quality",
    "definition": "Low quality evidence.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "very-low": {
    "display": "Very low quality",
    "definition": "Very low quality evidence.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "no-concern": {
    "display": "no serious concern",
    "definition": "no serious concern.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "serious-concern": {
    "display": "serious concern",
    "definition": "serious concern.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "very-serious-concern": {
    "display": "very serious concern",
    "definition": "very serious concern.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "extremely-serious-concern": {
    "display": "extremely serious concern",
    "definition": "extremely serious concern.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "present": {
    "display": "present",
    "definition": "possible reason for increasing quality rating was checked and found to be present.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "absent": {
    "display": "absent",
    "definition": "possible reason for increasing quality rating was checked and found to be absent.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "no-change": {
    "display": "no change to rating",
    "definition": "no change to quality rating.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "downcode1": {
    "display": "reduce rating: -1",
    "definition": "reduce quality rating by 1.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "downcode2": {
    "display": "reduce rating: -2",
    "definition": "reduce quality rating by 2.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "downcode3": {
    "display": "reduce rating: -3",
    "definition": "reduce quality rating by 3.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "upcode1": {
    "display": "increase rating: +1",
    "definition": "increase quality rating by 1.",
    "system": "http://hl7.org/fhir/certainty-rating"
  },
  "upcode2": {
    "display": "increase rating: +2",
    "definition": "increase quality rating by 2.",
    "system": "http://hl7.org/fhir/certainty-rating"
  }
};

const languageLookup = {
  "ar": {
    "display": "Arabic"
  },
  "bn": {
    "display": "Bengali"
  },
  "cs": {
    "display": "Czech"
  },
  "da": {
    "display": "Danish"
  },
  "de": {
    "display": "German"
  },
  "de-AT": {
    "display": "German (Austria)"
  },
  "de-CH": {
    "display": "German (Switzerland)"
  },
  "de-DE": {
    "display": "German (Germany)"
  },
  "el": {
    "display": "Greek"
  },
  "en": {
    "display": "English"
  },
  "en-AU": {
    "display": "English (Australia)"
  },
  "en-CA": {
    "display": "English (Canada)"
  },
  "en-GB": {
    "display": "English (Great Britain)"
  },
  "en-IN": {
    "display": "English (India)"
  },
  "en-NZ": {
    "display": "English (New Zealand)"
  },
  "en-SG": {
    "display": "English (Singapore)"
  },
  "en-US": {
    "display": "English (United States)"
  },
  "es": {
    "display": "Spanish"
  },
  "es-AR": {
    "display": "Spanish (Argentina)"
  },
  "es-ES": {
    "display": "Spanish (Spain)"
  },
  "es-UY": {
    "display": "Spanish (Uruguay)"
  },
  "fi": {
    "display": "Finnish"
  },
  "fr": {
    "display": "French"
  },
  "fr-BE": {
    "display": "French (Belgium)"
  },
  "fr-CH": {
    "display": "French (Switzerland)"
  },
  "fr-FR": {
    "display": "French (France)"
  },
  "fy": {
    "display": "Frysian"
  },
  "fy-NL": {
    "display": "Frysian (Netherlands)"
  },
  "hi": {
    "display": "Hindi"
  },
  "hr": {
    "display": "Croatian"
  },
  "it": {
    "display": "Italian"
  },
  "it-CH": {
    "display": "Italian (Switzerland)"
  },
  "it-IT": {
    "display": "Italian (Italy)"
  },
  "ja": {
    "display": "Japanese"
  },
  "ko": {
    "display": "Korean"
  },
  "nl": {
    "display": "Dutch"
  },
  "nl-BE": {
    "display": "Dutch (Belgium)"
  },
  "nl-NL": {
    "display": "Dutch (Netherlands)"
  },
  "no": {
    "display": "Norwegian"
  },
  "no-NO": {
    "display": "Norwegian (Norway)"
  },
  "pa": {
    "display": "Punjabi"
  },
  "pl": {
    "display": "Polish"
  },
  "pt": {
    "display": "Portuguese"
  },
  "pt-BR": {
    "display": "Portuguese (Brazil)"
  },
  "ru": {
    "display": "Russian"
  },
  "ru-RU": {
    "display": "Russian (Russia)"
  },
  "sr": {
    "display": "Serbian"
  },
  "sr-RS": {
    "display": "Serbian (Serbia)"
  },
  "sv": {
    "display": "Swedish"
  },
  "sv-SE": {
    "display": "Swedish (Sweden)"
  },
  "te": {
    "display": "Telegu"
  },
  "zh": {
    "display": "Chinese"
  },
  "zh-CN": {
    "display": "Chinese (China)"
  },
  "zh-HK": {
    "display": "Chinese (Hong Kong)"
  },
  "zh-SG": {
    "display": "Chinese (Singapore)"
  },
  "zh-TW": {
    "display": "Chinese (Taiwan)"
  }
};

const contributorIdentifierSystemLookup = {
  "https://orcid.org/": { "display": "ORCID", "typeText": "", "assignerDisplay": "", "systemDisabled": true, "typeDisabled": true, "assignerDisabled": true },
}


let identifierDisplayLookup = {};
for (let system in identifierSystemLookup) {
  identifierDisplayLookup[identifierSystemLookup[system].display] = { ...identifierSystemLookup[system], "system": system };
}

const startingIdentifierTypeOptions = Object.keys(identifierSystemLookup).map((typeText, index) => {
  let typeObj = {
    key: identifierSystemLookup[typeText].display,
    text: identifierSystemLookup[typeText].display,
    value: identifierSystemLookup[typeText].display,
    system: typeText
  };
  return typeObj;
});

let contributorIdentifierDisplayLookup = {};
for (let system in contributorIdentifierSystemLookup) {
  contributorIdentifierDisplayLookup[contributorIdentifierSystemLookup[system].display] = { ...contributorIdentifierSystemLookup[system], "system": system };
}

const startingContributorIdentifierTypeOptions = Object.keys(contributorIdentifierSystemLookup).map((typeText, index) => {
  let typeObj = {
    key: contributorIdentifierSystemLookup[typeText].display,
    text: contributorIdentifierSystemLookup[typeText].display,
    value: contributorIdentifierSystemLookup[typeText].display,
    system: typeText
  };
  return typeObj;
});

let publicationFormIdentifierDisplayLookup = {};
for (let system in publicationFormIdentifierSystemLookup) {
  publicationFormIdentifierDisplayLookup[publicationFormIdentifierSystemLookup[system].display] = { ...publicationFormIdentifierSystemLookup[system], "system": system };
}

const startingPublicationFormIdentifierTypeOptions = Object.keys(publicationFormIdentifierSystemLookup).map((typeText, index) => {
  let typeObj = {
    key: publicationFormIdentifierSystemLookup[typeText].display,
    text: publicationFormIdentifierSystemLookup[typeText].display,
    value: publicationFormIdentifierSystemLookup[typeText].display,
    system: typeText
  };
  return typeObj;
});

const startingTitleTypeOptions = Object.keys(titleTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: titleTypeLookup[type].display,
    value: type,
    definition: titleTypeLookup[type].definition,
    system: titleTypeLookup[type].system
  };
  return typeObj;
});

const startingAbstractTypeOptions = Object.keys(abstractTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: abstractTypeLookup[type].display,
    value: type,
    definition: abstractTypeLookup[type].definition,
    system: abstractTypeLookup[type].system
  };
  return typeObj;
});

const startingSummaryTypeOptions = Object.keys(summaryTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: summaryTypeLookup[type].display,
    value: type,
    definition: summaryTypeLookup[type].definition,
    system: summaryTypeLookup[type].system
  };
  return typeObj;
});

const startingArtifactRelationshipTypeOptions = Object.keys(artifactRelationshipTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: artifactRelationshipTypeLookup[type].display,
    value: type,
    definition: artifactRelationshipTypeLookup[type].definition,
    system: artifactRelationshipTypeLookup[type].system
  };
  return typeObj;
});

const startingArtifactClassifierTypeOptions = Object.keys(artifactClassifierTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: artifactClassifierTypeLookup[type].display,
    value: type,
    definition: artifactClassifierTypeLookup[type].definition,
    system: artifactClassifierTypeLookup[type].system
  };
  return typeObj;
});

const startingPartTypeOptions = Object.keys(partTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: partTypeLookup[type].display,
    value: type,
    definition: partTypeLookup[type].definition,
    system: partTypeLookup[type].system
  };
  return typeObj;
});

const startingSummarySourceOptions = Object.keys(summarySourceLookup).map((source, index) => {
  let sourceObj = {
    key: source,
    text: summarySourceLookup[source].display,
    value: source,
    definition: summarySourceLookup[source].definition,
    system: summarySourceLookup[source].system
  };
  return sourceObj;
});

const startingContributorRoleOptions = Object.keys(contributorRoleLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: contributorRoleLookup[type].display,
    value: type,
    definition: contributorRoleLookup[type].definition,
    system: contributorRoleLookup[type].system,
    plural: contributorRoleLookup[type].plural,
    priority: contributorRoleLookup[type].priority
  };
  return typeObj;
});

const startingContributionInstanceTypeOptions = Object.keys(contributionInstanceTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: contributionInstanceTypeLookup[type].display,
    value: type,
    definition: contributionInstanceTypeLookup[type].definition,
    system: contributionInstanceTypeLookup[type].system
  };
  return typeObj;
});

const startingPublishedInTypeOptions = Object.keys(publishedInTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: publishedInTypeLookup[type].display,
    value: type,
    definition: publishedInTypeLookup[type].definition,
    system: publishedInTypeLookup[type].system
  };
  return typeObj;
});

const startingJournalIssueMediumOptions = Object.keys(journalIssueMediumLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: journalIssueMediumLookup[type].display,
    value: type,
    definition: journalIssueMediumLookup[type].definition,
    system: journalIssueMediumLookup[type].system
  };
  return typeObj;
});

const startingClassificationTypeOptions = Object.keys(classificationTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: classificationTypeLookup[type].display,
    value: type,
    definition: classificationTypeLookup[type].definition,
    system: classificationTypeLookup[type].system
  };
  return typeObj;
});

const startingArticleUrlTypeOptions = Object.keys(articleUrlTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: articleUrlTypeLookup[type].display,
    value: type,
    definition: articleUrlTypeLookup[type].definition,
    system: articleUrlTypeLookup[type].system
  };
  return typeObj;
});

const startingContributionTypeOptions = Object.keys(contributionTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: contributionTypeLookup[type].display,
    value: type,
    definition: contributionTypeLookup[type].definition,
    system: contributionTypeLookup[type].system
  };
  return typeObj;
});

const startingCitedArtifactStatusTypeOptions = Object.keys(citedArtifactStatusTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: citedArtifactStatusTypeLookup[type].display,
    value: type,
    definition: citedArtifactStatusTypeLookup[type].definition,
    system: citedArtifactStatusTypeLookup[type].system
  };
  return typeObj;
});

let citationStatusTypeDisplayLookup = {};
for (let code in citationStatusTypeLookup) {
  citationStatusTypeDisplayLookup[citationStatusTypeLookup[code].display] = { ...citationStatusTypeLookup[code], "code": code };
}

const startingCitationStatusTypeOptions = Object.keys(citationStatusTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: citationStatusTypeLookup[type].display,
    value: type,
    definition: citationStatusTypeLookup[type].definition,
    system: citationStatusTypeLookup[type].system
  };
  return typeObj;
});

const startingEvidenceCertaintyTypeOptions = Object.keys(evidenceCertaintyTypeLookup).map((type, index) => {
  let typeObj = {
    key: type,
    text: evidenceCertaintyTypeLookup[type].display,
    value: type,
    definition: evidenceCertaintyTypeLookup[type].definition,
    system: evidenceCertaintyTypeLookup[type].system
  };
  return typeObj;
});

const startingEvidenceCertaintyRatingOptions = Object.keys(evidenceCertaintyRatingLookup).map((rating, index) => {
  let ratingObj = {
    key: rating,
    text: evidenceCertaintyRatingLookup[rating].display,
    value: rating,
    definition: evidenceCertaintyRatingLookup[rating].definition,
    system: evidenceCertaintyRatingLookup[rating].system
  };
  return ratingObj;
});

const startingLanguageOptions = Object.keys(languageLookup).map((language, index) => {
  let languageObj = {
    key: language,
    text: languageLookup[language].display,
    value: language
  };
  return languageObj;
});

const sectionCodeAcceptability = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "acceptability",
      "display": "Acceptability"
    }
  ]
};

const sectionCodeAcknowledgements = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "acknowledgements",
    display: "Acknowledgements"
  }]
};

const sectionCodeAction = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "action",
    display: "Action"
  }]
};

const sectionCodeAppendices = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "appendices",
    display: "Appendices"
  }]
};

const sectionCodeAssertion = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "assertion",
    display: "Assertion"
  }]
};

const sectionCodeBaselineMeasures = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "baseline-measures",
    display: "Baseline Measures"
  }]
};

const sectionCodeCertaintyOfEvidence = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "certainty-of-evidence",
    display: "Certainty of Evidence"
  }]
};

const sectionCodeClassifier = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "classifier",
    display: "Classifier"
  }]
};

const sectionCodeColumnHeaders = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "column-headers",
    display: "Column Headers"
  }]
};

const sectionCodeComparator = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "comparator",
    display: "Comparator"
  }]
};

const sectionCodeComparatorDescription = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "comparator-description",
    display: "Comparator Description"
  }]
};

const sectionCodeComparatorGroup = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "comparator-group",
    display: "Comparator Group"
  }]
};

const sectionCodeCompetingInterests = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "competing-interests",
    display: "Competing Interests"
  }]
};

const sectionCodeConsiderations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "considerations",
    display: "Considerations"
  }]
};

const sectionCodeConsiderationsSummary = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "summary",
    display: "Summary"
  }],
  "text": "Considerations Summary"
};

const sectionCodeCostEffectiveness = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "cost-effectiveness",
      "display": "Cost-effectiveness"
    }
  ]
};

const sectionCodeCosts = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "costs",
      "display": "Costs"
    }
  ]
};

const sectionCodeDesirableEffects = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "desirable-effects",
      "display": "Desirable Effects"
    }
  ]
};

const sectionCodeDirectionOfRecommendation = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "direction-of-recommendation",
      "display": "Direction of Recommendation"
    }
  ]
};

const sectionCodeDiscussion = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "discussion",
    display: "Discussion"
  }]
};

const sectionCodeEquity = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "equity",
      "display": "Equity"
    }
  ]
};

const sectionCodeEvidence = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "evidence",
    display: "Evidence"
  }]
};

const sectionCodeEvidenceWithComparatorAlone = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "evidence-with-comparator-alone",
    display: "Evidence with comparator alone"
  }]
};

const sectionCodeEvidenceWithInterventionAlone = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "evidence-with-intervention-alone",
    display: "Evidence with intervention alone"
  }]
};

const sectionCodeEvidenceWithInterventionVsComparator = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "evidence-with-intervention-vs-comparator",
    display: "Evidence with intervention vs. comparator"
  }]
};

const sectionCodeEvidenceWithTotalGroup = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "evidence-with-total-group",
    display: "Evidence with total group"
  }]
};

const sectionCodeFeasibility = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "feasibility",
      "display": "Feasibility"
    }
  ]
};

const sectionCodeGroupAssignment = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "GroupAssignment",
    display: "GroupAssignment"
  }]
};

const sectionCodeGroups = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "groups",
    display: "Groups"
  }]
};

const sectionCodeImplementationConsiderations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "implementation-considerations",
    display: "Implementation Considerations"
  }]
};

const sectionCodeIntervention = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "intervention",
    display: "Intervention"
  }]
};

const sectionCodeInterventionDescription = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "intervention-description",
    display: "Intervention Description"
  }]
};

const sectionCodeInterventionGroup = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "intervention-group",
    display: "Intervention Group"
  }]
};

const sectionCodeIntroduction = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "introduction",
    display: "Introduction"
  }]
};

const sectionCodeJudgments = {
  "coding": [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "judgments", 
    display: "Judgments"
  }]
};

const sectionCodeJustification = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "justification",
    display: "Justification"
  }]
};

const sectionCodeMethods = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "methods",
    display: "Methods"
  }]
};

const sectionCodeMonitoringConsiderations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "monitoring-considerations",
    display: "Monitoring Considerations"
  }]
};

const sectionCodeNetEffect = {
  "coding": [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "net-effect", 
    display: "Net Effect"
  }]
};

const sectionCodeNetEffectContribution = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "net-effect-contribution",
      "display": "Net Effect Contribution"
    }
  ]
};

const sectionCodeOppositeAction = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "opposite-action",
    display: "Opposite Action"
  }]
};

const sectionCodeOutcomeDesirability = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "outcome-desirability",
      "display": "Outcome Desirability"
    }
  ]
};

const sectionCodeOutcomeMeasure = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "outcome-measure",
    display: "Outcome Measure"
  }]
};

const sectionCodeOutcomeMeasures = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "outcome-measures",
    display: "Outcome Measures"
  }],
  "text": "Outcomes"
};

const sectionCodeParticipantFlow = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "participant-flow",
    display: "Participant Flow"
  }],
  "text": "Participant Flow"
};

const sectionCodePopulation = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "population",
    display: "Population"
  }]
};

const sectionCodePreferences = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "preferences",
      "display": "Preferences"
    }
  ]
};

const sectionCodeProblemImportance = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "problem-importance",
      "display": "Problem Importance"
    }
  ]
};

const sectionCodeRatings = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "ratings",
    display: "Ratings"
  }]
};

const sectionCodeRatingSystem = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "rating-system",
      "display": "Rating System"
    }
  ]
};

const sectionCodeRecommendations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "recommendations",
    display: "Recommendations"
  }]
};

const sectionCodeRecommendationSpecification = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "recommendation-specification",
    display: "Recommendation Specification"
  }]
};

const sectionCodeRecommendationStatement = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "recommendation-statement",
    display: "Recommendation Statement"
  }]
};

const sectionCodeReferences = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "references",
    display: "References"
  }]
};

const sectionCodeRelativeImportance = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "relative-importance",
      "display": "Relative Importance"
    }
  ],
  "text": "relative importance of outcome"
};

const sectionCodeResearchConsiderations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "research-considerations",
    display: "Research Considerations"
  }]
};

const sectionCodeResearchStudy = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "research-study",
    display: "Research Study"
  }]
};

const sectionCodeResults = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "results",
    display: "Results"
  }]
};

const sectionCodeResultWithComparatorAlone = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "result-with-comparator-alone",
    display: "Result with comparator alone"
  }]
};

const sectionCodeResultWithInterventionAlone = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "result-with-intervention-alone",
    display: "Result with intervention alone"
  }]
};

const sectionCodeResultWithInterventionAloneCalculated = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "result-with-intervention-alone-calculated",
    display: "Result with intervention alone (calculated)"
  }],
  "text": "result with intervention with calculated value derived from the control group and effect estimate"
};

const sectionCodeResultWithInterventionVsComparator = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "result-with-intervention-vs-comparator",
    display: "Result with intervention vs. comparator"
  }]
};

const sectionCodeSampleSize = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "sample-size",
    display: "Sample Size"
  }]
};

const sectionCodeStrengthOfRecommendation = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "strength-of-recommendation",
      "display": "Strength of Recommendation"
    }
  ]
};

const sectionCodeSubgroup = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "subgroup",
      "display": "Subgroup"
    }
  ]
};

const sectionCodeSubgroupConsiderations = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "subgroup-considerations",
    display: "Subgroup Considerations"
  }]
};

const sectionCodeSummary = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "summary",
    display: "Summary"
  }]
};

const sectionCodeSummaryOfFindings = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "summary-of-findings",
    display: "Summary of Findings"
  }]
};

const sectionCodeSummaryOfFindingsForSingleOutcome = {
  "coding": [{
    "system": "https://fevir.net/resources/CodeSystem/179423",
    "code": "summary-of-findings-entry-for-a-single-outcome",
    "display": "Summary of findings entry for a single outcome"
  }]
};

const sectionCodeUndesirableEffects = {
  "coding": [
    {
      "system": "https://fevir.net/resources/CodeSystem/179423",
      "code": "undesirable-effects",
      "display": "Undesirable Effects"
    }
  ]
};

const sectionCodeVariables = {
  coding: [{
    system: "https://fevir.net/resources/CodeSystem/179423",
    code: "variables",
    display: "Variables"
  }]
};

const usageContextCodeEvidenceCommunication = {
  "system": "https://fevir.net/resources/CodeSystem/179423",
  "code": "evidence-communication",
  "display": "Evidence Communication"
};

export {
  unlimitedStyleTypes, customStyleNames, generatedSummaryTexts, monthLookup, emptyTextNoData,
  emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet, identifierSystemLookup, covidSMRdiseaseValueSet, characteristicValuePresetOptions,
  publicationFormIdentifierSystemLookup, titleTypeLookup, abstractTypeLookup,
  recommendationJustificationClassifierLookup, recommendationJustificationClassifierOptions,
  summaryTypeLookup, artifactRelationshipTypeLookup, artifactClassifierTypeLookup, partTypeLookup, summarySourceLookup,
  groupCharacteristicKindLookup, contributorRoleLookup, contributionInstanceTypeLookup, publishedInTypeLookup,
  journalIssueMediumLookup, classificationTypeLookup, articleUrlTypeLookup, contributionTypeLookup,
  citedArtifactStatusTypeLookup, citationStatusTypeLookup, evidenceCertaintyTypeLookup, evidenceCertaintyRatingLookup, languageLookup, contributorIdentifierSystemLookup,
  identifierDisplayLookup, startingIdentifierTypeOptions, contributorIdentifierDisplayLookup, startingContributorIdentifierTypeOptions,
  publicationFormIdentifierDisplayLookup, startingPublicationFormIdentifierTypeOptions, startingTitleTypeOptions, startingAbstractTypeOptions,
  startingSummaryTypeOptions, startingArtifactRelationshipTypeOptions, startingArtifactClassifierTypeOptions, startingPartTypeOptions,
  startingSummarySourceOptions, startingContributorRoleOptions,
  startingContributionInstanceTypeOptions, startingPublishedInTypeOptions, startingJournalIssueMediumOptions,
  startingClassificationTypeOptions, startingArticleUrlTypeOptions, startingContributionTypeOptions, startingCitedArtifactStatusTypeOptions,
  citationStatusTypeDisplayLookup, startingCitationStatusTypeOptions, startingEvidenceCertaintyTypeOptions,
  startingEvidenceCertaintyRatingOptions, startingLanguageOptions,
  compositionTypeCodeBaselineMeasureReport,
  compositionTypeCodeComparativeEvidenceReport, compositionTypeCodeComparativeEvidenceSynthesisReport,
  compositionTypeCodeEvidenceReport, compositionTypeCodeEvidenceReportPackage,
  compositionTypeCodeGuideline, compositionTypeCodeOutcomeMeasureReport, 
  compositionTypeCodeOutcomeMeasureSynthesisReport, compositionTypeCodeParticipantFlowReport,
  compositionTypeCodeRecommendation, compositionTypeCodeResearchStudyDataDictionary,
  compositionTypeCodeSummaryOfFindings, compositionTypeCodeSummaryOfNetEffect,
  knowledgeArtifactTypeCoding, 
  sectionCodeAcceptability,
  sectionCodeAcknowledgements, sectionCodeAction, sectionCodeAppendices, sectionCodeAssertion,
  sectionCodeBaselineMeasures, sectionCodeCertaintyOfEvidence,
  sectionCodeClassifier, sectionCodeColumnHeaders,
  sectionCodeComparator, sectionCodeComparatorDescription, sectionCodeComparatorGroup, sectionCodeCompetingInterests,
  sectionCodeConsiderations, sectionCodeConsiderationsSummary, sectionCodeCostEffectiveness, sectionCodeCosts,
  sectionCodeDesirableEffects, sectionCodeDirectionOfRecommendation, sectionCodeDiscussion, sectionCodeEquity, 
  sectionCodeEvidence,
  sectionCodeEvidenceWithComparatorAlone, sectionCodeEvidenceWithInterventionAlone,
  sectionCodeEvidenceWithInterventionVsComparator, sectionCodeEvidenceWithTotalGroup, sectionCodeFeasibility,
  sectionCodeGroupAssignment, sectionCodeGroups, sectionCodeImplementationConsiderations,
  sectionCodeIntervention, sectionCodeInterventionDescription, sectionCodeInterventionGroup, 
  sectionCodeIntroduction, sectionCodeJudgments, sectionCodeJustification, sectionCodeMethods, 
  sectionCodeMonitoringConsiderations, sectionCodeNetEffect, sectionCodeNetEffectContribution,
  sectionCodeOppositeAction, sectionCodeOutcomeDesirability,
  sectionCodeOutcomeMeasure, sectionCodeOutcomeMeasures, sectionCodeParticipantFlow, sectionCodePopulation, 
  sectionCodePreferences, sectionCodeProblemImportance, sectionCodeRatings, sectionCodeRatingSystem,
  sectionCodeRecommendations, sectionCodeRecommendationSpecification, sectionCodeRecommendationStatement,
  sectionCodeReferences, sectionCodeRelativeImportance, sectionCodeResearchConsiderations, sectionCodeResearchStudy, sectionCodeResults,
  sectionCodeResultWithComparatorAlone, sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionAloneCalculated,
  sectionCodeResultWithInterventionVsComparator, sectionCodeSampleSize, sectionCodeStrengthOfRecommendation,
  sectionCodeSubgroup, sectionCodeSubgroupConsiderations,
  sectionCodeSummary, sectionCodeSummaryOfFindings, sectionCodeSummaryOfFindingsForSingleOutcome, 
  sectionCodeUndesirableEffects, sectionCodeVariables, usageContextCodeEvidenceCommunication
};