import React, { useEffect, useContext, useState, useImperativeHandle } from 'react';
import { emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet, sectionCodeSummary, sectionCodeIntroduction, sectionCodeDiscussion, sectionCodeMethods, sectionCodeReferences, sectionCodeCompetingInterests, sectionCodeAcknowledgements, sectionCodeAppendices, sectionCodeRecommendationSpecification, sectionCodeRecommendationStatement, sectionCodeRatings, sectionCodePopulation, sectionCodeAction, sectionCodeOppositeAction, sectionCodeEvidence, sectionCodeJustification, sectionCodeConsiderations, sectionCodeConsiderationsSummary, sectionCodeSubgroupConsiderations, sectionCodeImplementationConsiderations, sectionCodeMonitoringConsiderations, sectionCodeResearchConsiderations, compositionTypeCodeRecommendation, emptyTextNoData } from './CodeSystemLookup';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { generateRecommendationJustificationNarrative } from './RecommendationJustificationEdit';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { generateGroupSummary } from './GenerateNarrativeSummaryFunctions';
import FevirContext from './FevirContext';
import { DataEntry } from './DataEntryFormFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import { getFoiFromReference } from './ResourceDictionaryFunctions';
import { DisplayFromFHIR } from './ResourceFunctions';
import { Button } from 'semantic-ui-react';
import { EditTheSectionButton } from './NavigationCompositionSectionSegment';

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": sectionCodeSummary,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": sectionCodeIntroduction,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": sectionCodeDiscussion,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": sectionCodeMethods,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyReferences = {
    "title": "References Section",
    "code": sectionCodeReferences,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": sectionCodeCompetingInterests,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": sectionCodeAcknowledgements,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": sectionCodeAppendices,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyRecommendation = {
    "title": "Recommendation Specification",
    "code": sectionCodeRecommendationSpecification,
    "section": [
      {
        "title": "Recommendation Statement",
        "code": sectionCodeRecommendationStatement,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Recommendation Ratings",
        "code": sectionCodeRatings,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Population",
        "code": sectionCodePopulation,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Action",
        "code": sectionCodeAction,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Opposite Action",
        "code": sectionCodeOppositeAction,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  let emptyEvidence = {
    "title": "Evidence",
    "code": sectionCodeEvidence,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyJustification = {
    "title": "Justification",
    "code": sectionCodeJustification,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyConsiderations = {
    "title": "Considerations",
    "code": sectionCodeConsiderations,
    "text": emptyTextNoData,
    "section": [
      {
        "title": "Overall Considerations Summary",
        "code": sectionCodeConsiderationsSummary,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Subgroup Considerations",
        "code": sectionCodeSubgroupConsiderations,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Implementation Considerations",
        "code": sectionCodeImplementationConsiderations,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Monitoring Considerations",
        "code": sectionCodeMonitoringConsiderations,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      },
      {
        "title": "Research Considerations",
        "code": sectionCodeResearchConsiderations,
        "text": emptyTextNoData,
        "emptyReason": emptyReasonNotStarted
      }
    ]
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendation: emptyRecommendation,
    evidence: emptyEvidence,
    justification: emptyJustification,
    considerations: emptyConsiderations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode === "recommendation-specification" || sectionCode === "Recommendation Specification") {
      sectionDictionary.recommendation = section;
    } else if (sectionCode === "evidence" || sectionCode === "Evidence") {
      sectionDictionary.evidence = section;
    } else if (sectionCode === "justification" || sectionCode === "Justification" || sectionCode === "judgments" || sectionCode === "Judgments") {
      sectionDictionary.justification = section;
    } else if (sectionCode === "considerations" || sectionCode === "Considerations") {
      sectionDictionary.considerations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.recommendation,
    sectionDictionary.justification,
    sectionDictionary.considerations,
    sectionDictionary.methods,
    sectionDictionary.evidence,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray.length !== reorderedSectionArray.length) {
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const RecommendationAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded, history, setFhirEntryState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let defaultType = compositionTypeCodeRecommendation;

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  const EditSectionViaSectionDetail = ({ sectionNumber, sectionTitle }) => {
    return <div style={{ marginLeft: "24px" }}>
      <EditTheSectionButton theThis="the" sectionName={sectionTitle}
        sectionSelected={resourceState.section[sectionNumber]} sectionPathIndexes={[sectionNumber]}
        resourceStateId={resourceState.id} history={history}
        setFhirEntryState={setFhirEntryState} />
      <p><b>Section Title: </b>{resourceState.section[sectionNumber].title}</p>
      <p>Narrative Status: {resourceState.section[sectionNumber].text?.status || "empty"}</p>
      <DisplayFromFHIR xhtml={resourceState.section[sectionNumber].text?.div || null} />
      <br />
      {(Array.isArray(resourceState.section[sectionNumber].author) && resourceState.section[sectionNumber].author.length > 0) &&
        resourceState.section[sectionNumber].author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {resourceState.section[sectionNumber].orderedBy && <span>
        <b>Ordered by: </b>
        <DisplayFromFHIR codeableConcept={resourceState.section[sectionNumber].orderedBy} /><br />
      </span>}
      {((Array.isArray(resourceState.section[sectionNumber].entry) && resourceState.section[sectionNumber].entry.length > 0) ||
        (Array.isArray(resourceState.section[sectionNumber].section) && resourceState.section[sectionNumber].section.length > 0)) &&
        <div>
          <p><b>Section Content</b></p>
          <div style={{ marginLeft: "24px" }}>
            {(Array.isArray(resourceState.section[sectionNumber].entry) && resourceState.section[sectionNumber].entry.length > 0) &&
              resourceState.section[sectionNumber].entry.map((entry, entryIndex) => {
                return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
              })}
            {(Array.isArray(resourceState.section[sectionNumber].section) && resourceState.section[sectionNumber].section.length > 0) &&
              resourceState.section[sectionNumber].section.map((section, sectionIndex) => {
                return <div key={sectionIndex}>
                  <p><b>Section {sectionIndex + 1}: </b>{section.title}</p>
                  <p>Narrative Status: {section.text?.status || "empty"}</p>
                  <DisplayFromFHIR xhtml={section.text?.div || null} />
                  {(Array.isArray(section.entry) && section.entry.length > 0) &&
                    section.entry.map((entry, entryIndex) => {
                      return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                    })}
                  <br />
                  {(Array.isArray(section.section) && section.section.length > 0) && <div>
                    <p>Contained sections:</p>
                    <div style={{ marginLeft: "24px" }}>
                      {section.section.map((sect, sectIndex) => {
                        return <div key={sectIndex}>
                          <p>
                            <b>Section {sectIndex + 1}: </b>
                            {sect.title || sect.code?.text || sect.code?.coding?.[0]?.display || "Untitled section"}
                          </p>
                        </div>;
                      })}
                    </div>
                    <br />
                  </div>
                  }
                  <EditTheSectionButton theThis="this"
                    sectionName={section.title || sectionTitle + " section " + (sectionIndex + 1)}
                    sectionSelected={resourceState.section[sectionNumber].section[sectionIndex]}
                    sectionPathIndexes={[sectionNumber, sectionIndex]}
                    resourceStateId={resourceState.id} history={history}
                    setFhirEntryState={setFhirEntryState} />
                  <p>-----------------</p>
                </div>;
              })}
          </div>
        </div>
      }
      {resourceState.section[sectionNumber].emptyReason && <span>
        <b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={resourceState.section[sectionNumber].emptyReason} /><br />
      </span>}
      <EditTheSectionButton theThis="this" sectionName={sectionTitle}
        sectionSelected={resourceState.section[sectionNumber]} sectionPathIndexes={[sectionNumber]}
        resourceStateId={resourceState.id} history={history}
        setFhirEntryState={setFhirEntryState} />
    </div>
  }

  return <>{(sourceJsonState.loaded && resourceState.section?.length > 11) ?
    <div style={{ marginTop: "12px" }}>
      {adaptationReportState?.adaptOn ?
        <>
          <h3 id="summary">Summary</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={0}
              fieldLabel="Summary" sectionCode="section[0]:https://fevir.net/resources/CodeSystem/179423#summary"
              startingValue={resourceState.section[0] || null}
              fixedTitle={"Summary"} fixedCode={sectionCodeSummary} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
              noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="recommendation">Recommendation</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={2}
              startingValue={resourceState.section[2] || null}
              fieldLabel="Recommendation"
              sectionCode={"section[2]:https://fevir.net/resources/CodeSystem/179423#recommendation-specification"}
              fixedTitle={"Recommendation Specification"} fixedCode={sectionCodeRecommendationSpecification}
              startCollapsed editTextDiv={false} editAuthor={true}
              noEntry={true} editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              editSection={true}
              editableSectionCodes={false} addSectionAllowed={false}
              allowedSectionCodes={[
                sectionCodeRecommendationStatement,
                sectionCodeRatings,
                sectionCodePopulation,
                sectionCodeAction,
                sectionCodeOppositeAction
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"recommendation-statement","display":"Recommendation Statement"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Recommendation Statement",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"ratings","display":"Ratings"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Ratings (Strength/Level) of Recommendation",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryReferencedResourceTypes': ["ArtifactAssessment"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                  'entrySetProfile': "RecommendationJustification",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"population","display":"Population"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Population",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'generateTextDivFunction': generateGroupSummary,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryReferencedResourceTypes': ["Group"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "Group",
                  'entrySetProfile': "RecommendationEligibilityCriteria",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"action","display":"Action"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Action",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"opposite-action","display":"Opposite Action"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Opposite Action",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryReferencedResourceTypes': ["ActivityDefinition", "EvidenceVariable"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'noSection': true
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <h3 id="justification">Justification</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={3}
              startingValue={resourceState.section[3] || null}
              fieldLabel="Justification"
              sectionCode={"section[3]:https://fevir.net/resources/CodeSystem/179423#justification"}
              fixedTitle={"Justification"} fixedCode={sectionCodeJustification}
              startCollapsed editTextDiv={true} textDeletable={true} editAuthor={true}
              generateNarrativeFromSingleEntryFunction={generateRecommendationJustificationNarrative}
              resourceDictionary={sourceJsonState.resourceDictionary}
              emptyTextDivValue="[No data.]"
              entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
              entryReferencedResourceTypes={["ArtifactAssessment"]} entryStartCollapsed
              entryStartEmptyArrayClosed entryStartingResourceType="ArtifactAssessment"
              entrySetProfile="RecommendationJustification"
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <h3 id="considerations">Considerations</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={4}
              startingValue={resourceState.section[4] || null}
              fieldLabel="Considerations"
              sectionCode={"section[4]:https://fevir.net/resources/CodeSystem/179423#considerations"}
              fixedTitle={"Considerations"} fixedCode={sectionCodeConsiderations}
              startCollapsed editTextDiv={false} editAuthor={true}
              noEntry={true} editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              allowedSectionCodes={[
                sectionCodeConsiderationsSummary,
                sectionCodeSubgroupConsiderations,
                sectionCodeImplementationConsiderations,
                sectionCodeMonitoringConsiderations,
                sectionCodeCompetingInterests,
                sectionCodeResearchConsiderations
              ]}
              sectionDictionary={{
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"summary","display":"Summary"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Overall Considerations Summary",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"subgroup-considerations","display":"Subgroup Considerations"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Subgroup Considerations",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"implementation-considerations","display":"Implementation Considerations"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Implementation Considerations",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"monitoring-considerations","display":"Monitoring Considerations"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Monitoring Considerations",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"competing-interests","display":"Competing Interests"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Competing Interests",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'entryDeletable': true, 'entryInstanceDeletable': true, 'entryEnableCreation': true,
                  'entryReferencedResourceTypes': ["ArtifactAssessment", "Composition"], 'entryStartCollapsed': true,
                  'entryStartEmptyArrayClosed': true, 'entryStartingResourceType': "ArtifactAssessment",
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                },
                '{"coding":[{"system":"https://fevir.net/resources/CodeSystem/179423","code":"research-considerations","display":"Research Considerations"}]}': {
                  'startCollapsed': false,
                  'fixedTitle': "Research Considerations",
                  'editTextStatus': false, 'editTextDiv': true, 'textDeletable': true,
                  'emptyTextDivValue': "[No data.]",
                  'noEntry': true,
                  'editEmptyReason': true, 'emptyReasonValueSet': emptyReasonValueSet, 'emptyReasonCodeableConceptLevelValueSet': emptyReasonCodeableConceptSet,
                  'emptyReasonStartCollapsed': true, 'editSection': true, 'addSectionAllowed': true
                }
              }}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <EditMethods sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="evidence">Evidence</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={6}
              startingValue={resourceState.section[6] || null}
              fieldLabel="Evidence"
              sectionCode={"section[6]:https://fevir.net/resources/CodeSystem/179423#evidence"}
              fixedTitle={"Evidence"} fixedCode={sectionCodeEvidence}
              editTextDiv={true} textDeletable={true} editAuthor={true}
              emptyTextDivValue="[No data.]"
              entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
              entryReferencedResourceTypes={["ArtifactAssessment", "Composition", "Evidence"]}
              entryStartCollapsed={true} entryStartEmptyArrayClosed={true}
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <EditDiscussion sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditReferences sectionIndex={8} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditCompetingInterests sectionIndex={9} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAcknowledgements sectionIndex={10} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAppendices sectionIndex={11} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>
        :
        <>
          <h3 id="summary">Summary</h3>
          <EditSectionViaSectionDetail sectionNumber={0} sectionTitle={"Summary"} />
          <>
            <h3 id="introduction">Introduction</h3>
            <EditSectionViaSectionDetail sectionNumber={1} sectionTitle={"Introduction"} />
          </>
          <h3 id="recommendation">Recommendation</h3>
          <EditSectionViaSectionDetail sectionNumber={2} sectionTitle={"Recommendation"} />
          <h3 id="justification">Justification</h3>
          <EditSectionViaSectionDetail sectionNumber={3} sectionTitle={"Justification"} />
          <h3 id="considerations">Considerations</h3>
          <EditSectionViaSectionDetail sectionNumber={4} sectionTitle={"Considerations"} />
          <>
            <h3 id="methods">Methods</h3>
            <EditSectionViaSectionDetail sectionNumber={5} sectionTitle={"Methods"} />
          </>
          <h3 id="evidence">Evidence</h3>
          <EditSectionViaSectionDetail sectionNumber={6} sectionTitle={"Evidence"} />
          <>
            <h3 id="discussion">Discussion</h3>
            <EditSectionViaSectionDetail sectionNumber={7} sectionTitle={"Discussion"} />
          </>
          <>
            <h3 id="references">References</h3>
            <EditSectionViaSectionDetail sectionNumber={8} sectionTitle={"References"} />
          </>
          <>
            <h3 id="competing-interests">Competing Interests</h3>
            <EditSectionViaSectionDetail sectionNumber={9} sectionTitle={"Competing Interests"} />
          </>
          <>
            <h3 id="acknowledgements">Acknowledgements</h3>
            <EditSectionViaSectionDetail sectionNumber={10} sectionTitle={"Acknowledgements"} />
          </>
          <>
            <h3 id="appendices">Appendices</h3>
            <EditSectionViaSectionDetail sectionNumber={11} sectionTitle={"Appendices"} />
          </>
        </>
      }
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};


const RecommendationSpecificationEdit = ({ section, formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;

  let startingSection = {
    title: "Recommendation Specification", code: sectionCodeRecommendationSpecification,
    author: [], section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.section) { startingSection.section = section.section; }
  }

  let startingRecommendationStatementSection = {
    "title": "Recommendation Statement",
    "code": sectionCodeRecommendationStatement,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingRecommendationRatingsSection = {
    "title": "Recommendation Ratings",
    "code": sectionCodeRatings,
    "text": emptyTextNoData,
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingPopulationSection = {
    "title": "Population",
    "code": sectionCodePopulation,
    "text": emptyTextNoData,
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingActionSection = {
    "title": "Action",
    "code": sectionCodeAction,
    "text": emptyTextNoData,
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };
  let startingOppositeActionSection = {
    "title": "Opposite Action",
    "code": sectionCodeOppositeAction,
    "text": emptyTextNoData,
    "entry": [],
    "emptyReason": emptyReasonNotStarted
  };

  if (startingSection.section) {
    for (let subsection of startingSection.section) {
      let subsectionCode = subsection.code?.coding?.[0]?.code;
      if (subsectionCode === "population") {
        startingPopulationSection = subsection;
      } else if (subsectionCode === "recommendation-statement") {
        startingRecommendationStatementSection = subsection;
      } else if (subsectionCode === "ratings") {
        startingRecommendationRatingsSection = subsection;
      } else if (subsectionCode === "action") {
        startingActionSection = subsection;
      } else if (subsectionCode === "opposite-action") {
        startingOppositeActionSection = subsection;
      }
    }
  }

  let startingSubsections = {
    "recommendationStatement": startingRecommendationStatementSection,
    "recommendationRatings": startingRecommendationRatingsSection,
    "population": startingPopulationSection,
    "action": startingActionSection,
    "oppositeAction": startingOppositeActionSection
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [subsectionState, setSubsectionState] = useState(startingSubsections);
  const [populationState, setPopulationState] = useState(startingPopulationSection);
  const [recommendationStatementState, setRecommendationStatementState] = useState(startingRecommendationStatementSection);
  const [recommendationRatingsState, setRecommendationRatingsState] = useState(startingRecommendationRatingsSection);
  const [actionState, setActionState] = useState(startingActionSection);
  const [oppositeActionState, setOppositeActionState] = useState(startingOppositeActionSection);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(populationState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "population": newSection };
    });
  }, [populationState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(recommendationStatementState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "recommendationStatement": newSection };
    });
  }, [recommendationStatementState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(recommendationRatingsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "recommendationRatings": newSection };
    });
  }, [recommendationRatingsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(actionState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "action": newSection };
    });
  }, [actionState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(oppositeActionState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "oppositeAction": newSection };
    });
  }, [oppositeActionState]);

  useEffect(() => {
    setSectionState(prevState => {
      let newSection = [subsectionState.recommendationStatement, subsectionState.recommendationRatings,
      subsectionState.population, subsectionState.action, subsectionState.oppositeAction
      ];
      return { ...prevState, "section": newSection };
    });
  }, [subsectionState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code)) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];


  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 style={{ margin: "0px" }}>Recommendation Specification</h3>
      <div style={{ marginLeft: "24px" }}>
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
          {editAuthorCollapsedState ? <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </> : <>
            <b>Collapse Section Author Data Entry ▼</b>
          </>}
        </span>
        <br />
        {!editAuthorCollapsedState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author'
            fieldLabel='Section Author'
            startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            startCollapsed startEmptyArrayClosed
            setResourceState={setSectionState} />
        </>
        }
      </div>
      <h3 style={{ margin: "0px" }}>Recommendation Statement</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Recommendation Statement Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={recommendationStatementState.text} setResourceState={setRecommendationStatementState}
        />
        {((!recommendationStatementState.text || recommendationStatementState.text.status === "empty")) && <>
          <h3>Explain why empty</h3>
          <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
              startingValue={recommendationStatementState.emptyReason}
              startCollapsed={true}
              codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              setResourceState={setRecommendationStatementState} />
          </div>
        </>}
      </div>
      <h3 style={{ margin: "0px" }}>Recommendation Ratings</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Recommendation Ratings Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={recommendationRatingsState.text} setResourceState={setRecommendationRatingsState}
        />
        <h3>Create or identify the ArtifactAssessment Resource(s) for the Recommendation Ratings.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ArtifactAssessment Resource'}
            startingValue={recommendationRatingsState.entry} referencedResourceTypes={['ArtifactAssessment']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"ArtifactAssessment"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setRecommendationRatingsState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!recommendationRatingsState.entry || recommendationRatingsState.entry.length === 0) &&
          (!recommendationRatingsState.text || recommendationRatingsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={recommendationRatingsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setRecommendationRatingsState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Population</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Population Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          generateSummaryFunction={generateGroupSummary}
          entryFoi={getFoiFromReference(populationState.entry)}
          sectionEntry={populationState.entry}
          resourceType="Group"
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          startingValue={populationState.text} setResourceState={setPopulationState}
        />
        <h3>Create or identify the Group Resource for the Population.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'Group Resource'}
            startingValue={populationState.entry} referencedResourceTypes={['Group']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            startingResourceType={"Group"}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setPopulationState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!populationState.section || populationState.section.length === 0) &&
          (!populationState.entry || populationState.entry.length === 0) &&
          (!populationState.text || populationState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={populationState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setPopulationState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Action Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={actionState.text} setResourceState={setActionState}
        />
        <h3>Create or identify the ActivityDefinition or EvidenceVariable Resource.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ActivityDefinition or EvidenceVariable Resource'}
            startingValue={actionState.entry} referencedResourceTypes={['ActivityDefinition', 'EvidenceVariable']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setActionState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!actionState.entry || actionState.entry.length === 0) &&
          (!actionState.text || actionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={actionState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setActionState} />
            </div>
          </>}
      </div>
      <h3 style={{ margin: "0px" }}>Opposite Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Opposite Action Section Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={oppositeActionState.text} setResourceState={setOppositeActionState}
        />
        <h3>Create or identify the ActivityDefinition or EvidenceVariable Resource.</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='Reference' elementName='entry'
            fieldLabel={'ActivityDefinition or EvidenceVariable Resource'}
            startingValue={oppositeActionState.entry} referencedResourceTypes={['ActivityDefinition', 'EvidenceVariable']}
            startCollapsed={true}
            deletableArray={true}
            deletable={true}
            enableCreation={true}
            addElementValues={{
              relatedArtifact: [
                {
                  "type": "part-of",
                  "resourceReference": {
                    "reference": "Composition/" + resourceId,
                    "type": "Composition",
                    "display": resourceState.title || resourceState.name
                  }
                }
              ]
            }}
            setResourceState={setOppositeActionState} fullResourceState={resourceState}
            globalContext={globalContext} setSourceJsonState={setSourceJsonState} />
        </div>
        {((!oppositeActionState.entry || oppositeActionState.entry.length === 0) &&
          (!oppositeActionState.text || oppositeActionState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={oppositeActionState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setOppositeActionState} />
            </div>
          </>}
      </div>
    </div>
  </div>
}

const RecommendationConsiderationsEdit = ({ section, formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  resourceState, setResourceState, sourceJsonState, setSourceJsonState }) => {
  const globalContext = useContext(FevirContext);
  let resourceDictionary = sourceJsonState.resourceDictionary;
  let sectionPathIndexes = fhirEntryState.sectionPathIndexes;

  let startingSection = {
    title: "Considerations", code: sectionCodeConsiderations, 
    author: [], section: [], sectionPathIndexes: sectionPathIndexes
  }
  if (!section) {
    section = "";
  } else {
    if (section.extension) { startingSection.extension = section.extension; }
    if (section.title) { startingSection.title = section.title; }
    if (section.code) { startingSection.code = section.code; }
    if (section.author) { startingSection.author = section.author; }
    if (section.focus) { startingSection.focus = section.focus; }
    if (section.text) { startingSection.text = section.text; }
    if (section.orderedBy) { startingSection.orderedBy = section.orderedBy; }
    if (section.entry) { startingSection.entry = section.entry; }
    if (section.section) { startingSection.section = section.section; }
  }

  let startingSummarySection = {
    "title": "Overall Considerations Summary",
    "code": sectionCodeConsiderationsSummary,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingSubgroupConsiderationsSection = {
    "title": "Subgroup Considerations",
    "code": sectionCodeSubgroupConsiderations,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingImplementationConsiderationsSection = {
    "title": "Implementation Considerations",
    "code": sectionCodeImplementationConsiderations,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingMonitoringConsiderationsSection = {
    "title": "Monitoring Considerations",
    "code": sectionCodeMonitoringConsiderations,
    "text":emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingResearchConsiderationsSection = {
    "title": "Research Considerations",
    "code": sectionCodeResearchConsiderations,
    "text": emptyTextNoData,
    "emptyReason": emptyReasonNotStarted
  };
  let startingAdditionalSections = [];

  if (startingSection.section) {
    for (let subsection of startingSection.section) {
      let subsectionCode = subsection.code?.coding?.[0]?.code;
      if (subsectionCode === "summary") {
        startingSummarySection = subsection;
      } else if (subsectionCode === "subgroup-considerations") {
        startingSubgroupConsiderationsSection = subsection;
      } else if (subsectionCode === "implementation-considerations") {
        startingImplementationConsiderationsSection = subsection;
      } else if (subsectionCode === "monitoring-considerations") {
        startingMonitoringConsiderationsSection = subsection;
      } else if (subsectionCode === "research-considerations") {
        startingResearchConsiderationsSection = subsection;
      } else {
        startingAdditionalSections.push(subsection);
      }
    }
  }

  let startingSubsections = {
    "summaryConsiderations": startingSummarySection,
    "subgroupConsiderations": startingSubgroupConsiderationsSection,
    "implementationConsiderations": startingImplementationConsiderationsSection,
    "monitoringConsiderations": startingMonitoringConsiderationsSection,
    "researchConsiderations": startingResearchConsiderationsSection,
    "additionalSections": startingAdditionalSections
  }

  const [sectionState, setSectionState] = useState(startingSection);
  const [subsectionState, setSubsectionState] = useState(startingSubsections);
  const [summaryConsiderationsState, setSummaryConsiderationsState] = useState(startingSummarySection);
  const [subgroupConsiderationsState, setSubgroupConsiderationsState] = useState(startingSubgroupConsiderationsSection);
  const [implementationConsiderationsState, setImplementationConsiderationsState] = useState(startingImplementationConsiderationsSection);
  const [monitoringConsiderationsState, setMonitoringConsiderationsState] = useState(startingMonitoringConsiderationsSection);
  const [researchConsiderationsState, setResearchConsiderationsState] = useState(startingResearchConsiderationsSection);
  const [editAuthorCollapsedState, setEditAuthorCollapsedState] = useState(true);
  const [addedSectionState, setAddedSectionState] = useState({ "title": "", "textDiv": "" });

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(summaryConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "summaryConsiderations": newSection };
    });
  }, [summaryConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(subgroupConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "subgroupConsiderations": newSection };
    });
  }, [subgroupConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(implementationConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "implementationConsiderations": newSection };
    });
  }, [implementationConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(monitoringConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "monitoringConsiderations": newSection };
    });
  }, [monitoringConsiderationsState]);

  useEffect(() => {
    setSubsectionState(prevState => {
      let newSection = JSON.parse(JSON.stringify(researchConsiderationsState));
      newSection = autoEditEmptyReason(newSection);
      return { ...prevState, "researchConsiderations": newSection };
    });
  }, [researchConsiderationsState]);

  useEffect(() => {
    if (addedSectionState.addSection && addedSectionState.title) {
      let sectionToBeAdded = { "title": addedSectionState.title };
      if (addedSectionState.textDiv) {
        sectionToBeAdded.text = {
          "status": "additional",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + addedSectionState.textDiv + "</div>"
        }
      }
      setSubsectionState(prevState => {
        let newSection = prevState.additionalSections || [];
        newSection.push(sectionToBeAdded);
        return { ...prevState, "section": newSection };
      });
      setAddedSectionState({ "title": "", "textDiv": "" });
    }
  }, [addedSectionState]);

  useEffect(() => {
    setSectionState(prevState => {
      let newSection = [subsectionState.summaryConsiderations, subsectionState.subgroupConsiderations,
      subsectionState.implementationConsiderations, subsectionState.monitoringConsiderations,
      subsectionState.researchConsiderations
      ].concat(subsectionState.additionalSections);
      return { ...prevState, "section": newSection };
    });
  }, [subsectionState]);

  useEffect((() => {
    if (Object.keys(sectionState).length > 0) {
      if (JSON.stringify(sectionState.text) !== JSON.stringify(startingSection.text) ||
        sectionState.title !== startingSection.title ||
        JSON.stringify(sectionState.section) !== JSON.stringify(startingSection.section) ||
        JSON.stringify(sectionState.code) !== JSON.stringify(startingSection.code)) {
        setFhirEntryState(prevState => {
          return { ...prevState, sectionChanged: true }
        });
      }
    }
  }), [sectionState]);

  useImperativeHandle(formInputsStateRef, () => ({
    sectionState,
  }), [sectionState]);

  let authorReferencedResourceTypes = ['Practitioner', 'PractitionerRole', 'Device', 'Patient', 'RelatedPerson', 'Organization'];

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 style={{ margin: "0px" }}>Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <span className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => { setEditAuthorCollapsedState(!editAuthorCollapsedState) }}>
          {editAuthorCollapsedState ? <>
            <b>Add/Edit Author(s) for the Section ►</b>
          </> : <>
            <b>Collapse Section Author Data Entry ▼</b>
          </>}
        </span>
        <br />
        {!editAuthorCollapsedState && <>
          <DataEntry asArray={true} datatype='Reference' elementName='author'
            fieldLabel='Section Author'
            startingValue={sectionState.author} referencedResourceTypes={authorReferencedResourceTypes}
            startCollapsed startEmptyArrayClosed
            setResourceState={setSectionState} />
        </>
        }
      </div>
      <h3 style={{ margin: "0px" }}>Overall Considerations Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Overall Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={summaryConsiderationsState.text} setResourceState={setSummaryConsiderationsState}
        />
        {((!summaryConsiderationsState.section || summaryConsiderationsState.section.length === 0) &&
          (!summaryConsiderationsState.text || summaryConsiderationsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={summaryConsiderationsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setSummaryConsiderationsState} />
            </div>
          </>}
        <div style={{ marginLeft: "24px" }}>
          <p><b>Contained Sections (for text with subheadings):</b></p>
          {(Array.isArray(summaryConsiderationsState.section) && summaryConsiderationsState.section.length > 0) &&
            summaryConsiderationsState.section.map((subsection, subsectionIndex) => {
              let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
              let sectionTextDiv = subsection.text?.div;
              let sectionTextStatus = subsection.text?.status;
              return <span key={subsectionIndex}>
                <p><b>Section: </b>{sectionName}</p>
                {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                  <p>Narrative status: {sectionTextStatus}</p>
                  <p>Narrative summary:</p>
                  <DisplayFromFHIR xhtml={sectionTextDiv} />
                </div>}
              </span>;
            })}
          <div>
            <p>Use Section Detail for this section to add additional subsections.</p>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Subgroup Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Subgroup Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={subgroupConsiderationsState.text} setResourceState={setSubgroupConsiderationsState}
        />
        {((!subgroupConsiderationsState.section || subgroupConsiderationsState.section.length === 0) &&
          (!subgroupConsiderationsState.text || subgroupConsiderationsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={subgroupConsiderationsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setSubgroupConsiderationsState} />
            </div>
          </>}
        <div style={{ marginLeft: "24px" }}>
          <p><b>Contained Sections (for text with subheadings):</b></p>
          {(Array.isArray(subgroupConsiderationsState.section) && subgroupConsiderationsState.section.length > 0) &&
            subgroupConsiderationsState.section.map((subsection, subsectionIndex) => {
              let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
              let sectionTextDiv = subsection.text?.div;
              let sectionTextStatus = subsection.text?.status;
              return <span key={subsectionIndex}>
                <p><b>Section: </b>{sectionName}</p>
                {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                  <p>Narrative status: {sectionTextStatus}</p>
                  <p>Narrative summary:</p>
                  <DisplayFromFHIR xhtml={sectionTextDiv} />
                </div>}
              </span>;
            })}
          <div>
            <p>Use Section Detail for this section to add additional subsections.</p>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Implementation Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Implementation Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={implementationConsiderationsState.text} setResourceState={setImplementationConsiderationsState}
        />
        {((!implementationConsiderationsState.section || implementationConsiderationsState.section.length === 0) &&
          (!implementationConsiderationsState.text || implementationConsiderationsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={implementationConsiderationsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setImplementationConsiderationsState} />
            </div>
          </>}
        <div style={{ marginLeft: "24px" }}>
          <p><b>Contained Sections (for text with subheadings):</b></p>
          {(Array.isArray(implementationConsiderationsState.section) && implementationConsiderationsState.section.length > 0) &&
            implementationConsiderationsState.section.map((subsection, subsectionIndex) => {
              let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
              let sectionTextDiv = subsection.text?.div;
              let sectionTextStatus = subsection.text?.status;
              return <span key={subsectionIndex}>
                <p><b>Section: </b>{sectionName}</p>
                {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                  <p>Narrative status: {sectionTextStatus}</p>
                  <p>Narrative summary:</p>
                  <DisplayFromFHIR xhtml={sectionTextDiv} />
                </div>}
              </span>;
            })}
          <div>
            <p>Use Section Detail for this section to add additional subsections.</p>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Monitoring Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Monitoring Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={monitoringConsiderationsState.text} setResourceState={setMonitoringConsiderationsState}
        />
        {((!monitoringConsiderationsState.section || monitoringConsiderationsState.section.length === 0) &&
          (!monitoringConsiderationsState.text || monitoringConsiderationsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={monitoringConsiderationsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setMonitoringConsiderationsState} />
            </div>
          </>}
        <div style={{ marginLeft: "24px" }}>
          <p><b>Contained Sections (for text with subheadings):</b></p>
          {(Array.isArray(monitoringConsiderationsState.section) && monitoringConsiderationsState.section.length > 0) &&
            monitoringConsiderationsState.section.map((subsection, subsectionIndex) => {
              let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
              let sectionTextDiv = subsection.text?.div;
              let sectionTextStatus = subsection.text?.status;
              return <span key={subsectionIndex}>
                <p><b>Section: </b>{sectionName}</p>
                {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                  <p>Narrative status: {sectionTextStatus}</p>
                  <p>Narrative summary:</p>
                  <DisplayFromFHIR xhtml={sectionTextDiv} />
                </div>}
              </span>;
            })}
          <div>
            <p>Use Section Detail for this section to add additional subsections.</p>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Research Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <NarrativeEntry elementName='text' fieldLabel='Research Considerations Summary'
          editTextStatus={false} editTextDiv={true} emptyTextDivValue={"[No data.]"}
          startingValue={researchConsiderationsState.text} setResourceState={setResearchConsiderationsState}
        />
        {((!researchConsiderationsState.section || researchConsiderationsState.section.length === 0) &&
          (!researchConsiderationsState.text || researchConsiderationsState.text.status === "empty")) && <>
            <h3>Explain why empty</h3>
            <div style={{ marginLeft: "24px" }}>
              <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
                startingValue={researchConsiderationsState.emptyReason}
                startCollapsed={true}
                codeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
                setResourceState={setResearchConsiderationsState} />
            </div>
          </>}
        <div style={{ marginLeft: "24px" }}>
          <p><b>Contained Sections (for text with subheadings):</b></p>
          {(Array.isArray(researchConsiderationsState.section) && researchConsiderationsState.section.length > 0) &&
            researchConsiderationsState.section.map((subsection, subsectionIndex) => {
              let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
              let sectionTextDiv = subsection.text?.div;
              let sectionTextStatus = subsection.text?.status;
              return <span key={subsectionIndex}>
                <p><b>Section: </b>{sectionName}</p>
                {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                  <p>Narrative status: {sectionTextStatus}</p>
                  <p>Narrative summary:</p>
                  <DisplayFromFHIR xhtml={sectionTextDiv} />
                </div>}
              </span>;
            })}
          <div>
            <p>Use Section Detail for this section to add additional subsections.</p>
          </div>
        </div>
      </div>
      <h3 style={{ margin: "0px" }}>Additional Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Contained Sections (for text with subheadings):</b></p>
        {(Array.isArray(subsectionState.additionalSections) && subsectionState.additionalSections.length > 0) &&
          subsectionState.additionalSections.map((subsection, subsectionIndex) => {
            let sectionName = subsection.title || subsection.code?.text || subsection.code?.coding?.[0]?.display || subsection.code?.coding?.[0]?.code;
            let sectionTextDiv = subsection.text?.div;
            let sectionTextStatus = subsection.text?.status;
            return <span key={subsectionIndex}>
              <p><b>Section: </b>{sectionName}</p>
              {sectionTextDiv && <div style={{ marginLeft: "24px" }} >
                <p>Narrative status: {sectionTextStatus}</p>
                <p>Narrative summary:</p>
                <DisplayFromFHIR xhtml={sectionTextDiv} />
              </div>}
            </span>;
          })}
        <div>
          <p>Enter a title then click Add Section.</p>
          <DataEntry datatype='string' elementName='title' fieldLabel='Title'
            startingValue={addedSectionState.title}
            setResourceState={setAddedSectionState} />
          <DataEntry datatype="xhtml" elementName='textDiv' fieldLabel='Narrative Summary'
            startingValue={addedSectionState.textDiv}
            setResourceState={setAddedSectionState} />
          <Button className="formButton" style={{ color: "#000000", width: "100%" }}
            content={"Add Section"}
            onClick={() => {
              setAddedSectionState(prevState => { return { ...prevState, addSection: true }; });
            }}
            disabled={!addedSectionState.title} />
        </div>
      </div>
    </div>
  </div>
}

const changeSectionDetailEditSettingsForRecommendation = (sectionDetailEditSettings, section) => {

  if (section.code?.coding?.[0]?.code === "summary" || section.code?.text === "Summary") {
    sectionDetailEditSettings.fixedTitle = "Summary";
    sectionDetailEditSettings.editCode = false;
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "recommendation-specification") {
    sectionDetailEditSettings.RecommendationSpecificationEdit = RecommendationSpecificationEdit;
  }
  if (section.code?.coding?.[0]?.code === "recommendation-statement") {
    sectionDetailEditSettings.fixedTitle = "Recommendation Statement";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "ratings") {
    sectionDetailEditSettings.fixedTitle = "Ratings (Strength/Level) of Recommendation";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment Resource (RecommendationJustification Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment"];
    sectionDetailEditSettings.entryStartingResourceType = "ArtifactAssessment";
    sectionDetailEditSettings.entrySetProfile = "RecommendationJustification";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "population") {
    sectionDetailEditSettings.fixedTitle = "Population";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateTextDivFunction = generateGroupSummary;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add Group Resource (RecommendationEligibilityCriteria Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Group"];
    sectionDetailEditSettings.entryStartingResourceType = "Group";
    sectionDetailEditSettings.entrySetProfile = "RecommendationEligibilityCriteria";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "action") {
    sectionDetailEditSettings.fixedTitle = "Action";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ActivityDefinition or EvidenceVariable Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ActivityDefinition", "EvidenceVariable"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "opposite-action") {
    sectionDetailEditSettings.fixedTitle = "Opposite Action";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ActivityDefinition or EvidenceVariable Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ActivityDefinition", "EvidenceVariable"];
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.noSection = true;
  }
  if (section.code?.coding?.[0]?.code === "justification") {
    sectionDetailEditSettings.fixedTitle = "Justification";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.generateNarrativeFromSingleEntryFunction = generateRecommendationJustificationNarrative;
    sectionDetailEditSettings.entryFoi = getFoiFromReference(section.entry);
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment Resource (RecommendationJustification Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment"];
    sectionDetailEditSettings.entryStartingResourceType = "ArtifactAssessment";
    sectionDetailEditSettings.entrySetProfile = "RecommendationJustification";
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "considerations") {
    sectionDetailEditSettings.RecommendationConsiderationsEdit = RecommendationConsiderationsEdit;
  }
  if (section.code?.coding?.[0]?.code === "summary" && section.code.text === "Considerations Summary") {
    sectionDetailEditSettings.fixedTitle = "Overall Considerations Summary";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "subgroup-considerations") {
    sectionDetailEditSettings.fixedTitle = "Subgroup Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "implementation-considerations") {
    sectionDetailEditSettings.fixedTitle = "Implementation Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "monitoring-considerations") {
    sectionDetailEditSettings.fixedTitle = "Monitoring Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "research-considerations") {
    sectionDetailEditSettings.fixedTitle = "Research Considerations";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = false;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = false;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }
  if (section.code?.coding?.[0]?.code === "evidence") {
    sectionDetailEditSettings.fixedTitle = "Evidence";
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add ArtifactAssessment, Citation, Composition, or Evidence Resource";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["ArtifactAssessment", "Citation", "Composition", "Evidence"];
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.addSectionAllowed = true;
  }

  return sectionDetailEditSettings;
}

export { RecommendationAuthor, changeSectionDetailEditSettingsForRecommendation };