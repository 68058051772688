import React from 'react';
import { Link } from "react-router-dom";

const ActiveHEvKAProjects = ({}) => {

  return <div style={{ border: "2px solid #BBBBBB", borderRadius: "24px", backgroundColor: "#E7F7F0", padding: "10px 10px 10px 20px" }}>
    <table className={"HomePageNavigationTable"}>
      <tbody>
        <tr><td><h2>Active HEvKA Projects</h2></td></tr>
        <tr><td><Link to="/resources/Project/29681"><h3>Apollo Accelerator Priority List</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/32445"><h3>Bariatric Surgery Decision Example of FHIR Resources</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/292824"><h3>Eligibility Criteria Examples</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/29736"><h3>Evidence Based Medicine (EBM) Implementation Guide</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/108857"><h3>Formula Building with KaTeX</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/111563"><h3>GRADE Ontology</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/264325"><h3>Making Guidelines Computable</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/206346"><h3>Measuring the Rate of Scientific Knowledge Transfer</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/282860"><h3>Research Study Data Dictionary</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/27845"><h3>Scientific Evidence Code System (SEVCO)</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/139769"><h3>Setting the Scientific Record on FHIR</h3></Link></td></tr>
        <tr><td><Link to="/resources/Project/120066"><h3>StatisticsOnFHIR</h3></Link></td></tr>
      </tbody>
    </table>
  </div>
};

export default ActiveHEvKAProjects;