import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import "firebase/compat/auth";
import { getResource } from "./UserAccountFunctions";
import ResourceDisplayContent from './ResourceDisplayContent';
import { sendFli } from './ResourceFunctions';
import './App.css';
import FevirContext from './FevirContext';

const ResourceDisplayPage = ( { useTitle, resourceType, startEditMode, resourceId, formState, 
  setFormState, changeFormState, firebase, indexPath, pageEditModeState, setPageEditModeState, 
  urlFli, unsureFli, jsonView, viewMyWorkModalOpen } ) => {

  const [resourceEntryState, setResourceEntryState] = useState({
    found: null, 
    jsonView: jsonView, 
    viewMyWorkModalOpen: viewMyWorkModalOpen
  });
  const globalContext = useContext(FevirContext);

  const history = useHistory();

  let pageTitle = "FEvIR";
  if (formState["pageTitle"]) {
    pageTitle = "FEvIR " + formState["pageTitle"].replace("FEvIR®: ", "").replace("Computable Publishing®: ", "").replace("Risk of Bias Assessment Reader", "RoBAR").replace("Risk of Bias Assessment Tool", "RoBAT").replace(" Viewer", "").replace(" Builder", "");
  }
  
  if (resourceEntryState["title"]) {
    pageTitle += " - " + resourceEntryState["title"];
  } else if (resourceEntryState["name"]) {
    pageTitle += " - " + resourceEntryState["name"];
  }
  
  if (pageTitle === "FEvIR") {
    pageTitle = "FEvIR Resource Viewer";
  }
  
  useTitle(pageTitle);

  const loadResource = async () => {
    if (globalContext.userState.loading === false) {
      //FOR SOME REASON the USERSTATE is SET TWICE, FIGURE OUT WHERE IT'S HAPPENING
      //2022-12-30 note from Brian -- try removing the 'userState' from the last argument and
      // leaving only '[]' in line ??61 -- OR REMOVING ', [userState]' ALTOGETHER
      //console.log("-----------------------");
      //console.log(JSON.stringify(userState, null, 2));
      if (resourceId) {
        let resourceEntry = await getResource(resourceId, resourceType, globalContext.userState.idToken);
        if (resourceEntry) {
          resourceEntry.startEditMode = startEditMode;
          if (resourceEntry !== resourceEntryState) {
            resourceEntry['jsonView'] = jsonView;
            resourceEntry['viewMyWorkModalOpen'] =  viewMyWorkModalOpen;
            setResourceEntryState(() => { return resourceEntry; });
          }
          if ((resourceEntry["resourceType"] && resourceEntry["resourceType"] !== resourceType) || startEditMode) {
            history.replace(`/resources/${resourceEntry["resourceType"]}/${resourceId}`);
          }
        } else {
          setResourceEntryState((prevState) =>  { return { ...prevState, 'found': false, 'jsonView': jsonView, 'viewMyWorkModalOpen': viewMyWorkModalOpen } });
        }
        if (viewMyWorkModalOpen || jsonView) {
          if (resourceType && resourceId) {
            history.replace(`/resources/${resourceType}/${resourceId}`);
          } else if (resourceId) {
            history.replace(`/resources/${resourceId}`);
          }
        }
      } else if (urlFli) {
        let fliredirect = false;
        let hideAlert = unsureFli || false;
        let foiAndType = await sendFli(urlFli, globalContext, undefined, hideAlert);
        if (foiAndType) {
          let foi = foiAndType[0];
          let foiResourceType = foiAndType[1];
          if (foi) {
            fliredirect = true;
            let resourceEntry = await getResource(foi, foiResourceType, globalContext.userState.idToken);
            if (resourceEntry !== resourceEntryState) {
              setResourceEntryState(() => { return resourceEntry; });
            }
            let redirectURL = "/resources/"+foiResourceType+"/"+foi;
            history.replace(redirectURL);
            //return <Redirect from={redirectURL} to={redirectURL} /> 
          }
        }
        if (fliredirect === false) {
          if (unsureFli) {
            history.replace("/pagenotfound");
          } else {
            history.replace("/flitofoi/"+urlFli);
          }
        }
      }
    }
  };
  
  useEffect(() => {
    loadResource();
  }, [globalContext.userState]);

  if (resourceEntryState.resourceType) {
    return <ResourceDisplayContent history={history} resourceEntryState={resourceEntryState} 
    pageEditModeState={pageEditModeState} setPageEditModeState={setPageEditModeState} 
    claimedResourceType={resourceType} resourceType={resourceEntryState.resourceType} resourceTypeURL={resourceType} resourceId={resourceId} 
    firebase={firebase} getResource={getResource} formState={formState} setFormState={setFormState}
    changeFormState={changeFormState} indexPath={indexPath} jsonView={jsonView}/>
  } else {
    return <ResourceDisplayContent history={history} resourceEntryState={resourceEntryState} 
    pageEditModeState={pageEditModeState} setPageEditModeState={setPageEditModeState} 
    claimedResourceType={resourceType} resourceType={""} resourceTypeURL={resourceType} resourceId={resourceId} 
    firebase={firebase} getResource={getResource} formState={formState} 
    setFormState={setFormState} changeFormState={changeFormState} indexPath={indexPath} jsonView={jsonView} />
  }
    /*    <Dimmer className={"loadingDimmer"} active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>  */
};

export default ResourceDisplayPage;